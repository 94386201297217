/* SASS variables and intellisense */
/*---------------------------------------------------------------------------
                          SYMPTOM TYPE BUTTON 
---------------------------------------------------------------------------*/
.symptom-card {
  padding: 10px 16px 12px;
  margin-bottom: 15px;
  margin-top: 15px; }
  .theme-default .symptom-card {
    border-radius: 0; }
  .theme-helsa .symptom-card {
    border-radius: 0; }
  .theme-ptj .symptom-card {
    border-radius: 0px; }
  .theme-rjl .symptom-card {
    border-radius: 0; }
  .theme-achima .symptom-card {
    border-radius: 10px; }
  .theme-blekinge .symptom-card {
    border-radius: 0; }
  .theme-sll .symptom-card {
    border-radius: 0; }
  .theme-halland .symptom-card {
    border-radius: 0; }
  .theme-orebro .symptom-card {
    border-radius: 5px; }
  .theme-rg .symptom-card {
    border-radius: 0px; }

.theme-default .symptom-card-primary {
  border: 1px solid #A0F0EB;
  border-bottom: 3px solid #A0F0EB;
  background-color: #D0F8F5;
  color: #0A4A7C; }

.theme-helsa .symptom-card-primary {
  border: 1px solid #5b9c6c;
  border-bottom: 2px solid #5b9c6c;
  background-color: #6CB680;
  color: #FFFFFF; }

.theme-ptj .symptom-card-primary {
  border: 1px solid #0075B0;
  border-bottom: 3px solid #0075B0;
  background-color: #00A0C8;
  color: #ffffff; }

.theme-rjl .symptom-card-primary {
  border: 1px solid #8cc63f;
  border-bottom: 1px solid #8cc63f;
  background-color: #8cc63f;
  color: #FFF; }

.theme-achima .symptom-card-primary {
  border: 1px solid #880000;
  border-bottom: 3px solid #880000;
  background-color: #AA2B23;
  color: #fff; }

.theme-blekinge .symptom-card-primary {
  border: 1px solid #14325E;
  border-bottom: 1px solid #14325E;
  background-color: #193F76;
  color: #fff; }

.theme-sll .symptom-card-primary {
  border: 1px solid #006FB4;
  border-bottom: 3px solid #006FB4;
  background-color: #00aeef;
  color: #fff; }

.theme-halland .symptom-card-primary {
  border: 1px solid #2F7CAC;
  border-bottom: 3px solid #2F7CAC;
  background-color: #7BB1DB;
  color: #fff; }

.theme-orebro .symptom-card-primary {
  border: 1px solid #004F9F;
  border-bottom: 3px solid #004F9F;
  background-color: #4F80FF;
  color: #fff; }

.theme-rg .symptom-card-primary {
  border: 0px solid #26BDF5;
  border-bottom: 0px solid #26BDF5;
  background-color: #0097cf;
  color: #fff; }

.theme-default .symptom-card-primary:hover, .theme-default .symptom-card-primary:active {
  border: 1px solid #09385c;
  border-bottom: 3px solid #09385c;
  background-color: #0A4A7C;
  color: #FFFFFF; }

.theme-helsa .symptom-card-primary:hover, .theme-helsa .symptom-card-primary:active {
  border: 1px solid #044557;
  border-bottom: 2px solid #044557;
  background-color: #1D5E74;
  color: #FFFFFF; }

.theme-ptj .symptom-card-primary:hover, .theme-ptj .symptom-card-primary:active {
  border: 1px solid #0f5274;
  border-bottom: 3px solid #0f5274;
  background-color: #0075B0;
  color: #FFFFFF; }

.theme-rjl .symptom-card-primary:hover, .theme-rjl .symptom-card-primary:active {
  border: 1px solid #579835;
  border-bottom: 1px solid #579835;
  background-color: #579835;
  color: #FFFFFF; }

.theme-achima .symptom-card-primary:hover, .theme-achima .symptom-card-primary:active {
  border: 1px solid #000000;
  border-bottom: 3px solid #000000;
  background-color: #880000;
  color: #FFFFFF; }

.theme-blekinge .symptom-card-primary:hover, .theme-blekinge .symptom-card-primary:active {
  border: 1px solid #26426A;
  border-bottom: 1px solid #26426A;
  background-color: #14325E;
  color: #FFFFFF; }

.theme-sll .symptom-card-primary:hover, .theme-sll .symptom-card-primary:active {
  border: 1px solid #000000;
  border-bottom: 3px solid #000000;
  background-color: #002d5a;
  color: #FFFFFF; }

.theme-halland .symptom-card-primary:hover, .theme-halland .symptom-card-primary:active {
  border: 1px solid #000000;
  border-bottom: 3px solid #000000;
  background-color: #014C93;
  color: #FFFFFF; }

.theme-orebro .symptom-card-primary:hover, .theme-orebro .symptom-card-primary:active {
  border: 1px solid #000000;
  border-bottom: 3px solid #000000;
  background-color: #0b1d32;
  color: #FFFFFF; }

.theme-rg .symptom-card-primary:hover, .theme-rg .symptom-card-primary:active {
  border: 0px solid #26BDF5;
  border-bottom: 0px solid #26BDF5;
  background-color: #26BDF5;
  color: #FFFFFF; }

.theme-default .symptom-card-danger {
  border: 1px solid #c81e1e;
  border-bottom: 3px solid #c81e1e;
  background-color: #E32424;
  color: #fff; }

.theme-helsa .symptom-card-danger {
  border: 1px solid #d06b60;
  border-bottom: 2px solid #d06b60;
  background-color: #F48174;
  color: #fff; }

.theme-ptj .symptom-card-danger {
  border: 1px solid #d92a3f;
  border-bottom: 3px solid #d92a3f;
  background-color: #F54359;
  color: #fff; }

.theme-rjl .symptom-card-danger {
  border: 1px solid #b1063a;
  border-bottom: 1px solid #b1063a;
  background-color: #ce1141;
  color: #fff; }

.theme-achima .symptom-card-danger {
  border: 1px solid #880000;
  border-bottom: 3px solid #880000;
  background-color: #AA2B23;
  color: #fff; }

.theme-blekinge .symptom-card-danger {
  border: 1px solid #AC0366;
  border-bottom: 1px solid #AC0366;
  background-color: #d70480;
  color: #fff; }

.theme-sll .symptom-card-danger {
  border: 1px solid #9a0932;
  border-bottom: 3px solid #9a0932;
  background-color: #9a0932;
  color: #fff; }

.theme-halland .symptom-card-danger {
  border: 1px solid #7c0000;
  border-bottom: 3px solid #7c0000;
  background-color: #8E0000;
  color: #fff; }

.theme-orebro .symptom-card-danger {
  border: 1px solid #c81e1e;
  border-bottom: 3px solid #c81e1e;
  background-color: #E32424;
  color: #fff; }

.theme-rg .symptom-card-danger {
  border: 0px solid #f588b2;
  border-bottom: 0px solid #f588b2;
  background-color: #ee3780;
  color: #fff; }

.theme-default .symptom-card-danger:hover {
  border: 1px solid #E32424;
  border-bottom: 3px solid #E32424;
  background-color: #fff;
  color: #E32424; }

.theme-helsa .symptom-card-danger:hover {
  border: 1px solid #F48174;
  border-bottom: 2px solid #F48174;
  background-color: #fff;
  color: #F48174; }

.theme-ptj .symptom-card-danger:hover {
  border: 1px solid #F54359;
  border-bottom: 3px solid #F54359;
  background-color: #fff;
  color: #F54359; }

.theme-rjl .symptom-card-danger:hover {
  border: 1px solid #ce1141;
  border-bottom: 1px solid #ce1141;
  background-color: #fff;
  color: #ce1141; }

.theme-achima .symptom-card-danger:hover {
  border: 1px solid #AA2B23;
  border-bottom: 3px solid #AA2B23;
  background-color: #fff;
  color: #AA2B23; }

.theme-blekinge .symptom-card-danger:hover {
  border: 1px solid #d70480;
  border-bottom: 1px solid #d70480;
  background-color: #fff;
  color: #d70480; }

.theme-sll .symptom-card-danger:hover {
  border: 1px solid #9a0932;
  border-bottom: 3px solid #9a0932;
  background-color: #fff;
  color: #9a0932; }

.theme-halland .symptom-card-danger:hover {
  border: 1px solid #8E0000;
  border-bottom: 3px solid #8E0000;
  background-color: #fff;
  color: #8E0000; }

.theme-orebro .symptom-card-danger:hover {
  border: 1px solid #E32424;
  border-bottom: 3px solid #E32424;
  background-color: #fff;
  color: #E32424; }

.theme-rg .symptom-card-danger:hover {
  border: 0px solid #ee3780;
  border-bottom: 0px solid #ee3780;
  background-color: #fff;
  color: #ee3780; }

.arrow-card {
  right: 26px;
  position: absolute; }
  .theme-default .arrow-card {
    opacity: 1; }
  .theme-helsa .arrow-card {
    opacity: 1; }
  .theme-ptj .arrow-card {
    opacity: 1; }
  .theme-rjl .arrow-card {
    opacity: 1; }
  .theme-achima .arrow-card {
    opacity: 1; }
  .theme-blekinge .arrow-card {
    opacity: 1; }
  .theme-sll .arrow-card {
    opacity: 1; }
  .theme-halland .arrow-card {
    opacity: 1; }
  .theme-orebro .arrow-card {
    opacity: 1; }
  .theme-rg .arrow-card {
    opacity: 1; }

/* Tiny screens sizes */
@media screen and (max-width: 543px) {
  .symptom-card-title {
    font-size: 14px;
    font-weight: 600; }
  .symptom-card {
    height: 100px;
    padding: 10px; }
  .arrow-card {
    margin-top: 56px;
    height: 18px; }
  .symptom-card.symptom-card-thin {
    height: auto; }
  .symptom-card-thin .arrow-card {
    margin-top: 0; } }

/* Small screens sizes */
@media screen and (min-width: 544px) and (max-width: 767px) {
  .symptom-card-title {
    font-size: 18px; }
  .symptom-card {
    height: 100px; }
  .arrow-card {
    margin-top: 46px;
    height: 24px; }
  .symptom-card.symptom-card-thin {
    height: auto; }
  .symptom-card-thin .arrow-card {
    margin-top: 0; } }

/* Medium screens sizes */
@media screen and (min-width: 768px) and (max-width: 1200px) {
  .symptom-card-title {
    font-size: 18px; }
  .symptom-card {
    height: 110px; }
  .arrow-card {
    margin-top: 42px;
    height: 36px; }
  .symptom-card.symptom-card-thin {
    height: auto; }
  .symptom-card-thin .arrow-card {
    margin-top: 0; } }

/* Bigger screens sizes */
@media screen and (min-width: 1200px) {
  .symptom-card-title {
    font-size: 22px; }
  .symptom-card {
    height: 130px; }
  .arrow-card {
    margin-top: 62px;
    height: 36px; }
  .symptom-card.symptom-card-thin {
    height: auto; }
  .symptom-card-thin .arrow-card {
    margin-top: 0; } }

.card-block {
  padding: 10px 16px 12px; }

/*---------------------------------------------------------------------------
                          START MENU BUTTON
---------------------------------------------------------------------------*/
.startMenuDiv {
  width: 100%;
  padding: 10px;
  text-align: left; }
  .theme-default .startMenuDiv {
    border-radius: 0;
    background-color: #D0F8F5;
    border: 1px solid #A0F0EB;
    border-bottom: 3px solid #A0F0EB; }
  .theme-helsa .startMenuDiv {
    border-radius: 0;
    background-color: #6CB680;
    border: 1px solid #5b9c6c;
    border-bottom: 2px solid #5b9c6c; }
  .theme-ptj .startMenuDiv {
    border-radius: 0px;
    background-color: #00A0C8;
    border: 1px solid #0075B0;
    border-bottom: 3px solid #0075B0; }
  .theme-rjl .startMenuDiv {
    border-radius: 0;
    background-color: #8cc63f;
    border: 1px solid #8cc63f;
    border-bottom: 1px solid #8cc63f; }
  .theme-achima .startMenuDiv {
    border-radius: 10px;
    background-color: #AA2B23;
    border: 1px solid #880000;
    border-bottom: 3px solid #880000; }
  .theme-blekinge .startMenuDiv {
    border-radius: 0;
    background-color: #193F76;
    border: 1px solid #14325E;
    border-bottom: 1px solid #14325E; }
  .theme-sll .startMenuDiv {
    border-radius: 0;
    background-color: #00aeef;
    border: 1px solid #006FB4;
    border-bottom: 3px solid #006FB4; }
  .theme-halland .startMenuDiv {
    border-radius: 0;
    background-color: #7BB1DB;
    border: 1px solid #2F7CAC;
    border-bottom: 3px solid #2F7CAC; }
  .theme-orebro .startMenuDiv {
    border-radius: 5px;
    background-color: #4F80FF;
    border: 1px solid #004F9F;
    border-bottom: 3px solid #004F9F; }
  .theme-rg .startMenuDiv {
    border-radius: 0px;
    background-color: #0097cf;
    border: 0px solid #26BDF5;
    border-bottom: 0px solid #26BDF5; }

.startBtnMargin {
  margin-bottom: 30px; }

@media screen and (max-width: 767px) {
  .startBtnMargin {
    margin-bottom: 15px; } }

.startMenuDiv p {
  position: absolute;
  top: 10px; }
  .theme-default .startMenuDiv p {
    color: #0A4A7C; }
  .theme-helsa .startMenuDiv p {
    color: #FFFFFF; }
  .theme-ptj .startMenuDiv p {
    color: #ffffff; }
  .theme-rjl .startMenuDiv p {
    color: #FFF; }
  .theme-achima .startMenuDiv p {
    color: #fff; }
  .theme-blekinge .startMenuDiv p {
    color: #fff; }
  .theme-sll .startMenuDiv p {
    color: #fff; }
  .theme-halland .startMenuDiv p {
    color: #fff; }
  .theme-orebro .startMenuDiv p {
    color: #fff; }
  .theme-rg .startMenuDiv p {
    color: #fff; }

.theme-default .startMenuDiv:hover p {
  color: #FFFFFF; }

.theme-helsa .startMenuDiv:hover p {
  color: #FFFFFF; }

.theme-ptj .startMenuDiv:hover p {
  color: #FFFFFF; }

.theme-rjl .startMenuDiv:hover p {
  color: #FFFFFF; }

.theme-achima .startMenuDiv:hover p {
  color: #FFFFFF; }

.theme-blekinge .startMenuDiv:hover p {
  color: #FFFFFF; }

.theme-sll .startMenuDiv:hover p {
  color: #FFFFFF; }

.theme-halland .startMenuDiv:hover p {
  color: #FFFFFF; }

.theme-orebro .startMenuDiv:hover p {
  color: #FFFFFF; }

.theme-rg .startMenuDiv:hover p {
  color: #FFFFFF; }

.theme-default .startMenuDiv:hover {
  background-color: #0A4A7C;
  border: 1px solid #09385c;
  border-bottom: 3px solid #09385c; }

.theme-helsa .startMenuDiv:hover {
  background-color: #1D5E74;
  border: 1px solid #044557;
  border-bottom: 2px solid #044557; }

.theme-ptj .startMenuDiv:hover {
  background-color: #0075B0;
  border: 1px solid #0f5274;
  border-bottom: 3px solid #0f5274; }

.theme-rjl .startMenuDiv:hover {
  background-color: #579835;
  border: 1px solid #579835;
  border-bottom: 1px solid #579835; }

.theme-achima .startMenuDiv:hover {
  background-color: #880000;
  border: 1px solid #000000;
  border-bottom: 3px solid #000000; }

.theme-blekinge .startMenuDiv:hover {
  background-color: #14325E;
  border: 1px solid #26426A;
  border-bottom: 1px solid #26426A; }

.theme-sll .startMenuDiv:hover {
  background-color: #002d5a;
  border: 1px solid #000000;
  border-bottom: 3px solid #000000; }

.theme-halland .startMenuDiv:hover {
  background-color: #014C93;
  border: 1px solid #000000;
  border-bottom: 3px solid #000000; }

.theme-orebro .startMenuDiv:hover {
  background-color: #0b1d32;
  border: 1px solid #000000;
  border-bottom: 3px solid #000000; }

.theme-rg .startMenuDiv:hover {
  background-color: #26BDF5;
  border: 0px solid #26BDF5;
  border-bottom: 0px solid #26BDF5; }

/* Tiny screens sizes */
@media screen and (max-width: 543px) {
  .startMenuDiv p {
    font-size: 14px;
    font-weight: 600; }
  .startMenuDiv {
    height: 120px; } }

/* Small screens sizes */
@media screen and (min-width: 544px) and (max-width: 767px) {
  .startMenuDiv p {
    font-size: 18px; }
  .startMenuDiv {
    height: 140px; } }

/* Bigger screens sizes */
@media screen and (min-width: 768px) {
  .startMenuDiv p {
    font-size: 20px; }
  .startMenuDiv {
    height: 160px; } }

/*---------------------------------------------------------------------------
                             BOOTSTRAP BUTTON
---------------------------------------------------------------------------*/
.btn-fullwidth {
  width: 100%; }

.btn[hidden] {
  display: none; }

/*--------------------------[ General setting ]---------------------------*/
.btn {
  font-size: 16px;
  margin: 0 2px; }
  .theme-default .btn {
    border-radius: 30px;
    border: 1px solid #0A4A7C; }
  .theme-helsa .btn {
    border-radius: 30px;
    border: 1px solid #1D5E74; }
  .theme-ptj .btn {
    border-radius: 5px;
    border: 1px solid #0075B0; }
  .theme-rjl .btn {
    border-radius: 0;
    border: 1px solid #579835; }
  .theme-achima .btn {
    border-radius: 25px;
    border: 1px solid #AA2B23; }
  .theme-blekinge .btn {
    border-radius: 0;
    border: 1px solid #00A6E2; }
  .theme-sll .btn {
    border-radius: 0;
    border: 1px solid #002d5a; }
  .theme-halland .btn {
    border-radius: 0;
    border: 1px solid #7BB1DB; }
  .theme-orebro .btn {
    border-radius: 5px;
    border: 1px solid #4F80FF; }
  .theme-rg .btn {
    border-radius: 5px;
    border: 0px solid #0097cf; }

.btn:focus, .btn:active:focus, .btn.active:focus {
  outline: none;
  box-shadow: none; }
  .theme-default .btn:focus, .theme-default .btn:active:focus, .theme-default .btn.active:focus {
    border: 1px solid #0A4A7C; }
  .theme-helsa .btn:focus, .theme-helsa .btn:active:focus, .theme-helsa .btn.active:focus {
    border: 1px solid #1D5E74; }
  .theme-ptj .btn:focus, .theme-ptj .btn:active:focus, .theme-ptj .btn.active:focus {
    border: 1px solid #0075B0; }
  .theme-rjl .btn:focus, .theme-rjl .btn:active:focus, .theme-rjl .btn.active:focus {
    border: 1px solid #579835; }
  .theme-achima .btn:focus, .theme-achima .btn:active:focus, .theme-achima .btn.active:focus {
    border: 1px solid #AA2B23; }
  .theme-blekinge .btn:focus, .theme-blekinge .btn:active:focus, .theme-blekinge .btn.active:focus {
    border: 1px solid #00A6E2; }
  .theme-sll .btn:focus, .theme-sll .btn:active:focus, .theme-sll .btn.active:focus {
    border: 1px solid #002d5a; }
  .theme-halland .btn:focus, .theme-halland .btn:active:focus, .theme-halland .btn.active:focus {
    border: 1px solid #7BB1DB; }
  .theme-orebro .btn:focus, .theme-orebro .btn:active:focus, .theme-orebro .btn.active:focus {
    border: 1px solid #4F80FF; }
  .theme-rg .btn:focus, .theme-rg .btn:active:focus, .theme-rg .btn.active:focus {
    border: 0px solid #0097cf; }

.toggle .toggle-group .btn {
  border: 0 solid #555; }

/*-----------------------[ Primary (BLUE) Button ]------------------------*/
/* btn-primary */
.theme-default .btn-primary {
  background-color: #0A4A7C;
  border: 1px solid #0A4A7C; }

.theme-helsa .btn-primary {
  background-color: #1D5E74;
  border: 1px solid #1D5E74; }

.theme-ptj .btn-primary {
  background-color: #0075B0;
  border: 1px solid #0075B0; }

.theme-rjl .btn-primary {
  background-color: #579835;
  border: 1px solid #579835; }

.theme-achima .btn-primary {
  background-color: #AA2B23;
  border: 1px solid #AA2B23; }

.theme-blekinge .btn-primary {
  background-color: #00A6E2;
  border: 1px solid #00A6E2; }

.theme-sll .btn-primary {
  background-color: #002d5a;
  border: 1px solid #002d5a; }

.theme-halland .btn-primary {
  background-color: #7BB1DB;
  border: 1px solid #7BB1DB; }

.theme-orebro .btn-primary {
  background-color: #4F80FF;
  border: 1px solid #4F80FF; }

.theme-rg .btn-primary {
  background-color: #0097cf;
  border: 0px solid #0097cf; }

.theme-default .modal .btn.btn-primary span, .theme-default .secondHeader .btn-primary span, .theme-default .secondHeader .btn-primary label {
  color: #fff; }

.theme-helsa .modal .btn.btn-primary span, .theme-helsa .secondHeader .btn-primary span, .theme-helsa .secondHeader .btn-primary label {
  color: #fff; }

.theme-ptj .modal .btn.btn-primary span, .theme-ptj .secondHeader .btn-primary span, .theme-ptj .secondHeader .btn-primary label {
  color: #fff; }

.theme-rjl .modal .btn.btn-primary span, .theme-rjl .secondHeader .btn-primary span, .theme-rjl .secondHeader .btn-primary label {
  color: #fff; }

.theme-achima .modal .btn.btn-primary span, .theme-achima .secondHeader .btn-primary span, .theme-achima .secondHeader .btn-primary label {
  color: #fff; }

.theme-blekinge .modal .btn.btn-primary span, .theme-blekinge .secondHeader .btn-primary span, .theme-blekinge .secondHeader .btn-primary label {
  color: #fff; }

.theme-sll .modal .btn.btn-primary span, .theme-sll .secondHeader .btn-primary span, .theme-sll .secondHeader .btn-primary label {
  color: #fff; }

.theme-halland .modal .btn.btn-primary span, .theme-halland .secondHeader .btn-primary span, .theme-halland .secondHeader .btn-primary label {
  color: #fff; }

.theme-orebro .modal .btn.btn-primary span, .theme-orebro .secondHeader .btn-primary span, .theme-orebro .secondHeader .btn-primary label {
  color: #fff; }

.theme-rg .modal .btn.btn-primary span, .theme-rg .secondHeader .btn-primary span, .theme-rg .secondHeader .btn-primary label {
  color: #fff; }

.theme-default .btn-outline-primary {
  background: #fff;
  border: 1px solid #0A4A7C;
  color: #0A4A7C; }

.theme-helsa .btn-outline-primary {
  background: #fff;
  border: 1px solid #1D5E74;
  color: #1D5E74; }

.theme-ptj .btn-outline-primary {
  background: #fff;
  border: 1px solid #0075B0;
  color: #0075B0; }

.theme-rjl .btn-outline-primary {
  background: #fff;
  border: 1px solid #579835;
  color: #579835; }

.theme-achima .btn-outline-primary {
  background: #fff;
  border: 1px solid #AA2B23;
  color: #AA2B23; }

.theme-blekinge .btn-outline-primary {
  background: #fff;
  border: 1px solid #00A6E2;
  color: #00A6E2; }

.theme-sll .btn-outline-primary {
  background: #fff;
  border: 1px solid #002d5a;
  color: #002d5a; }

.theme-halland .btn-outline-primary {
  background: #fff;
  border: 1px solid #7BB1DB;
  color: #7BB1DB; }

.theme-orebro .btn-outline-primary {
  background: #fff;
  border: 1px solid #4F80FF;
  color: #4F80FF; }

.theme-rg .btn-outline-primary {
  background: #fff;
  border: 1px solid #0097cf;
  color: #0097cf; }

.theme-default .secondHeader .btn-outline-primary span {
  color: #0A4A7C; }

.theme-helsa .secondHeader .btn-outline-primary span {
  color: #1D5E74; }

.theme-ptj .secondHeader .btn-outline-primary span {
  color: #0075B0; }

.theme-rjl .secondHeader .btn-outline-primary span {
  color: #579835; }

.theme-achima .secondHeader .btn-outline-primary span {
  color: #AA2B23; }

.theme-blekinge .secondHeader .btn-outline-primary span {
  color: #00A6E2; }

.theme-sll .secondHeader .btn-outline-primary span {
  color: #002d5a; }

.theme-halland .secondHeader .btn-outline-primary span {
  color: #7BB1DB; }

.theme-orebro .secondHeader .btn-outline-primary span {
  color: #4F80FF; }

.theme-rg .secondHeader .btn-outline-primary span {
  color: #0097cf; }

.theme-default a.btn-primary, .theme-default a.btn-primary:hover {
  color: #fff; }

.theme-helsa a.btn-primary, .theme-helsa a.btn-primary:hover {
  color: #fff; }

.theme-ptj a.btn-primary, .theme-ptj a.btn-primary:hover {
  color: #fff; }

.theme-rjl a.btn-primary, .theme-rjl a.btn-primary:hover {
  color: #fff; }

.theme-achima a.btn-primary, .theme-achima a.btn-primary:hover {
  color: #fff; }

.theme-blekinge a.btn-primary, .theme-blekinge a.btn-primary:hover {
  color: #fff; }

.theme-sll a.btn-primary, .theme-sll a.btn-primary:hover {
  color: #fff; }

.theme-halland a.btn-primary, .theme-halland a.btn-primary:hover {
  color: #fff; }

.theme-orebro a.btn-primary, .theme-orebro a.btn-primary:hover {
  color: #fff; }

.theme-rg a.btn-primary, .theme-rg a.btn-primary:hover {
  color: #fff; }

.theme-default a.btn-outline-primary, .theme-default a.btn-outline-hover {
  color: #0A4A7C; }

.theme-helsa a.btn-outline-primary, .theme-helsa a.btn-outline-hover {
  color: #1D5E74; }

.theme-ptj a.btn-outline-primary, .theme-ptj a.btn-outline-hover {
  color: #0075B0; }

.theme-rjl a.btn-outline-primary, .theme-rjl a.btn-outline-hover {
  color: #579835; }

.theme-achima a.btn-outline-primary, .theme-achima a.btn-outline-hover {
  color: #AA2B23; }

.theme-blekinge a.btn-outline-primary, .theme-blekinge a.btn-outline-hover {
  color: #00A6E2; }

.theme-sll a.btn-outline-primary, .theme-sll a.btn-outline-hover {
  color: #002d5a; }

.theme-halland a.btn-outline-primary, .theme-halland a.btn-outline-hover {
  color: #7BB1DB; }

.theme-orebro a.btn-outline-primary, .theme-orebro a.btn-outline-hover {
  color: #4F80FF; }

.theme-rg a.btn-outline-primary, .theme-rg a.btn-outline-hover {
  color: #0097cf; }

/* btn-primary on hover */
.theme-default .btn-primary:hover {
  background-color: #09385c; }

.theme-helsa .btn-primary:hover {
  background-color: #044557; }

.theme-ptj .btn-primary:hover {
  background-color: #00A0C8; }

.theme-rjl .btn-primary:hover {
  background-color: #4c8b2b; }

.theme-achima .btn-primary:hover {
  background-color: #880000; }

.theme-blekinge .btn-primary:hover {
  background-color: #00A6E2; }

.theme-sll .btn-primary:hover {
  background-color: #000; }

.theme-halland .btn-primary:hover {
  background-color: #2F7CAC; }

.theme-orebro .btn-primary:hover {
  background-color: #004F9F; }

.theme-rg .btn-primary:hover {
  background-color: #26BDF5; }

.theme-default .btn-outline-primary:hover {
  border: 1px solid #09385c;
  color: #09385c; }

.theme-helsa .btn-outline-primary:hover {
  border: 1px solid #044557;
  color: #044557; }

.theme-ptj .btn-outline-primary:hover {
  border: 1px solid #00A0C8;
  color: #00A0C8; }

.theme-rjl .btn-outline-primary:hover {
  border: 1px solid #4c8b2b;
  color: #4c8b2b; }

.theme-achima .btn-outline-primary:hover {
  border: 1px solid #880000;
  color: #880000; }

.theme-blekinge .btn-outline-primary:hover {
  border: 1px solid #00A6E2;
  color: #00A6E2; }

.theme-sll .btn-outline-primary:hover {
  border: 1px solid #000;
  color: #000; }

.theme-halland .btn-outline-primary:hover {
  border: 1px solid #2F7CAC;
  color: #2F7CAC; }

.theme-orebro .btn-outline-primary:hover {
  border: 1px solid #004F9F;
  color: #004F9F; }

.theme-rg .btn-outline-primary:hover {
  border: 1px solid #26BDF5;
  color: #26BDF5; }

/* btn-primary on clikc-down */
.theme-default .btn-primary:active:focus {
  background-color: #0A4A7C; }

.theme-helsa .btn-primary:active:focus {
  background-color: #1D5E74; }

.theme-ptj .btn-primary:active:focus {
  background-color: #0075B0; }

.theme-rjl .btn-primary:active:focus {
  background-color: #579835; }

.theme-achima .btn-primary:active:focus {
  background-color: #AA2B23; }

.theme-blekinge .btn-primary:active:focus {
  background-color: #00A6E2; }

.theme-sll .btn-primary:active:focus {
  background-color: #002d5a; }

.theme-halland .btn-primary:active:focus {
  background-color: #7BB1DB; }

.theme-orebro .btn-primary:active:focus {
  background-color: #4F80FF; }

.theme-rg .btn-primary:active:focus {
  background-color: #0097cf; }

.btn-outline-primary:not(:disabled):not(.disabled).active:focus, .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary.dropdown-toggle:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary.dropdown-toggle:focus{
  outline-style: none;
  box-shadow: none;
}

.theme-default .btn-outline-primary:active:focus{
  background-color: #fff;
  border: 1px solid #0A4A7C;
  color: #0A4A7C;
  box-shadow: none}

.theme-helsa .btn-outline-primary:active:focus {
  background-color: #fff;
  border: 1px solid #1D5E74;
  color: #1D5E74;
  box-shadow: none}

.theme-ptj .btn-outline-primary:active:focus {
  background-color: #fff;
  border: 1px solid #0075B0;
  color: #0075B0;
  box-shadow: none}

.theme-rjl .btn-outline-primary:active:focus {
  background-color: #fff;
  border: 1px solid #579835;
  color: #579835;
  box-shadow: none}

.theme-achima .btn-outline-primary:active:focus {
  background-color: #fff;
  border: 1px solid #AA2B23;
  color: #AA2B23;
  box-shadow: none}

.theme-blekinge .btn-outline-primary:active:focus {
  background-color: #fff;
  border: 1px solid #00A6E2;
  color: #00A6E2;
  box-shadow: none}

.theme-sll .btn-outline-primary:active:focus {
  background-color: #fff;
  border: 1px solid #002d5a;
  color: #002d5a;
  box-shadow: none}

.theme-halland .btn-outline-primary:active:focus {
  background-color: #fff;
  border: 1px solid #7BB1DB;
  color: #7BB1DB;
  box-shadow: none}

.theme-orebro .btn-outline-primary:active:focus {
  background-color: #fff;
  border: 1px solid #4F80FF;
  color: #4F80FF;
  box-shadow: none}

.theme-rg .btn-outline-primary:active:focus {
  background-color: #fff;
  border: 1px solid #0097cf;
  color: #0097cf;
  box-shadow: none}

/* btn-primary after click */
.theme-default .btn-primary:focus {
  background-color: #09385c; }

.theme-helsa .btn-primary:focus {
  background-color: #044557; }

.theme-ptj .btn-primary:focus {
  background-color: #00A0C8; }

.theme-rjl .btn-primary:focus {
  background-color: #4c8b2b; }

.theme-achima .btn-primary:focus {
  background-color: #880000; }

.theme-blekinge .btn-primary:focus {
  background-color: #00A6E2; }

.theme-sll .btn-primary:focus {
  background-color: #000; }

.theme-halland .btn-primary:focus {
  background-color: #2F7CAC; }

.theme-orebro .btn-primary:focus {
  background-color: #004F9F; }

.theme-rg .btn-primary:focus {
  background-color: #26BDF5; }

.theme-default .btn-outline-primary:focus {
  border: 1px solid #09385c;
  color: #09385c; }

.theme-helsa .btn-outline-primary:focus {
  border: 1px solid #044557;
  color: #044557; }

.theme-ptj .btn-outline-primary:focus {
  border: 1px solid #00A0C8;
  color: #00A0C8; }

.theme-rjl .btn-outline-primary:focus {
  border: 1px solid #4c8b2b;
  color: #4c8b2b; }

.theme-achima .btn-outline-primary:focus {
  border: 1px solid #880000;
  color: #880000; }

.theme-blekinge .btn-outline-primary:focus {
  border: 1px solid #00A6E2;
  color: #00A6E2; }

.theme-sll .btn-outline-primary:focus {
  border: 1px solid #000;
  color: #000; }

.theme-halland .btn-outline-primary:focus {
  border: 1px solid #2F7CAC;
  color: #2F7CAC; }

.theme-orebro .btn-outline-primary:focus {
  border: 1px solid #004F9F;
  color: #004F9F; }

.theme-rg .btn-outline-primary:focus {
  border: 1px solid #26BDF5;
  color: #26BDF5; }

/*--------------------------[ Default (WHITE) Button ]---------------------------*/
.theme-default .admin .commentBoxDiv-reg .answer .btn-commentBtn {
  margin-bottom: 3px;
  margin-top: 3px; }

.theme-helsa .admin .commentBoxDiv-reg .answer .btn-commentBtn {
  margin-bottom: 3px;
  margin-top: 3px; }

.theme-ptj .admin .commentBoxDiv-reg .answer .btn-commentBtn {
  margin-bottom: 3px;
  margin-top: 3px; }

.theme-rjl .admin .commentBoxDiv-reg .answer .btn-commentBtn {
  margin-bottom: 3px;
  margin-top: 3px; }

.theme-achima .admin .commentBoxDiv-reg .answer .btn-commentBtn {
  margin-bottom: 3px;
  margin-top: 3px; }

.theme-blekinge .admin .commentBoxDiv-reg .answer .btn-commentBtn {
  margin-bottom: 3px;
  margin-top: 3px; }

.theme-sll .admin .commentBoxDiv-reg .answer .btn-commentBtn {
  margin-bottom: 3px;
  margin-top: 3px; }

.theme-halland .admin .commentBoxDiv-reg .answer .btn-commentBtn {
  margin-bottom: 3px;
  margin-top: 3px; }

.theme-orebro .admin .commentBoxDiv-reg .answer .btn-commentBtn {
  margin-bottom: 3px;
  margin-top: 3px; }

.theme-rg .admin .commentBoxDiv-reg .answer .btn-commentBtn {
  margin-bottom: 3px;
  margin-top: 3px; }

/*--------------------------[ Default (GRAY) Button ]---------------------------*/
/* btn-default after click */
.theme-default .btn-default {
  border: 1px solid #555;
  background-color: #fff; }

.theme-helsa .btn-default {
  border: 1px solid #555;
  background-color: #fff; }

.theme-ptj .btn-default {
  border: 1px solid #555;
  background-color: #fff; }

.theme-rjl .btn-default {
  border: 1px solid #636466;
  background-color: #fff; }

.theme-achima .btn-default {
  border: 1px solid #555;
  background-color: #fff; }

.theme-blekinge .btn-default {
  border: 1px solid #193f76;
  background-color: #fff; }

.theme-sll .btn-default {
  border: 1px solid #002d5a;
  background-color: #fff; }

.theme-halland .btn-default {
  border: 1px solid #000000;
  background-color: #fff; }

.theme-orebro .btn-default {
  border: 1px solid #000000;
  background-color: #fff; }

.theme-rg .btn-default {
  border: 0px solid #000000;
  background-color: #fff; }

.theme-default .btn-outline-default {
  background: #fff;
  border: 1px solid #555;
  color: #000; }

.theme-helsa .btn-outline-default {
  background: #fff;
  border: 1px solid #555;
  color: #000; }

.theme-ptj .btn-outline-default {
  background: #fff;
  border: 1px solid #555;
  color: #333333; }

.theme-rjl .btn-outline-default {
  background: #fff;
  border: 1px solid #636466;
  color: #000; }

.theme-achima .btn-outline-default {
  background: #fff;
  border: 1px solid #555;
  color: #000; }

.theme-blekinge .btn-outline-default {
  background: #fff;
  border: 1px solid #193f76;
  color: #000; }

.theme-sll .btn-outline-default {
  background: #fff;
  border: 1px solid #002d5a;
  color: #000; }

.theme-halland .btn-outline-default {
  background: #fff;
  border: 1px solid #000000;
  color: #000; }

.theme-orebro .btn-outline-default {
  background: #fff;
  border: 1px solid #000000;
  color: #000; }

.theme-rg .btn-outline-default {
  background: #fff;
  border: 1px solid #000000;
  color: #000; }

.theme-default .secondHeader .btn-outline-default span {
  color: #000; }

.theme-helsa .secondHeader .btn-outline-default span {
  color: #000; }

.theme-ptj .secondHeader .btn-outline-default span {
  color: #333333; }

.theme-rjl .secondHeader .btn-outline-default span {
  color: #000; }

.theme-achima .secondHeader .btn-outline-default span {
  color: #000; }

.theme-blekinge .secondHeader .btn-outline-default span {
  color: #000; }

.theme-sll .secondHeader .btn-outline-default span {
  color: #000; }

.theme-halland .secondHeader .btn-outline-default span {
  color: #000; }

.theme-orebro .secondHeader .btn-outline-default span {
  color: #000; }

.theme-rg .secondHeader .btn-outline-default span {
  color: #000; }

.theme-default .btn-outline-default.yesnobutton {
  background-color: #fff;
  background: #fff;
  color: #000; }

.theme-helsa .btn-outline-default.yesnobutton {
  background-color: #fff;
  background: #fff;
  color: #000; }

.theme-ptj .btn-outline-default.yesnobutton {
  background-color: #fff;
  background: #fff;
  color: #333333; }

.theme-rjl .btn-outline-default.yesnobutton {
  background-color: #fff;
  background: #fff;
  color: #000; }

.theme-achima .btn-outline-default.yesnobutton {
  background-color: #fff;
  background: #fff;
  color: #000; }

.theme-blekinge .btn-outline-default.yesnobutton {
  background-color: #fff;
  background: #fff;
  color: #000; }

.theme-sll .btn-outline-default.yesnobutton {
  background-color: #fff;
  background: #fff;
  color: #000; }

.theme-halland .btn-outline-default.yesnobutton {
  background-color: #fff;
  background: #fff;
  color: #000; }

.theme-orebro .btn-outline-default.yesnobutton {
  background-color: #fff;
  background: #fff;
  color: #000; }

.theme-rg .btn-outline-default.yesnobutton {
  background-color: #fff;
  background: #fff;
  color: #000; }

.theme-default a.btn-default, .theme-default a.btn-default:hover {
  color: #fff; }

.theme-helsa a.btn-default, .theme-helsa a.btn-default:hover {
  color: #fff; }

.theme-ptj a.btn-default, .theme-ptj a.btn-default:hover {
  color: #fff; }

.theme-rjl a.btn-default, .theme-rjl a.btn-default:hover {
  color: #fff; }

.theme-achima a.btn-default, .theme-achima a.btn-default:hover {
  color: #fff; }

.theme-blekinge a.btn-default, .theme-blekinge a.btn-default:hover {
  color: #fff; }

.theme-sll a.btn-default, .theme-sll a.btn-default:hover {
  color: #fff; }

.theme-halland a.btn-default, .theme-halland a.btn-default:hover {
  color: #fff; }

.theme-orebro a.btn-default, .theme-orebro a.btn-default:hover {
  color: #fff; }

.theme-rg a.btn-default, .theme-rg a.btn-default:hover {
  color: #fff; }

.theme-default a.btn-outline-default, .theme-default a.btn-outline-default:hover {
  color: #000; }

.theme-helsa a.btn-outline-default, .theme-helsa a.btn-outline-default:hover {
  color: #000; }

.theme-ptj a.btn-outline-default, .theme-ptj a.btn-outline-default:hover {
  color: #333333; }

.theme-rjl a.btn-outline-default, .theme-rjl a.btn-outline-default:hover {
  color: #000; }

.theme-achima a.btn-outline-default, .theme-achima a.btn-outline-default:hover {
  color: #000; }

.theme-blekinge a.btn-outline-default, .theme-blekinge a.btn-outline-default:hover {
  color: #000; }

.theme-sll a.btn-outline-default, .theme-sll a.btn-outline-default:hover {
  color: #000; }

.theme-halland a.btn-outline-default, .theme-halland a.btn-outline-default:hover {
  color: #000; }

.theme-orebro a.btn-outline-default, .theme-orebro a.btn-outline-default:hover {
  color: #000; }

.theme-rg a.btn-outline-default, .theme-rg a.btn-outline-default:hover {
  color: #000; }

/*---------------------[ Default (GRAY) Outline in List ]----------------------*/
.theme-default .btn-outline-default.stopSelectSymptomFormOnClick:hover {
  background-color: #fff; }

.theme-helsa .btn-outline-default.stopSelectSymptomFormOnClick:hover {
  background-color: #fff; }

.theme-ptj .btn-outline-default.stopSelectSymptomFormOnClick:hover {
  background-color: #fff; }

.theme-rjl .btn-outline-default.stopSelectSymptomFormOnClick:hover {
  background-color: #fff; }

.theme-achima .btn-outline-default.stopSelectSymptomFormOnClick:hover {
  background-color: #fff; }

.theme-blekinge .btn-outline-default.stopSelectSymptomFormOnClick:hover {
  background-color: #fff; }

.theme-sll .btn-outline-default.stopSelectSymptomFormOnClick:hover {
  background-color: #fff; }

.theme-halland .btn-outline-default.stopSelectSymptomFormOnClick:hover {
  background-color: #fff; }

.theme-orebro .btn-outline-default.stopSelectSymptomFormOnClick:hover {
  background-color: #fff; }

.theme-rg .btn-outline-default.stopSelectSymptomFormOnClick:hover {
  background-color: #fff; }

/*-------------------------[ Success (GREEN) Button ]--------------------------*/
/* btn-success */
.theme-default .btn-success {
  color: #fff;
  background-color: #4FD142;
  border-color: #4FD142; }

.theme-helsa .btn-success {
  color: #fff;
  background-color: #6CB680;
  border-color: #6CB680; }

.theme-ptj .btn-success {
  color: #fff;
  background-color: #B6BF02;
  border-color: #B6BF02; }

.theme-rjl .btn-success {
  color: #fff;
  background-color: #579835;
  border-color: #579835; }

.theme-achima .btn-success {
  color: #fff;
  background-color: #00ADA8;
  border-color: #00ADA8; }

.theme-blekinge .btn-success {
  color: #fff;
  background-color: #288749;
  border-color: #288749; }

.theme-sll .btn-success {
  color: #fff;
  background-color: #78be00;
  border-color: #78be00; }

.theme-halland .btn-success {
  color: #fff;
  background-color: #4E9023;
  border-color: #4E9023; }

.theme-orebro .btn-success {
  color: #fff;
  background-color: #9FC63B;
  border-color: #9FC63B; }

.theme-rg .btn-success {
  color: #fff;
  background-color: #50b848;
  border-color: #50b848; }

.btn-outline-success {
  background: none; }
  .theme-default .btn-outline-success {
    border: 1px solid #4FD142;
    color: #4FD142; }
  .theme-helsa .btn-outline-success {
    border: 1px solid #6CB680;
    color: #6CB680; }
  .theme-ptj .btn-outline-success {
    border: 1px solid #B6BF02;
    color: #B6BF02; }
  .theme-rjl .btn-outline-success {
    border: 1px solid #579835;
    color: #579835; }
  .theme-achima .btn-outline-success {
    border: 1px solid #00ADA8;
    color: #00ADA8; }
  .theme-blekinge .btn-outline-success {
    border: 1px solid #288749;
    color: #288749; }
  .theme-sll .btn-outline-success {
    border: 1px solid #78be00;
    color: #78be00; }
  .theme-halland .btn-outline-success {
    border: 1px solid #4E9023;
    color: #4E9023; }
  .theme-orebro .btn-outline-success {
    border: 1px solid #9FC63B;
    color: #9FC63B; }
  .theme-rg .btn-outline-success {
    border: 1px solid #50b848;
    color: #50b848; }

/* btn-success on hover */
.theme-default .btn-success:hover {
  background-color: #49b63e; }

.theme-helsa .btn-success:hover {
  background-color: #5b9c6c; }

.theme-ptj .btn-success:hover {
  background-color: #a3aa17; }

.theme-rjl .btn-success:hover {
  background-color: #4c8b2b; }

.theme-achima .btn-success:hover {
  background-color: #009894; }

.theme-blekinge .btn-success:hover {
  background-color: #206C3A; }

.theme-sll .btn-success:hover {
  background-color: #406618; }

.theme-halland .btn-success:hover {
  background-color: #046E15; }

.theme-orebro .btn-success:hover {
  background-color: #76B82A; }

.theme-rg .btn-success:hover {
  background-color: #9ACF8A; }

.btn-outline-success:hover {
  background: none; }
  .theme-default .btn-outline-success:hover {
    border: 1px solid #49b63e;
    color: #49b63e; }
  .theme-helsa .btn-outline-success:hover {
    border: 1px solid #5b9c6c;
    color: #5b9c6c; }
  .theme-ptj .btn-outline-success:hover {
    border: 1px solid #a3aa17;
    color: #a3aa17; }
  .theme-rjl .btn-outline-success:hover {
    border: 1px solid #4c8b2b;
    color: #4c8b2b; }
  .theme-achima .btn-outline-success:hover {
    border: 1px solid #009894;
    color: #009894; }
  .theme-blekinge .btn-outline-success:hover {
    border: 1px solid #206C3A;
    color: #206C3A; }
  .theme-sll .btn-outline-success:hover {
    border: 1px solid #406618;
    color: #406618; }
  .theme-halland .btn-outline-success:hover {
    border: 1px solid #046E15;
    color: #046E15; }
  .theme-orebro .btn-outline-success:hover {
    border: 1px solid #76B82A;
    color: #76B82A; }
  .theme-rg .btn-outline-success:hover {
    border: 1px solid #9ACF8A;
    color: #9ACF8A; }

/* btn-success on clikc-down */
.theme-default .btn-success:active:focus {
  background-color: #49b63e; }

.theme-helsa .btn-success:active:focus {
  background-color: #5b9c6c; }

.theme-ptj .btn-success:active:focus {
  background-color: #a3aa17; }

.theme-rjl .btn-success:active:focus {
  background-color: #4c8b2b; }

.theme-achima .btn-success:active:focus {
  background-color: #009894; }

.theme-blekinge .btn-success:active:focus {
  background-color: #206C3A; }

.theme-sll .btn-success:active:focus {
  background-color: #406618; }

.theme-halland .btn-success:active:focus {
  background-color: #046E15; }

.theme-orebro .btn-success:active:focus {
  background-color: #76B82A; }

.theme-rg .btn-success:active:focus {
  background-color: #9ACF8A; }

.btn-outline-success:active:focus {
  background: none; }
  .theme-default .btn-outline-success:active:focus {
    border: 1px solid #49b63e;
    color: #49b63e; }
  .theme-helsa .btn-outline-success:active:focus {
    border: 1px solid #5b9c6c;
    color: #5b9c6c; }
  .theme-ptj .btn-outline-success:active:focus {
    border: 1px solid #a3aa17;
    color: #a3aa17; }
  .theme-rjl .btn-outline-success:active:focus {
    border: 1px solid #4c8b2b;
    color: #4c8b2b; }
  .theme-achima .btn-outline-success:active:focus {
    border: 1px solid #009894;
    color: #009894; }
  .theme-blekinge .btn-outline-success:active:focus {
    border: 1px solid #206C3A;
    color: #206C3A; }
  .theme-sll .btn-outline-success:active:focus {
    border: 1px solid #406618;
    color: #406618; }
  .theme-halland .btn-outline-success:active:focus {
    border: 1px solid #046E15;
    color: #046E15; }
  .theme-orebro .btn-outline-success:active:focus {
    border: 1px solid #76B82A;
    color: #76B82A; }
  .theme-rg .btn-outline-success:active:focus {
    border: 1px solid #9ACF8A;
    color: #9ACF8A; }

/* btn-success after click */
.theme-default .btn-success:focus {
  background-color: #49b63e; }

.theme-helsa .btn-success:focus {
  background-color: #5b9c6c; }

.theme-ptj .btn-success:focus {
  background-color: #a3aa17; }

.theme-rjl .btn-success:focus {
  background-color: #4c8b2b; }

.theme-achima .btn-success:focus {
  background-color: #009894; }

.theme-blekinge .btn-success:focus {
  background-color: #206C3A; }

.theme-sll .btn-success:focus {
  background-color: #406618; }

.theme-halland .btn-success:focus {
  background-color: #046E15; }

.theme-orebro .btn-success:focus {
  background-color: #76B82A; }

.theme-rg .btn-success:focus {
  background-color: #9ACF8A; }

.btn-outline-success:focus {
  background: none; }
  .theme-default .btn-outline-success:focus {
    border: 1px solid #49b63e;
    color: #49b63e; }
  .theme-helsa .btn-outline-success:focus {
    border: 1px solid #5b9c6c;
    color: #5b9c6c; }
  .theme-ptj .btn-outline-success:focus {
    border: 1px solid #a3aa17;
    color: #a3aa17; }
  .theme-rjl .btn-outline-success:focus {
    border: 1px solid #4c8b2b;
    color: #4c8b2b; }
  .theme-achima .btn-outline-success:focus {
    border: 1px solid #009894;
    color: #009894; }
  .theme-blekinge .btn-outline-success:focus {
    border: 1px solid #206C3A;
    color: #206C3A; }
  .theme-sll .btn-outline-success:focus {
    border: 1px solid #406618;
    color: #406618; }
  .theme-halland .btn-outline-success:focus {
    border: 1px solid #046E15;
    color: #046E15; }
  .theme-orebro .btn-outline-success:focus {
    border: 1px solid #76B82A;
    color: #76B82A; }
  .theme-rg .btn-outline-success:focus {
    border: 1px solid #9ACF8A;
    color: #9ACF8A; }

.theme-default a.btn-success, .theme-default a.btn-success:hover {
  color: #fff; }

.theme-helsa a.btn-success, .theme-helsa a.btn-success:hover {
  color: #fff; }

.theme-ptj a.btn-success, .theme-ptj a.btn-success:hover {
  color: #fff; }

.theme-rjl a.btn-success, .theme-rjl a.btn-success:hover {
  color: #fff; }

.theme-achima a.btn-success, .theme-achima a.btn-success:hover {
  color: #fff; }

.theme-blekinge a.btn-success, .theme-blekinge a.btn-success:hover {
  color: #fff; }

.theme-sll a.btn-success, .theme-sll a.btn-success:hover {
  color: #fff; }

.theme-halland a.btn-success, .theme-halland a.btn-success:hover {
  color: #fff; }

.theme-orebro a.btn-success, .theme-orebro a.btn-success:hover {
  color: #fff; }

.theme-rg a.btn-success, .theme-rg a.btn-success:hover {
  color: #fff; }

.theme-default a.btn-outline-success, .theme-default a.btn-outline-success:hover {
  color: #4FD142; }

.theme-helsa a.btn-outline-success, .theme-helsa a.btn-outline-success:hover {
  color: #6CB680; }

.theme-ptj a.btn-outline-success, .theme-ptj a.btn-outline-success:hover {
  color: #B6BF02; }

.theme-rjl a.btn-outline-success, .theme-rjl a.btn-outline-success:hover {
  color: #579835; }

.theme-achima a.btn-outline-success, .theme-achima a.btn-outline-success:hover {
  color: #00ADA8; }

.theme-blekinge a.btn-outline-success, .theme-blekinge a.btn-outline-success:hover {
  color: #288749; }

.theme-sll a.btn-outline-success, .theme-sll a.btn-outline-success:hover {
  color: #78be00; }

.theme-halland a.btn-outline-success, .theme-halland a.btn-outline-success:hover {
  color: #4E9023; }

.theme-orebro a.btn-outline-success, .theme-orebro a.btn-outline-success:hover {
  color: #9FC63B; }

.theme-rg a.btn-outline-success, .theme-rg a.btn-outline-success:hover {
  color: #50b848; }

/*---------------------------[ Danger (RED) Button ]----------------------------*/
/* btn-danger */
.theme-default .btn-danger {
  color: #fff;
  background-color: #E32424;
  border-color: #E32424; }

.theme-helsa .btn-danger {
  color: #fff;
  background-color: #F48174;
  border-color: #F48174; }

.theme-ptj .btn-danger {
  color: #fff;
  background-color: #F54359;
  border-color: #F54359; }

.theme-rjl .btn-danger {
  color: #fff;
  background-color: #ce1141;
  border-color: #ce1141; }

.theme-achima .btn-danger {
  color: #fff;
  background-color: #AA2B23;
  border-color: #AA2B23; }

.theme-blekinge .btn-danger {
  color: #fff;
  background-color: #d70480;
  border-color: #d70480; }

.theme-sll .btn-danger {
  color: #fff;
  background-color: #9a0932;
  border-color: #9a0932; }

.theme-halland .btn-danger {
  color: #fff;
  background-color: #8E0000;
  border-color: #8E0000; }

.theme-orebro .btn-danger {
  color: #fff;
  background-color: #E32424;
  border-color: #E32424; }

.theme-rg .btn-danger {
  color: #fff;
  background-color: #ee3780;
  border-color: #ee3780; }

.btn-outline-danger {
  background: none; }
  .theme-default .btn-outline-danger {
    border: 1px solid #E32424;
    color: #E32424; }
  .theme-helsa .btn-outline-danger {
    border: 1px solid #F48174;
    color: #F48174; }
  .theme-ptj .btn-outline-danger {
    border: 1px solid #F54359;
    color: #F54359; }
  .theme-rjl .btn-outline-danger {
    border: 1px solid #ce1141;
    color: #ce1141; }
  .theme-achima .btn-outline-danger {
    border: 1px solid #AA2B23;
    color: #AA2B23; }
  .theme-blekinge .btn-outline-danger {
    border: 1px solid #d70480;
    color: #d70480; }
  .theme-sll .btn-outline-danger {
    border: 1px solid #9a0932;
    color: #9a0932; }
  .theme-halland .btn-outline-danger {
    border: 1px solid #8E0000;
    color: #8E0000; }
  .theme-orebro .btn-outline-danger {
    border: 1px solid #E32424;
    color: #E32424; }
  .theme-rg .btn-outline-danger {
    border: 1px solid #ee3780;
    color: #ee3780; }

/* btn-danger on hover */
.theme-default .btn-danger:hover {
  background-color: #c81e1e; }

.theme-helsa .btn-danger:hover {
  background-color: #d06b60; }

.theme-ptj .btn-danger:hover {
  background-color: #d92a3f; }

.theme-rjl .btn-danger:hover {
  background-color: #b1063a; }

.theme-achima .btn-danger:hover {
  background-color: #880000; }

.theme-blekinge .btn-danger:hover {
  background-color: #AC0366; }

.theme-sll .btn-danger:hover {
  background-color: #9a0932; }

.theme-halland .btn-danger:hover {
  background-color: #7c0000; }

.theme-orebro .btn-danger:hover {
  background-color: #c81e1e; }

.theme-rg .btn-danger:hover {
  background-color: #f588b2; }

.btn-outline-danger:hover {
  background: none; }
  .theme-default .btn-outline-danger:hover {
    border: 1px solid #c81e1e;
    color: #c81e1e; }
  .theme-helsa .btn-outline-danger:hover {
    border: 1px solid #d06b60;
    color: #d06b60; }
  .theme-ptj .btn-outline-danger:hover {
    border: 1px solid #d92a3f;
    color: #d92a3f; }
  .theme-rjl .btn-outline-danger:hover {
    border: 1px solid #b1063a;
    color: #b1063a; }
  .theme-achima .btn-outline-danger:hover {
    border: 1px solid #880000;
    color: #880000; }
  .theme-blekinge .btn-outline-danger:hover {
    border: 1px solid #AC0366;
    color: #AC0366; }
  .theme-sll .btn-outline-danger:hover {
    border: 1px solid #9a0932;
    color: #9a0932; }
  .theme-halland .btn-outline-danger:hover {
    border: 1px solid #7c0000;
    color: #7c0000; }
  .theme-orebro .btn-outline-danger:hover {
    border: 1px solid #c81e1e;
    color: #c81e1e; }
  .theme-rg .btn-outline-danger:hover {
    border: 1px solid #f588b2;
    color: #f588b2; }

/* btn-danger on clikc-down */
.theme-default .btn-danger:active:focus {
  background-color: #c81e1e; }

.theme-helsa .btn-danger:active:focus {
  background-color: #d06b60; }

.theme-ptj .btn-danger:active:focus {
  background-color: #d92a3f; }

.theme-rjl .btn-danger:active:focus {
  background-color: #b1063a; }

.theme-achima .btn-danger:active:focus {
  background-color: #880000; }

.theme-blekinge .btn-danger:active:focus {
  background-color: #AC0366; }

.theme-sll .btn-danger:active:focus {
  background-color: #9a0932; }

.theme-halland .btn-danger:active:focus {
  background-color: #7c0000; }

.theme-orebro .btn-danger:active:focus {
  background-color: #c81e1e; }

.theme-rg .btn-danger:active:focus {
  background-color: #f588b2; }

.btn-outline-danger:active:focus {
  background: none; }
  .theme-default .btn-outline-danger:active:focus {
    border: 1px solid #c81e1e;
    color: #c81e1e; }
  .theme-helsa .btn-outline-danger:active:focus {
    border: 1px solid #d06b60;
    color: #d06b60; }
  .theme-ptj .btn-outline-danger:active:focus {
    border: 1px solid #d92a3f;
    color: #d92a3f; }
  .theme-rjl .btn-outline-danger:active:focus {
    border: 1px solid #b1063a;
    color: #b1063a; }
  .theme-achima .btn-outline-danger:active:focus {
    border: 1px solid #880000;
    color: #880000; }
  .theme-blekinge .btn-outline-danger:active:focus {
    border: 1px solid #AC0366;
    color: #AC0366; }
  .theme-sll .btn-outline-danger:active:focus {
    border: 1px solid #9a0932;
    color: #9a0932; }
  .theme-halland .btn-outline-danger:active:focus {
    border: 1px solid #7c0000;
    color: #7c0000; }
  .theme-orebro .btn-outline-danger:active:focus {
    border: 1px solid #c81e1e;
    color: #c81e1e; }
  .theme-rg .btn-outline-danger:active:focus {
    border: 1px solid #f588b2;
    color: #f588b2; }

/* btn-danger after click */
.theme-default .btn-danger:focus {
  background-color: #c81e1e; }

.theme-helsa .btn-danger:focus {
  background-color: #d06b60; }

.theme-ptj .btn-danger:focus {
  background-color: #d92a3f; }

.theme-rjl .btn-danger:focus {
  background-color: #b1063a; }

.theme-achima .btn-danger:focus {
  background-color: #880000; }

.theme-blekinge .btn-danger:focus {
  background-color: #AC0366; }

.theme-sll .btn-danger:focus {
  background-color: #9a0932; }

.theme-halland .btn-danger:focus {
  background-color: #7c0000; }

.theme-orebro .btn-danger:focus {
  background-color: #c81e1e; }

.theme-rg .btn-danger:focus {
  background-color: #f588b2; }

.btn-outline-danger:focus {
  background: none; }
  .theme-default .btn-outline-danger:focus {
    border: 1px solid #c81e1e;
    color: #c81e1e; }
  .theme-helsa .btn-outline-danger:focus {
    border: 1px solid #d06b60;
    color: #d06b60; }
  .theme-ptj .btn-outline-danger:focus {
    border: 1px solid #d92a3f;
    color: #d92a3f; }
  .theme-rjl .btn-outline-danger:focus {
    border: 1px solid #b1063a;
    color: #b1063a; }
  .theme-achima .btn-outline-danger:focus {
    border: 1px solid #880000;
    color: #880000; }
  .theme-blekinge .btn-outline-danger:focus {
    border: 1px solid #AC0366;
    color: #AC0366; }
  .theme-sll .btn-outline-danger:focus {
    border: 1px solid #9a0932;
    color: #9a0932; }
  .theme-halland .btn-outline-danger:focus {
    border: 1px solid #7c0000;
    color: #7c0000; }
  .theme-orebro .btn-outline-danger:focus {
    border: 1px solid #c81e1e;
    color: #c81e1e; }
  .theme-rg .btn-outline-danger:focus {
    border: 1px solid #f588b2;
    color: #f588b2; }

.theme-default a.btn-danger, .theme-default a.btn-danger:hover {
  color: #fff; }

.theme-helsa a.btn-danger, .theme-helsa a.btn-danger:hover {
  color: #fff; }

.theme-ptj a.btn-danger, .theme-ptj a.btn-danger:hover {
  color: #fff; }

.theme-rjl a.btn-danger, .theme-rjl a.btn-danger:hover {
  color: #fff; }

.theme-achima a.btn-danger, .theme-achima a.btn-danger:hover {
  color: #fff; }

.theme-blekinge a.btn-danger, .theme-blekinge a.btn-danger:hover {
  color: #fff; }

.theme-sll a.btn-danger, .theme-sll a.btn-danger:hover {
  color: #fff; }

.theme-halland a.btn-danger, .theme-halland a.btn-danger:hover {
  color: #fff; }

.theme-orebro a.btn-danger, .theme-orebro a.btn-danger:hover {
  color: #fff; }

.theme-rg a.btn-danger, .theme-rg a.btn-danger:hover {
  color: #fff; }

.theme-default a.btn-outline-danger, .theme-default a.btn-outline-danger:hover {
  color: #E32424; }

.theme-helsa a.btn-outline-danger, .theme-helsa a.btn-outline-danger:hover {
  color: #F48174; }

.theme-ptj a.btn-outline-danger, .theme-ptj a.btn-outline-danger:hover {
  color: #F54359; }

.theme-rjl a.btn-outline-danger, .theme-rjl a.btn-outline-danger:hover {
  color: #ce1141; }

.theme-achima a.btn-outline-danger, .theme-achima a.btn-outline-danger:hover {
  color: #AA2B23; }

.theme-blekinge a.btn-outline-danger, .theme-blekinge a.btn-outline-danger:hover {
  color: #d70480; }

.theme-sll a.btn-outline-danger, .theme-sll a.btn-outline-danger:hover {
  color: #9a0932; }

.theme-halland a.btn-outline-danger, .theme-halland a.btn-outline-danger:hover {
  color: #8E0000; }

.theme-orebro a.btn-outline-danger, .theme-orebro a.btn-outline-danger:hover {
  color: #E32424; }

.theme-rg a.btn-outline-danger, .theme-rg a.btn-outline-danger:hover {
  color: #ee3780; }

/*---------------------------[ FOCUS (Yellow) Button ]----------------------------*/
/* btn-focus */
.theme-default .btn-focus {
  color: #0A4A7C;
  background-color: #FFE150;
  border-color: #f7da4f; }

.theme-helsa .btn-focus {
  color: #fff;
  background-color: #6CB680;
  border-color: #5b9c6c; }

.theme-ptj .btn-focus {
  color: #fff;
  background-color: #E78300;
  border-color: #cc790b; }

.theme-rjl .btn-focus {
  color: #fff;
  background-color: #579835;
  border-color: #4c8b2b; }

.theme-achima .btn-focus {
  color: #fff;
  background-color: #00ADA8;
  border-color: #009894; }

.theme-blekinge .btn-focus {
  color: #fff;
  background-color: #193F76;
  border-color: #14325E; }

.theme-sll .btn-focus {
  color: #fff;
  background-color: #e1056d;
  border-color: #e1056d; }

.theme-halland .btn-focus {
  color: #fff;
  background-color: #4E9023;
  border-color: #046E15; }

.theme-orebro .btn-focus {
  color: #fff;
  background-color: #9FC63B;
  border-color: #76B82A; }

.theme-rg .btn-focus {
  color: #fff;
  background-color: #50b848;
  border-color: #9ACF8A; }

.btn-outline-focus {
  background: none; }
  .theme-default .btn-outline-focus {
    border: 1px solid #0A4A7C;
    color: #0A4A7C; }
  .theme-helsa .btn-outline-focus {
    border: 1px solid #1D5E74;
    color: #1D5E74; }
  .theme-ptj .btn-outline-focus {
    border: 1px solid #0075B0;
    color: #0075B0; }
  .theme-rjl .btn-outline-focus {
    border: 1px solid #579835;
    color: #579835; }
  .theme-achima .btn-outline-focus {
    border: 1px solid #AA2B23;
    color: #AA2B23; }
  .theme-blekinge .btn-outline-focus {
    border: 1px solid #00A6E2;
    color: #00A6E2; }
  .theme-sll .btn-outline-focus {
    border: 1px solid #002d5a;
    color: #002d5a; }
  .theme-halland .btn-outline-focus {
    border: 1px solid #7BB1DB;
    color: #7BB1DB; }
  .theme-orebro .btn-outline-focus {
    border: 1px solid #4F80FF;
    color: #4F80FF; }
  .theme-rg .btn-outline-focus {
    border: 1px solid #0097cf;
    color: #0097cf; }

/* btn-focus on hover */
.theme-default .btn-focus:hover {
  background-color: #f7da4f; }

.theme-helsa .btn-focus:hover {
  background-color: #5b9c6c; }

.theme-ptj .btn-focus:hover {
  background-color: #cc790b; }

.theme-rjl .btn-focus:hover {
  background-color: #4c8b2b; }

.theme-achima .btn-focus:hover {
  background-color: #009894; }

.theme-blekinge .btn-focus:hover {
  background-color: #14325E; }

.theme-sll .btn-focus:hover {
  background-color: #e1056d; }

.theme-halland .btn-focus:hover {
  background-color: #046E15; }

.theme-orebro .btn-focus:hover {
  background-color: #76B82A; }

.theme-rg .btn-focus:hover {
  background-color: #9ACF8A; }

.btn-outline-focus:hover {
  background: none; }
  .theme-default .btn-outline-focus:hover {
    border: 1px solid #09385c;
    color: #09385c; }
  .theme-helsa .btn-outline-focus:hover {
    border: 1px solid #044557;
    color: #044557; }
  .theme-ptj .btn-outline-focus:hover {
    border: 1px solid #00A0C8;
    color: #00A0C8; }
  .theme-rjl .btn-outline-focus:hover {
    border: 1px solid #4c8b2b;
    color: #4c8b2b; }
  .theme-achima .btn-outline-focus:hover {
    border: 1px solid #880000;
    color: #880000; }
  .theme-blekinge .btn-outline-focus:hover {
    border: 1px solid #00A6E2;
    color: #00A6E2; }
  .theme-sll .btn-outline-focus:hover {
    border: 1px solid #000;
    color: #000; }
  .theme-halland .btn-outline-focus:hover {
    border: 1px solid #2F7CAC;
    color: #2F7CAC; }
  .theme-orebro .btn-outline-focus:hover {
    border: 1px solid #004F9F;
    color: #004F9F; }
  .theme-rg .btn-outline-focus:hover {
    border: 1px solid #26BDF5;
    color: #26BDF5; }

/* btn-focus on clikc-down */
.theme-default .btn-focus:active:focus {
  background-color: #f7da4f; }

.theme-helsa .btn-focus:active:focus {
  background-color: #5b9c6c; }

.theme-ptj .btn-focus:active:focus {
  background-color: #cc790b; }

.theme-rjl .btn-focus:active:focus {
  background-color: #4c8b2b; }

.theme-achima .btn-focus:active:focus {
  background-color: #009894; }

.theme-blekinge .btn-focus:active:focus {
  background-color: #14325E; }

.theme-sll .btn-focus:active:focus {
  background-color: #e1056d; }

.theme-halland .btn-focus:active:focus {
  background-color: #046E15; }

.theme-orebro .btn-focus:active:focus {
  background-color: #76B82A; }

.theme-rg .btn-focus:active:focus {
  background-color: #9ACF8A; }

.btn-outline-focus:active:focus {
  background: none; }
  .theme-default .btn-outline-focus:active:focus {
    border: 1px solid #09385c;
    color: #09385c; }
  .theme-helsa .btn-outline-focus:active:focus {
    border: 1px solid #044557;
    color: #044557; }
  .theme-ptj .btn-outline-focus:active:focus {
    border: 1px solid #00A0C8;
    color: #00A0C8; }
  .theme-rjl .btn-outline-focus:active:focus {
    border: 1px solid #4c8b2b;
    color: #4c8b2b; }
  .theme-achima .btn-outline-focus:active:focus {
    border: 1px solid #880000;
    color: #880000; }
  .theme-blekinge .btn-outline-focus:active:focus {
    border: 1px solid #00A6E2;
    color: #00A6E2; }
  .theme-sll .btn-outline-focus:active:focus {
    border: 1px solid #000;
    color: #000; }
  .theme-halland .btn-outline-focus:active:focus {
    border: 1px solid #2F7CAC;
    color: #2F7CAC; }
  .theme-orebro .btn-outline-focus:active:focus {
    border: 1px solid #004F9F;
    color: #004F9F; }
  .theme-rg .btn-outline-focus:active:focus {
    border: 1px solid #26BDF5;
    color: #26BDF5; }

/* btn-danger after click */
.theme-default .btn-focus:focus {
  background-color: #f7da4f; }

.theme-helsa .btn-focus:focus {
  background-color: #5b9c6c; }

.theme-ptj .btn-focus:focus {
  background-color: #cc790b; }

.theme-rjl .btn-focus:focus {
  background-color: #4c8b2b; }

.theme-achima .btn-focus:focus {
  background-color: #009894; }

.theme-blekinge .btn-focus:focus {
  background-color: #14325E; }

.theme-sll .btn-focus:focus {
  background-color: #e1056d; }

.theme-halland .btn-focus:focus {
  background-color: #046E15; }

.theme-orebro .btn-focus:focus {
  background-color: #76B82A; }

.theme-rg .btn-focus:focus {
  background-color: #9ACF8A; }

.btn-outline-focus:focus {
  background: none; }
  .theme-default .btn-outline-focus:focus {
    border: 1px solid #09385c;
    color: #09385c; }
  .theme-helsa .btn-outline-focus:focus {
    border: 1px solid #044557;
    color: #044557; }
  .theme-ptj .btn-outline-focus:focus {
    border: 1px solid #00A0C8;
    color: #00A0C8; }
  .theme-rjl .btn-outline-focus:focus {
    border: 1px solid #4c8b2b;
    color: #4c8b2b; }
  .theme-achima .btn-outline-focus:focus {
    border: 1px solid #880000;
    color: #880000; }
  .theme-blekinge .btn-outline-focus:focus {
    border: 1px solid #00A6E2;
    color: #00A6E2; }
  .theme-sll .btn-outline-focus:focus {
    border: 1px solid #000;
    color: #000; }
  .theme-halland .btn-outline-focus:focus {
    border: 1px solid #2F7CAC;
    color: #2F7CAC; }
  .theme-orebro .btn-outline-focus:focus {
    border: 1px solid #004F9F;
    color: #004F9F; }
  .theme-rg .btn-outline-focus:focus {
    border: 1px solid #26BDF5;
    color: #26BDF5; }

.theme-default a.btn-focus, .theme-default a.btn-focus:hover {
  color: #0A4A7C; }

.theme-helsa a.btn-focus, .theme-helsa a.btn-focus:hover {
  color: #1D5E74; }

.theme-ptj a.btn-focus, .theme-ptj a.btn-focus:hover {
  color: #0075B0; }

.theme-rjl a.btn-focus, .theme-rjl a.btn-focus:hover {
  color: #579835; }

.theme-achima a.btn-focus, .theme-achima a.btn-focus:hover {
  color: #AA2B23; }

.theme-blekinge a.btn-focus, .theme-blekinge a.btn-focus:hover {
  color: #00A6E2; }

.theme-sll a.btn-focus, .theme-sll a.btn-focus:hover {
  color: #002d5a; }

.theme-halland a.btn-focus, .theme-halland a.btn-focus:hover {
  color: #7BB1DB; }

.theme-orebro a.btn-focus, .theme-orebro a.btn-focus:hover {
  color: #4F80FF; }

.theme-rg a.btn-focus, .theme-rg a.btn-focus:hover {
  color: #0097cf; }

.theme-default a.btn-outline-focus, .theme-default a.btn-outline-focus:hover {
  color: #f7da4f; }

.theme-helsa a.btn-outline-focus, .theme-helsa a.btn-outline-focus:hover {
  color: #5b9c6c; }

.theme-ptj a.btn-outline-focus, .theme-ptj a.btn-outline-focus:hover {
  color: #cc790b; }

.theme-rjl a.btn-outline-focus, .theme-rjl a.btn-outline-focus:hover {
  color: #4c8b2b; }

.theme-achima a.btn-outline-focus, .theme-achima a.btn-outline-focus:hover {
  color: #009894; }

.theme-blekinge a.btn-outline-focus, .theme-blekinge a.btn-outline-focus:hover {
  color: #14325E; }

.theme-sll a.btn-outline-focus, .theme-sll a.btn-outline-focus:hover {
  color: #e1056d; }

.theme-halland a.btn-outline-focus, .theme-halland a.btn-outline-focus:hover {
  color: #046E15; }

.theme-orebro a.btn-outline-focus, .theme-orebro a.btn-outline-focus:hover {
  color: #76B82A; }

.theme-rg a.btn-outline-focus, .theme-rg a.btn-outline-focus:hover {
  color: #9ACF8A; }

/*---------------------------[ Disabled (Gray) Button ]----------------------------*/
/* btn-danger */
.btn-disabled {
  cursor: not-allowed; }
  .theme-default .btn-disabled {
    color: #E4E4E4;
    background-color: #F1F1F1;
    border-color: #F1F1F1; }
  .theme-helsa .btn-disabled {
    color: #d7d7d7;
    background-color: #E7E7E8;
    border-color: #E7E7E8; }
  .theme-ptj .btn-disabled {
    color: #F3F3F3;
    background-color: #F1F1F1;
    border-color: #F1F1F1; }
  .theme-rjl .btn-disabled {
    color: #E4E4E4;
    background-color: #F1F1F1;
    border-color: #F1F1F1; }
  .theme-achima .btn-disabled {
    color: #E4E4E4;
    background-color: #F1F1F1;
    border-color: #F1F1F1; }
  .theme-blekinge .btn-disabled {
    color: #E5E5E5;
    background-color: #B7C5CA;
    border-color: #B7C5CA; }
  .theme-sll .btn-disabled {
    color: #a79d96;
    background-color: #e0ded9;
    border-color: #e0ded9; }
  .theme-halland .btn-disabled {
    color: #CCCCCC;
    background-color: #F5F5F5;
    border-color: #F5F5F5; }
  .theme-orebro .btn-disabled {
    color: #E4E4E4;
    background-color: #F1F1F1;
    border-color: #F1F1F1; }
  .theme-rg .btn-disabled {
    color: #E4E4E4;
    background-color: #F1F1F1;
    border-color: #F1F1F1; }

.btn-outline-disabled {
  background: none;
  cursor: not-allowed; }
  .theme-default .btn-outline-disabled {
    border: 1px solid #E4E4E4;
    color: #E4E4E4; }
  .theme-helsa .btn-outline-disabled {
    border: 1px solid #d7d7d7;
    color: #d7d7d7; }
  .theme-ptj .btn-outline-disabled {
    border: 1px solid #F3F3F3;
    color: #F3F3F3; }
  .theme-rjl .btn-outline-disabled {
    border: 1px solid #E4E4E4;
    color: #E4E4E4; }
  .theme-achima .btn-outline-disabled {
    border: 1px solid #E4E4E4;
    color: #E4E4E4; }
  .theme-blekinge .btn-outline-disabled {
    border: 1px solid #E5E5E5;
    color: #E5E5E5; }
  .theme-sll .btn-outline-disabled {
    border: 1px solid #a79d96;
    color: #a79d96; }
  .theme-halland .btn-outline-disabled {
    border: 1px solid #CCCCCC;
    color: #CCCCCC; }
  .theme-orebro .btn-outline-disabled {
    border: 1px solid #E4E4E4;
    color: #E4E4E4; }
  .theme-rg .btn-outline-disabled {
    border: 1px solid #E4E4E4;
    color: #E4E4E4; }

/* btn-danger on hover */
.btn-disabled:hover {
  cursor: not-allowed; }
  .theme-default .btn-disabled:hover {
    color: #E4E4E4;
    background-color: #F1F1F1; }
  .theme-helsa .btn-disabled:hover {
    color: #d7d7d7;
    background-color: #E7E7E8; }
  .theme-ptj .btn-disabled:hover {
    color: #F3F3F3;
    background-color: #F1F1F1; }
  .theme-rjl .btn-disabled:hover {
    color: #E4E4E4;
    background-color: #F1F1F1; }
  .theme-achima .btn-disabled:hover {
    color: #E4E4E4;
    background-color: #F1F1F1; }
  .theme-blekinge .btn-disabled:hover {
    color: #E5E5E5;
    background-color: #B7C5CA; }
  .theme-sll .btn-disabled:hover {
    color: #a79d96;
    background-color: #e0ded9; }
  .theme-halland .btn-disabled:hover {
    color: #CCCCCC;
    background-color: #F5F5F5; }
  .theme-orebro .btn-disabled:hover {
    color: #E4E4E4;
    background-color: #F1F1F1; }
  .theme-rg .btn-disabled:hover {
    color: #E4E4E4;
    background-color: #F1F1F1; }

.btn-outline-disabled:hover {
  background: none;
  cursor: not-allowed; }
  .theme-default .btn-outline-disabled:hover {
    border: 1px solid #E4E4E4;
    color: #E4E4E4; }
  .theme-helsa .btn-outline-disabled:hover {
    border: 1px solid #d7d7d7;
    color: #d7d7d7; }
  .theme-ptj .btn-outline-disabled:hover {
    border: 1px solid #F3F3F3;
    color: #F3F3F3; }
  .theme-rjl .btn-outline-disabled:hover {
    border: 1px solid #E4E4E4;
    color: #E4E4E4; }
  .theme-achima .btn-outline-disabled:hover {
    border: 1px solid #E4E4E4;
    color: #E4E4E4; }
  .theme-blekinge .btn-outline-disabled:hover {
    border: 1px solid #E5E5E5;
    color: #E5E5E5; }
  .theme-sll .btn-outline-disabled:hover {
    border: 1px solid #a79d96;
    color: #a79d96; }
  .theme-halland .btn-outline-disabled:hover {
    border: 1px solid #CCCCCC;
    color: #CCCCCC; }
  .theme-orebro .btn-outline-disabled:hover {
    border: 1px solid #E4E4E4;
    color: #E4E4E4; }
  .theme-rg .btn-outline-disabled:hover {
    border: 1px solid #E4E4E4;
    color: #E4E4E4; }

/* btn-danger on clikc-down */
.btn-disabled:active:focus {
  cursor: not-allowed; }
  .theme-default .btn-disabled:active:focus {
    color: #E4E4E4;
    background-color: #F1F1F1; }
  .theme-helsa .btn-disabled:active:focus {
    color: #d7d7d7;
    background-color: #E7E7E8; }
  .theme-ptj .btn-disabled:active:focus {
    color: #F3F3F3;
    background-color: #F1F1F1; }
  .theme-rjl .btn-disabled:active:focus {
    color: #E4E4E4;
    background-color: #F1F1F1; }
  .theme-achima .btn-disabled:active:focus {
    color: #E4E4E4;
    background-color: #F1F1F1; }
  .theme-blekinge .btn-disabled:active:focus {
    color: #E5E5E5;
    background-color: #B7C5CA; }
  .theme-sll .btn-disabled:active:focus {
    color: #a79d96;
    background-color: #e0ded9; }
  .theme-halland .btn-disabled:active:focus {
    color: #CCCCCC;
    background-color: #F5F5F5; }
  .theme-orebro .btn-disabled:active:focus {
    color: #E4E4E4;
    background-color: #F1F1F1; }
  .theme-rg .btn-disabled:active:focus {
    color: #E4E4E4;
    background-color: #F1F1F1; }

.btn-outline-disabled:active:focus {
  background: none;
  cursor: not-allowed; }
  .theme-default .btn-outline-disabled:active:focus {
    border: 1px solid #E4E4E4;
    color: #E4E4E4; }
  .theme-helsa .btn-outline-disabled:active:focus {
    border: 1px solid #d7d7d7;
    color: #d7d7d7; }
  .theme-ptj .btn-outline-disabled:active:focus {
    border: 1px solid #F3F3F3;
    color: #F3F3F3; }
  .theme-rjl .btn-outline-disabled:active:focus {
    border: 1px solid #E4E4E4;
    color: #E4E4E4; }
  .theme-achima .btn-outline-disabled:active:focus {
    border: 1px solid #E4E4E4;
    color: #E4E4E4; }
  .theme-blekinge .btn-outline-disabled:active:focus {
    border: 1px solid #E5E5E5;
    color: #E5E5E5; }
  .theme-sll .btn-outline-disabled:active:focus {
    border: 1px solid #a79d96;
    color: #a79d96; }
  .theme-halland .btn-outline-disabled:active:focus {
    border: 1px solid #CCCCCC;
    color: #CCCCCC; }
  .theme-orebro .btn-outline-disabled:active:focus {
    border: 1px solid #E4E4E4;
    color: #E4E4E4; }
  .theme-rg .btn-outline-disabled:active:focus {
    border: 0px solid #E4E4E4;
    color: #E4E4E4; }

/* btn-danger after click */
.btn-disabled:focus {
  cursor: not-allowed; }
  .theme-default .btn-disabled:focus {
    color: #E4E4E4;
    background-color: #F1F1F1; }
  .theme-helsa .btn-disabled:focus {
    color: #d7d7d7;
    background-color: #E7E7E8; }
  .theme-ptj .btn-disabled:focus {
    color: #F3F3F3;
    background-color: #F1F1F1; }
  .theme-rjl .btn-disabled:focus {
    color: #E4E4E4;
    background-color: #F1F1F1; }
  .theme-achima .btn-disabled:focus {
    color: #E4E4E4;
    background-color: #F1F1F1; }
  .theme-blekinge .btn-disabled:focus {
    color: #E5E5E5;
    background-color: #B7C5CA; }
  .theme-sll .btn-disabled:focus {
    color: #a79d96;
    background-color: #e0ded9; }
  .theme-halland .btn-disabled:focus {
    color: #CCCCCC;
    background-color: #F5F5F5; }
  .theme-orebro .btn-disabled:focus {
    color: #E4E4E4;
    background-color: #F1F1F1; }
  .theme-rg .btn-disabled:focus {
    color: #E4E4E4;
    background-color: #F1F1F1; }

.btn-outline-disabled:focus {
  background: none;
  cursor: not-allowed; }
  .theme-default .btn-outline-disabled:focus {
    border: 1px solid #E4E4E4;
    color: #E4E4E4; }
  .theme-helsa .btn-outline-disabled:focus {
    border: 1px solid #d7d7d7;
    color: #d7d7d7; }
  .theme-ptj .btn-outline-disabled:focus {
    border: 1px solid #F3F3F3;
    color: #F3F3F3; }
  .theme-rjl .btn-outline-disabled:focus {
    border: 1px solid #E4E4E4;
    color: #E4E4E4; }
  .theme-achima .btn-outline-disabled:focus {
    border: 1px solid #E4E4E4;
    color: #E4E4E4; }
  .theme-blekinge .btn-outline-disabled:focus {
    border: 1px solid #E5E5E5;
    color: #E5E5E5; }
  .theme-sll .btn-outline-disabled:focus {
    border: 1px solid #a79d96;
    color: #a79d96; }
  .theme-halland .btn-outline-disabled:focus {
    border: 1px solid #CCCCCC;
    color: #CCCCCC; }
  .theme-orebro .btn-outline-disabled:focus {
    border: 1px solid #E4E4E4;
    color: #E4E4E4; }
  .theme-rg .btn-outline-disabled:focus {
    border: 1px solid #E4E4E4;
    color: #E4E4E4; }

.theme-default a.btn-disabled, .theme-default a.btn-disabled:hover {
  color: #E4E4E4; }

.theme-helsa a.btn-disabled, .theme-helsa a.btn-disabled:hover {
  color: #d7d7d7; }

.theme-ptj a.btn-disabled, .theme-ptj a.btn-disabled:hover {
  color: #F3F3F3; }

.theme-rjl a.btn-disabled, .theme-rjl a.btn-disabled:hover {
  color: #E4E4E4; }

.theme-achima a.btn-disabled, .theme-achima a.btn-disabled:hover {
  color: #E4E4E4; }

.theme-blekinge a.btn-disabled, .theme-blekinge a.btn-disabled:hover {
  color: #E5E5E5; }

.theme-sll a.btn-disabled, .theme-sll a.btn-disabled:hover {
  color: #a79d96; }

.theme-halland a.btn-disabled, .theme-halland a.btn-disabled:hover {
  color: #CCCCCC; }

.theme-orebro a.btn-disabled, .theme-orebro a.btn-disabled:hover {
  color: #E4E4E4; }

.theme-rg a.btn-disabled, .theme-rg a.btn-disabled:hover {
  color: #E4E4E4; }

.theme-default a.btn-outline-disabled, .theme-default a.btn-outline-disabled:hover {
  color: #F1F1F1; }

.theme-helsa a.btn-outline-disabled, .theme-helsa a.btn-outline-disabled:hover {
  color: #E7E7E8; }

.theme-ptj a.btn-outline-disabled, .theme-ptj a.btn-outline-disabled:hover {
  color: #F1F1F1; }

.theme-rjl a.btn-outline-disabled, .theme-rjl a.btn-outline-disabled:hover {
  color: #F1F1F1; }

.theme-achima a.btn-outline-disabled, .theme-achima a.btn-outline-disabled:hover {
  color: #F1F1F1; }

.theme-blekinge a.btn-outline-disabled, .theme-blekinge a.btn-outline-disabled:hover {
  color: #B7C5CA; }

.theme-sll a.btn-outline-disabled, .theme-sll a.btn-outline-disabled:hover {
  color: #e0ded9; }

.theme-halland a.btn-outline-disabled, .theme-halland a.btn-outline-disabled:hover {
  color: #F5F5F5; }

.theme-orebro a.btn-outline-disabled, .theme-orebro a.btn-outline-disabled:hover {
  color: #F1F1F1; }

.theme-rg a.btn-outline-disabled, .theme-rg a.btn-outline-disabled:hover {
  color: #F1F1F1; }

/*---------------------------[ Transparent Button ]----------------------------*/
.theme-default .btn.btn-transparent {
  background-color: transparent;
  border: none; }

.theme-helsa .btn.btn-transparent {
  background-color: transparent;
  border: none; }

.theme-ptj .btn.btn-transparent {
  background-color: transparent;
  border: none; }

.theme-rjl .btn.btn-transparent {
  background-color: transparent;
  border: none; }

.theme-achima .btn.btn-transparent {
  background-color: transparent;
  border: none; }

.theme-blekinge .btn.btn-transparent {
  background-color: transparent;
  border: none; }

.theme-sll .btn.btn-transparent {
  background-color: transparent;
  border: none; }

.theme-halland .btn.btn-transparent {
  background-color: transparent;
  border: none; }

.theme-orebro .btn.btn-transparent {
  background-color: transparent;
  border: none; }

.theme-rg .btn.btn-transparent {
  background-color: transparent;
  border: none; }

/* btn-transparent on hover */
.btn.btn-transparent:hover {
  background-color: transparent; }
  .theme-default .btn.btn-transparent:hover {
    color: #0A4A7C; }
  .theme-helsa .btn.btn-transparent:hover {
    color: #1D5E74; }
  .theme-ptj .btn.btn-transparent:hover {
    color: #0075B0; }
  .theme-rjl .btn.btn-transparent:hover {
    color: #579835; }
  .theme-achima .btn.btn-transparent:hover {
    color: #AA2B23; }
  .theme-blekinge .btn.btn-transparent:hover {
    color: #00A6E2; }
  .theme-sll .btn.btn-transparent:hover {
    color: #002d5a; }
  .theme-halland .btn.btn-transparent:hover {
    color: #7BB1DB; }
  .theme-orebro .btn.btn-transparent:hover {
    color: #4F80FF; }
  .theme-rg .btn.btn-transparent:hover {
    color: #0097cf; }

/* btn-transparent on clikc-down */
.theme-default .btn.btn-transparent:active:focus {
  background-color: transparent;
  border: none; }

.theme-helsa .btn.btn-transparent:active:focus {
  background-color: transparent;
  border: none; }

.theme-ptj .btn.btn-transparent:active:focus {
  background-color: transparent;
  border: none; }

.theme-rjl .btn.btn-transparent:active:focus {
  background-color: transparent;
  border: none; }

.theme-achima .btn.btn-transparent:active:focus {
  background-color: transparent;
  border: none; }

.theme-blekinge .btn.btn-transparent:active:focus {
  background-color: transparent;
  border: none; }

.theme-sll .btn.btn-transparent:active:focus {
  background-color: transparent;
  border: none; }

.theme-halland .btn.btn-transparent:active:focus {
  background-color: transparent;
  border: none; }

.theme-orebro .btn.btn-transparent:active:focus {
  background-color: transparent;
  border: none; }

.theme-rg .btn.btn-transparent:active:focus {
  background-color: transparent;
  border: none; }

/* btn-transparent after click */
.theme-default .btn.btn-transparent:focus {
  background-color: transparent;
  border: none; }

.theme-helsa .btn.btn-transparent:focus {
  background-color: transparent;
  border: none; }

.theme-ptj .btn.btn-transparent:focus {
  background-color: transparent;
  border: none; }

.theme-rjl .btn.btn-transparent:focus {
  background-color: transparent;
  border: none; }

.theme-achima .btn.btn-transparent:focus {
  background-color: transparent;
  border: none; }

.theme-blekinge .btn.btn-transparent:focus {
  background-color: transparent;
  border: none; }

.theme-sll .btn.btn-transparent:focus {
  background-color: transparent;
  border: none; }

.theme-halland .btn.btn-transparent:focus {
  background-color: transparent;
  border: none; }

.theme-orebro .btn.btn-transparent:focus {
  background-color: transparent;
  border: none; }

.theme-rg .btn.btn-transparent:focus {
  background-color: transparent;
  border: none; }

.languageHeader .btn.btn-transparent:hover {
  border-radius: 0; }
  .theme-default .languageHeader .btn.btn-transparent:hover {
    background-color: #A0F0EB;
    color: #0A4A7C; }
  .theme-helsa .languageHeader .btn.btn-transparent:hover {
    background-color: transparent;
    color: #6CB680; }
  .theme-ptj .languageHeader .btn.btn-transparent:hover {
    background-color: #E78300;
    color: #ffffff; }
  .theme-rjl .languageHeader .btn.btn-transparent:hover {
    background-color: #bfd730;
    color: #579835; }
  .theme-achima .languageHeader .btn.btn-transparent:hover {
    background-color: #fff;
    color: #AA2B23; }
  .theme-blekinge .languageHeader .btn.btn-transparent:hover {
    background-color: #00A6E2;
    color: #fff; }
  .theme-sll .languageHeader .btn.btn-transparent:hover {
    background-color: #00aeef;
    color: #fff; }
  .theme-halland .languageHeader .btn.btn-transparent:hover {
    background-color: #6DACDE;
    color: #fff; }
  .theme-orebro .languageHeader .btn.btn-transparent:hover {
    background-color: #4F80FF;
    color: #fff; }
  .theme-rg .languageHeader .btn.btn-transparent:hover {
    background-color: #96d491;
    color: #fff; }

/*---------------------------[ Button Groups ]----------------------------*/
.form-block .btn-group {
  display: block;
  margin-top: 26px; }

.theme-default .admin .btn-group-vertical .btn {
  border-radius: 30px; }

.theme-helsa .admin .btn-group-vertical .btn {
  border-radius: 30px; }

.theme-ptj .admin .btn-group-vertical .btn {
  border-radius: 5px; }

.theme-rjl .admin .btn-group-vertical .btn {
  border-radius: 0; }

.theme-achima .admin .btn-group-vertical .btn {
  border-radius: 25px; }

.theme-blekinge .admin .btn-group-vertical .btn {
  border-radius: 0; }

.theme-sll .admin .btn-group-vertical .btn {
  border-radius: 0; }

.theme-halland .admin .btn-group-vertical .btn {
  border-radius: 0; }

.theme-orebro .admin .btn-group-vertical .btn {
  border-radius: 5px; }

.theme-rg .admin .btn-group-vertical .btn {
  border-radius: 5px; }

.form-horizontal .form-group {
  margin-left: 0;
  margin-right: 0; }

.btn.btn-icon {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: none;
  border: 0;
  background: transparent; }

.btn-block + .btn-block {
  margin-top: 5px;
  padding: 8px 16px; }

/*---------------------------[ Round Button ]----------------------------*/
.theme-default .roundPaginationBtn {
  border-radius: 50%; }

.theme-helsa .roundPaginationBtn {
  border-radius: 50%; }

.theme-ptj .roundPaginationBtn {
  border-radius: 50%; }

.theme-rjl .roundPaginationBtn {
  border-radius: 50%; }

.theme-achima .roundPaginationBtn {
  border-radius: 50%; }

.theme-blekinge .roundPaginationBtn {
  border-radius: 50%; }

.theme-sll .roundPaginationBtn {
  border-radius: 50%; }

.theme-halland .roundPaginationBtn {
  border-radius: 50%; }

.theme-orebro .roundPaginationBtn {
  border-radius: 50%; }

.theme-rg .roundPaginationBtn {
  border-radius: 50%; }

/*---------------------------------------------------------------------------
                             DROP-DOWN BUTTONS
---------------------------------------------------------------------------*/
.select-wrapper::after {
  font-family: FontAwesome;
  content: "\f0dc";
  display: block;
  position: absolute;
  margin-top: -34px;
  margin-right: 33px;
  cursor: pointer;
  right: 0; }
  .theme-default .select-wrapper::after {
    color: #0A4A7C; }
  .theme-helsa .select-wrapper::after {
    color: #1D5E74; }
  .theme-ptj .select-wrapper::after {
    color: #0075B0; }
  .theme-rjl .select-wrapper::after {
    color: #579835; }
  .theme-achima .select-wrapper::after {
    color: #AA2B23; }
  .theme-blekinge .select-wrapper::after {
    color: #00A6E2; }
  .theme-sll .select-wrapper::after {
    color: #002d5a; }
  .theme-halland .select-wrapper::after {
    color: #7BB1DB; }
  .theme-orebro .select-wrapper::after {
    color: #4F80FF; }
  .theme-rg .select-wrapper::after {
    color: #0097cf; }

.form-block label {
  text-align: center;
  display: block;
  font-size: 14px; }

/*.form-block .form-control {
    @include themify($themes) {
        border-radius: themed('sm-radius');
    }
}*/
.form-group #pnr {
  margin-top: -8px; }

polymer-element .test-control {
  padding-top: 10px;
  border: none;
  text-align: left;
  height: 100%;
  width: 100%;
  margin-bottom: 8px; }
  .theme-default polymer-element .test-control {
    border-bottom: 0 solid #000; }
  .theme-helsa polymer-element .test-control {
    border-bottom: 0 solid #000; }
  .theme-ptj polymer-element .test-control {
    border-bottom: 0px solid #333333; }
  .theme-rjl polymer-element .test-control {
    border-bottom: 0 solid #000; }
  .theme-achima polymer-element .test-control {
    border-bottom: 2px solid #000; }
  .theme-blekinge polymer-element .test-control {
    border-bottom: 0 solid #000; }
  .theme-sll polymer-element .test-control {
    border-bottom: 0 solid #000; }
  .theme-halland polymer-element .test-control {
    border-bottom: 0 solid #000; }
  .theme-orebro polymer-element .test-control {
    border-bottom: 1px solid #000; }
  .theme-rg polymer-element .test-control {
    border-bottom: 0px solid #000; }

/* SUPER SMALL screens sizes */
@media screen and (max-width: 340px) {
  .doctorViewDropDownListCSS {
    width: 50%; } }

/* EXTRA SMALL screens sizes */
@media screen and (min-width: 340px) and (max-width: 543px) {
  .doctorViewDropDownListCSS {
    width: 60%; } }

/* VERRY SMALL screens sizes */
@media screen and (min-width: 544px) and (max-width: 767px) {
  .doctorViewDropDownListCSS {
    width: 75%; } }

/* SMALL screens sizes */
@media screen and (min-width: 768px) {
  .doctorViewDropDownListCSS {
    width: 100%; } }

/*---------------------------------------------------------------------------
                             SPECIAL BUTTONS
---------------------------------------------------------------------------*/
@media screen and (min-width: 992px) {
  .queueBtn {
    min-width: 100px; } }

btn-Big {
  height: 50px; }

.journalTypeButtons {
  margin: 10px 0px 0px 0px; }

.copyJournalBtn {
  margin: 10px 0; }

.theme-default .btn.copy-btn-sm {
  padding: 1px 5px;
  margin: 2px;
  font-size: 14px; }

.theme-helsa .btn.copy-btn-sm {
  padding: 1px 5px;
  margin: 2px;
  font-size: 14px; }

.theme-ptj .btn.copy-btn-sm {
  padding: 1px 5px;
  margin: 2px;
  font-size: 14px; }

.theme-rjl .btn.copy-btn-sm {
  padding: 1px 5px;
  margin: 2px;
  font-size: 14px; }

.theme-achima .btn.copy-btn-sm {
  padding: 1px 5px;
  margin: 2px;
  font-size: 14px; }

.theme-blekinge .btn.copy-btn-sm {
  padding: 1px 5px;
  margin: 2px;
  font-size: 14px; }

.theme-sll .btn.copy-btn-sm {
  padding: 1px 5px;
  margin: 2px;
  font-size: 14px; }

.theme-halland .btn.copy-btn-sm {
  padding: 1px 5px;
  margin: 2px;
  font-size: 14px; }

.theme-orebro .btn.copy-btn-sm {
  padding: 1px 5px;
  margin: 2px;
  font-size: 14px; }

.theme-rg .btn.copy-btn-sm {
  padding: 1px 5px;
  margin: 2px;
  font-size: 14px; }

.theme-default .btn span.copy-color:before {
  color: #000; }

.theme-helsa .btn span.copy-color:before {
  color: #000; }

.theme-ptj .btn span.copy-color:before {
  color: #333333; }

.theme-rjl .btn span.copy-color:before {
  color: #000; }

.theme-achima .btn span.copy-color:before {
  color: #000; }

.theme-blekinge .btn span.copy-color:before {
  color: #000; }

.theme-sll .btn span.copy-color:before {
  color: #000; }

.theme-halland .btn span.copy-color:before {
  color: #000; }

.theme-orebro .btn span.copy-color:before {
  color: #000; }

.theme-rg .btn span.copy-color:before {
  color: #000; }

.btn.lockSection {
  font-size: 12px;
  margin-top: -3px; }

.theme-default .buttonassignment .btn.btn-outline-primary, .theme-default .buttonassignment .btn.btn-outline-default {
  background-color: #fff; }

.theme-helsa .buttonassignment .btn.btn-outline-primary, .theme-helsa .buttonassignment .btn.btn-outline-default {
  background-color: #fff; }

.theme-ptj .buttonassignment .btn.btn-outline-primary, .theme-ptj .buttonassignment .btn.btn-outline-default {
  background-color: #fff; }

.theme-rjl .buttonassignment .btn.btn-outline-primary, .theme-rjl .buttonassignment .btn.btn-outline-default {
  background-color: #fff; }

.theme-achima .buttonassignment .btn.btn-outline-primary, .theme-achima .buttonassignment .btn.btn-outline-default {
  background-color: #fff; }

.theme-blekinge .buttonassignment .btn.btn-outline-primary, .theme-blekinge .buttonassignment .btn.btn-outline-default {
  background-color: #fff; }

.theme-sll .buttonassignment .btn.btn-outline-primary, .theme-sll .buttonassignment .btn.btn-outline-default {
  background-color: #fff; }

.theme-halland .buttonassignment .btn.btn-outline-primary, .theme-halland .buttonassignment .btn.btn-outline-default {
  background-color: #fff; }

.theme-orebro .buttonassignment .btn.btn-outline-primary, .theme-orebro .buttonassignment .btn.btn-outline-default {
  background-color: #fff; }

.theme-rg .buttonassignment .btn.btn-outline-primary, .theme-rg .buttonassignment .btn.btn-outline-default {
  background-color: #fff; }

.theme-default .buttonassignment button.btn.btn-outline-default.active {
  background-color: #0A4A7C;
  border-color: #0A4A7C;
  color: #fff; }

.theme-helsa .buttonassignment button.btn.btn-outline-default.active {
  background-color: #1D5E74;
  border-color: #1D5E74;
  color: #fff; }

.theme-ptj .buttonassignment button.btn.btn-outline-default.active {
  background-color: #0075B0;
  border-color: #0075B0;
  color: #fff; }

.theme-rjl .buttonassignment button.btn.btn-outline-default.active {
  background-color: #579835;
  border-color: #579835;
  color: #fff; }

.theme-achima .buttonassignment button.btn.btn-outline-default.active {
  background-color: #AA2B23;
  border-color: #AA2B23;
  color: #fff; }

.theme-blekinge .buttonassignment button.btn.btn-outline-default.active {
  background-color: #00A6E2;
  border-color: #00A6E2;
  color: #fff; }

.theme-sll .buttonassignment button.btn.btn-outline-default.active {
  background-color: #002d5a;
  border-color: #002d5a;
  color: #fff; }

.theme-halland .buttonassignment button.btn.btn-outline-default.active {
  background-color: #7BB1DB;
  border-color: #7BB1DB;
  color: #fff; }

.theme-orebro .buttonassignment button.btn.btn-outline-default.active {
  background-color: #4F80FF;
  border-color: #4F80FF;
  color: #fff; }

.theme-rg .buttonassignment button.btn.btn-outline-default.active {
  background-color: #0097cf;
  border-color: #0097cf;
  color: #fff; }

/*.admin button.btn-outline-default.validationbutton.active,
.admin button.btn-outline-default.active#selfWrittenByNurseBtn {
    box-shadow: none;
    background-color: $focus-color;
    border-color: $focus-color;
    color: $primary-color;
}*/
.theme-default .buttonassignment button.activeYellow {
  background-color: #FFE150;
  border: 1px solid #f7da4f;
  color: #fff; }

.theme-helsa .buttonassignment button.activeYellow {
  background-color: #FFE150;
  border: 1px solid #f7da4f;
  color: #fff; }

.theme-ptj .buttonassignment button.activeYellow {
  background-color: #F9B360;
  border: 1px solid #e49f4e;
  color: #fff; }

.theme-rjl .buttonassignment button.activeYellow {
  background-color: #fdb913;
  border: 1px solid #f59a1b;
  color: #fff; }

.theme-achima .buttonassignment button.activeYellow {
  background-color: #FFE150;
  border: 1px solid #f7da4f;
  color: #fff; }

.theme-blekinge .buttonassignment button.activeYellow {
  background-color: #FFE150;
  border: 1px solid #f7da4f;
  color: #fff; }

.theme-sll .buttonassignment button.activeYellow {
  background-color: #ffd400;
  border: 1px solid #ffd400;
  color: #fff; }

.theme-halland .buttonassignment button.activeYellow {
  background-color: #FFE150;
  border: 1px solid #f7da4f;
  color: #fff; }

.theme-orebro .buttonassignment button.activeYellow {
  background-color: #FFE150;
  border: 1px solid #f7da4f;
  color: #fff; }

.theme-rg .buttonassignment button.activeYellow {
  background-color: #faa634;
  border: 0px solid #ffec98;
  color: #fff; }

.close-alertMessage-btn {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer; }

@media screen and (min-width: 768px) {
  .btn-Half {
    width: 48%; }
  .btn-consultant {
    width: 48%; }
  .row .paragraphHeadding {
    font-size: 28px; }
  .registration button, .registration .btn, .registration .bigBtn.btn {
    font-size: 28px; }
  .symptomFormQuestionsSection .wizard-nav .bigBtn, .btn.btn-primary.bigBtn {
    padding-left: 16px;
    padding-right: 16px;
    font-size: 22px; } }

.waitingRoomSection .queue-div .queue-block .bigBtn {
  margin-bottom: 30px; }

.waitingRoomSection .queue-div .queue-block .bigBtn:hover,
.waitingRoomSection .queue-div .queue-block .bigBtn:active,
.waitingRoomSection .queue-div .queue-block .bigBtn:after,
.waitingRoomSection .queue-div .queue-block .bigBtn:focus {
  cursor: default; }
  .theme-default .waitingRoomSection .queue-div .queue-block .bigBtn:hover, .theme-default
  .waitingRoomSection .queue-div .queue-block .bigBtn:active, .theme-default
  .waitingRoomSection .queue-div .queue-block .bigBtn:after, .theme-default
  .waitingRoomSection .queue-div .queue-block .bigBtn:focus {
    background-color: #0A4A7C; }
  .theme-helsa .waitingRoomSection .queue-div .queue-block .bigBtn:hover, .theme-helsa
  .waitingRoomSection .queue-div .queue-block .bigBtn:active, .theme-helsa
  .waitingRoomSection .queue-div .queue-block .bigBtn:after, .theme-helsa
  .waitingRoomSection .queue-div .queue-block .bigBtn:focus {
    background-color: #1D5E74; }
  .theme-ptj .waitingRoomSection .queue-div .queue-block .bigBtn:hover, .theme-ptj
  .waitingRoomSection .queue-div .queue-block .bigBtn:active, .theme-ptj
  .waitingRoomSection .queue-div .queue-block .bigBtn:after, .theme-ptj
  .waitingRoomSection .queue-div .queue-block .bigBtn:focus {
    background-color: #0075B0; }
  .theme-rjl .waitingRoomSection .queue-div .queue-block .bigBtn:hover, .theme-rjl
  .waitingRoomSection .queue-div .queue-block .bigBtn:active, .theme-rjl
  .waitingRoomSection .queue-div .queue-block .bigBtn:after, .theme-rjl
  .waitingRoomSection .queue-div .queue-block .bigBtn:focus {
    background-color: #579835; }
  .theme-achima .waitingRoomSection .queue-div .queue-block .bigBtn:hover, .theme-achima
  .waitingRoomSection .queue-div .queue-block .bigBtn:active, .theme-achima
  .waitingRoomSection .queue-div .queue-block .bigBtn:after, .theme-achima
  .waitingRoomSection .queue-div .queue-block .bigBtn:focus {
    background-color: #AA2B23; }
  .theme-blekinge .waitingRoomSection .queue-div .queue-block .bigBtn:hover, .theme-blekinge
  .waitingRoomSection .queue-div .queue-block .bigBtn:active, .theme-blekinge
  .waitingRoomSection .queue-div .queue-block .bigBtn:after, .theme-blekinge
  .waitingRoomSection .queue-div .queue-block .bigBtn:focus {
    background-color: #00A6E2; }
  .theme-sll .waitingRoomSection .queue-div .queue-block .bigBtn:hover, .theme-sll
  .waitingRoomSection .queue-div .queue-block .bigBtn:active, .theme-sll
  .waitingRoomSection .queue-div .queue-block .bigBtn:after, .theme-sll
  .waitingRoomSection .queue-div .queue-block .bigBtn:focus {
    background-color: #002d5a; }
  .theme-halland .waitingRoomSection .queue-div .queue-block .bigBtn:hover, .theme-halland
  .waitingRoomSection .queue-div .queue-block .bigBtn:active, .theme-halland
  .waitingRoomSection .queue-div .queue-block .bigBtn:after, .theme-halland
  .waitingRoomSection .queue-div .queue-block .bigBtn:focus {
    background-color: #7BB1DB; }
  .theme-orebro .waitingRoomSection .queue-div .queue-block .bigBtn:hover, .theme-orebro
  .waitingRoomSection .queue-div .queue-block .bigBtn:active, .theme-orebro
  .waitingRoomSection .queue-div .queue-block .bigBtn:after, .theme-orebro
  .waitingRoomSection .queue-div .queue-block .bigBtn:focus {
    background-color: #4F80FF; }
  .theme-rg .waitingRoomSection .queue-div .queue-block .bigBtn:hover, .theme-rg
  .waitingRoomSection .queue-div .queue-block .bigBtn:active, .theme-rg
  .waitingRoomSection .queue-div .queue-block .bigBtn:after, .theme-rg
  .waitingRoomSection .queue-div .queue-block .bigBtn:focus {
    background-color: #0097cf; }

@media screen and (max-width: 768px) {
  .symptomFormQuestionsSection .wizard-nav .bigBtn, .btn.btn-primary.bigBtn {
    font-size: 18px; } }

@media screen and (max-width: 768px) and (min-width: 400px) {
  .btn-Half {
    width: 42%; }
  .btn-consultant {
    width: 46%;
    text-align: center;
    padding-left: 0;
    padding-right: 0; } }

@media screen and (max-width: 400px) {
  .btn-Half {
    width: 42%; }
  .btn-consultant {
    width: 46%;
    text-align: center;
    padding-left: 0;
    padding-right: 0;
    font-size: 16px; } }

.theme-default .admin .btn {
  font-size: 14px; }

.theme-helsa .admin .btn {
  font-size: 14px; }

.theme-ptj .admin .btn {
  font-size: 14px; }

.theme-rjl .admin .btn {
  font-size: 14px; }

.theme-achima .admin .btn {
  font-size: 14px; }

.theme-blekinge .admin .btn {
  font-size: 14px; }

.theme-sll .admin .btn {
  font-size: 14px; }

.theme-halland .admin .btn {
  font-size: 14px; }

.theme-orebro .admin .btn {
  font-size: 14px; }

.theme-rg .admin .btn {
  font-size: 14px; }

.showTestButton {
  float: right; }

@media screen and (min-width: 768px) {
  .registration .btn .fa {
    margin-top: 4px; } }

.theme-default .admin .nav-pills > li > a {
  border-radius: 0; }

.theme-helsa .admin .nav-pills > li > a {
  border-radius: 0; }

.theme-ptj .admin .nav-pills > li > a {
  border-radius: 0px; }

.theme-rjl .admin .nav-pills > li > a {
  border-radius: 0; }

.theme-achima .admin .nav-pills > li > a {
  border-radius: 3px; }

.theme-blekinge .admin .nav-pills > li > a {
  border-radius: 0; }

.theme-sll .admin .nav-pills > li > a {
  border-radius: 0; }

.theme-halland .admin .nav-pills > li > a {
  border-radius: 0; }

.theme-orebro .admin .nav-pills > li > a {
  border-radius: 3px; }

.theme-rg .admin .nav-pills > li > a {
  border-radius: 0px; }

@media screen and (min-width: 1400px) {
  #longCopyText {
    display: normal; }
  #shortCopyText {
    display: none; } }

@media screen and (max-width: 1400px) and (min-width: 1200px) {
  #longCopyText {
    display: none; }
  #shortCopyText {
    display: normal; } }

/*@media screen and (max-width: 1200px) and (min-width: 992px) {
    #longCopyText {
        display: normal;
    }

    #shortCopyText {
        display: none;
    }
}*/
/*---------------------------[ Yes / No Buttons ]----------------------------*/
/* Clicked "Yes"/"No" buttons */
.theme-default .answer .yesnobuttongroup .active, .theme-default .patientanswer .active, .theme-default .yesnobutton.active, .theme-default .diagnoseButtons .active {
  background-color: #0A4A7C;
  color: #fff;
  border: 1px solid #09385c;
  box-shadow: none; }

.theme-helsa .answer .yesnobuttongroup .active, .theme-helsa .patientanswer .active, .theme-helsa .yesnobutton.active, .theme-helsa .diagnoseButtons .active {
  background-color: #1D5E74;
  color: #fff;
  border: 1px solid #044557;
  box-shadow: none; }

.theme-ptj .answer .yesnobuttongroup .active, .theme-ptj .patientanswer .active, .theme-ptj .yesnobutton.active, .theme-ptj .diagnoseButtons .active {
  background-color: #0075B0;
  color: #fff;
  border: 1px solid #00A0C8;
  box-shadow: none; }

.theme-rjl .answer .yesnobuttongroup .active, .theme-rjl .patientanswer .active, .theme-rjl .yesnobutton.active, .theme-rjl .diagnoseButtons .active {
  background-color: #579835;
  color: #fff;
  border: 1px solid #4c8b2b;
  box-shadow: none; }

.theme-achima .answer .yesnobuttongroup .active, .theme-achima .patientanswer .active, .theme-achima .yesnobutton.active, .theme-achima .diagnoseButtons .active {
  background-color: #AA2B23;
  color: #fff;
  border: 1px solid #880000;
  box-shadow: none; }

.theme-blekinge .answer .yesnobuttongroup .active, .theme-blekinge .patientanswer .active, .theme-blekinge .yesnobutton.active, .theme-blekinge .diagnoseButtons .active {
  background-color: #00A6E2;
  color: #fff;
  border: 1px solid #00A6E2;
  box-shadow: none; }

.theme-sll .answer .yesnobuttongroup .active, .theme-sll .patientanswer .active, .theme-sll .yesnobutton.active, .theme-sll .diagnoseButtons .active {
  background-color: #002d5a;
  color: #fff;
  border: 1px solid #000;
  box-shadow: none; }

.theme-halland .answer .yesnobuttongroup .active, .theme-halland .patientanswer .active, .theme-halland .yesnobutton.active, .theme-halland .diagnoseButtons .active {
  background-color: #7BB1DB;
  color: #fff;
  border: 1px solid #2F7CAC;
  box-shadow: none; }

.theme-orebro .answer .yesnobuttongroup .active, .theme-orebro .patientanswer .active, .theme-orebro .yesnobutton.active, .theme-orebro .diagnoseButtons .active {
  background-color: #4F80FF;
  color: #fff;
  border: 1px solid #004F9F;
  box-shadow: none; }

.theme-rg .answer .yesnobuttongroup .active, .theme-rg .patientanswer .active, .theme-rg .yesnobutton.active, .theme-rg .diagnoseButtons .active {
  background-color: #0097cf;
  color: #fff;
  border: 0px solid #26BDF5;
  box-shadow: none; }

.theme-default .answer .active.noBtn, .theme-default #testpartial .answer .yesnobutton.active.noBtn {
  background-color: #7A7B7C;
  /*!important;*/
  color: #FFF;
  border: 1px solid #364552;
  /*!important;*/ }

.theme-helsa .answer .active.noBtn, .theme-helsa #testpartial .answer .yesnobutton.active.noBtn {
  background-color: #d7d7d7;
  /*!important;*/
  color: #000;
  border: 1px solid #CCCCCC;
  /*!important;*/ }

.theme-ptj .answer .active.noBtn, .theme-ptj #testpartial .answer .yesnobutton.active.noBtn {
  background-color: #00A0C8;
  /*!important;*/
  color: #ffffff;
  border: 1px solid #00A0C8;
  /*!important;*/ }

.theme-rjl .answer .active.noBtn, .theme-rjl #testpartial .answer .yesnobutton.active.noBtn {
  background-color: #E4E4E4;
  /*!important;*/
  color: #000;
  border: 1px solid #000;
  /*!important;*/ }

.theme-achima .answer .active.noBtn, .theme-achima #testpartial .answer .yesnobutton.active.noBtn {
  background-color: #c2c2c2;
  /*!important;*/
  color: #000;
  border: 1px solid #777;
  /*!important;*/ }

.theme-blekinge .answer .active.noBtn, .theme-blekinge #testpartial .answer .yesnobutton.active.noBtn {
  background-color: #c2c2c2;
  /*!important;*/
  color: #000;
  border: 1px solid #777;
  /*!important;*/ }

.theme-sll .answer .active.noBtn, .theme-sll #testpartial .answer .yesnobutton.active.noBtn {
  background-color: #685c53;
  /*!important;*/
  color: #FFFFFF;
  border: 1px solid #000;
  /*!important;*/ }

.theme-halland .answer .active.noBtn, .theme-halland #testpartial .answer .yesnobutton.active.noBtn {
  background-color: #DEDEDE;
  /*!important;*/
  color: #000;
  border: 1px solid #AAAAAA;
  /*!important;*/ }

.theme-orebro .answer .active.noBtn, .theme-orebro #testpartial .answer .yesnobutton.active.noBtn {
  background-color: #DEDEDE;
  /*!important;*/
  color: #000;
  border: 1px solid #AAAAAA;
  /*!important;*/ }

.theme-rg .answer .active.noBtn, .theme-rg #testpartial .answer .yesnobutton.active.noBtn {
  background-color: #DEDEDE;
  /*!important;*/
  color: #000;
  border: 1px solid #ccc;
  /*!important;*/ }

/*Only form Strep a (Thats the only button in #testpartial)*/
.theme-default #testpartial .answer .yesnobutton.active.yesBtn {
  color: #fff;
  background-color: #E32424;
  border-color: #E32424; }

.theme-helsa #testpartial .answer .yesnobutton.active.yesBtn {
  color: #fff;
  background-color: #F48174;
  border-color: #F48174; }

.theme-ptj #testpartial .answer .yesnobutton.active.yesBtn {
  color: #fff;
  background-color: #F54359;
  border-color: #F54359; }

.theme-rjl #testpartial .answer .yesnobutton.active.yesBtn {
  color: #fff;
  background-color: #ce1141;
  border-color: #ce1141; }

.theme-achima #testpartial .answer .yesnobutton.active.yesBtn {
  color: #fff;
  background-color: #AA2B23;
  border-color: #AA2B23; }

.theme-blekinge #testpartial .answer .yesnobutton.active.yesBtn {
  color: #fff;
  background-color: #d70480;
  border-color: #d70480; }

.theme-sll #testpartial .answer .yesnobutton.active.yesBtn {
  color: #fff;
  background-color: #9a0932;
  border-color: #9a0932; }

.theme-halland #testpartial .answer .yesnobutton.active.yesBtn {
  color: #fff;
  background-color: #8E0000;
  border-color: #8E0000; }

.theme-orebro #testpartial .answer .yesnobutton.active.yesBtn {
  color: #fff;
  background-color: #E32424;
  border-color: #E32424; }

.theme-rg #testpartial .answer .yesnobutton.active.yesBtn {
  color: #fff;
  background-color: #ee3780;
  border-color: #ee3780; }

.theme-default #testdropdown, .theme-default #diagnosisvariationselect {
  border-color: #000;
  background-color: #fff;
  color: #000; }

.theme-helsa #testdropdown, .theme-helsa #diagnosisvariationselect {
  border-color: #000;
  background-color: #fff;
  color: #000; }

.theme-ptj #testdropdown, .theme-ptj #diagnosisvariationselect {
  border-color: #333333;
  background-color: #fff;
  color: #333333; }

.theme-rjl #testdropdown, .theme-rjl #diagnosisvariationselect {
  border-color: #000;
  background-color: #fff;
  color: #000; }

.theme-achima #testdropdown, .theme-achima #diagnosisvariationselect {
  border-color: #000;
  background-color: #fff;
  color: #000; }

.theme-blekinge #testdropdown, .theme-blekinge #diagnosisvariationselect {
  border-color: #000;
  background-color: #fff;
  color: #000; }

.theme-sll #testdropdown, .theme-sll #diagnosisvariationselect {
  border-color: #000;
  background-color: #fff;
  color: #000; }

.theme-halland #testdropdown, .theme-halland #diagnosisvariationselect {
  border-color: #000;
  background-color: #fff;
  color: #000; }

.theme-orebro #testdropdown, .theme-orebro #diagnosisvariationselect {
  border-color: #000;
  background-color: #fff;
  color: #000; }

.theme-rg #testdropdown, .theme-rg #diagnosisvariationselect {
  border-color: #000;
  background-color: #fff;
  color: #000; }

/*col MD*/
@media screen and (min-width: 992px) {
  .doctorViewCSS .answer {
    float: right; } }

.doctorViewCSS .yesnobutton {
  min-width: 40px;
  width: auto;
  margin-top: 3px;
  margin-bottom: 3px; }

.theme-default .admin .btn {
  padding: 1px 5px; }

.theme-helsa .admin .btn {
  padding: 1px 5px; }

.theme-ptj .admin .btn {
  padding: 1px 5px; }

.theme-rjl .admin .btn {
  padding: 1px 5px; }

.theme-achima .admin .btn {
  padding: 1px 5px; }

.theme-blekinge .admin .btn {
  padding: 1px 5px; }

.theme-sll .admin .btn {
  padding: 1px 5px; }

.theme-halland .admin .btn {
  padding: 1px 5px; }

.theme-orebro .admin .btn {
  padding: 1px 5px; }

.theme-rg .admin .btn {
  padding: 1px 5px; }

.patientanswer .btn {
  width: calc(50% - 8px);
  display: inline-block;
  min-width: 50px;
  font-size: 18px;
  padding-bottom: 5px;
  padding-top: 5px; }

.patientanswer .leftBtn {
  margin-right: 5px; }

.patientanswer .rightBtn {
  margin-left: 5px;
  margin-right: -20px; }

.confirm-dialog {
  font-size: 24px; }

/*---------------------------[ Test Button ]----------------------------*/
/* LARGE screens sizes */
@media screen and (min-width: 768px) {
  .showTestButton {
    margin-top: -4px; } }

/* SMALL screens sizes */
@media screen and (max-width: 767px) {
  .showTestButton {
    margin-top: -32px; } }

.remove-button {
  float: right;
  background-color: transparent;
  border: none;
  margin-left: -40px; }
  .theme-default .remove-button {
    color: #000; }
  .theme-helsa .remove-button {
    color: #000; }
  .theme-ptj .remove-button {
    color: #333333; }
  .theme-rjl .remove-button {
    color: #000; }
  .theme-achima .remove-button {
    color: #000; }
  .theme-blekinge .remove-button {
    color: #000; }
  .theme-sll .remove-button {
    color: #000; }
  .theme-halland .remove-button {
    color: #000; }
  .theme-orebro .remove-button {
    color: #000; }
  .theme-rg .remove-button {
    color: #000; }

.remove-button-nomargin {
  float: right;
  background-color: transparent;
  border: none; }
  .theme-default .remove-button-nomargin {
    color: #000; }
  .theme-helsa .remove-button-nomargin {
    color: #000; }
  .theme-ptj .remove-button-nomargin {
    color: #333333; }
  .theme-rjl .remove-button-nomargin {
    color: #000; }
  .theme-achima .remove-button-nomargin {
    color: #000; }
  .theme-blekinge .remove-button-nomargin {
    color: #000; }
  .theme-sll .remove-button-nomargin {
    color: #000; }
  .theme-halland .remove-button-nomargin {
    color: #000; }
  .theme-orebro .remove-button-nomargin {
    color: #000; }
  .theme-rg .remove-button-nomargin {
    color: #000; }

.testdropdown .btn, .testdropdown .dropdown-menu {
  width: 100%; }
  .theme-default .testdropdown .btn, .theme-default .testdropdown .dropdown-menu {
    border: 1px solid #999; }
  .theme-helsa .testdropdown .btn, .theme-helsa .testdropdown .dropdown-menu {
    border: 1px solid #CCCCCC; }
  .theme-ptj .testdropdown .btn, .theme-ptj .testdropdown .dropdown-menu {
    border: 1px solid #333333; }
  .theme-rjl .testdropdown .btn, .theme-rjl .testdropdown .dropdown-menu {
    border: 1px solid #000; }
  .theme-achima .testdropdown .btn, .theme-achima .testdropdown .dropdown-menu {
    border: 1px solid #777; }
  .theme-blekinge .testdropdown .btn, .theme-blekinge .testdropdown .dropdown-menu {
    border: 1px solid #000; }
  .theme-sll .testdropdown .btn, .theme-sll .testdropdown .dropdown-menu {
    border: 1px solid #685c53; }
  .theme-halland .testdropdown .btn, .theme-halland .testdropdown .dropdown-menu {
    border: 1px solid #AAAAAA; }
  .theme-orebro .testdropdown .btn, .theme-orebro .testdropdown .dropdown-menu {
    border: 1px solid #C8E1EB; }
  .theme-rg .testdropdown .btn, .theme-rg .testdropdown .dropdown-menu {
    border: 1px solid #ccc; }

.theme-default .testdropdown dropdown-menu li a:hover {
  background-color: #F1F1F1; }

.theme-helsa .testdropdown dropdown-menu li a:hover {
  background-color: #E7E7E8; }

.theme-ptj .testdropdown dropdown-menu li a:hover {
  background-color: #F1F1F1; }

.theme-rjl .testdropdown dropdown-menu li a:hover {
  background-color: #F1F1F1; }

.theme-achima .testdropdown dropdown-menu li a:hover {
  background-color: #EDEDED; }

.theme-blekinge .testdropdown dropdown-menu li a:hover {
  background-color: #E5F6FC; }

.theme-sll .testdropdown dropdown-menu li a:hover {
  background-color: #e0ded9; }

.theme-halland .testdropdown dropdown-menu li a:hover {
  background-color: #F5F5F5; }

.theme-orebro .testdropdown dropdown-menu li a:hover {
  background-color: #D1E9F2; }

.theme-rg .testdropdown dropdown-menu li a:hover {
  background-color: #F6F6F6; }

/*--------------------[ Follow Up Form Button ]---------------------*/
.followUpOtionsDiv {
  cursor: pointer; }

.theme-default .followUpOption3 {
  color: #4FD142; }

.theme-helsa .followUpOption3 {
  color: #6CB680; }

.theme-ptj .followUpOption3 {
  color: #B6BF02; }

.theme-rjl .followUpOption3 {
  color: #579835; }

.theme-achima .followUpOption3 {
  color: #00ADA8; }

.theme-blekinge .followUpOption3 {
  color: #288749; }

.theme-sll .followUpOption3 {
  color: #78be00; }

.theme-halland .followUpOption3 {
  color: #4E9023; }

.theme-orebro .followUpOption3 {
  color: #9FC63B; }

.theme-rg .followUpOption3 {
  color: #50b848; }

.followUpOption2 {
  color: #b4c700; }

.followUpOption1 {
  color: #FF7B0A; }

.theme-default .followUpOption0 {
  color: #E32424; }

.theme-helsa .followUpOption0 {
  color: #F48174; }

.theme-ptj .followUpOption0 {
  color: #F54359; }

.theme-rjl .followUpOption0 {
  color: #ce1141; }

.theme-achima .followUpOption0 {
  color: #AA2B23; }

.theme-blekinge .followUpOption0 {
  color: #d70480; }

.theme-sll .followUpOption0 {
  color: #9a0932; }

.theme-halland .followUpOption0 {
  color: #8E0000; }

.theme-orebro .followUpOption0 {
  color: #E32424; }

.theme-rg .followUpOption0 {
  color: #ee3780; }

.followUpOption4 {
  color: #AAAAAA; }

/*-----------------------[ Power BI Button ]------------------------*/
/* For the Power BI report */
#print-button {
  z-index: 1;
  position: absolute;
  margin-left: 15px;
  margin-top: -8px; }

/*---------------------------[ Links ]----------------------------*/
#secondaryDiagnosisLink {
  font-weight: bold; }

/*---------------------------[ Circle ]----------------------------*/
.circle {
  border-radius: 50%;
  display: inline-block;
  position: relative;
  left: 25px;
  top: 2px; }

#circle1 {
  width: 33px;
  height: 33px; }
  .theme-default #circle1 {
    background: #4FD142; }
  .theme-helsa #circle1 {
    background: #6CB680; }
  .theme-ptj #circle1 {
    background: #B6BF02; }
  .theme-rjl #circle1 {
    background: #579835; }
  .theme-achima #circle1 {
    background: #00ADA8; }
  .theme-blekinge #circle1 {
    background: #288749; }
  .theme-sll #circle1 {
    background: #78be00; }
  .theme-halland #circle1 {
    background: #4E9023; }
  .theme-orebro #circle1 {
    background: #9FC63B; }
  .theme-rg #circle1 {
    background: #50b848; }
