/* SASS variables and intellisense */
/*---------------------------------------------------------------------------
                             FOOTER
---------------------------------------------------------------------------*/
.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 60px;
  padding-top: 10px;
  padding-left: 16px;
  z-index: 8; }
  .theme-default .footer {
    background-color: #F1F1F1;
    border-top: 1px solid #CCC; }
  .theme-helsa .footer {
    background-color: #D7EAD9;
    border-top: 1px solid #6CB680; }
  .theme-ptj .footer {
    background-color: #00A0C8;
    border-top: 1px solid transparent; }
  .theme-rjl .footer {
    background-color: #E4E4E4;
    border-top: 1px solid #E4E4E4; }
  .theme-achima .footer {
    background-color: #777;
    border-top: 1px solid #777; }
  .theme-blekinge .footer {
    background-color: #222222;
    border-top: 1px solid #222222; }
  .theme-sll .footer {
    background-color: #00aeef;
    border-top: 1px solid #002d5a; }
  .theme-halland .footer {
    background-color: #D1E6F5;
    border-top: 1px solid #6DACDE; }
  .theme-orebro .footer {
    background-color: #76B82A;
    border-top: 1px solid #6ca728; }
  .theme-rg .footer {
    background-color: #DCF1DA;
    border-top: 0px solid #DCF1DA; }

.secondFooter {
  position: fixed;
  bottom: 59px;
  width: 100%;
  padding-top: 10px;
  padding-left: 16px;
  z-index: 9; }
  .theme-default .secondFooter {
    border-top: 1px solid #CCC;
    border-bottom: 1px solid #F1F1F1;
    background-color: #F1F1F1; }
  .theme-helsa .secondFooter {
    border-top: 1px solid #6CB680;
    border-bottom: 1px solid #D7EAD9;
    background-color: #D7EAD9; }
  .theme-ptj .secondFooter {
    border-top: 1px solid transparent;
    border-bottom: 1px solid #00A0C8;
    background-color: #00A0C8; }
  .theme-rjl .secondFooter {
    border-top: 1px solid #E4E4E4;
    border-bottom: 1px solid #E4E4E4;
    background-color: #E4E4E4; }
  .theme-achima .secondFooter {
    border-top: 1px solid #777;
    border-bottom: 1px solid #777;
    background-color: #777; }
  .theme-blekinge .secondFooter {
    border-top: 1px solid #222222;
    border-bottom: 1px solid #222222;
    background-color: #222222; }
  .theme-sll .secondFooter {
    border-top: 1px solid #002d5a;
    border-bottom: 1px solid #00aeef;
    background-color: #00aeef; }
  .theme-halland .secondFooter {
    border-top: 1px solid #6DACDE;
    border-bottom: 1px solid #D1E6F5;
    background-color: #D1E6F5; }
  .theme-orebro .secondFooter {
    border-top: 1px solid #6ca728;
    border-bottom: 1px solid #76B82A;
    background-color: #76B82A; }
  .theme-rg .secondFooter {
    border-top: 0px solid #DCF1DA;
    border-bottom: 0px solid #DCF1DA;
    background-color: #DCF1DA; }

.secondFooter .container {
  padding-left: 0;
  padding-right: 0; }

.panel-collapse {
  padding-top: 15px; }

.customFooter {
  position: fixed;
  bottom: 0;
  width: 100%; }

#infoSection {
  position: fixed;
  bottom: 0;
  z-index: -1; }

.theme-default .bottomPageInformation, .theme-default .bottomPageInformation span, .theme-default .bottomPageInformation p {
  opacity: 0.8; }

.theme-helsa .bottomPageInformation, .theme-helsa .bottomPageInformation span, .theme-helsa .bottomPageInformation p {
  opacity: 0.8; }

.theme-ptj .bottomPageInformation, .theme-ptj .bottomPageInformation span, .theme-ptj .bottomPageInformation p {
  opacity: 0.8; }

.theme-rjl .bottomPageInformation, .theme-rjl .bottomPageInformation span, .theme-rjl .bottomPageInformation p {
  opacity: 0.8; }

.theme-achima .bottomPageInformation, .theme-achima .bottomPageInformation span, .theme-achima .bottomPageInformation p {
  opacity: 0.8; }

.theme-blekinge .bottomPageInformation, .theme-blekinge .bottomPageInformation span, .theme-blekinge .bottomPageInformation p {
  opacity: 0.8; }

.theme-sll .bottomPageInformation, .theme-sll .bottomPageInformation span, .theme-sll .bottomPageInformation p {
  opacity: 0.8; }

.theme-halland .bottomPageInformation, .theme-halland .bottomPageInformation span, .theme-halland .bottomPageInformation p {
  opacity: 0.8; }

.theme-orebro .bottomPageInformation, .theme-orebro .bottomPageInformation span, .theme-orebro .bottomPageInformation p {
  opacity: 0.8; }

.theme-rg .bottomPageInformation, .theme-rg .bottomPageInformation span, .theme-rg .bottomPageInformation p {
  opacity: 0.8; }
