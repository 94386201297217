


/*---------------- Adding font families from file -------------------------*/

/* Collabodoc Heading */
@font-face {
    font-family: 'proximanova-bold, sans-serif';
    src: url('./../fonts/Proxima_Nova/Proxima Nova Bold.otf') format('truetype');
    src: url('./../fonts/Proxima_Nova/ProximaNova-Bold.ttf') format('truetype');
}

/* Collabodoc Paragraph */
@font-face {
    font-family: 'proximanova-regular, sans-serif';
    src: url('./../fonts/Proxima_Nova/Proxima Nova Regular.otf') format('truetype');
    src: url('./../fonts/Proxima_Nova/ProximaNova-Regular.ttf') format('truetype');
}

/* Helsa Heading */
@font-face {
    font-family: 'source-sans-bold, sans-serif';
    src: url('./../fonts/Source_Sans/SourceSansPro-Bold.ttf') format('truetype');
}

/* Helsa Paragraph */
@font-face {
    font-family: 'source-sans-regular, sans-serif';
    src: url('./../fonts/Source_Sans/SourceSansPro-Regular.ttf') format('truetype');
}

/* PTJ Heading */
@font-face {
    font-family: 'source-sans-pro-sb, sans-serif';
    src: url('./../fonts/Source_Sans_Pro/SourceSansPro-SemiBold.ttf') format('truetype');
}

/* PTJ Paragraph */
@font-face {
    font-family: 'source-sans-pro-r, sans-serif';
    src: url('./../fonts/Source_Sans_Pro/SourceSansPro-Regular.ttf') format('truetype');
}


/* rjl Heading */
@font-face {
    font-family: 'open-sans-semibold, sans-serif';
    src: url('./../fonts/Open_Sans/OpenSans-SemiBold.ttf') format('truetype');
}

/* rjl Paragraph */
@font-face {
    font-family: 'open-sans-regular, sans-serif';
    src: url('./../fonts/Open_Sans/OpenSans-Regular.ttf') format('truetype');
}

/* achima Heading */
@font-face {
    font-family: 'lato-bold, sans-serif';
    src: url('./../fonts/Lato/Lato-Bold.ttf') format('truetype');
}

/* achima Paragraph */
@font-face {
    font-family: 'lato-regular, sans-serif';
    src: url('./../fonts/Lato/Lato-Regular.ttf') format('truetype');
}

/* Örebro Heading */
@font-face {
    font-family: 'arial-bold, sans-serif';
    src: url('./../fonts/Arial/arialbd.ttf') format('truetype');
}

/* Örebro Paragraph */
@font-face {
    font-family: 'arial-regular, sans-serif';
    src: url('./../fonts/Arial/arial.ttf') format('truetype');
}

/* Örebro Heading and Paragraph*/
@font-face {
    font-family: 'museo-sans, sans-serif';
    src: url('./../fonts/Museo_Sans/MuseoSans_500.ttf') format('truetype');
}
