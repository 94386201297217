/* SASS variables and intellisense */
/*---------------------------------------------------------------------------
                             DIV & ROW 
---------------------------------------------------------------------------*/
.well {
  padding: 15px 30px 5px; }
  .theme-default .well {
    background-color: #D0F8F5;
    border: 1px solid #A0F0EB; }
  .theme-helsa .well {
    background-color: #D7EAD9;
    border: 1px solid #BEDCC1; }
  .theme-ptj .well {
    background-color: #F3F3F3;
    border: 1px solid #CCCCCC; }
  .theme-rjl .well {
    background-color: #EDEDED;
    border: 1px solid #EDEDED; }
  .theme-achima .well {
    background-color: #EDEDED;
    border: 1px solid #EDEDED; }
  .theme-blekinge .well {
    background-color: #E5F6FC;
    border: 1px solid #E5F6FC; }
  .theme-sll .well {
    background-color: #FFFFFF;
    border: 1px solid #FFFFFF; }
  .theme-halland .well {
    background-color: #D3E6F5;
    border: 1px solid #6DACDE; }
  .theme-orebro .well {
    background-color: #E3F2F8;
    border: 1px solid #C8E1EB; }
  .theme-rg .well {
    background-color: #F6F6F6;
    border: 0px solid #ccc; }

.queue-div {
  position: relative;
  height: 80%; }

.theme-default .queue-block {
  background-color: #D0F8F5;
  border: 1px solid #A0F0EB;
  padding: 15px;
  overflow: hidden; }

.theme-helsa .queue-block {
  background-color: #D7EAD9;
  border: 1px solid #BEDCC1;
  padding: 15px;
  overflow: hidden; }

.theme-ptj .queue-block {
  background-color: #F3F3F3;
  border: 1px solid #CCCCCC;
  padding: 15px;
  overflow: hidden; }

.theme-rjl .queue-block {
  background-color: #EDEDED;
  border: 1px solid #EDEDED;
  padding: 15px;
  overflow: hidden; }

.theme-achima .queue-block {
  background-color: #EDEDED;
  border: 1px solid #EDEDED;
  padding: 15px;
  overflow: hidden; }

.theme-blekinge .queue-block {
  background-color: #E5F6FC;
  border: 1px solid #E5F6FC;
  padding: 15px;
  overflow: hidden; }

.theme-sll .queue-block {
  background-color: #FFFFFF;
  border: 1px solid #FFFFFF;
  padding: 15px;
  overflow: hidden; }

.theme-halland .queue-block {
  background-color: #D3E6F5;
  border: 1px solid #6DACDE;
  padding: 15px;
  overflow: hidden; }

.theme-orebro .queue-block {
  background-color: #E3F2F8;
  border: 1px solid #C8E1EB;
  padding: 15px;
  overflow: hidden; }

.theme-rg .queue-block {
  background-color: #F6F6F6;
  border: 0px solid #ccc;
  padding: 15px;
  overflow: hidden; }

@media screen and (max-width: 768px) {
  .well {
    margin-left: -15px;
    margin-right: -15px;
    border-radius: 0;
    border-left: none;
    border-right: none;
    padding-left: 15px;
    padding-right: 15px; }
  .queue-div {
    margin-left: -15px;
    margin-right: -15px; } }

@media screen and (min-width: 768px) {
  .well {
    margin-top: 20px;
    margin-bottom: 20px; } }

/*---------------------------------------------------------------------------
                           DOCTORS VIEW
---------------------------------------------------------------------------*/
.admin-section {
  padding: 20px 0; }

/*------------------------  SYMPTOM FORM QUESTIONS ------------------------*/
.admin .question-block {
  padding: 0 4px;
  margin-right: 1px;
  margin-left: 1px; }
  .theme-default .admin .question-block {
    background-color: #F1F1F1;
    border: 1px solid #CCC; }
  .theme-helsa .admin .question-block {
    background-color: #F3F6F6;
    border: 1px solid #CCCCCC; }
  .theme-ptj .admin .question-block {
    background-color: #ECECEC;
    border: 1px solid #e0e0e0; }
  .theme-rjl .admin .question-block {
    background-color: #EDEDED;
    border: 1px solid #EDEDED; }
  .theme-achima .admin .question-block {
    background-color: #EDEDED;
    border: 1px solid #777; }
  .theme-blekinge .admin .question-block {
    background-color: #E5F6FC;
    border: 1px solid #dbeaef; }
  .theme-sll .admin .question-block {
    background-color: #e0ded9;
    border: 1px solid #e0ded9; }
  .theme-halland .admin .question-block {
    background-color: #F5F5F5;
    border: 1px solid #DDDDDD; }
  .theme-orebro .admin .question-block {
    background-color: #E3F2F8;
    border: 1px solid #C8E1EB; }
  .theme-rg .admin .question-block {
    background-color: #F6F6F6;
    border: 0px solid #ccc; }

.theme-default .admin .question-block.question-block-danger {
  background-color: #faa4a4; }

.theme-helsa .admin .question-block.question-block-danger {
  background-color: #F48174; }

.theme-ptj .admin .question-block.question-block-danger {
  background-color: #faa4a4; }

.theme-rjl .admin .question-block.question-block-danger {
  background-color: #ef4044; }

.theme-achima .admin .question-block.question-block-danger {
  background-color: #BB7167; }

.theme-blekinge .admin .question-block.question-block-danger {
  background-color: #de7ab5; }

.theme-sll .admin .question-block.question-block-danger {
  background-color: #e278aa; }

.theme-halland .admin .question-block.question-block-danger {
  background-color: #ff9c9c; }

.theme-orebro .admin .question-block.question-block-danger {
  background-color: #faa4a4; }

.theme-rg .admin .question-block.question-block-danger {
  background-color: #fac3d8; }

/*LG screen size*/
@media screen and (min-width: 1200px) {
  .admin .panel .mid-col {
    padding-left: 0;
    padding-right: 0; } }

/*MD and SM screen size*/
@media screen and (max-width: 1200px) and (min-width: 768px) {
  .admin .panel .mid-col,
  .admin .panel .right-col,
  .admin .panel #commentBoxID {
    padding-left: 0; } }

.admin .answer .yesnobutton, .admin .answer .btn {
  margin-top: 0;
  margin-bottom: 0; }

.admin .question-phrase, .admin .answerCssClass {
  margin: 0;
  padding: 0; }

/*------------------------------  Journal note ----------------------------*/
.journalMenuBody {
  padding: 10px; }

.journalMenuBody .form-horizontal .form-group {
  margin: 0px; }

.journalMenuBody .form-horizontal .form-group label input {
  display: inline-block; }

/*--------------------------  Symptom Form Questions ------------------------*/
.symptomFormQuestionsSection .row {
  margin-left: 0;
  margin-right: 0; }

.theme-default .select-form {
  background: #F1F1F1; }

.theme-helsa .select-form {
  background: #E7E7E8; }

.theme-ptj .select-form {
  background: #F1F1F1; }

.theme-rjl .select-form {
  background: #F1F1F1; }

.theme-achima .select-form {
  background: #EDEDED; }

.theme-blekinge .select-form {
  background: #E5F6FC; }

.theme-sll .select-form {
  background: #e0ded9; }

.theme-halland .select-form {
  background: #F5F5F5; }

.theme-orebro .select-form {
  background: #D1E9F2; }

.theme-rg .select-form {
  background: #F6F6F6; }

.theme-default .question-block.row.RowWithCheckBoxOrRadioBtn {
  margin-top: -20px;
  border-top: 1px solid #D0F8F5;
  padding-top: 0; }

.theme-helsa .question-block.row.RowWithCheckBoxOrRadioBtn {
  margin-top: -20px;
  border-top: 1px solid #D7EAD9;
  padding-top: 0; }

.theme-ptj .question-block.row.RowWithCheckBoxOrRadioBtn {
  margin-top: -20px;
  border-top: 1px solid #F3F3F3;
  padding-top: 0; }

.theme-rjl .question-block.row.RowWithCheckBoxOrRadioBtn {
  margin-top: -20px;
  border-top: 1px solid #EDEDED;
  padding-top: 0; }

.theme-achima .question-block.row.RowWithCheckBoxOrRadioBtn {
  margin-top: -20px;
  border-top: 1px solid #EDEDED;
  padding-top: 0; }

.theme-blekinge .question-block.row.RowWithCheckBoxOrRadioBtn {
  margin-top: -20px;
  border-top: 1px solid #E5F6FC;
  padding-top: 0; }

.theme-sll .question-block.row.RowWithCheckBoxOrRadioBtn {
  margin-top: -20px;
  border-top: 1px solid #FFFFFF;
  padding-top: 0; }

.theme-halland .question-block.row.RowWithCheckBoxOrRadioBtn {
  margin-top: -20px;
  border-top: 1px solid #D3E6F5;
  padding-top: 0; }

.theme-orebro .question-block.row.RowWithCheckBoxOrRadioBtn {
  margin-top: -20px;
  border-top: 1px solid #E3F2F8;
  padding-top: 0; }

.theme-rg .question-block.row.RowWithCheckBoxOrRadioBtn {
  margin-top: -18px;
  border-top: 0px solid #F6F6F6;
  padding-top: 0; }

.question-block.row.child, .child {
  margin-left: 16px; }

.question-block.row.childchild, .childchild {
  margin-left: 32px; }

.question-block.row.childchildchild, .childchildchild {
  margin-left: 48px; }

.question-block.row {
  margin-bottom: 10px; }

@media screen and (max-width: 768px) {
  .patientanswer, .slider.row {
    margin-top: 10px; } }

.mobileApplication .patientanswer, .slider.row {
  margin-top: 10px; }

/*----------------------------  Audit Logs --------------------------------*/
@media screen and (min-width: 768px) {
  .bigControl {
    height: 50px;
    margin-bottom: 20px; } }

/*---------------------------------------------------------------------------
                           BOOTSTRAP ALERTS
---------------------------------------------------------------------------*/
/*-----------------------  Icon and Text placement -------------------------*/
.alert-icon-div {
  margin-right: 12px; }

@media screen and (max-width: 768px) {
  .fa-alert-message {
    font-size: 40px; } }

@media screen and (min-width: 768px) {
  .fa-alert-message {
    font-size: 60px; } }

/*------------------------ Alert-box Colors ----------------------------*/
.alert-box {
  padding: 15px;
  position: relative;
  z-index: 1;
  margin-bottom: 15px; }

.alert-content {
  position: relative;
  z-index: 5;
  background-color: transparent; }

.alert-background {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 2;
  /* These three lines are for transparency in all browsers. */
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=25)";
  filter: alpha(opacity=25);
  opacity: .25; }

.alert-background.alert-secondary, .alert-background.alert-warning, .alert-background.alert-info, .alert-background.alert-light {
  /* These three lines are for transparency in all browsers. */
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=1)";
  filter: alpha(opacity=100);
  opacity: 1; }

/* Success */
.theme-default .alert-content.alert-success, .theme-default .alert-content.alert-success h3 {
  color: #4FD142; }

.theme-helsa .alert-content.alert-success, .theme-helsa .alert-content.alert-success h3 {
  color: #6CB680; }

.theme-ptj .alert-content.alert-success, .theme-ptj .alert-content.alert-success h3 {
  color: #B6BF02; }

.theme-rjl .alert-content.alert-success, .theme-rjl .alert-content.alert-success h3 {
  color: #579835; }

.theme-achima .alert-content.alert-success, .theme-achima .alert-content.alert-success h3 {
  color: #00ADA8; }

.theme-blekinge .alert-content.alert-success, .theme-blekinge .alert-content.alert-success h3 {
  color: #288749; }

.theme-sll .alert-content.alert-success, .theme-sll .alert-content.alert-success h3 {
  color: #78be00; }

.theme-halland .alert-content.alert-success, .theme-halland .alert-content.alert-success h3 {
  color: #4E9023; }

.theme-orebro .alert-content.alert-success, .theme-orebro .alert-content.alert-success h3 {
  color: #9FC63B; }

.theme-rg .alert-content.alert-success, .theme-rg .alert-content.alert-success h3 {
  color: #50b848; }

.theme-default .alert-background.alert-success {
  background-color: #4FD142; }

.theme-helsa .alert-background.alert-success {
  background-color: #6CB680; }

.theme-ptj .alert-background.alert-success {
  background-color: #B6BF02; }

.theme-rjl .alert-background.alert-success {
  background-color: #579835; }

.theme-achima .alert-background.alert-success {
  background-color: #00ADA8; }

.theme-blekinge .alert-background.alert-success {
  background-color: #288749; }

.theme-sll .alert-background.alert-success {
  background-color: #78be00; }

.theme-halland .alert-background.alert-success {
  background-color: #4E9023; }

.theme-orebro .alert-background.alert-success {
  background-color: #9FC63B; }

.theme-rg .alert-background.alert-success {
  background-color: #50b848; }

/* Secondary */
.theme-default .alert-content.alert-secondary, .theme-default .alert-content.alert-secondary h3, .theme-default .alert-content.alert-secondary p {
  color: #0A4A7C; }

.theme-helsa .alert-content.alert-secondary, .theme-helsa .alert-content.alert-secondary h3, .theme-helsa .alert-content.alert-secondary p {
  color: #fff; }

.theme-ptj .alert-content.alert-secondary, .theme-ptj .alert-content.alert-secondary h3, .theme-ptj .alert-content.alert-secondary p {
  color: #fff; }

.theme-rjl .alert-content.alert-secondary, .theme-rjl .alert-content.alert-secondary h3, .theme-rjl .alert-content.alert-secondary p {
  color: #fff; }

.theme-achima .alert-content.alert-secondary, .theme-achima .alert-content.alert-secondary h3, .theme-achima .alert-content.alert-secondary p {
  color: #fff; }

.theme-blekinge .alert-content.alert-secondary, .theme-blekinge .alert-content.alert-secondary h3, .theme-blekinge .alert-content.alert-secondary p {
  color: #fff; }

.theme-sll .alert-content.alert-secondary, .theme-sll .alert-content.alert-secondary h3, .theme-sll .alert-content.alert-secondary p {
  color: #fff; }

.theme-halland .alert-content.alert-secondary, .theme-halland .alert-content.alert-secondary h3, .theme-halland .alert-content.alert-secondary p {
  color: #fff; }

.theme-orebro .alert-content.alert-secondary, .theme-orebro .alert-content.alert-secondary h3, .theme-orebro .alert-content.alert-secondary p {
  color: #fff; }

.theme-rg .alert-content.alert-secondary, .theme-rg .alert-content.alert-secondary h3, .theme-rg .alert-content.alert-secondary p {
  color: #fff; }

.theme-default .alert-background.alert-secondary {
  background-color: #FFE150; }

.theme-helsa .alert-background.alert-secondary {
  background-color: #6CB680; }

.theme-ptj .alert-background.alert-secondary {
  background-color: #E78300; }

.theme-rjl .alert-background.alert-secondary {
  background-color: #579835; }

.theme-achima .alert-background.alert-secondary {
  background-color: #00ADA8; }

.theme-blekinge .alert-background.alert-secondary {
  background-color: #193F76; }

.theme-sll .alert-background.alert-secondary {
  background-color: #e1056d; }

.theme-halland .alert-background.alert-secondary {
  background-color: #4E9023; }

.theme-orebro .alert-background.alert-secondary {
  background-color: #9FC63B; }

.theme-rg .alert-background.alert-secondary {
  background-color: #50b848; }

/* primary */
.theme-default .alert-content.alert-primary, .theme-default .alert-content.alert-primary h3 {
  color: #0A4A7C; }

.theme-helsa .alert-content.alert-primary, .theme-helsa .alert-content.alert-primary h3 {
  color: #1D5E74; }

.theme-ptj .alert-content.alert-primary, .theme-ptj .alert-content.alert-primary h3 {
  color: #0075B0; }

.theme-rjl .alert-content.alert-primary, .theme-rjl .alert-content.alert-primary h3 {
  color: #579835; }

.theme-achima .alert-content.alert-primary, .theme-achima .alert-content.alert-primary h3 {
  color: #AA2B23; }

.theme-blekinge .alert-content.alert-primary, .theme-blekinge .alert-content.alert-primary h3 {
  color: #00A6E2; }

.theme-sll .alert-content.alert-primary, .theme-sll .alert-content.alert-primary h3 {
  color: #002d5a; }

.theme-halland .alert-content.alert-primary, .theme-halland .alert-content.alert-primary h3 {
  color: #7BB1DB; }

.theme-orebro .alert-content.alert-primary, .theme-orebro .alert-content.alert-primary h3 {
  color: #4F80FF; }

.theme-rg .alert-content.alert-primary, .theme-rg .alert-content.alert-primary h3 {
  color: #0097cf; }

.theme-default .alert-background.alert-primary {
  background-color: #0A4A7C; }

.theme-helsa .alert-background.alert-primary {
  background-color: #1D5E74; }

.theme-ptj .alert-background.alert-primary {
  background-color: #0075B0; }

.theme-rjl .alert-background.alert-primary {
  background-color: #579835; }

.theme-achima .alert-background.alert-primary {
  background-color: #AA2B23; }

.theme-blekinge .alert-background.alert-primary {
  background-color: #00A6E2; }

.theme-sll .alert-background.alert-primary {
  background-color: #002d5a; }

.theme-halland .alert-background.alert-primary {
  background-color: #7BB1DB; }

.theme-orebro .alert-background.alert-primary {
  background-color: #4F80FF; }

.theme-rg .alert-background.alert-primary {
  background-color: #0097cf; }

/* danger */
.theme-default .alert-content.alert-danger, .theme-default .alert-content.alert-danger h3 {
  color: #E32424; }

.theme-helsa .alert-content.alert-danger, .theme-helsa .alert-content.alert-danger h3 {
  color: #F48174; }

.theme-ptj .alert-content.alert-danger, .theme-ptj .alert-content.alert-danger h3 {
  color: #F54359; }

.theme-rjl .alert-content.alert-danger, .theme-rjl .alert-content.alert-danger h3 {
  color: #ce1141; }

.theme-achima .alert-content.alert-danger, .theme-achima .alert-content.alert-danger h3 {
  color: #AA2B23; }

.theme-blekinge .alert-content.alert-danger, .theme-blekinge .alert-content.alert-danger h3 {
  color: #d70480; }

.theme-sll .alert-content.alert-danger, .theme-sll .alert-content.alert-danger h3 {
  color: #9a0932; }

.theme-halland .alert-content.alert-danger, .theme-halland .alert-content.alert-danger h3 {
  color: #8E0000; }

.theme-orebro .alert-content.alert-danger, .theme-orebro .alert-content.alert-danger h3 {
  color: #E32424; }

.theme-rg .alert-content.alert-danger, .theme-rg .alert-content.alert-danger h3 {
  color: #ee3780; }

.theme-default .alert-background.alert-danger {
  background-color: #E32424; }

.theme-helsa .alert-background.alert-danger {
  background-color: #F48174; }

.theme-ptj .alert-background.alert-danger {
  background-color: #F54359; }

.theme-rjl .alert-background.alert-danger {
  background-color: #ce1141; }

.theme-achima .alert-background.alert-danger {
  background-color: #AA2B23; }

.theme-blekinge .alert-background.alert-danger {
  background-color: #d70480; }

.theme-sll .alert-background.alert-danger {
  background-color: #9a0932; }

.theme-halland .alert-background.alert-danger {
  background-color: #8E0000; }

.theme-orebro .alert-background.alert-danger {
  background-color: #E32424; }

.theme-rg .alert-background.alert-danger {
  background-color: #ee3780; }

/* warning */
.theme-default .alert-content.alert-warning, .theme-default .alert-content.alert-warning h3, .theme-default .alert-content.alert-warning p {
  color: #0A4A7C; }

.theme-helsa .alert-content.alert-warning, .theme-helsa .alert-content.alert-warning h3, .theme-helsa .alert-content.alert-warning p {
  color: #1D5E74; }

.theme-ptj .alert-content.alert-warning, .theme-ptj .alert-content.alert-warning h3, .theme-ptj .alert-content.alert-warning p {
  color: #0075B0; }

.theme-rjl .alert-content.alert-warning, .theme-rjl .alert-content.alert-warning h3, .theme-rjl .alert-content.alert-warning p {
  color: #579835; }

.theme-achima .alert-content.alert-warning, .theme-achima .alert-content.alert-warning h3, .theme-achima .alert-content.alert-warning p {
  color: #AA2B23; }

.theme-blekinge .alert-content.alert-warning, .theme-blekinge .alert-content.alert-warning h3, .theme-blekinge .alert-content.alert-warning p {
  color: #00A6E2; }

.theme-sll .alert-content.alert-warning, .theme-sll .alert-content.alert-warning h3, .theme-sll .alert-content.alert-warning p {
  color: #002d5a; }

.theme-halland .alert-content.alert-warning, .theme-halland .alert-content.alert-warning h3, .theme-halland .alert-content.alert-warning p {
  color: #7BB1DB; }

.theme-orebro .alert-content.alert-warning, .theme-orebro .alert-content.alert-warning h3, .theme-orebro .alert-content.alert-warning p {
  color: #4F80FF; }

.theme-rg .alert-content.alert-warning, .theme-rg .alert-content.alert-warning h3, .theme-rg .alert-content.alert-warning p {
  color: #0097cf; }

.theme-default .alert-background.alert-warning {
  background-color: #FFE150; }

.theme-helsa .alert-background.alert-warning {
  background-color: #FFE150; }

.theme-ptj .alert-background.alert-warning {
  background-color: #F9B360; }

.theme-rjl .alert-background.alert-warning {
  background-color: #fdb913; }

.theme-achima .alert-background.alert-warning {
  background-color: #FFE150; }

.theme-blekinge .alert-background.alert-warning {
  background-color: #FFE150; }

.theme-sll .alert-background.alert-warning {
  background-color: #ffd400; }

.theme-halland .alert-background.alert-warning {
  background-color: #FFE150; }

.theme-orebro .alert-background.alert-warning {
  background-color: #FFE150; }

.theme-rg .alert-background.alert-warning {
  background-color: #faa634; }

/* info */
.theme-default .alert-content.alert-info, .theme-default .alert-content.alert-info h3, .theme-default .alert-content.alert-info p {
  color: #000000; }

.theme-helsa .alert-content.alert-info, .theme-helsa .alert-content.alert-info h3, .theme-helsa .alert-content.alert-info p {
  color: #000000; }

.theme-ptj .alert-content.alert-info, .theme-ptj .alert-content.alert-info h3, .theme-ptj .alert-content.alert-info p {
  color: #333333; }

.theme-rjl .alert-content.alert-info, .theme-rjl .alert-content.alert-info h3, .theme-rjl .alert-content.alert-info p {
  color: #000000; }

.theme-achima .alert-content.alert-info, .theme-achima .alert-content.alert-info h3, .theme-achima .alert-content.alert-info p {
  color: #000; }

.theme-blekinge .alert-content.alert-info, .theme-blekinge .alert-content.alert-info h3, .theme-blekinge .alert-content.alert-info p {
  color: #000; }

.theme-sll .alert-content.alert-info, .theme-sll .alert-content.alert-info h3, .theme-sll .alert-content.alert-info p {
  color: #000; }

.theme-halland .alert-content.alert-info, .theme-halland .alert-content.alert-info h3, .theme-halland .alert-content.alert-info p {
  color: #000; }

.theme-orebro .alert-content.alert-info, .theme-orebro .alert-content.alert-info h3, .theme-orebro .alert-content.alert-info p {
  color: #000000; }

.theme-rg .alert-content.alert-info, .theme-rg .alert-content.alert-info h3, .theme-rg .alert-content.alert-info p {
  color: #000000; }

.theme-default .alert-background.alert-info {
  background-color: #D0F8F5; }

.theme-helsa .alert-background.alert-info {
  background-color: #D7EAD9; }

.theme-ptj .alert-background.alert-info {
  background-color: #F3F3F3; }

.theme-rjl .alert-background.alert-info {
  background-color: #EDEDED; }

.theme-achima .alert-background.alert-info {
  background-color: #EDEDED; }

.theme-blekinge .alert-background.alert-info {
  background-color: #E5F6FC; }

.theme-sll .alert-background.alert-info {
  background-color: #FFFFFF; }

.theme-halland .alert-background.alert-info {
  background-color: #D3E6F5; }

.theme-orebro .alert-background.alert-info {
  background-color: #E3F2F8; }

.theme-rg .alert-background.alert-info {
  background-color: #F6F6F6; }

/* light */
.theme-default .alert-content.alert-light, .theme-default .alert-content.alert-light h3 {
  color: #0A4A7C; }

.theme-helsa .alert-content.alert-light, .theme-helsa .alert-content.alert-light h3 {
  color: #1D5E74; }

.theme-ptj .alert-content.alert-light, .theme-ptj .alert-content.alert-light h3 {
  color: #0075B0; }

.theme-rjl .alert-content.alert-light, .theme-rjl .alert-content.alert-light h3 {
  color: #579835; }

.theme-achima .alert-content.alert-light, .theme-achima .alert-content.alert-light h3 {
  color: #AA2B23; }

.theme-blekinge .alert-content.alert-light, .theme-blekinge .alert-content.alert-light h3 {
  color: #00A6E2; }

.theme-sll .alert-content.alert-light, .theme-sll .alert-content.alert-light h3 {
  color: #002d5a; }

.theme-halland .alert-content.alert-light, .theme-halland .alert-content.alert-light h3 {
  color: #7BB1DB; }

.theme-orebro .alert-content.alert-light, .theme-orebro .alert-content.alert-light h3 {
  color: #4F80FF; }

.theme-rg .alert-content.alert-light, .theme-rg .alert-content.alert-light h3 {
  color: #0097cf; }

.theme-default .alert-background.alert-light {
  background-color: #F1F1F1; }

.theme-helsa .alert-background.alert-light {
  background-color: #E7E7E8; }

.theme-ptj .alert-background.alert-light {
  background-color: #F1F1F1; }

.theme-rjl .alert-background.alert-light {
  background-color: #F1F1F1; }

.theme-achima .alert-background.alert-light {
  background-color: #F1F1F1; }

.theme-blekinge .alert-background.alert-light {
  background-color: #B7C5CA; }

.theme-sll .alert-background.alert-light {
  background-color: #e0ded9; }

.theme-halland .alert-background.alert-light {
  background-color: #F5F5F5; }

.theme-orebro .alert-background.alert-light {
  background-color: #F1F1F1; }

.theme-rg .alert-background.alert-light {
  background-color: #F1F1F1; }

/* dark */
.theme-default .alert-content.alert-dark, .theme-default .alert-content.alert-dark h3, .theme-default .alert-content.alert-dark p {
  color: #000; }

.theme-helsa .alert-content.alert-dark, .theme-helsa .alert-content.alert-dark h3, .theme-helsa .alert-content.alert-dark p {
  color: #000; }

.theme-ptj .alert-content.alert-dark, .theme-ptj .alert-content.alert-dark h3, .theme-ptj .alert-content.alert-dark p {
  color: #333333; }

.theme-rjl .alert-content.alert-dark, .theme-rjl .alert-content.alert-dark h3, .theme-rjl .alert-content.alert-dark p {
  color: #000; }

.theme-achima .alert-content.alert-dark, .theme-achima .alert-content.alert-dark h3, .theme-achima .alert-content.alert-dark p {
  color: #000; }

.theme-blekinge .alert-content.alert-dark, .theme-blekinge .alert-content.alert-dark h3, .theme-blekinge .alert-content.alert-dark p {
  color: #000; }

.theme-sll .alert-content.alert-dark, .theme-sll .alert-content.alert-dark h3, .theme-sll .alert-content.alert-dark p {
  color: #000; }

.theme-halland .alert-content.alert-dark, .theme-halland .alert-content.alert-dark h3, .theme-halland .alert-content.alert-dark p {
  color: #000; }

.theme-orebro .alert-content.alert-dark, .theme-orebro .alert-content.alert-dark h3, .theme-orebro .alert-content.alert-dark p {
  color: #000; }

.theme-rg .alert-content.alert-dark, .theme-rg .alert-content.alert-dark h3, .theme-rg .alert-content.alert-dark p {
  color: #000; }

.theme-default .alert-background.alert-dark {
  background-color: #000; }

.theme-helsa .alert-background.alert-dark {
  background-color: #000; }

.theme-ptj .alert-background.alert-dark {
  background-color: #333333; }

.theme-rjl .alert-background.alert-dark {
  background-color: #000; }

.theme-achima .alert-background.alert-dark {
  background-color: #000; }

.theme-blekinge .alert-background.alert-dark {
  background-color: #000; }

.theme-sll .alert-background.alert-dark {
  background-color: #000; }

.theme-halland .alert-background.alert-dark {
  background-color: #000; }

.theme-orebro .alert-background.alert-dark {
  background-color: #000; }

.theme-rg .alert-background.alert-dark {
  background-color: #000; }

/*.alert.alert-success {
    @include themify($themes) {
        color: themed('success-color');
        background: themed('success-color');
        opacity: 0.4;
        border: themed('button-boarder-thickness') solid themed('symptom-btn-border-color');
    }
}

.alert .alert-icon-div, .alert .alert-text-div{
    opacity: 1;
}*/
