/* SASS variables and intellisense */
/*---------------------------------------------------------------------------
                                 IMAGES
---------------------------------------------------------------------------*/
/* Responsive div-images */
.divImage {
  width: 100%;
  background-size: cover;
  background-position: center; }

/* Bigger than MEDIUM sqreen size (col-md-up) */
@media screen and (min-width: 992px) {
  .handHoldingTicketImg {
    padding-left: 30%; }
  .writingHandDiv {
    margin-top: -20px; }
  .writingHandImg {
    padding-left: 10%; }
  .writingHandAndBtnDiv {
    margin-top: 40px;
    margin-bottom: -20px; } }

.handHoldingTicketDiv {
  margin-bottom: -40px;
  margin-top: -220px; }

.handHoldingTicketImg {
  height: 260px; }

.writingHandImg {
  height: 260px; }

.TrofeImg {
  height: 150px;
  float: left;
  margin-right: 20px; }

.TimeImg {
  height: 140px;
  float: right; }
  .theme-default .TimeImg {
    opacity: 1; }
  .theme-helsa .TimeImg {
    opacity: 1; }
  .theme-ptj .TimeImg {
    opacity: 1; }
  .theme-rjl .TimeImg {
    opacity: 1; }
  .theme-achima .TimeImg {
    opacity: 1; }
  .theme-blekinge .TimeImg {
    opacity: 1; }
  .theme-sll .TimeImg {
    opacity: 1; }
  .theme-halland .TimeImg {
    opacity: 1; }
  .theme-orebro .TimeImg {
    opacity: 1; }
  .theme-rg .TimeImg {
    opacity: 1; }

.TrofeText {
  padding-left: 40px; }

.cogwheelAndStethoscope {
  width: 72px; }
  .theme-default .cogwheelAndStethoscope {
    opacity: 1; }
  .theme-helsa .cogwheelAndStethoscope {
    opacity: 1; }
  .theme-ptj .cogwheelAndStethoscope {
    opacity: 1; }
  .theme-rjl .cogwheelAndStethoscope {
    opacity: 1; }
  .theme-achima .cogwheelAndStethoscope {
    opacity: 1; }
  .theme-blekinge .cogwheelAndStethoscope {
    opacity: 1; }
  .theme-sll .cogwheelAndStethoscope {
    opacity: 1; }
  .theme-halland .cogwheelAndStethoscope {
    opacity: 1; }
  .theme-orebro .cogwheelAndStethoscope {
    opacity: 1; }
  .theme-rg .cogwheelAndStethoscope {
    opacity: 1; }

/* Bigger than XS sqreen size (col-md-up) */
@media screen and (min-width: 768px) {
  .loginImg {
    margin-left: 16%;
    height: 100px; }
    .theme-default .loginImg {
      opacity: 1; }
    .theme-helsa .loginImg {
      opacity: 1; }
    .theme-ptj .loginImg {
      opacity: 1; }
    .theme-rjl .loginImg {
      opacity: 1; }
    .theme-achima .loginImg {
      opacity: 1; }
    .theme-blekinge .loginImg {
      opacity: 1; }
    .theme-sll .loginImg {
      opacity: 1; }
    .theme-halland .loginImg {
      opacity: 1; }
    .theme-orebro .loginImg {
      opacity: 1; }
    .theme-rg .loginImg {
      opacity: 1; } }

/* Smaller than XS sqreen size (col-md-up) */
@media screen and (min-width: 500px) and (max-width: 768px) {
  .loginImg {
    height: 52px;
    margin-top: 16px; }
    .theme-default .loginImg {
      opacity: 1; }
    .theme-helsa .loginImg {
      opacity: 1; }
    .theme-ptj .loginImg {
      opacity: 1; }
    .theme-rjl .loginImg {
      opacity: 1; }
    .theme-achima .loginImg {
      opacity: 1; }
    .theme-blekinge .loginImg {
      opacity: 1; }
    .theme-sll .loginImg {
      opacity: 1; }
    .theme-halland .loginImg {
      opacity: 1; }
    .theme-orebro .loginImg {
      opacity: 1; }
    .theme-rg .loginImg {
      opacity: 1; } }

/* Smaller than XS sqreen size (col-md-up) */
@media screen and (max-width: 500px) {
  .loginImg {
    height: 36px;
    margin-top: 24px;
    margin-left: -8px; }
    .theme-default .loginImg {
      opacity: 1; }
    .theme-helsa .loginImg {
      opacity: 1; }
    .theme-ptj .loginImg {
      opacity: 1; }
    .theme-rjl .loginImg {
      opacity: 1; }
    .theme-achima .loginImg {
      opacity: 1; }
    .theme-blekinge .loginImg {
      opacity: 1; }
    .theme-sll .loginImg {
      opacity: 1; }
    .theme-halland .loginImg {
      opacity: 1; }
    .theme-orebro .loginImg {
      opacity: 1; }
    .theme-rg .loginImg {
      opacity: 1; } }

.SithsImgInstructions {
  width: 100px;
  display: block;
  /* Makes image in center off the div */
  margin: 0 auto;
  /* Makes image in center off the div */ }

.pictogram-img {
  width: 100px;
  float: right;
  margin-top: 5px; }
  .theme-default .pictogram-img {
    opacity: 1; }
  .theme-helsa .pictogram-img {
    opacity: 1; }
  .theme-ptj .pictogram-img {
    opacity: 1; }
  .theme-rjl .pictogram-img {
    opacity: 1; }
  .theme-achima .pictogram-img {
    opacity: 1; }
  .theme-blekinge .pictogram-img {
    opacity: 1; }
  .theme-sll .pictogram-img {
    opacity: 1; }
  .theme-halland .pictogram-img {
    opacity: 1; }
  .theme-orebro .pictogram-img {
    opacity: 1; }
  .theme-rg .pictogram-img {
    opacity: 1; }

.symptom-icon {
  width: auto;
  position: absolute;
  right: 30px;
  bottom: 30px; }
  .theme-default .symptom-icon {
    opacity: 1; }
  .theme-helsa .symptom-icon {
    opacity: 1; }
  .theme-ptj .symptom-icon {
    opacity: 1; }
  .theme-rjl .symptom-icon {
    opacity: 1; }
  .theme-achima .symptom-icon {
    opacity: 1; }
  .theme-blekinge .symptom-icon {
    opacity: 1; }
  .theme-sll .symptom-icon {
    opacity: 1; }
  .theme-halland .symptom-icon {
    opacity: 1; }
  .theme-orebro .symptom-icon {
    opacity: 1; }
  .theme-rg .symptom-icon {
    opacity: 1; }

/* Internet Explorer 10+ */
/* Fix for SVG-files in IE, they need other placement method */
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .symptom-icon {
    right: -30%; } }

/* LARGE SCREENS */
@media screen and (min-width: 768px) {
  .symptom-icon {
    height: 40%; } }

/* SMALL SCREENS */
@media screen and (min-width: 350px) and (max-width: 768px) {
  .symptom-icon {
    height: 33%; } }

/* TINY SCREENS */
@media screen and (max-width: 350px) {
  .symptom-icon {
    height: 25%; } }

.patientListIcon {
  height: 28px;
  margin-bottom: -8px; }

.startMenuDiv .pictogram-img {
  width: auto;
  height: 50%;
  position: absolute;
  right: 25px;
  bottom: 15px; }

.waitingroom-icon {
  height: 48px;
  width: auto;
  display: block;
  margin: auto; }

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* IE10+ CSS styles go here */
  .waitingroom-icon {
    margin-left: -45%; } }

.theme-default .collabodoc-icon-fill-color {
  fill: #0A4A7C; }

.theme-helsa .collabodoc-icon-fill-color {
  fill: #1D5E74; }

.theme-ptj .collabodoc-icon-fill-color {
  fill: #00A0C8; }

.theme-rjl .collabodoc-icon-fill-color {
  fill: #579835; }

.theme-achima .collabodoc-icon-fill-color {
  fill: #880000; }

.theme-blekinge .collabodoc-icon-fill-color {
  fill: #00A6E2; }

.theme-sll .collabodoc-icon-fill-color {
  fill: #002d5a; }

.theme-halland .collabodoc-icon-fill-color {
  fill: #6DACDE; }

.theme-orebro .collabodoc-icon-fill-color {
  fill: #4F80FF; }

.theme-rg .collabodoc-icon-fill-color {
  fill: #0097cf; }

.theme-default .pictogram-img.collabodoc-icon-fill-color {
  fill: #0A4A7C; }

.theme-helsa .pictogram-img.collabodoc-icon-fill-color {
  fill: #FFFFFF; }

.theme-ptj .pictogram-img.collabodoc-icon-fill-color {
  fill: #ffffff; }

.theme-rjl .pictogram-img.collabodoc-icon-fill-color {
  fill: #FFF; }

.theme-achima .pictogram-img.collabodoc-icon-fill-color {
  fill: #fff; }

.theme-blekinge .pictogram-img.collabodoc-icon-fill-color {
  fill: #fff; }

.theme-sll .pictogram-img.collabodoc-icon-fill-color {
  fill: #fff; }

.theme-halland .pictogram-img.collabodoc-icon-fill-color {
  fill: #fff; }

.theme-orebro .pictogram-img.collabodoc-icon-fill-color {
  fill: #fff; }

.theme-rg .pictogram-img.collabodoc-icon-fill-color {
  fill: #fff; }

.theme-default .symptom-card-primary .symptom-icon.symptom-icon-color .st0 {
  fill: #0A4A7C; }

.theme-helsa .symptom-card-primary .symptom-icon.symptom-icon-color .st0 {
  fill: #FFFFFF; }

.theme-ptj .symptom-card-primary .symptom-icon.symptom-icon-color .st0 {
  fill: #ffffff; }

.theme-rjl .symptom-card-primary .symptom-icon.symptom-icon-color .st0 {
  fill: #FFF; }

.theme-achima .symptom-card-primary .symptom-icon.symptom-icon-color .st0 {
  fill: #fff; }

.theme-blekinge .symptom-card-primary .symptom-icon.symptom-icon-color .st0 {
  fill: #fff; }

.theme-sll .symptom-card-primary .symptom-icon.symptom-icon-color .st0 {
  fill: #fff; }

.theme-halland .symptom-card-primary .symptom-icon.symptom-icon-color .st0 {
  fill: #fff; }

.theme-orebro .symptom-card-primary .symptom-icon.symptom-icon-color .st0 {
  fill: #fff; }

.theme-rg .symptom-card-primary .symptom-icon.symptom-icon-color .st0 {
  fill: #fff; }

.theme-default .symptom-card-primary .symptom-icon.symptom-icon-color .st1 {
  fill: #E32424; }

.theme-helsa .symptom-card-primary .symptom-icon.symptom-icon-color .st1 {
  fill: #F48174; }

.theme-ptj .symptom-card-primary .symptom-icon.symptom-icon-color .st1 {
  fill: #F9B360; }

.theme-rjl .symptom-card-primary .symptom-icon.symptom-icon-color .st1 {
  fill: #fdb913; }

.theme-achima .symptom-card-primary .symptom-icon.symptom-icon-color .st1 {
  fill: #00ADA8; }

.theme-blekinge .symptom-card-primary .symptom-icon.symptom-icon-color .st1 {
  fill: #00A6E2; }

.theme-sll .symptom-card-primary .symptom-icon.symptom-icon-color .st1 {
  fill: #ffd400; }

.theme-halland .symptom-card-primary .symptom-icon.symptom-icon-color .st1 {
  fill: #8E0000; }

.theme-orebro .symptom-card-primary .symptom-icon.symptom-icon-color .st1 {
  fill: #C6D76B; }

.theme-rg .symptom-card-primary .symptom-icon.symptom-icon-color .st1 {
  fill: #ee3780; }

.theme-default .symptom-card-primary:hover .symptom-icon.symptom-icon-color .st0 {
  fill: #A0F0EB; }

.theme-helsa .symptom-card-primary:hover .symptom-icon.symptom-icon-color .st0 {
  fill: #FFFFFF; }

.theme-ptj .symptom-card-primary:hover .symptom-icon.symptom-icon-color .st0 {
  fill: #ffffff; }

.theme-rjl .symptom-card-primary:hover .symptom-icon.symptom-icon-color .st0 {
  fill: #FFF; }

.theme-achima .symptom-card-primary:hover .symptom-icon.symptom-icon-color .st0 {
  fill: #fff; }

.theme-blekinge .symptom-card-primary:hover .symptom-icon.symptom-icon-color .st0 {
  fill: #fff; }

.theme-sll .symptom-card-primary:hover .symptom-icon.symptom-icon-color .st0 {
  fill: #fff; }

.theme-halland .symptom-card-primary:hover .symptom-icon.symptom-icon-color .st0 {
  fill: #fff; }

.theme-orebro .symptom-card-primary:hover .symptom-icon.symptom-icon-color .st0 {
  fill: #fff; }

.theme-rg .symptom-card-primary:hover .symptom-icon.symptom-icon-color .st0 {
  fill: #fff; }

.theme-default .symptom-card-primary:hover .symptom-icon.symptom-icon-color .st1 {
  fill: #FFE150; }

.theme-helsa .symptom-card-primary:hover .symptom-icon.symptom-icon-color .st1 {
  fill: #F48174; }

.theme-ptj .symptom-card-primary:hover .symptom-icon.symptom-icon-color .st1 {
  fill: #F9B360; }

.theme-rjl .symptom-card-primary:hover .symptom-icon.symptom-icon-color .st1 {
  fill: #fdb913; }

.theme-achima .symptom-card-primary:hover .symptom-icon.symptom-icon-color .st1 {
  fill: #00ADA8; }

.theme-blekinge .symptom-card-primary:hover .symptom-icon.symptom-icon-color .st1 {
  fill: #de7ab5; }

.theme-sll .symptom-card-primary:hover .symptom-icon.symptom-icon-color .st1 {
  fill: #e1056d; }

.theme-halland .symptom-card-primary:hover .symptom-icon.symptom-icon-color .st1 {
  fill: #ff9c9c; }

.theme-orebro .symptom-card-primary:hover .symptom-icon.symptom-icon-color .st1 {
  fill: #C6D76B; }

.theme-rg .symptom-card-primary:hover .symptom-icon.symptom-icon-color .st1 {
  fill: #ee3780; }

.theme-default .symptom-card-danger .symptom-icon.symptom-icon-color .st0, .theme-default
.symptom-card-danger .symptom-icon.symptom-icon-color .st1 {
  fill: #fff; }

.theme-helsa .symptom-card-danger .symptom-icon.symptom-icon-color .st0, .theme-helsa
.symptom-card-danger .symptom-icon.symptom-icon-color .st1 {
  fill: #fff; }

.theme-ptj .symptom-card-danger .symptom-icon.symptom-icon-color .st0, .theme-ptj
.symptom-card-danger .symptom-icon.symptom-icon-color .st1 {
  fill: #fff; }

.theme-rjl .symptom-card-danger .symptom-icon.symptom-icon-color .st0, .theme-rjl
.symptom-card-danger .symptom-icon.symptom-icon-color .st1 {
  fill: #fff; }

.theme-achima .symptom-card-danger .symptom-icon.symptom-icon-color .st0, .theme-achima
.symptom-card-danger .symptom-icon.symptom-icon-color .st1 {
  fill: #fff; }

.theme-blekinge .symptom-card-danger .symptom-icon.symptom-icon-color .st0, .theme-blekinge
.symptom-card-danger .symptom-icon.symptom-icon-color .st1 {
  fill: #fff; }

.theme-sll .symptom-card-danger .symptom-icon.symptom-icon-color .st0, .theme-sll
.symptom-card-danger .symptom-icon.symptom-icon-color .st1 {
  fill: #fff; }

.theme-halland .symptom-card-danger .symptom-icon.symptom-icon-color .st0, .theme-halland
.symptom-card-danger .symptom-icon.symptom-icon-color .st1 {
  fill: #fff; }

.theme-orebro .symptom-card-danger .symptom-icon.symptom-icon-color .st0, .theme-orebro
.symptom-card-danger .symptom-icon.symptom-icon-color .st1 {
  fill: #fff; }

.theme-rg .symptom-card-danger .symptom-icon.symptom-icon-color .st0, .theme-rg
.symptom-card-danger .symptom-icon.symptom-icon-color .st1 {
  fill: #fff; }

.theme-default .symptom-card-danger:hover .symptom-icon.symptom-icon-color .st0, .theme-default
.symptom-card-danger:hover .symptom-icon.symptom-icon-color .st1 {
  fill: #E32424; }

.theme-helsa .symptom-card-danger:hover .symptom-icon.symptom-icon-color .st0, .theme-helsa
.symptom-card-danger:hover .symptom-icon.symptom-icon-color .st1 {
  fill: #F48174; }

.theme-ptj .symptom-card-danger:hover .symptom-icon.symptom-icon-color .st0, .theme-ptj
.symptom-card-danger:hover .symptom-icon.symptom-icon-color .st1 {
  fill: #F54359; }

.theme-rjl .symptom-card-danger:hover .symptom-icon.symptom-icon-color .st0, .theme-rjl
.symptom-card-danger:hover .symptom-icon.symptom-icon-color .st1 {
  fill: #ce1141; }

.theme-achima .symptom-card-danger:hover .symptom-icon.symptom-icon-color .st0, .theme-achima
.symptom-card-danger:hover .symptom-icon.symptom-icon-color .st1 {
  fill: #AA2B23; }

.theme-blekinge .symptom-card-danger:hover .symptom-icon.symptom-icon-color .st0, .theme-blekinge
.symptom-card-danger:hover .symptom-icon.symptom-icon-color .st1 {
  fill: #d70480; }

.theme-sll .symptom-card-danger:hover .symptom-icon.symptom-icon-color .st0, .theme-sll
.symptom-card-danger:hover .symptom-icon.symptom-icon-color .st1 {
  fill: #9a0932; }

.theme-halland .symptom-card-danger:hover .symptom-icon.symptom-icon-color .st0, .theme-halland
.symptom-card-danger:hover .symptom-icon.symptom-icon-color .st1 {
  fill: #8E0000; }

.theme-orebro .symptom-card-danger:hover .symptom-icon.symptom-icon-color .st0, .theme-orebro
.symptom-card-danger:hover .symptom-icon.symptom-icon-color .st1 {
  fill: #E32424; }

.theme-rg .symptom-card-danger:hover .symptom-icon.symptom-icon-color .st0, .theme-rg
.symptom-card-danger:hover .symptom-icon.symptom-icon-color .st1 {
  fill: #ee3780; }

.theme-default .collabodoc-icon-fill-color-light .cls-1 {
  fill: #A0F0EB; }

.theme-helsa .collabodoc-icon-fill-color-light .cls-1 {
  fill: #EBF5EC; }

.theme-ptj .collabodoc-icon-fill-color-light .cls-1 {
  fill: #ffffff; }

.theme-rjl .collabodoc-icon-fill-color-light .cls-1 {
  fill: #fff; }

.theme-achima .collabodoc-icon-fill-color-light .cls-1 {
  fill: #fff; }

.theme-blekinge .collabodoc-icon-fill-color-light .cls-1 {
  fill: #fff; }

.theme-sll .collabodoc-icon-fill-color-light .cls-1 {
  fill: #fff; }

.theme-halland .collabodoc-icon-fill-color-light .cls-1 {
  fill: #fff; }

.theme-orebro .collabodoc-icon-fill-color-light .cls-1 {
  fill: #fff; }

.theme-rg .collabodoc-icon-fill-color-light .cls-1 {
  fill: #fff; }

.theme-default .collabodoc-check-icon-fill-color .cls-1 {
  fill: #4FD142; }

.theme-helsa .collabodoc-check-icon-fill-color .cls-1 {
  fill: #6CB680; }

.theme-ptj .collabodoc-check-icon-fill-color .cls-1 {
  fill: #B6BF02; }

.theme-rjl .collabodoc-check-icon-fill-color .cls-1 {
  fill: #579835; }

.theme-achima .collabodoc-check-icon-fill-color .cls-1 {
  fill: #00ADA8; }

.theme-blekinge .collabodoc-check-icon-fill-color .cls-1 {
  fill: #288749; }

.theme-sll .collabodoc-check-icon-fill-color .cls-1 {
  fill: #78be00; }

.theme-halland .collabodoc-check-icon-fill-color .cls-1 {
  fill: #4E9023; }

.theme-orebro .collabodoc-check-icon-fill-color .cls-1 {
  fill: #9FC63B; }

.theme-rg .collabodoc-check-icon-fill-color .cls-1 {
  fill: #50b848; }

.theme-default .collabodoc-user-icon-fill-color .cls-1 {
  fill: #E32424; }

.theme-helsa .collabodoc-user-icon-fill-color .cls-1 {
  fill: #F48174; }

.theme-ptj .collabodoc-user-icon-fill-color .cls-1 {
  fill: #F54359; }

.theme-rjl .collabodoc-user-icon-fill-color .cls-1 {
  fill: #ce1141; }

.theme-achima .collabodoc-user-icon-fill-color .cls-1 {
  fill: #AA2B23; }

.theme-blekinge .collabodoc-user-icon-fill-color .cls-1 {
  fill: #d70480; }

.theme-sll .collabodoc-user-icon-fill-color .cls-1 {
  fill: #9a0932; }

.theme-halland .collabodoc-user-icon-fill-color .cls-1 {
  fill: #8E0000; }

.theme-orebro .collabodoc-user-icon-fill-color .cls-1 {
  fill: #E32424; }

.theme-rg .collabodoc-user-icon-fill-color .cls-1 {
  fill: #ee3780; }

.theme-default .collabodoc-message-icon-fill-color .cls-1 {
  fill: #FFE150; }

.theme-helsa .collabodoc-message-icon-fill-color .cls-1 {
  fill: #FFE150; }

.theme-ptj .collabodoc-message-icon-fill-color .cls-1 {
  fill: #F9B360; }

.theme-rjl .collabodoc-message-icon-fill-color .cls-1 {
  fill: #fdb913; }

.theme-achima .collabodoc-message-icon-fill-color .cls-1 {
  fill: #FFE150; }

.theme-blekinge .collabodoc-message-icon-fill-color .cls-1 {
  fill: #FFE150; }

.theme-sll .collabodoc-message-icon-fill-color .cls-1 {
  fill: #ffd400; }

.theme-halland .collabodoc-message-icon-fill-color .cls-1 {
  fill: #FFE150; }

.theme-orebro .collabodoc-message-icon-fill-color .cls-1 {
  fill: #FFE150; }

.theme-rg .collabodoc-message-icon-fill-color .cls-1 {
  fill: #faa634; }

.collabodoc-check-icon-fill-color .cls-2, .collabodoc-check-icon-fill-color .cls-3,
.collabodoc-user-icon-fill-color .cls-2, .collabodoc-user-icon-fill-color .cls-3,
.collabodoc-message-icon-fill-color .cls-2, .collabodoc-message-icon-fill-color .cls-3 {
  opacity: 0.2; }

.collabodoc-check-icon-fill-color .cls-3, .collabodoc-check-icon-fill-color .cls-4,
.collabodoc-user-icon-fill-color .cls-3, .collabodoc-user-icon-fill-color .cls-4,
.collabodoc-message-icon-fill-color .cls-3, .collabodoc-message-icon-fill-color .cls-4 {
  fill: #Fff; }

.theme-default .collabodoc-arrow-dark {
  fill: #0A4A7C; }

.theme-helsa .collabodoc-arrow-dark {
  fill: #1D5E74; }

.theme-ptj .collabodoc-arrow-dark {
  fill: #00A0C8; }

.theme-rjl .collabodoc-arrow-dark {
  fill: #579835; }

.theme-achima .collabodoc-arrow-dark {
  fill: #880000; }

.theme-blekinge .collabodoc-arrow-dark {
  fill: #00A6E2; }

.theme-sll .collabodoc-arrow-dark {
  fill: #002d5a; }

.theme-halland .collabodoc-arrow-dark {
  fill: #6DACDE; }

.theme-orebro .collabodoc-arrow-dark {
  fill: #4F80FF; }

.theme-rg .collabodoc-arrow-dark {
  fill: #0097cf; }

.theme-default .collabodoc-arrow-default {
  fill: #000; }

.theme-helsa .collabodoc-arrow-default {
  fill: #000; }

.theme-ptj .collabodoc-arrow-default {
  fill: #333333; }

.theme-rjl .collabodoc-arrow-default {
  fill: #000; }

.theme-achima .collabodoc-arrow-default {
  fill: #000; }

.theme-blekinge .collabodoc-arrow-default {
  fill: #000; }

.theme-sll .collabodoc-arrow-default {
  fill: #000; }

.theme-halland .collabodoc-arrow-default {
  fill: #000; }

.theme-orebro .collabodoc-arrow-default {
  fill: #000; }

.theme-rg .collabodoc-arrow-default {
  fill: #000; }

.theme-default .collabodoc-arrow-light {
  fill: #A0F0EB; }

.theme-helsa .collabodoc-arrow-light {
  fill: #EBF5EC; }

.theme-ptj .collabodoc-arrow-light {
  fill: #ffffff; }

.theme-rjl .collabodoc-arrow-light {
  fill: #fff; }

.theme-achima .collabodoc-arrow-light {
  fill: #fff; }

.theme-blekinge .collabodoc-arrow-light {
  fill: #fff; }

.theme-sll .collabodoc-arrow-light {
  fill: #fff; }

.theme-halland .collabodoc-arrow-light {
  fill: #fff; }

.theme-orebro .collabodoc-arrow-light {
  fill: #fff; }

.theme-rg .collabodoc-arrow-light {
  fill: #fff; }

.theme-default .collabodoc-arrow-light {
  fill: #A0F0EB; }

.theme-helsa .collabodoc-arrow-light {
  fill: #EBF5EC; }

.theme-ptj .collabodoc-arrow-light {
  fill: #ffffff; }

.theme-rjl .collabodoc-arrow-light {
  fill: #fff; }

.theme-achima .collabodoc-arrow-light {
  fill: #fff; }

.theme-blekinge .collabodoc-arrow-light {
  fill: #fff; }

.theme-sll .collabodoc-arrow-light {
  fill: #fff; }

.theme-halland .collabodoc-arrow-light {
  fill: #fff; }

.theme-orebro .collabodoc-arrow-light {
  fill: #fff; }

.theme-rg .collabodoc-arrow-light {
  fill: #fff; }

.theme-default .symptom-card-primary .collabodoc-arrow-dark {
  fill: #0A4A7C; }

.theme-helsa .symptom-card-primary .collabodoc-arrow-dark {
  fill: #FFFFFF; }

.theme-ptj .symptom-card-primary .collabodoc-arrow-dark {
  fill: #ffffff; }

.theme-rjl .symptom-card-primary .collabodoc-arrow-dark {
  fill: #FFF; }

.theme-achima .symptom-card-primary .collabodoc-arrow-dark {
  fill: #fff; }

.theme-blekinge .symptom-card-primary .collabodoc-arrow-dark {
  fill: #fff; }

.theme-sll .symptom-card-primary .collabodoc-arrow-dark {
  fill: #fff; }

.theme-halland .symptom-card-primary .collabodoc-arrow-dark {
  fill: #fff; }

.theme-orebro .symptom-card-primary .collabodoc-arrow-dark {
  fill: #fff; }

.theme-rg .symptom-card-primary .collabodoc-arrow-dark {
  fill: #fff; }

.theme-default .symptom-card-primary:hover .collabodoc-arrow-dark {
  fill: #A0F0EB; }

.theme-helsa .symptom-card-primary:hover .collabodoc-arrow-dark {
  fill: #FFFFFF; }

.theme-ptj .symptom-card-primary:hover .collabodoc-arrow-dark {
  fill: #ffffff; }

.theme-rjl .symptom-card-primary:hover .collabodoc-arrow-dark {
  fill: #FFF; }

.theme-achima .symptom-card-primary:hover .collabodoc-arrow-dark {
  fill: #fff; }

.theme-blekinge .symptom-card-primary:hover .collabodoc-arrow-dark {
  fill: #fff; }

.theme-sll .symptom-card-primary:hover .collabodoc-arrow-dark {
  fill: #fff; }

.theme-halland .symptom-card-primary:hover .collabodoc-arrow-dark {
  fill: #fff; }

.theme-orebro .symptom-card-primary:hover .collabodoc-arrow-dark {
  fill: #fff; }

.theme-rg .symptom-card-primary:hover .collabodoc-arrow-dark {
  fill: #fff; }

.theme-default .collabodoc-arrow-dark.danger-arrow {
  fill: #fff; }

.theme-helsa .collabodoc-arrow-dark.danger-arrow {
  fill: #fff; }

.theme-ptj .collabodoc-arrow-dark.danger-arrow {
  fill: #fff; }

.theme-rjl .collabodoc-arrow-dark.danger-arrow {
  fill: #fff; }

.theme-achima .collabodoc-arrow-dark.danger-arrow {
  fill: #fff; }

.theme-blekinge .collabodoc-arrow-dark.danger-arrow {
  fill: #fff; }

.theme-sll .collabodoc-arrow-dark.danger-arrow {
  fill: #fff; }

.theme-halland .collabodoc-arrow-dark.danger-arrow {
  fill: #fff; }

.theme-orebro .collabodoc-arrow-dark.danger-arrow {
  fill: #fff; }

.theme-rg .collabodoc-arrow-dark.danger-arrow {
  fill: #fff; }

.theme-default .symptom-card-danger:hover .collabodoc-arrow-dark.danger-arrow {
  fill: #E32424; }

.theme-helsa .symptom-card-danger:hover .collabodoc-arrow-dark.danger-arrow {
  fill: #F48174; }

.theme-ptj .symptom-card-danger:hover .collabodoc-arrow-dark.danger-arrow {
  fill: #F54359; }

.theme-rjl .symptom-card-danger:hover .collabodoc-arrow-dark.danger-arrow {
  fill: #ce1141; }

.theme-achima .symptom-card-danger:hover .collabodoc-arrow-dark.danger-arrow {
  fill: #AA2B23; }

.theme-blekinge .symptom-card-danger:hover .collabodoc-arrow-dark.danger-arrow {
  fill: #d70480; }

.theme-sll .symptom-card-danger:hover .collabodoc-arrow-dark.danger-arrow {
  fill: #9a0932; }

.theme-halland .symptom-card-danger:hover .collabodoc-arrow-dark.danger-arrow {
  fill: #8E0000; }

.theme-orebro .symptom-card-danger:hover .collabodoc-arrow-dark.danger-arrow {
  fill: #E32424; }

.theme-rg .symptom-card-danger:hover .collabodoc-arrow-dark.danger-arrow {
  fill: #ee3780; }

.theme-default .startMenuDiv:hover .collabodoc-icon-stroke-color {
  fill: #A0F0EB; }

.theme-helsa .startMenuDiv:hover .collabodoc-icon-stroke-color {
  fill: #FFFFFF; }

.theme-ptj .startMenuDiv:hover .collabodoc-icon-stroke-color {
  fill: #ffffff; }

.theme-rjl .startMenuDiv:hover .collabodoc-icon-stroke-color {
  fill: #FFF; }

.theme-achima .startMenuDiv:hover .collabodoc-icon-stroke-color {
  fill: #fff; }

.theme-blekinge .startMenuDiv:hover .collabodoc-icon-stroke-color {
  fill: #fff; }

.theme-sll .startMenuDiv:hover .collabodoc-icon-stroke-color {
  fill: #fff; }

.theme-halland .startMenuDiv:hover .collabodoc-icon-stroke-color {
  fill: #fff; }

.theme-orebro .startMenuDiv:hover .collabodoc-icon-stroke-color {
  fill: #fff; }

.theme-rg .startMenuDiv:hover .collabodoc-icon-stroke-color {
  fill: #fff; }

.theme-default .startMenuDiv:hover .collabodoc-icon-fill-color {
  fill: #A0F0EB; }

.theme-helsa .startMenuDiv:hover .collabodoc-icon-fill-color {
  fill: #FFFFFF; }

.theme-ptj .startMenuDiv:hover .collabodoc-icon-fill-color {
  fill: #ffffff; }

.theme-rjl .startMenuDiv:hover .collabodoc-icon-fill-color {
  fill: #FFF; }

.theme-achima .startMenuDiv:hover .collabodoc-icon-fill-color {
  fill: #fff; }

.theme-blekinge .startMenuDiv:hover .collabodoc-icon-fill-color {
  fill: #fff; }

.theme-sll .startMenuDiv:hover .collabodoc-icon-fill-color {
  fill: #fff; }

.theme-halland .startMenuDiv:hover .collabodoc-icon-fill-color {
  fill: #fff; }

.theme-orebro .startMenuDiv:hover .collabodoc-icon-fill-color {
  fill: #fff; }

.theme-rg .startMenuDiv:hover .collabodoc-icon-fill-color {
  fill: #fff; }

/* Only for IE, Internet Explorer */
@media screen and (min-width: 1200px) and (-ms-high-contrast: none), screen and (min-width: 1200px) and (-ms-high-contrast: active) {
  .IE-icon-margin-fix {
    margin-top: -50%; } }

@media screen and (max-width: 576px) and (-ms-high-contrast: none), screen and (max-width: 576px) and (-ms-high-contrast: active) {
  .IE-icon-margin-fix {
    margin-top: -50%; } }

/*---------------------------  Flags -----------------------*/
.flag-img {
  height: 20px;
  display: inline;
  margin-right: 6px;
  margin-left: -10px; }

.btnFlag {
  margin-right: 2px; }

/*---------------------------  Arrows -----------------------*/
.arrow-right {
  height: 26px;
  margin-left: 12px; }
  .theme-default .arrow-right {
    opacity: 1; }
  .theme-helsa .arrow-right {
    opacity: 1; }
  .theme-ptj .arrow-right {
    opacity: 1; }
  .theme-rjl .arrow-right {
    opacity: 1; }
  .theme-achima .arrow-right {
    opacity: 1; }
  .theme-blekinge .arrow-right {
    opacity: 1; }
  .theme-sll .arrow-right {
    opacity: 1; }
  .theme-halland .arrow-right {
    opacity: 1; }
  .theme-orebro .arrow-right {
    opacity: 1; }
  .theme-rg .arrow-right {
    opacity: 1; }

.arrow-right-mini {
  height: 20px;
  margin-left: 12px;
  float: right; }
  .theme-default .arrow-right-mini {
    opacity: 1; }
  .theme-helsa .arrow-right-mini {
    opacity: 1; }
  .theme-ptj .arrow-right-mini {
    opacity: 1; }
  .theme-rjl .arrow-right-mini {
    opacity: 1; }
  .theme-achima .arrow-right-mini {
    opacity: 1; }
  .theme-blekinge .arrow-right-mini {
    opacity: 1; }
  .theme-sll .arrow-right-mini {
    opacity: 1; }
  .theme-halland .arrow-right-mini {
    opacity: 1; }
  .theme-orebro .arrow-right-mini {
    opacity: 1; }
  .theme-rg .arrow-right-mini {
    opacity: 1; }

.arrow-left-mini {
  height: 20px;
  margin-right: 12px;
  float: left; }
  .theme-default .arrow-left-mini {
    opacity: 1; }
  .theme-helsa .arrow-left-mini {
    opacity: 1; }
  .theme-ptj .arrow-left-mini {
    opacity: 1; }
  .theme-rjl .arrow-left-mini {
    opacity: 1; }
  .theme-achima .arrow-left-mini {
    opacity: 1; }
  .theme-blekinge .arrow-left-mini {
    opacity: 1; }
  .theme-sll .arrow-left-mini {
    opacity: 1; }
  .theme-halland .arrow-left-mini {
    opacity: 1; }
  .theme-orebro .arrow-left-mini {
    opacity: 1; }
  .theme-rg .arrow-left-mini {
    opacity: 1; }

.admin .arrow-left-mini {
  margin-top: 2px;
  height: 14px;
  margin-right: 2px;
  float: left; }
  .theme-default .admin .arrow-left-mini {
    opacity: 1; }
  .theme-helsa .admin .arrow-left-mini {
    opacity: 1; }
  .theme-ptj .admin .arrow-left-mini {
    opacity: 1; }
  .theme-rjl .admin .arrow-left-mini {
    opacity: 1; }
  .theme-achima .admin .arrow-left-mini {
    opacity: 1; }
  .theme-blekinge .admin .arrow-left-mini {
    opacity: 1; }
  .theme-sll .admin .arrow-left-mini {
    opacity: 1; }
  .theme-halland .admin .arrow-left-mini {
    opacity: 1; }
  .theme-orebro .admin .arrow-left-mini {
    opacity: 1; }
  .theme-rg .admin .arrow-left-mini {
    opacity: 1; }

/*@media screen and (max-width: 768px) {
    .admin .arrow-left-mini {
        margin-right: 0;
    }
}*/
/* Internet Explorer 10+ */
/* Fix for SVG-files in IE, they need both a width and height in order to be placed proparly */
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .arrow-right-mini, .arrow-left-mini {
    max-width: 20px; }
  .arrow-right-floatRight, .bigBulletArrow {
    max-width: 36px; }
  .cogwheelAndStethoscope {
    height: 72px; }
  .patientListIcon {
    width: 28px; } }

@-moz-document url-prefix() {
  .hideInFirefox {
    display: none; } }

.arrow-right-floatRight {
  float: right; }

.arrow-left-floatLeft {
  float: left; }

@media screen and (min-width: 768px) {
  .arrow-right-floatRight, .arrow-left-floatLeft {
    height: 36px; }
    .theme-default .arrow-right-floatRight, .theme-default .arrow-left-floatLeft {
      opacity: 1; }
    .theme-helsa .arrow-right-floatRight, .theme-helsa .arrow-left-floatLeft {
      opacity: 1; }
    .theme-ptj .arrow-right-floatRight, .theme-ptj .arrow-left-floatLeft {
      opacity: 1; }
    .theme-rjl .arrow-right-floatRight, .theme-rjl .arrow-left-floatLeft {
      opacity: 1; }
    .theme-achima .arrow-right-floatRight, .theme-achima .arrow-left-floatLeft {
      opacity: 1; }
    .theme-blekinge .arrow-right-floatRight, .theme-blekinge .arrow-left-floatLeft {
      opacity: 1; }
    .theme-sll .arrow-right-floatRight, .theme-sll .arrow-left-floatLeft {
      opacity: 1; }
    .theme-halland .arrow-right-floatRight, .theme-halland .arrow-left-floatLeft {
      opacity: 1; }
    .theme-orebro .arrow-right-floatRight, .theme-orebro .arrow-left-floatLeft {
      opacity: 1; }
    .theme-rg .arrow-right-floatRight, .theme-rg .arrow-left-floatLeft {
      opacity: 1; }
  .arrow-adjustments {
    margin-top: 6px; } }

@media screen and (max-width: 768px) {
  .arrow-right-floatRight, .arrow-left-floatLeft {
    height: 18px; }
    .theme-default .arrow-right-floatRight, .theme-default .arrow-left-floatLeft {
      opacity: 1; }
    .theme-helsa .arrow-right-floatRight, .theme-helsa .arrow-left-floatLeft {
      opacity: 1; }
    .theme-ptj .arrow-right-floatRight, .theme-ptj .arrow-left-floatLeft {
      opacity: 1; }
    .theme-rjl .arrow-right-floatRight, .theme-rjl .arrow-left-floatLeft {
      opacity: 1; }
    .theme-achima .arrow-right-floatRight, .theme-achima .arrow-left-floatLeft {
      opacity: 1; }
    .theme-blekinge .arrow-right-floatRight, .theme-blekinge .arrow-left-floatLeft {
      opacity: 1; }
    .theme-sll .arrow-right-floatRight, .theme-sll .arrow-left-floatLeft {
      opacity: 1; }
    .theme-halland .arrow-right-floatRight, .theme-halland .arrow-left-floatLeft {
      opacity: 1; }
    .theme-orebro .arrow-right-floatRight, .theme-orebro .arrow-left-floatLeft {
      opacity: 1; }
    .theme-rg .arrow-right-floatRight, .theme-rg .arrow-left-floatLeft {
      opacity: 1; } }

section div .modal .modal-content span.bigWarning {
  float: right;
  font-size: 120px;
  padding-top: 8px;
  padding-right: 50px; }
  .theme-default section div .modal .modal-content span.bigWarning {
    color: #E32424; }
  .theme-helsa section div .modal .modal-content span.bigWarning {
    color: #F48174; }
  .theme-ptj section div .modal .modal-content span.bigWarning {
    color: #F54359; }
  .theme-rjl section div .modal .modal-content span.bigWarning {
    color: #ce1141; }
  .theme-achima section div .modal .modal-content span.bigWarning {
    color: #AA2B23; }
  .theme-blekinge section div .modal .modal-content span.bigWarning {
    color: #d70480; }
  .theme-sll section div .modal .modal-content span.bigWarning {
    color: #9a0932; }
  .theme-halland section div .modal .modal-content span.bigWarning {
    color: #8E0000; }
  .theme-orebro section div .modal .modal-content span.bigWarning {
    color: #E32424; }
  .theme-rg section div .modal .modal-content span.bigWarning {
    color: #ee3780; }
