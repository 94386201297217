/* SASS variables and intellisense */
/*---------------------------------------------------------------------------
                                 INPUT 
---------------------------------------------------------------------------*/
/*-----------------------------  Second Header ----------------------------*/
@media screen and (min-width: 768px) {
  .registration input {
    font-size: 28px;
  }
  .theme-default .registration input {
    border-radius: 0;
  }
  .theme-helsa .registration input {
    border-radius: 0;
  }
  .theme-ptj .registration input {
    border-radius: 0px;
  }
  .theme-rjl .registration input {
    border-radius: 0;
  }
  .theme-achima .registration input {
    border-radius: 5px;
  }
  .theme-blekinge .registration input {
    border-radius: 0;
  }
  .theme-sll .registration input {
    border-radius: 0;
  }
  .theme-halland .registration input {
    border-radius: 0;
  }
  .theme-orebro .registration input {
    border-radius: 3px;
  }
  .theme-rg .registration input {
    border-radius: 0px;
  }
}

.admin .input-group {
  margin-top: 3px;
  margin-bottom: 3px;
}

.theme-default
  .admin
  .input-group
  .form-control.inputMaxWidthSM.borderRadiusAllCorners {
  border-radius: 0;
}

.theme-helsa
  .admin
  .input-group
  .form-control.inputMaxWidthSM.borderRadiusAllCorners {
  border-radius: 0;
}

.theme-ptj
  .admin
  .input-group
  .form-control.inputMaxWidthSM.borderRadiusAllCorners {
  border-radius: 0px;
}

.theme-rjl
  .admin
  .input-group
  .form-control.inputMaxWidthSM.borderRadiusAllCorners {
  border-radius: 0;
}

.theme-achima
  .admin
  .input-group
  .form-control.inputMaxWidthSM.borderRadiusAllCorners {
  border-radius: 5px;
}

.theme-blekinge
  .admin
  .input-group
  .form-control.inputMaxWidthSM.borderRadiusAllCorners {
  border-radius: 0;
}

.theme-sll
  .admin
  .input-group
  .form-control.inputMaxWidthSM.borderRadiusAllCorners {
  border-radius: 0;
}

.theme-halland
  .admin
  .input-group
  .form-control.inputMaxWidthSM.borderRadiusAllCorners {
  border-radius: 0;
}

.theme-orebro
  .admin
  .input-group
  .form-control.inputMaxWidthSM.borderRadiusAllCorners {
  border-radius: 3px;
}

.theme-rg
  .admin
  .input-group
  .form-control.inputMaxWidthSM.borderRadiusAllCorners {
  border-radius: 0px;
}

.admin .form-control {
  height: auto;
}

.admin .input-group .form-control.inputMaxWidthSM {
  width: 65px;
}

.admin .input-group input,
.admin textarea {
  height: 26px;
  font-size: 14px;
  float: right;
}
.theme-default .admin .input-group input,
.theme-default .admin textarea {
  color: #000;
  background-color: #fff;
  border: 1px solid #999;
}
.theme-helsa .admin .input-group input,
.theme-helsa .admin textarea {
  color: #000000;
  background-color: #ffffff;
  border: 1px solid #cccccc;
}
.theme-ptj .admin .input-group input,
.theme-ptj .admin textarea {
  color: #333333;
  background-color: #fff;
  border: 1px solid #333333;
}
.theme-rjl .admin .input-group input,
.theme-rjl .admin textarea {
  color: #000;
  background-color: #fff;
  border: 1px solid #000;
}
.theme-achima .admin .input-group input,
.theme-achima .admin textarea {
  color: #000;
  background-color: #fff;
  border: 1px solid #777;
}
.theme-blekinge .admin .input-group input,
.theme-blekinge .admin textarea {
  color: #000;
  background-color: #fff;
  border: 1px solid #000;
}
.theme-sll .admin .input-group input,
.theme-sll .admin textarea {
  color: #002d5a;
  background-color: #fff;
  border: 1px solid #685c53;
}
.theme-halland .admin .input-group input,
.theme-halland .admin textarea {
  color: #000;
  background-color: #fff;
  border: 1px solid #aaaaaa;
}
.theme-orebro .admin .input-group input,
.theme-orebro .admin textarea {
  color: #000;
  background-color: #fff;
  border: 1px solid #c8e1eb;
}
.theme-rg .admin .input-group input,
.theme-rg .admin textarea {
  color: #000;
  background-color: #fff;
  border: 1px solid #ccc;
}

.theme-default .admin .diagnosissearch input,
.theme-default #secondaryDiagnosisSection .secondarydiagnosissearch input {
  color: #000;
  background-color: #fff;
  border: 1px solid #999;
}

.theme-helsa .admin .diagnosissearch input,
.theme-helsa #secondaryDiagnosisSection .secondarydiagnosissearch input {
  color: #000000;
  background-color: #ffffff;
  border: 1px solid #cccccc;
}

.theme-ptj .admin .diagnosissearch input,
.theme-ptj #secondaryDiagnosisSection .secondarydiagnosissearch input {
  color: #333333;
  background-color: #fff;
  border: 1px solid #333333;
}

.theme-rjl .admin .diagnosissearch input,
.theme-rjl #secondaryDiagnosisSection .secondarydiagnosissearch input {
  color: #000;
  background-color: #fff;
  border: 1px solid #000;
}

.theme-achima .admin .diagnosissearch input,
.theme-achima #secondaryDiagnosisSection .secondarydiagnosissearch input {
  color: #000;
  background-color: #fff;
  border: 1px solid #777;
}

.theme-blekinge .admin .diagnosissearch input,
.theme-blekinge #secondaryDiagnosisSection .secondarydiagnosissearch input {
  color: #000;
  background-color: #fff;
  border: 1px solid #000;
}

.theme-sll .admin .diagnosissearch input,
.theme-sll #secondaryDiagnosisSection .secondarydiagnosissearch input {
  color: #002d5a;
  background-color: #fff;
  border: 1px solid #685c53;
}

.theme-halland .admin .diagnosissearch input,
.theme-halland #secondaryDiagnosisSection .secondarydiagnosissearch input {
  color: #000;
  background-color: #fff;
  border: 1px solid #aaaaaa;
}

.theme-orebro .admin .diagnosissearch input,
.theme-orebro #secondaryDiagnosisSection .secondarydiagnosissearch input {
  color: #000;
  background-color: #fff;
  border: 1px solid #c8e1eb;
}

.theme-rg .admin .diagnosissearch input,
.theme-rg #secondaryDiagnosisSection .secondarydiagnosissearch input {
  color: #000;
  background-color: #fff;
  border: 1px solid #ccc;
}

.theme-default .admin .input-group input:active,
.theme-default .admin .input-group input:focus,
.theme-default .admin textarea:focus,
.theme-default .admin .diagnosissearch input:active,
.theme-default .admin .diagnosissearch input:focus,
.theme-default
  #secondaryDiagnosisSection
  .secondarydiagnosissearch
  input:active,
.theme-default
  #secondaryDiagnosisSection
  .secondarydiagnosissearch
  input:focus {
  background-color: #d0f8f5;
  border: 1px solid #0a4a7c;
  color: #000;
  outline: none;
}

.theme-helsa .admin .input-group input:active,
.theme-helsa .admin .input-group input:focus,
.theme-helsa .admin textarea:focus,
.theme-helsa .admin .diagnosissearch input:active,
.theme-helsa .admin .diagnosissearch input:focus,
.theme-helsa #secondaryDiagnosisSection .secondarydiagnosissearch input:active,
.theme-helsa #secondaryDiagnosisSection .secondarydiagnosissearch input:focus {
  background-color: #d7ead9;
  border: 1px solid #6cb680;
  color: #000000;
  outline: none;
}

.theme-ptj .admin .input-group input:active,
.theme-ptj .admin .input-group input:focus,
.theme-ptj .admin textarea:focus,
.theme-ptj .admin .diagnosissearch input:active,
.theme-ptj .admin .diagnosissearch input:focus,
.theme-ptj #secondaryDiagnosisSection .secondarydiagnosissearch input:active,
.theme-ptj #secondaryDiagnosisSection .secondarydiagnosissearch input:focus {
  background-color: #fff;
  border: 1px solid #0a4a7c;
  color: #333333;
  outline: none;
}

.theme-rjl .admin .input-group input:active,
.theme-rjl .admin .input-group input:focus,
.theme-rjl .admin textarea:focus,
.theme-rjl .admin .diagnosissearch input:active,
.theme-rjl .admin .diagnosissearch input:focus,
.theme-rjl #secondaryDiagnosisSection .secondarydiagnosissearch input:active,
.theme-rjl #secondaryDiagnosisSection .secondarydiagnosissearch input:focus {
  background-color: #fff;
  border: 1px solid #579835;
  color: #000;
  outline: none;
}

.theme-achima .admin .input-group input:active,
.theme-achima .admin .input-group input:focus,
.theme-achima .admin textarea:focus,
.theme-achima .admin .diagnosissearch input:active,
.theme-achima .admin .diagnosissearch input:focus,
.theme-achima #secondaryDiagnosisSection .secondarydiagnosissearch input:active,
.theme-achima #secondaryDiagnosisSection .secondarydiagnosissearch input:focus {
  background-color: #fff;
  border: 1px solid #00ada8;
  color: #000;
  outline: none;
}

.theme-blekinge .admin .input-group input:active,
.theme-blekinge .admin .input-group input:focus,
.theme-blekinge .admin textarea:focus,
.theme-blekinge .admin .diagnosissearch input:active,
.theme-blekinge .admin .diagnosissearch input:focus,
.theme-blekinge
  #secondaryDiagnosisSection
  .secondarydiagnosissearch
  input:active,
.theme-blekinge
  #secondaryDiagnosisSection
  .secondarydiagnosissearch
  input:focus {
  background-color: #fff;
  border: 1px solid #00a6e2;
  color: #000;
  outline: none;
}

.theme-sll .admin .input-group input:active,
.theme-sll .admin .input-group input:focus,
.theme-sll .admin textarea:focus,
.theme-sll .admin .diagnosissearch input:active,
.theme-sll .admin .diagnosissearch input:focus,
.theme-sll #secondaryDiagnosisSection .secondarydiagnosissearch input:active,
.theme-sll #secondaryDiagnosisSection .secondarydiagnosissearch input:focus {
  background-color: #e5f1f8;
  border: 1px solid #00a6e2;
  color: #002d5a;
  outline: none;
}

.theme-halland .admin .input-group input:active,
.theme-halland .admin .input-group input:focus,
.theme-halland .admin textarea:focus,
.theme-halland .admin .diagnosissearch input:active,
.theme-halland .admin .diagnosissearch input:focus,
.theme-halland
  #secondaryDiagnosisSection
  .secondarydiagnosissearch
  input:active,
.theme-halland
  #secondaryDiagnosisSection
  .secondarydiagnosissearch
  input:focus {
  background-color: #fff;
  border: 1px solid #6dacde;
  color: #000;
  outline: none;
}

.theme-orebro .admin .input-group input:active,
.theme-orebro .admin .input-group input:focus,
.theme-orebro .admin textarea:focus,
.theme-orebro .admin .diagnosissearch input:active,
.theme-orebro .admin .diagnosissearch input:focus,
.theme-orebro #secondaryDiagnosisSection .secondarydiagnosissearch input:active,
.theme-orebro #secondaryDiagnosisSection .secondarydiagnosissearch input:focus {
  background-color: #fff;
  border: 1px solid #4f80ff;
  color: #000;
  outline: none;
}

.theme-rg .admin .input-group input:active,
.theme-rg .admin .input-group input:focus,
.theme-rg .admin textarea:focus,
.theme-rg .admin .diagnosissearch input:active,
.theme-rg .admin .diagnosissearch input:focus,
.theme-rg #secondaryDiagnosisSection .secondarydiagnosissearch input:active,
.theme-rg #secondaryDiagnosisSection .secondarydiagnosissearch input:focus {
  background-color: #fff;
  border: 1px solid #faa634;
  color: #000;
  outline: none;
}

.theme-default .admin .input-group .input-group-addon {
  color: #000;
  background-color: #e4e4e4;
  border: 1px solid #999;
  height: 26px;
  font-size: 14px;
  padding: 2px 8px;
  border-left: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.theme-helsa .admin .input-group .input-group-addon {
  color: #000000;
  background-color: #d7d7d7;
  border: 1px solid #cccccc;
  height: 26px;
  font-size: 14px;
  padding: 2px 8px;
  border-left: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.theme-ptj .admin .input-group .input-group-addon {
  color: #333333;
  background-color: #e4e4e4;
  border: 1px solid #333333;
  height: 26px;
  font-size: 14px;
  padding: 2px 8px;
  border-left: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.theme-rjl .admin .input-group .input-group-addon {
  color: #000;
  background-color: #e4e4e4;
  border: 1px solid #000;
  height: 26px;
  font-size: 14px;
  padding: 2px 8px;
  border-left: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.theme-achima .admin .input-group .input-group-addon {
  color: #000;
  background-color: #e4e4e4;
  border: 1px solid #777;
  height: 26px;
  font-size: 14px;
  padding: 2px 8px;
  border-left: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.theme-blekinge .admin .input-group .input-group-addon {
  color: #000;
  background-color: #e5e5e5;
  border: 1px solid #000;
  height: 26px;
  font-size: 14px;
  padding: 2px 8px;
  border-left: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.theme-sll .admin .input-group .input-group-addon {
  color: #002d5a;
  background-color: #a79d96;
  border: 1px solid #685c53;
  height: 26px;
  font-size: 14px;
  padding: 2px 8px;
  border-left: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.theme-halland .admin .input-group .input-group-addon {
  color: #000;
  background-color: #dfdfdf;
  border: 1px solid #aaaaaa;
  height: 26px;
  font-size: 14px;
  padding: 2px 8px;
  border-left: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.theme-orebro .admin .input-group .input-group-addon {
  color: #000;
  background-color: #eeeeee;
  border: 1px solid #c8e1eb;
  height: 26px;
  font-size: 14px;
  padding: 2px 8px;
  border-left: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.theme-rg .admin .input-group .input-group-addon {
  color: #000;
  background-color: #eeeeee;
  border: 1px solid #ccc;
  height: 26px;
  font-size: 14px;
  padding: 2px 8px;
  border-left: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

@media screen and (max-width: 992px) {
  .admin .input-group .form-control.inputMaxWidthSM {
    float: left;
  }
  .admin .input-group .input-group-addon {
    width: auto;
    padding: 4px 8px;
  }
}

/*.patientRegistration .rangeInputValue {
    margin-right: 6px;
}*/
/*---------------------------------------------------------------------------
                                  FORM
---------------------------------------------------------------------------*/
.theme-default .form-control {
  border-radius: 0;
  background: #d0f8f5;
  border: 1px solid #a0f0eb;
  color: #0a4a7c;
}

.theme-helsa .form-control {
  border-radius: 0;
  background: #d7ead9;
  border: 1px solid #bedcc1;
  color: #000000;
}

.theme-ptj .form-control {
  border-radius: 0px;
  background: #ffffff;
  border: 1px solid #0075b0;
  color: #333333;
}

.theme-rjl .form-control {
  border-radius: 0;
  background: #fff;
  border: 1px solid #8cc63f;
  color: #000;
}

.theme-achima .form-control {
  border-radius: 5px;
  background: #fff;
  border: 1px solid #777;
  color: #000;
}

.theme-blekinge .form-control {
  border-radius: 0;
  background: #fff;
  border: 1px solid #000;
  color: #000;
}

.theme-sll .form-control {
  border-radius: 0;
  background: #fff;
  border: 1px solid #002d5a;
  color: #000;
}

.theme-halland .form-control {
  border-radius: 0;
  background: #fff;
  border: 1px solid #6dacde;
  color: #000;
}

.theme-orebro .form-control {
  border-radius: 3px;
  background: #fff;
  border: 1px solid #000;
  color: #000;
}

.theme-rg .form-control {
  border-radius: 0px;
  background: #fff;
  border: 1px solid #000;
  color: #000;
}

/* SMALL, MEDIUM and LARGE screens sizes */
@media screen and (min-width: 768px) {
  .input-group.date {
    max-width: 150px;
  }
}

.form-control:focus {
  box-shadow: none;
}
.theme-default .form-control:focus {
  background: #d0f8f5;
  border: 1px solid;
  color: #000000;
}
.theme-helsa .form-control:focus {
  background: #d7ead9;
  border: 1px solid;
  color: #000000;
}
.theme-ptj .form-control:focus {
  background: #faf2e8;
  border: 1px solid;
  color: #333333;
}
.theme-rjl .form-control:focus {
  background: #fff;
  border: 1px solid;
  color: #000000;
}
.theme-achima .form-control:focus {
  background: #fff;
  border: 1px solid;
  color: #000000;
}
.theme-blekinge .form-control:focus {
  background: #fff;
  border: 1px solid;
  color: #000000;
}
.theme-sll .form-control:focus {
  background: #e5f1f8;
  border: 1px solid;
  color: #002d5a;
}
.theme-halland .form-control:focus {
  background: #d3e6f5;
  border: 1px solid;
  color: #000;
}
.theme-orebro .form-control:focus {
  background: #d1e9f2;
  border: 1px solid;
  color: #000;
}
.theme-rg .form-control:focus {
  background: #fff;
  border: 1px solid;
  color: #000;
}

.theme-default .patientanswer .form-control {
  color: #000000;
  background-color: #ffffff;
}

.theme-helsa .patientanswer .form-control {
  color: #000000;
  background-color: #ffffff;
}

.theme-ptj .patientanswer .form-control {
  color: #333333;
  background-color: #ffffff;
}

.theme-rjl .patientanswer .form-control {
  color: #000000;
  background-color: #ffffff;
}

.theme-achima .patientanswer .form-control {
  color: #000;
  background-color: #fff;
}

.theme-blekinge .patientanswer .form-control {
  color: #000;
  background-color: #fff;
}

.theme-sll .patientanswer .form-control {
  color: #000;
  background-color: #fff;
}

.theme-halland .patientanswer .form-control {
  color: #000;
  background-color: #fff;
}

.theme-orebro .patientanswer .form-control {
  color: #000000;
  background-color: #fff;
}

.theme-rg .patientanswer .form-control {
  color: #000000;
  background-color: #fff;
}

select.form-control {
  box-shadow: none;
  background-image: none;
  appearance: none;
  cursor: pointer;
}
.theme-default select.form-control {
  border-radius: 0;
  background: #d0f8f5;
  border: 1px solid;
  color: #0a4a7c;
}
.theme-helsa select.form-control {
  border-radius: 0;
  background: #d7ead9;
  border: 1px solid;
  color: #000000;
}
.theme-ptj select.form-control {
  border-radius: 0px;
  background: #ffffff;
  border: 1px solid;
  color: #333333;
}
.theme-rjl select.form-control {
  border-radius: 0;
  background: #fff;
  border: 1px solid;
  color: #000;
}
.theme-achima select.form-control {
  border-radius: 5px;
  background: #fff;
  border: 1px solid;
  color: #000;
}
.theme-blekinge select.form-control {
  border-radius: 0;
  background: #fff;
  border: 1px solid;
  color: #000;
}
.theme-sll select.form-control {
  border-radius: 0;
  background: #fff;
  border: 1px solid;
  color: #000;
}
.theme-halland select.form-control {
  border-radius: 0;
  background: #fff;
  border: 1px solid;
  color: #000;
}
.theme-orebro select.form-control {
  border-radius: 3px;
  background: #fff;
  border: 1px solid;
  color: #000;
}
.theme-rg select.form-control {
  border-radius: 0px;
  background: #fff;
  border: 1px solid;
  color: #000;
}

.theme-default #patientListTable .form-control.stopSelectSymptomFormOnClick,
.theme-default .admin .secondHeader .form-control {
  background: #ffffff;
  border: 1px solid #000000;
  color: #000000;
}

.theme-helsa #patientListTable .form-control.stopSelectSymptomFormOnClick,
.theme-helsa .admin .secondHeader .form-control {
  background: #ffffff;
  border: 1px solid #000000;
  color: #000000;
}

.theme-ptj #patientListTable .form-control.stopSelectSymptomFormOnClick,
.theme-ptj .admin .secondHeader .form-control {
  background: #ffffff;
  border: 1px solid #0075b0;
  color: #333333;
}

.theme-rjl #patientListTable .form-control.stopSelectSymptomFormOnClick,
.theme-rjl .admin .secondHeader .form-control {
  background: #ffffff;
  border: 1px solid #8cc63f;
  color: #000000;
}

.theme-achima #patientListTable .form-control.stopSelectSymptomFormOnClick,
.theme-achima .admin .secondHeader .form-control {
  background: #fff;
  border: 1px solid #000;
  color: #000;
}

.theme-blekinge #patientListTable .form-control.stopSelectSymptomFormOnClick,
.theme-blekinge .admin .secondHeader .form-control {
  background: #fff;
  border: 1px solid #000;
  color: #000;
}

.theme-sll #patientListTable .form-control.stopSelectSymptomFormOnClick,
.theme-sll .admin .secondHeader .form-control {
  background: #fff;
  border: 1px solid #002d5a;
  color: #000;
}

.theme-halland #patientListTable .form-control.stopSelectSymptomFormOnClick,
.theme-halland .admin .secondHeader .form-control {
  background: #fff;
  border: 1px solid #aaaaaa;
  color: #000;
}

.theme-orebro #patientListTable .form-control.stopSelectSymptomFormOnClick,
.theme-orebro .admin .secondHeader .form-control {
  background: #fff;
  border: 1px solid #000000;
  color: #000000;
}

.theme-rg #patientListTable .form-control.stopSelectSymptomFormOnClick,
.theme-rg .admin .secondHeader .form-control {
  background: #fff;
  border: 1px solid #000000;
  color: #000000;
}

.theme-default
  #patientListTable
  .form-control.stopSelectSymptomFormOnClick:focus,
.theme-default .admin .secondHeader .form-control:focus {
  background: #ffffff;
  border: 1px solid #0a4a7c;
  color: #000000;
}

.theme-helsa #patientListTable .form-control.stopSelectSymptomFormOnClick:focus,
.theme-helsa .admin .secondHeader .form-control:focus {
  background: #ffffff;
  border: 1px solid #6cb680;
  color: #000000;
}

.theme-ptj #patientListTable .form-control.stopSelectSymptomFormOnClick:focus,
.theme-ptj .admin .secondHeader .form-control:focus {
  background: #f8f8f8;
  border: 1px solid #e78300;
  color: #333333;
}

.theme-rjl #patientListTable .form-control.stopSelectSymptomFormOnClick:focus,
.theme-rjl .admin .secondHeader .form-control:focus {
  background: #ffffff;
  border: 1px solid #579835;
  color: #000000;
}

.theme-achima
  #patientListTable
  .form-control.stopSelectSymptomFormOnClick:focus,
.theme-achima .admin .secondHeader .form-control:focus {
  background: #fff;
  border: 1px solid #00ada8;
  color: #000;
}

.theme-blekinge
  #patientListTable
  .form-control.stopSelectSymptomFormOnClick:focus,
.theme-blekinge .admin .secondHeader .form-control:focus {
  background: #fff;
  border: 1px solid #00a6e2;
  color: #193f76;
}

.theme-sll #patientListTable .form-control.stopSelectSymptomFormOnClick:focus,
.theme-sll .admin .secondHeader .form-control:focus {
  background: #e5f1f8;
  border: 1px solid #00aeef;
  color: #002d5a;
}

.theme-halland
  #patientListTable
  .form-control.stopSelectSymptomFormOnClick:focus,
.theme-halland .admin .secondHeader .form-control:focus {
  background: #fff;
  border: 1px solid #6dacde;
  color: #000;
}

.theme-orebro
  #patientListTable
  .form-control.stopSelectSymptomFormOnClick:focus,
.theme-orebro .admin .secondHeader .form-control:focus {
  background: #fff;
  border: 1px solid #4f80ff;
  color: #000000;
}

.theme-rg #patientListTable .form-control.stopSelectSymptomFormOnClick:focus,
.theme-rg .admin .secondHeader .form-control:focus {
  background: #fff;
  border: 1px solid #faa634;
  color: #000000;
}

.theme-default select.form-control[disabled] {
  color: #e4e4e4;
}

.theme-helsa select.form-control[disabled] {
  color: #d7d7d7;
}

.theme-ptj select.form-control[disabled] {
  color: #f3f3f3;
}

.theme-rjl select.form-control[disabled] {
  color: #e4e4e4;
}

.theme-achima select.form-control[disabled] {
  color: #e4e4e4;
}

.theme-blekinge select.form-control[disabled] {
  color: #e5e5e5;
}

.theme-sll select.form-control[disabled] {
  color: #a79d96;
}

.theme-halland select.form-control[disabled] {
  color: #cccccc;
}

.theme-orebro select.form-control[disabled] {
  color: #e4e4e4;
}

.theme-rg select.form-control[disabled] {
  color: #e4e4e4;
}

.theme-default .form-block select.form-control {
  border-radius: 0;
  background: #d0f8f5;
  border: 1px solid;
  color: #0a4a7c;
}

.theme-helsa .form-block select.form-control {
  border-radius: 0;
  background: #d7ead9;
  border: 1px solid;
  color: #000000;
}

.theme-ptj .form-block select.form-control {
  border-radius: 0px;
  background: #ffffff;
  border: 1px solid;
  color: #333333;
}

.theme-rjl .form-block select.form-control {
  border-radius: 0;
  background: #fff;
  border: 1px solid;
  color: #000;
}

.theme-achima .form-block select.form-control {
  border-radius: 5px;
  background: #fff;
  border: 1px solid;
  color: #000;
}

.theme-blekinge .form-block select.form-control {
  border-radius: 0;
  background: #fff;
  border: 1px solid;
  color: #000;
}

.theme-sll .form-block select.form-control {
  border-radius: 0;
  background: #fff;
  border: 1px solid;
  color: #000;
}

.theme-halland .form-block select.form-control {
  border-radius: 0;
  background: #fff;
  border: 1px solid;
  color: #000;
}

.theme-orebro .form-block select.form-control {
  border-radius: 3px;
  background: #fff;
  border: 1px solid;
  color: #000;
}

.theme-rg .form-block select.form-control {
  border-radius: 0px;
  background: #fff;
  border: 1px solid;
  color: #000;
}

.form-block .select-wrapper::after {
  margin-top: -42px;
  margin-right: 40px;
}
.theme-default .form-block .select-wrapper::after {
  color: #000;
}
.theme-helsa .form-block .select-wrapper::after {
  color: #000;
}
.theme-ptj .form-block .select-wrapper::after {
  color: #333333;
}
.theme-rjl .form-block .select-wrapper::after {
  color: #000;
}
.theme-achima .form-block .select-wrapper::after {
  color: #000;
}
.theme-blekinge .form-block .select-wrapper::after {
  color: #000;
}
.theme-sll .form-block .select-wrapper::after {
  color: #000;
}
.theme-halland .form-block .select-wrapper::after {
  color: #000;
}
.theme-orebro .form-block .select-wrapper::after {
  color: #000;
}
.theme-rg .form-block .select-wrapper::after {
  color: #000;
}

#diagnosisvariationselect {
  margin: 15px 0;
}

/*---------------------------------------------------------------------------
                        CHECKBOX / RADIO BUTTON
---------------------------------------------------------------------------*/
.carecenter-checkbox-label {
  font-weight: 400;
  font-size: 14px;
}

#reservenumber,
.registration .consentCheckBox {
  width: 22px;
  height: 22px;
  margin-right: -30px;
}

.checkbox-inline,
.radio-inline {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 14px;
  padding-left: 22px;
}

.tightCheckBoxLabel {
  margin-left: -20px;
}

.checkBoxLabelMargin {
  margin-left: 30px;
}

.btnLabel {
  font-weight: 100;
  font-size: 15px;
}

/*---------------------------------------------------------------------------
                                 TEXT AREA
---------------------------------------------------------------------------*/
.answer .small-textarea,
.patientanswer .small-textarea {
  height: 2.5em;
}

.answer .medium-textarea,
.patientanswer .medium-textarea {
  height: 8em;
}

.nurse-journalnote-section {
  width: 100%;
}

.nurse-journalnote-section:focus,
nurse-journalnote-section:active {
  box-shadow: none;
}

.journaltext-area {
  border: none;
  padding: 0 0 20px;
  min-height: 1em;
  white-space: pre-wrap;
}

.autoresize {
  width: 100%;
  min-height: 20px;
  border: none;
  padding-bottom: 7px;
  white-space: pre-wrap;
}
.theme-default .autoresize {
  border-radius: 0;
}
.theme-helsa .autoresize {
  border-radius: 0;
}
.theme-ptj .autoresize {
  border-radius: 0px;
}
.theme-rjl .autoresize {
  border-radius: 0;
}
.theme-achima .autoresize {
  border-radius: 5px;
}
.theme-blekinge .autoresize {
  border-radius: 0;
}
.theme-sll .autoresize {
  border-radius: 0;
}
.theme-halland .autoresize {
  border-radius: 0;
}
.theme-orebro .autoresize {
  border-radius: 3px;
}
.theme-rg .autoresize {
  border-radius: 0px;
}

.autoresize:focus,
.autoresize:active {
  margin: -1px;
  outline-color: transparent;
  outline-style: none;
}
.theme-default .autoresize:focus,
.theme-default .autoresize:active {
  border: 1px solid #0a4a7c;
}
.theme-helsa .autoresize:focus,
.theme-helsa .autoresize:active {
  border: 1px solid #1d5e74;
}
.theme-ptj .autoresize:focus,
.theme-ptj .autoresize:active {
  border: 1px solid #0075b0;
}
.theme-rjl .autoresize:focus,
.theme-rjl .autoresize:active {
  border: 1px solid #579835;
}
.theme-achima .autoresize:focus,
.theme-achima .autoresize:active {
  border: 1px solid #aa2b23;
}
.theme-blekinge .autoresize:focus,
.theme-blekinge .autoresize:active {
  border: 1px solid #00a6e2;
}
.theme-sll .autoresize:focus,
.theme-sll .autoresize:active {
  border: 1px solid #002d5a;
}
.theme-halland .autoresize:focus,
.theme-halland .autoresize:active {
  border: 1px solid #7bb1db;
}
.theme-orebro .autoresize:focus,
.theme-orebro .autoresize:active {
  border: 1px solid #4f80ff;
}
.theme-rg .autoresize:focus,
.theme-rg .autoresize:active {
  border: 1px solid #0097cf;
}

.autosize-input::-ms-clear {
  display: none;
}

input.autosize-input {
  border: none;
}

.answer .large-textarea,
.patientanswer .large-textarea {
  height: 12em;
}

textarea:disabled,
input[type="text"]:disabled {
  cursor: not-allowed;
}
.theme-default textarea:disabled,
.theme-default input[type="text"]:disabled {
  border: 1px solid #555;
  border-radius: 0;
  background-color: #f1f1f1;
}
.theme-helsa textarea:disabled,
.theme-helsa input[type="text"]:disabled {
  border: 1px solid #555;
  border-radius: 0;
  background-color: #e7e7e8;
}
.theme-ptj textarea:disabled,
.theme-ptj input[type="text"]:disabled {
  border: 1px solid #555;
  border-radius: 0px;
  background-color: #f1f1f1;
}
.theme-rjl textarea:disabled,
.theme-rjl input[type="text"]:disabled {
  border: 1px solid #636466;
  border-radius: 0;
  background-color: #f1f1f1;
}
.theme-achima textarea:disabled,
.theme-achima input[type="text"]:disabled {
  border: 1px solid #555;
  border-radius: 5px;
  background-color: #f1f1f1;
}
.theme-blekinge textarea:disabled,
.theme-blekinge input[type="text"]:disabled {
  border: 1px solid #193f76;
  border-radius: 0;
  background-color: #b7c5ca;
}
.theme-sll textarea:disabled,
.theme-sll input[type="text"]:disabled {
  border: 1px solid #002d5a;
  border-radius: 0;
  background-color: #e0ded9;
}
.theme-halland textarea:disabled,
.theme-halland input[type="text"]:disabled {
  border: 1px solid #000000;
  border-radius: 0;
  background-color: #f5f5f5;
}
.theme-orebro textarea:disabled,
.theme-orebro input[type="text"]:disabled {
  border: 1px solid #000000;
  border-radius: 3px;
  background-color: #f1f1f1;
}
.theme-rg textarea:disabled,
.theme-rg input[type="text"]:disabled {
  border: 1px solid #000000;
  border-radius: 0px;
  background-color: #f1f1f1;
}

.theme-default .question-block .slider label .unitLabel:disabled {
  background-color: #f1f1f1;
}

.theme-helsa .question-block .slider label .unitLabel:disabled {
  background-color: #e7e7e8;
}

.theme-ptj .question-block .slider label .unitLabel:disabled {
  background-color: #f1f1f1;
}

.theme-rjl .question-block .slider label .unitLabel:disabled {
  background-color: #f1f1f1;
}

.theme-achima .question-block .slider label .unitLabel:disabled {
  background-color: #f1f1f1;
}

.theme-blekinge .question-block .slider label .unitLabel:disabled {
  background-color: #b7c5ca;
}

.theme-sll .question-block .slider label .unitLabel:disabled {
  background-color: #e0ded9;
}

.theme-halland .question-block .slider label .unitLabel:disabled {
  background-color: #f5f5f5;
}

.theme-orebro .question-block .slider label .unitLabel:disabled {
  background-color: #f1f1f1;
}

.theme-rg .question-block .slider label .unitLabel:disabled {
  background-color: #f1f1f1;
}

/*---------------------------------------------------------------------------
                               MESSAGES FOR 
                         VALIDATION / ERROR / WARNING 
---------------------------------------------------------------------------*/
.theme-default input[type="text"].input-validation-error,
.theme-default .form-group .input-validation-error,
.theme-default .input-validation-error {
  border-radius: 0;
  border: 1px solid #e32424;
  background-color: #faa4a4;
}

.theme-helsa input[type="text"].input-validation-error,
.theme-helsa .form-group .input-validation-error,
.theme-helsa .input-validation-error {
  border-radius: 0;
  border: 1px solid #f48174;
  background-color: #f48174;
}

.theme-ptj input[type="text"].input-validation-error,
.theme-ptj .form-group .input-validation-error,
.theme-ptj .input-validation-error {
  border-radius: 0px;
  border: 1px solid #f54359;
  background-color: #faa4a4;
}

.theme-rjl input[type="text"].input-validation-error,
.theme-rjl .form-group .input-validation-error,
.theme-rjl .input-validation-error {
  border-radius: 0;
  border: 1px solid #ce1141;
  background-color: #ef4044;
}

.theme-achima input[type="text"].input-validation-error,
.theme-achima .form-group .input-validation-error,
.theme-achima .input-validation-error {
  border-radius: 5px;
  border: 1px solid #aa2b23;
  background-color: #bb7167;
}

.theme-blekinge input[type="text"].input-validation-error,
.theme-blekinge .form-group .input-validation-error,
.theme-blekinge .input-validation-error {
  border-radius: 0;
  border: 1px solid #d70480;
  background-color: #de7ab5;
}

.theme-sll input[type="text"].input-validation-error,
.theme-sll .form-group .input-validation-error,
.theme-sll .input-validation-error {
  border-radius: 0;
  border: 1px solid #9a0932;
  background-color: #e278aa;
}

.theme-halland input[type="text"].input-validation-error,
.theme-halland .form-group .input-validation-error,
.theme-halland .input-validation-error {
  border-radius: 0;
  border: 1px solid #8e0000;
  background-color: #ff9c9c;
}

.theme-orebro input[type="text"].input-validation-error,
.theme-orebro .form-group .input-validation-error,
.theme-orebro .input-validation-error {
  border-radius: 3px;
  border: 1px solid #e32424;
  background-color: #faa4a4;
}

.theme-rg input[type="text"].input-validation-error,
.theme-rg .form-group .input-validation-error,
.theme-rg .input-validation-error {
  border-radius: 0px;
  border: 1px solid #ee3780;
  background-color: #fac3d8;
}

.theme-default .form-group .input-validation-error::placeholder,
.theme-default .input-validation-error::placeholder,
.theme-default .form-group .input-validation-error:-ms-input-placeholder,
.theme-default .input-validation-error:-ms-input-placeholder,
.theme-default .form-group .input-validation-error::-ms-input-placeholder,
.theme-default .input-validation-error::-ms-input-placeholder {
  color: #e32424;
}

.theme-helsa .form-group .input-validation-error::placeholder,
.theme-helsa .input-validation-error::placeholder,
.theme-helsa .form-group .input-validation-error:-ms-input-placeholder,
.theme-helsa .input-validation-error:-ms-input-placeholder,
.theme-helsa .form-group .input-validation-error::-ms-input-placeholder,
.theme-helsa .input-validation-error::-ms-input-placeholder {
  color: #f48174;
}

.theme-ptj .form-group .input-validation-error::placeholder,
.theme-ptj .input-validation-error::placeholder,
.theme-ptj .form-group .input-validation-error:-ms-input-placeholder,
.theme-ptj .input-validation-error:-ms-input-placeholder,
.theme-ptj .form-group .input-validation-error::-ms-input-placeholder,
.theme-ptj .input-validation-error::-ms-input-placeholder {
  color: #f54359;
}

.theme-rjl .form-group .input-validation-error::placeholder,
.theme-rjl .input-validation-error::placeholder,
.theme-rjl .form-group .input-validation-error:-ms-input-placeholder,
.theme-rjl .input-validation-error:-ms-input-placeholder,
.theme-rjl .form-group .input-validation-error::-ms-input-placeholder,
.theme-rjl .input-validation-error::-ms-input-placeholder {
  color: #ce1141;
}

.theme-achima .form-group .input-validation-error::placeholder,
.theme-achima .input-validation-error::placeholder,
.theme-achima .form-group .input-validation-error:-ms-input-placeholder,
.theme-achima .input-validation-error:-ms-input-placeholder,
.theme-achima .form-group .input-validation-error::-ms-input-placeholder,
.theme-achima .input-validation-error::-ms-input-placeholder {
  color: #aa2b23;
}

.theme-blekinge .form-group .input-validation-error::placeholder,
.theme-blekinge .input-validation-error::placeholder,
.theme-blekinge .form-group .input-validation-error:-ms-input-placeholder,
.theme-blekinge .input-validation-error:-ms-input-placeholder,
.theme-blekinge .form-group .input-validation-error::-ms-input-placeholder,
.theme-blekinge .input-validation-error::-ms-input-placeholder {
  color: #d70480;
}

.theme-sll .form-group .input-validation-error::placeholder,
.theme-sll .input-validation-error::placeholder,
.theme-sll .form-group .input-validation-error:-ms-input-placeholder,
.theme-sll .input-validation-error:-ms-input-placeholder,
.theme-sll .form-group .input-validation-error::-ms-input-placeholder,
.theme-sll .input-validation-error::-ms-input-placeholder {
  color: #9a0932;
}

.theme-halland .form-group .input-validation-error::placeholder,
.theme-halland .input-validation-error::placeholder,
.theme-halland .form-group .input-validation-error:-ms-input-placeholder,
.theme-halland .input-validation-error:-ms-input-placeholder,
.theme-halland .form-group .input-validation-error::-ms-input-placeholder,
.theme-halland .input-validation-error::-ms-input-placeholder {
  color: #8e0000;
}

.theme-orebro .form-group .input-validation-error::placeholder,
.theme-orebro .input-validation-error::placeholder,
.theme-orebro .form-group .input-validation-error:-ms-input-placeholder,
.theme-orebro .input-validation-error:-ms-input-placeholder,
.theme-orebro .form-group .input-validation-error::-ms-input-placeholder,
.theme-orebro .input-validation-error::-ms-input-placeholder {
  color: #e32424;
}

.theme-rg .form-group .input-validation-error::placeholder,
.theme-rg .input-validation-error::placeholder,
.theme-rg .form-group .input-validation-error:-ms-input-placeholder,
.theme-rg .input-validation-error:-ms-input-placeholder,
.theme-rg .form-group .input-validation-error::-ms-input-placeholder,
.theme-rg .input-validation-error::-ms-input-placeholder {
  color: #ee3780;
}

.theme-default .field-validation-error {
  color: #e32424;
}

.theme-helsa .field-validation-error {
  color: #f48174;
}

.theme-ptj .field-validation-error {
  color: #f54359;
}

.theme-rjl .field-validation-error {
  color: #ce1141;
}

.theme-achima .field-validation-error {
  color: #aa2b23;
}

.theme-blekinge .field-validation-error {
  color: #d70480;
}

.theme-sll .field-validation-error {
  color: #9a0932;
}

.theme-halland .field-validation-error {
  color: #8e0000;
}

.theme-orebro .field-validation-error {
  color: #e32424;
}

.theme-rg .field-validation-error {
  color: #ee3780;
}

.warning {
  text-align: center;
  font-size: 20px;
}
.theme-default .warning {
  color: #e32424;
}
.theme-helsa .warning {
  color: #f48174;
}
.theme-ptj .warning {
  color: #f54359;
}
.theme-rjl .warning {
  color: #ce1141;
}
.theme-achima .warning {
  color: #aa2b23;
}
.theme-blekinge .warning {
  color: #d70480;
}
.theme-sll .warning {
  color: #9a0932;
}
.theme-halland .warning {
  color: #8e0000;
}
.theme-orebro .warning {
  color: #e32424;
}
.theme-rg .warning {
  color: #ee3780;
}

.field-validation-valid {
  display: none;
}

/*---------------------------------------------------------------------------
                             INPUT / TEXTBOXES 
---------------------------------------------------------------------------*/
question-block .input-group {
  display: normal;
}

input[type="range"] {
  -webkit-appearance: none;
  margin: 15px 0;
  padding: 0;
  transition: all 0.3s;
  width: auto;
}
.theme-default input[type="range"] {
  border-radius: 0;
}
.theme-helsa input[type="range"] {
  border-radius: 0;
}
.theme-ptj input[type="range"] {
  border-radius: 0px;
}
.theme-rjl input[type="range"] {
  border-radius: 0;
}
.theme-achima input[type="range"] {
  border-radius: 5px;
}
.theme-blekinge input[type="range"] {
  border-radius: 0;
}
.theme-sll input[type="range"] {
  border-radius: 0;
}
.theme-halland input[type="range"] {
  border-radius: 0;
}
.theme-orebro input[type="range"] {
  border-radius: 3px;
}
.theme-rg input[type="range"] {
  border-radius: 0px;
}

input[type="range"]:focus {
  outline: none;
}

input.symptomform-fullname,
input.symptomform-ssn {
  border: none;
}

input.symptomform-fullname {
  font-weight: bold;
}

@media screen and (min-width: 992px) {
  .admin .journalnote {
    padding-left: 0;
  }
}

.admin .question-block .slider {
  margin-bottom: -20px;
  height: 15px;
}

@media screen and (min-width: 768px) {
  .admin .question-block .slider .unitLabel {
    float: right;
  }
}

.admin .question-block .slider .unitLabel {
  font-size: 14px;
}

.question-block .slider .unitLabel {
  font-weight: normal;
}

.admin .question-block .slider .unitLabel .unitSpan {
  margin-top: 6px;
}

.question-block .slider label .unitLabel {
  font-size: 18px;
}
.theme-default .question-block .slider label .unitLabel {
  background-color: #ffffff;
  border-color: #000000;
  color: #000000;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.theme-helsa .question-block .slider label .unitLabel {
  background-color: #ffffff;
  border-color: #000000;
  color: #000000;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.theme-ptj .question-block .slider label .unitLabel {
  background-color: #ffffff;
  border-color: #0075b0;
  color: #333333;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.theme-rjl .question-block .slider label .unitLabel {
  background-color: #ffffff;
  border-color: #8cc63f;
  color: #000000;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.theme-achima .question-block .slider label .unitLabel {
  background-color: #fff;
  border-color: #000;
  color: #000;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.theme-blekinge .question-block .slider label .unitLabel {
  background-color: #fff;
  border-color: #000;
  color: #000;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.theme-sll .question-block .slider label .unitLabel {
  background-color: #fff;
  border-color: #002d5a;
  color: #000;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.theme-halland .question-block .slider label .unitLabel {
  background-color: #fff;
  border-color: #aaaaaa;
  color: #000;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.theme-orebro .question-block .slider label .unitLabel {
  background-color: #fff;
  border-color: #000000;
  color: #000000;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.theme-rg .question-block .slider label .unitLabel {
  background-color: #fff;
  border-color: #000000;
  color: #000000;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.admin .question-block .slider label .unitLabel {
  margin-top: 2px;
  margin-bottom: -6px;
  height: 26px;
}

.theme-default .question-block .slider label .unitLabel:focus {
  background-color: #ffffff;
  border-color: #0a4a7c;
  color: #000000;
}

.theme-helsa .question-block .slider label .unitLabel:focus {
  background-color: #ffffff;
  border-color: #6cb680;
  color: #000000;
}

.theme-ptj .question-block .slider label .unitLabel:focus {
  background-color: #f8f8f8;
  border-color: #e78300;
  color: #333333;
}

.theme-rjl .question-block .slider label .unitLabel:focus {
  background-color: #ffffff;
  border-color: #579835;
  color: #000000;
}

.theme-achima .question-block .slider label .unitLabel:focus {
  background-color: #fff;
  border-color: #00ada8;
  color: #000;
}

.theme-blekinge .question-block .slider label .unitLabel:focus {
  background-color: #fff;
  border-color: #00a6e2;
  color: #193f76;
}

.theme-sll .question-block .slider label .unitLabel:focus {
  background-color: #e5f1f8;
  border-color: #00aeef;
  color: #002d5a;
}

.theme-halland .question-block .slider label .unitLabel:focus {
  background-color: #fff;
  border-color: #6dacde;
  color: #000;
}

.theme-orebro .question-block .slider label .unitLabel:focus {
  background-color: #fff;
  border-color: #4f80ff;
  color: #000000;
}

.theme-rg .question-block .slider label .unitLabel:focus {
  background-color: #fff;
  border-color: #faa634;
  color: #000000;
}

.admin .form-control {
  padding: 2px 8px;
  font-weight: normal;
}

.admin .answer textarea,
.admin textarea,
.nurseJournalSectionDiv textarea,
.patientcontact .panel .panel-body textarea {
  resize: none;
  width: 100%;
  height: auto;
  padding: 2px 8px;
  margin-top: 2px;
  margin-bottom: 2px;
  font-size: 14px;
  font-weight: normal;
}
.theme-default .admin .answer textarea,
.theme-default .admin textarea,
.theme-default .nurseJournalSectionDiv textarea,
.theme-default .patientcontact .panel .panel-body textarea {
  color: #000;
  background-color: #fff;
  border: 1px solid #999;
  border-radius: 0;
}
.theme-helsa .admin .answer textarea,
.theme-helsa .admin textarea,
.theme-helsa .nurseJournalSectionDiv textarea,
.theme-helsa .patientcontact .panel .panel-body textarea {
  color: #000000;
  background-color: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 0;
}
.theme-ptj .admin .answer textarea,
.theme-ptj .admin textarea,
.theme-ptj .nurseJournalSectionDiv textarea,
.theme-ptj .patientcontact .panel .panel-body textarea {
  color: #333333;
  background-color: #fff;
  border: 1px solid #333333;
  border-radius: 0px;
}
.theme-rjl .admin .answer textarea,
.theme-rjl .admin textarea,
.theme-rjl .nurseJournalSectionDiv textarea,
.theme-rjl .patientcontact .panel .panel-body textarea {
  color: #000;
  background-color: #fff;
  border: 1px solid #000;
  border-radius: 0;
}
.theme-achima .admin .answer textarea,
.theme-achima .admin textarea,
.theme-achima .nurseJournalSectionDiv textarea,
.theme-achima .patientcontact .panel .panel-body textarea {
  color: #000;
  background-color: #fff;
  border: 1px solid #777;
  border-radius: 5px;
}
.theme-blekinge .admin .answer textarea,
.theme-blekinge .admin textarea,
.theme-blekinge .nurseJournalSectionDiv textarea,
.theme-blekinge .patientcontact .panel .panel-body textarea {
  color: #000;
  background-color: #fff;
  border: 1px solid #000;
  border-radius: 0;
}
.theme-sll .admin .answer textarea,
.theme-sll .admin textarea,
.theme-sll .nurseJournalSectionDiv textarea,
.theme-sll .patientcontact .panel .panel-body textarea {
  color: #002d5a;
  background-color: #fff;
  border: 1px solid #685c53;
  border-radius: 0;
}
.theme-halland .admin .answer textarea,
.theme-halland .admin textarea,
.theme-halland .nurseJournalSectionDiv textarea,
.theme-halland .patientcontact .panel .panel-body textarea {
  color: #000;
  background-color: #fff;
  border: 1px solid #aaaaaa;
  border-radius: 0;
}
.theme-orebro .admin .answer textarea,
.theme-orebro .admin textarea,
.theme-orebro .nurseJournalSectionDiv textarea,
.theme-orebro .patientcontact .panel .panel-body textarea {
  color: #000;
  background-color: #fff;
  border: 1px solid #c8e1eb;
  border-radius: 3px;
}
.theme-rg .admin .answer textarea,
.theme-rg .admin textarea,
.theme-rg .nurseJournalSectionDiv textarea,
.theme-rg .patientcontact .panel .panel-body textarea {
  color: #000;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 0px;
}

.admin textarea:focus,
.admin textarea:active,
.admin .answer textarea:focus,
.admin .answer textarea:active,
.nurseJournalSectionDiv textarea {
  resize: vertical;
  overflow: auto;
}
.theme-default .admin textarea:focus,
.theme-default .admin textarea:active,
.theme-default .admin .answer textarea:focus,
.theme-default .admin .answer textarea:active,
.theme-default .nurseJournalSectionDiv textarea {
  background-color: #d0f8f5;
  border: 1px solid #0a4a7c;
  outline: none;
}
.theme-helsa .admin textarea:focus,
.theme-helsa .admin textarea:active,
.theme-helsa .admin .answer textarea:focus,
.theme-helsa .admin .answer textarea:active,
.theme-helsa .nurseJournalSectionDiv textarea {
  background-color: #d7ead9;
  border: 1px solid #6cb680;
  outline: none;
}
.theme-ptj .admin textarea:focus,
.theme-ptj .admin textarea:active,
.theme-ptj .admin .answer textarea:focus,
.theme-ptj .admin .answer textarea:active,
.theme-ptj .nurseJournalSectionDiv textarea {
  background-color: #fff;
  border: 1px solid #0a4a7c;
  outline: none;
}
.theme-rjl .admin textarea:focus,
.theme-rjl .admin textarea:active,
.theme-rjl .admin .answer textarea:focus,
.theme-rjl .admin .answer textarea:active,
.theme-rjl .nurseJournalSectionDiv textarea {
  background-color: #fff;
  border: 1px solid #579835;
  outline: none;
}
.theme-achima .admin textarea:focus,
.theme-achima .admin textarea:active,
.theme-achima .admin .answer textarea:focus,
.theme-achima .admin .answer textarea:active,
.theme-achima .nurseJournalSectionDiv textarea {
  background-color: #fff;
  border: 1px solid #00ada8;
  outline: none;
}
.theme-blekinge .admin textarea:focus,
.theme-blekinge .admin textarea:active,
.theme-blekinge .admin .answer textarea:focus,
.theme-blekinge .admin .answer textarea:active,
.theme-blekinge .nurseJournalSectionDiv textarea {
  background-color: #fff;
  border: 1px solid #00a6e2;
  outline: none;
}
.theme-sll .admin textarea:focus,
.theme-sll .admin textarea:active,
.theme-sll .admin .answer textarea:focus,
.theme-sll .admin .answer textarea:active,
.theme-sll .nurseJournalSectionDiv textarea {
  background-color: #e5f1f8;
  border: 1px solid #00a6e2;
  outline: none;
}
.theme-halland .admin textarea:focus,
.theme-halland .admin textarea:active,
.theme-halland .admin .answer textarea:focus,
.theme-halland .admin .answer textarea:active,
.theme-halland .nurseJournalSectionDiv textarea {
  background-color: #fff;
  border: 1px solid #6dacde;
  outline: none;
}
.theme-orebro .admin textarea:focus,
.theme-orebro .admin textarea:active,
.theme-orebro .admin .answer textarea:focus,
.theme-orebro .admin .answer textarea:active,
.theme-orebro .nurseJournalSectionDiv textarea {
  background-color: #fff;
  border: 1px solid #4f80ff;
  outline: none;
}
.theme-rg .admin textarea:focus,
.theme-rg .admin textarea:active,
.theme-rg .admin .answer textarea:focus,
.theme-rg .admin .answer textarea:active,
.theme-rg .nurseJournalSectionDiv textarea {
  background-color: #fff;
  border: 1px solid #faa634;
  outline: none;
}

.patientanswer textarea {
  resize: vertical;
  width: 100%;
  height: 58px;
  padding: 4px 8px;
  border: none;
}
.theme-default .patientanswer textarea {
  border: 1px solid #000000;
  color: #000000;
  background-color: #ffffff;
  border-radius: 0;
}
.theme-helsa .patientanswer textarea {
  border: 1px solid #000000;
  color: #000000;
  background-color: #ffffff;
  border-radius: 0;
}
.theme-ptj .patientanswer textarea {
  border: 1px solid #0075b0;
  color: #333333;
  background-color: #ffffff;
  border-radius: 0px;
}
.theme-rjl .patientanswer textarea {
  border: 1px solid #8cc63f;
  color: #000000;
  background-color: #ffffff;
  border-radius: 0;
}
.theme-achima .patientanswer textarea {
  border: 1px solid #000;
  color: #000;
  background-color: #fff;
  border-radius: 5px;
}
.theme-blekinge .patientanswer textarea {
  border: 1px solid #000;
  color: #000;
  background-color: #fff;
  border-radius: 0;
}
.theme-sll .patientanswer textarea {
  border: 1px solid #002d5a;
  color: #000;
  background-color: #fff;
  border-radius: 0;
}
.theme-halland .patientanswer textarea {
  border: 1px solid #aaaaaa;
  color: #000;
  background-color: #fff;
  border-radius: 0;
}
.theme-orebro .patientanswer textarea {
  border: 1px solid #000000;
  color: #000000;
  background-color: #fff;
  border-radius: 3px;
}
.theme-rg .patientanswer textarea {
  border: 1px solid #000000;
  color: #000000;
  background-color: #fff;
  border-radius: 0px;
}

.mediumAndLongStringDiv {
  width: 100%;
}

/*komment*/
.theme-default .answer textarea:focus,
.theme-default .patientanswer textarea:focus,
.theme-default .patientanswer .input-group.date .form-control:active,
.theme-default .patientanswer .input-group.date .form-control:focus {
  border: 1px solid #0a4a7c;
  color: #000000;
  background-color: #ffffff;
  outline: none;
}

.theme-helsa .answer textarea:focus,
.theme-helsa .patientanswer textarea:focus,
.theme-helsa .patientanswer .input-group.date .form-control:active,
.theme-helsa .patientanswer .input-group.date .form-control:focus {
  border: 1px solid #6cb680;
  color: #000000;
  background-color: #ffffff;
  outline: none;
}

.theme-ptj .answer textarea:focus,
.theme-ptj .patientanswer textarea:focus,
.theme-ptj .patientanswer .input-group.date .form-control:active,
.theme-ptj .patientanswer .input-group.date .form-control:focus {
  border: 1px solid #e78300;
  color: #333333;
  background-color: #f8f8f8;
  outline: none;
}

.theme-rjl .answer textarea:focus,
.theme-rjl .patientanswer textarea:focus,
.theme-rjl .patientanswer .input-group.date .form-control:active,
.theme-rjl .patientanswer .input-group.date .form-control:focus {
  border: 1px solid #579835;
  color: #000000;
  background-color: #ffffff;
  outline: none;
}

.theme-achima .answer textarea:focus,
.theme-achima .patientanswer textarea:focus,
.theme-achima .patientanswer .input-group.date .form-control:active,
.theme-achima .patientanswer .input-group.date .form-control:focus {
  border: 1px solid #00ada8;
  color: #000;
  background-color: #fff;
  outline: none;
}

.theme-blekinge .answer textarea:focus,
.theme-blekinge .patientanswer textarea:focus,
.theme-blekinge .patientanswer .input-group.date .form-control:active,
.theme-blekinge .patientanswer .input-group.date .form-control:focus {
  border: 1px solid #00a6e2;
  color: #193f76;
  background-color: #fff;
  outline: none;
}

.theme-sll .answer textarea:focus,
.theme-sll .patientanswer textarea:focus,
.theme-sll .patientanswer .input-group.date .form-control:active,
.theme-sll .patientanswer .input-group.date .form-control:focus {
  border: 1px solid #00aeef;
  color: #002d5a;
  background-color: #e5f1f8;
  outline: none;
}

.theme-halland .answer textarea:focus,
.theme-halland .patientanswer textarea:focus,
.theme-halland .patientanswer .input-group.date .form-control:active,
.theme-halland .patientanswer .input-group.date .form-control:focus {
  border: 1px solid #6dacde;
  color: #000;
  background-color: #fff;
  outline: none;
}

.theme-orebro .answer textarea:focus,
.theme-orebro .patientanswer textarea:focus,
.theme-orebro .patientanswer .input-group.date .form-control:active,
.theme-orebro .patientanswer .input-group.date .form-control:focus {
  border: 1px solid #4f80ff;
  color: #000000;
  background-color: #fff;
  outline: none;
}

.theme-rg .answer textarea:focus,
.theme-rg .patientanswer textarea:focus,
.theme-rg .patientanswer .input-group.date .form-control:active,
.theme-rg .patientanswer .input-group.date .form-control:focus {
  border: 1px solid #faa634;
  color: #000000;
  background-color: #fff;
  outline: none;
}

.followUpFormTextarea {
  width: 100%;
  resize: none;
}
.theme-default .followUpFormTextarea {
  border-radius: 0;
}
.theme-helsa .followUpFormTextarea {
  border-radius: 0;
}
.theme-ptj .followUpFormTextarea {
  border-radius: 0px;
}
.theme-rjl .followUpFormTextarea {
  border-radius: 0;
}
.theme-achima .followUpFormTextarea {
  border-radius: 5px;
}
.theme-blekinge .followUpFormTextarea {
  border-radius: 0;
}
.theme-sll .followUpFormTextarea {
  border-radius: 0;
}
.theme-halland .followUpFormTextarea {
  border-radius: 0;
}
.theme-orebro .followUpFormTextarea {
  border-radius: 3px;
}
.theme-rg .followUpFormTextarea {
  border-radius: 0px;
}

.admin .answer .test-string-short {
  width: 60px;
  height: 26px;
  font-size: 14px;
  float: inherit;
  font-weight: normal;
  margin-top: 2px;
  margin-bottom: -6px;
}

.admin .answer .test-string-medium {
  width: 120px;
  height: 26px;
  font-size: 14px;
  float: inherit;
  font-weight: normal;
  margin-top: 2px;
  margin-bottom: -6px;
}

.question-block {
  padding: 8px 0 8px;
  margin-bottom: 0.3em;
  text-align: left;
}
.theme-default .question-block {
  background: #d0f8f5;
  border: 1px solid #a0f0eb;
  border-radius: 0;
}
.theme-helsa .question-block {
  background: #d7ead9;
  border: 1px solid #bedcc1;
  border-radius: 0;
}
.theme-ptj .question-block {
  background: #f3f3f3;
  border: 1px solid #cccccc;
  border-radius: 0px;
}
.theme-rjl .question-block {
  background: #ededed;
  border: 1px solid #ededed;
  border-radius: 0;
}
.theme-achima .question-block {
  background: #ededed;
  border: 1px solid #ededed;
  border-radius: 5px;
}
.theme-blekinge .question-block {
  background: #e5f6fc;
  border: 1px solid #e5f6fc;
  border-radius: 0;
}
.theme-sll .question-block {
  background: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 0;
}
.theme-halland .question-block {
  background: #d3e6f5;
  border: 1px solid #6dacde;
  border-radius: 0;
}
.theme-orebro .question-block {
  background: #e3f2f8;
  border: 1px solid #c8e1eb;
  border-radius: 3px;
}
.theme-rg .question-block {
  background: #f6f6f6;
  border: 1px solid #ccc;
  border-radius: 0px;
}

/*---------------------------------------------------------------------------
                             RANGE SLIDER
---------------------------------------------------------------------------*/
/*-------[ Draggable Element Input[type=range] on DOCTOR VIEW ]------------*/
/* SLIDER margin - Mozilla Firefox (Also affects Chrome, Safari and Opera in a negative way, so overite it later) */
.admin .draggable-element {
  margin-top: 6px !important;
  margin-bottom: 0px !important;
}

.admin .question-block input[type="range"].patientRegSlider,
.patientRegistration input[type="range"].patientRegSlider {
  background-color: transparent;
}

.admin .question-block input[type="range"] {
  /*removes default webkit styles*/
  -webkit-appearance: none;
  /*fix for FF unable to apply focus style bug */
  border: none;
  /*required for proper track sizing in FF*/
  width: 50%;
}

/* SLIDER - Chrome Opera Safari */
.admin .question-block input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 6px;
  border: none;
  border-radius: 3px;
  margin-top: 6px;
  /* Margin - since Fierfox had margins, Chrome, Opera and Safari had to compensate... */
  margin-bottom: 10px;
  /* Adds space between the unitbox and the slider on smal screens when the unitbox is below the slider */
}
.theme-default
  .admin
  .question-block
  input[type="range"]::-webkit-slider-runnable-track {
  background: #000;
  border-radius: 0;
}
.theme-helsa
  .admin
  .question-block
  input[type="range"]::-webkit-slider-runnable-track {
  background: #000;
  border-radius: 0;
}
.theme-ptj
  .admin
  .question-block
  input[type="range"]::-webkit-slider-runnable-track {
  background: #333333;
  border-radius: 0px;
}
.theme-rjl
  .admin
  .question-block
  input[type="range"]::-webkit-slider-runnable-track {
  background: #000;
  border-radius: 0;
}
.theme-achima
  .admin
  .question-block
  input[type="range"]::-webkit-slider-runnable-track {
  background: #000;
  border-radius: 3px;
}
.theme-blekinge
  .admin
  .question-block
  input[type="range"]::-webkit-slider-runnable-track {
  background: #000;
  border-radius: 0;
}
.theme-sll
  .admin
  .question-block
  input[type="range"]::-webkit-slider-runnable-track {
  background: #000;
  border-radius: 0;
}
.theme-halland
  .admin
  .question-block
  input[type="range"]::-webkit-slider-runnable-track {
  background: #000;
  border-radius: 0;
}
.theme-orebro
  .admin
  .question-block
  input[type="range"]::-webkit-slider-runnable-track {
  background: #000;
  border-radius: 3px;
}
.theme-rg
  .admin
  .question-block
  input[type="range"]::-webkit-slider-runnable-track {
  background: #000;
  border-radius: 0px;
}

/* BALL - Chrome Opera Safari */
.admin .question-block input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: none;
  height: 16px;
  width: 16px;
  border-radius: 100%;
  margin-top: -6px;
  /* Adjusting the placement of the ball. Changes depening on the balles size (height and width) */
}
.theme-default
  .admin
  .question-block
  input[type="range"]::-webkit-slider-thumb {
  background: #0a4a7c;
}
.theme-helsa .admin .question-block input[type="range"]::-webkit-slider-thumb {
  background: #1d5e74;
}
.theme-ptj .admin .question-block input[type="range"]::-webkit-slider-thumb {
  background: #0075b0;
}
.theme-rjl .admin .question-block input[type="range"]::-webkit-slider-thumb {
  background: #579835;
}
.theme-achima .admin .question-block input[type="range"]::-webkit-slider-thumb {
  background: #aa2b23;
}
.theme-blekinge
  .admin
  .question-block
  input[type="range"]::-webkit-slider-thumb {
  background: #00a6e2;
}
.theme-sll .admin .question-block input[type="range"]::-webkit-slider-thumb {
  background: #002d5a;
}
.theme-halland
  .admin
  .question-block
  input[type="range"]::-webkit-slider-thumb {
  background: #7bb1db;
}
.theme-orebro .admin .question-block input[type="range"]::-webkit-slider-thumb {
  background: #4f80ff;
}
.theme-rg .admin .question-block input[type="range"]::-webkit-slider-thumb {
  background: #0097cf;
}

/* SLIDER - Mozilla Firefox */
.admin .question-block input[type="range"]::-moz-range-track {
  width: 100%;
  height: 6px;
  border: none;
  margin-bottom: 10px;
  /* Adds space between the unitbox and the slider on smal screens when the unitbox is below the slider */
}
.theme-default .admin .question-block input[type="range"]::-moz-range-track {
  background: #000;
  border-radius: 0;
}
.theme-helsa .admin .question-block input[type="range"]::-moz-range-track {
  background: #000;
  border-radius: 0;
}
.theme-ptj .admin .question-block input[type="range"]::-moz-range-track {
  background: #333333;
  border-radius: 0px;
}
.theme-rjl .admin .question-block input[type="range"]::-moz-range-track {
  background: #000;
  border-radius: 0;
}
.theme-achima .admin .question-block input[type="range"]::-moz-range-track {
  background: #000;
  border-radius: 3px;
}
.theme-blekinge .admin .question-block input[type="range"]::-moz-range-track {
  background: #000;
  border-radius: 0;
}
.theme-sll .admin .question-block input[type="range"]::-moz-range-track {
  background: #000;
  border-radius: 0;
}
.theme-halland .admin .question-block input[type="range"]::-moz-range-track {
  background: #000;
  border-radius: 0;
}
.theme-orebro .admin .question-block input[type="range"]::-moz-range-track {
  background: #000;
  border-radius: 3px;
}
.theme-rg .admin .question-block input[type="range"]::-moz-range-track {
  background: #000;
  border-radius: 0px;
}

/* BALL - Mozilla Firefox */
.admin .question-block input[type="range"]::-moz-range-thumb {
  border: none;
  height: 16px;
  width: 16px;
  border-radius: 50%;
}
.theme-default .admin .question-block input[type="range"]::-moz-range-thumb {
  background: #0a4a7c;
}
.theme-helsa .admin .question-block input[type="range"]::-moz-range-thumb {
  background: #1d5e74;
}
.theme-ptj .admin .question-block input[type="range"]::-moz-range-thumb {
  background: #0075b0;
}
.theme-rjl .admin .question-block input[type="range"]::-moz-range-thumb {
  background: #579835;
}
.theme-achima .admin .question-block input[type="range"]::-moz-range-thumb {
  background: #aa2b23;
}
.theme-blekinge .admin .question-block input[type="range"]::-moz-range-thumb {
  background: #00a6e2;
}
.theme-sll .admin .question-block input[type="range"]::-moz-range-thumb {
  background: #002d5a;
}
.theme-halland .admin .question-block input[type="range"]::-moz-range-thumb {
  background: #7bb1db;
}
.theme-orebro .admin .question-block input[type="range"]::-moz-range-thumb {
  background: #4f80ff;
}
.theme-rg .admin .question-block input[type="range"]::-moz-range-thumb {
  background: #0097cf;
}

/* SLIDER - Internet Explorer */
.admin .question-block input[type="range"]::-ms-track {
  width: 100%;
  height: 6px;
  /*remove bg colour from the track, we'll use ms-fill-lower and ms-fill-upper instead */
  background: transparent;
  /*leave room for the larger thumb to overflow with a transparent border */
  border-color: transparent;
  border-width: 4px 0;
  /*remove default tick marks*/
  color: transparent;
  /*margin-top: -2px;*/
}

/* SLIDER - Internet Explorer */
.theme-default .admin .question-block input[type="range"]::-ms-fill-lower {
  background: #555;
  border-radius: 0;
}

.theme-helsa .admin .question-block input[type="range"]::-ms-fill-lower {
  background: #555;
  border-radius: 0;
}

.theme-ptj .admin .question-block input[type="range"]::-ms-fill-lower {
  background: #555;
  border-radius: 0px;
}

.theme-rjl .admin .question-block input[type="range"]::-ms-fill-lower {
  background: #636466;
  border-radius: 0;
}

.theme-achima .admin .question-block input[type="range"]::-ms-fill-lower {
  background: #555;
  border-radius: 3px;
}

.theme-blekinge .admin .question-block input[type="range"]::-ms-fill-lower {
  background: #193f76;
  border-radius: 0;
}

.theme-sll .admin .question-block input[type="range"]::-ms-fill-lower {
  background: #002d5a;
  border-radius: 0;
}

.theme-halland .admin .question-block input[type="range"]::-ms-fill-lower {
  background: #000000;
  border-radius: 0;
}

.theme-orebro .admin .question-block input[type="range"]::-ms-fill-lower {
  background: #000000;
  border-radius: 3px;
}

.theme-rg .admin .question-block input[type="range"]::-ms-fill-lower {
  background: #000000;
  border-radius: 0px;
}

/* SLIDER - Internet Explorer */
.theme-default .admin .question-block input[type="range"]::-ms-fill-upper {
  background: #000;
  border-radius: 0;
}

.theme-helsa .admin .question-block input[type="range"]::-ms-fill-upper {
  background: #000;
  border-radius: 0;
}

.theme-ptj .admin .question-block input[type="range"]::-ms-fill-upper {
  background: #333333;
  border-radius: 0px;
}

.theme-rjl .admin .question-block input[type="range"]::-ms-fill-upper {
  background: #000;
  border-radius: 0;
}

.theme-achima .admin .question-block input[type="range"]::-ms-fill-upper {
  background: #000;
  border-radius: 3px;
}

.theme-blekinge .admin .question-block input[type="range"]::-ms-fill-upper {
  background: #000;
  border-radius: 0;
}

.theme-sll .admin .question-block input[type="range"]::-ms-fill-upper {
  background: #000;
  border-radius: 0;
}

.theme-halland .admin .question-block input[type="range"]::-ms-fill-upper {
  background: #000;
  border-radius: 0;
}

.theme-orebro .admin .question-block input[type="range"]::-ms-fill-upper {
  background: #000;
  border-radius: 3px;
}

.theme-rg .admin .question-block input[type="range"]::-ms-fill-upper {
  background: #000;
  border-radius: 0px;
}

/* BALL - Internet Explorer */
.admin .question-block input[type="range"]::-ms-thumb {
  border: none;
  height: 20px;
  width: 20px;
  z-index: 10;
  margin-top: -2px;
}
.theme-default .admin .question-block input[type="range"]::-ms-thumb {
  background: #0a4a7c;
  border-radius: 0;
}
.theme-helsa .admin .question-block input[type="range"]::-ms-thumb {
  background: #1d5e74;
  border-radius: 0;
}
.theme-ptj .admin .question-block input[type="range"]::-ms-thumb {
  background: #0075b0;
  border-radius: 0px;
}
.theme-rjl .admin .question-block input[type="range"]::-ms-thumb {
  background: #579835;
  border-radius: 0;
}
.theme-achima .admin .question-block input[type="range"]::-ms-thumb {
  background: #aa2b23;
  border-radius: 2px;
}
.theme-blekinge .admin .question-block input[type="range"]::-ms-thumb {
  background: #00a6e2;
  border-radius: 0;
}
.theme-sll .admin .question-block input[type="range"]::-ms-thumb {
  background: #002d5a;
  border-radius: 0;
}
.theme-halland .admin .question-block input[type="range"]::-ms-thumb {
  background: #7bb1db;
  border-radius: 0;
}
.theme-orebro .admin .question-block input[type="range"]::-ms-thumb {
  background: #4f80ff;
  border-radius: 1px;
}
.theme-rg .admin .question-block input[type="range"]::-ms-thumb {
  background: #0097cf;
  border-radius: 0px;
}

/*------------------[ Draggable Element Input[type=range] on PATIENT VIEW]--------------------*/
.patientRegistration .patientRegUnitLabel {
  margin-bottom: -4px;
}

.patientRegistration .patientRegSlider {
  margin-bottom: 0;
}

input[type="range"] {
  /*removes default webkit styles*/
  -webkit-appearance: none;
  /*fix for FF unable to apply focus style bug */
  border: none;
  background: none;
}

@media screen and (min-width: 768px) {
  input[type="range"] {
    /*required for proper track sizing in FF*/
    width: 50%;
  }
}

/* SLIDER - Chrome Opera Safari */
input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 5px;
  border: none;
  margin-bottom: 20px;
}
.theme-default input[type="range"]::-webkit-slider-runnable-track {
  background: #000;
  border-radius: 0;
}
.theme-helsa input[type="range"]::-webkit-slider-runnable-track {
  background: #000;
  border-radius: 0;
}
.theme-ptj input[type="range"]::-webkit-slider-runnable-track {
  background: #333333;
  border-radius: 0px;
}
.theme-rjl input[type="range"]::-webkit-slider-runnable-track {
  background: #000;
  border-radius: 0;
}
.theme-achima input[type="range"]::-webkit-slider-runnable-track {
  background: #000;
  border-radius: 3px;
}
.theme-blekinge input[type="range"]::-webkit-slider-runnable-track {
  background: #000;
  border-radius: 0;
}
.theme-sll input[type="range"]::-webkit-slider-runnable-track {
  background: #000;
  border-radius: 0;
}
.theme-halland input[type="range"]::-webkit-slider-runnable-track {
  background: #000;
  border-radius: 0;
}
.theme-orebro input[type="range"]::-webkit-slider-runnable-track {
  background: #000;
  border-radius: 3px;
}
.theme-rg input[type="range"]::-webkit-slider-runnable-track {
  background: #000;
  border-radius: 0px;
}

/* BALL - Chrome Opera Safari */
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: none;
  height: 30px;
  width: 30px;
  border-radius: 100%;
  margin-top: -12px;
}
.theme-default input[type="range"]::-webkit-slider-thumb {
  background: #0a4a7c;
}
.theme-helsa input[type="range"]::-webkit-slider-thumb {
  background: #1d5e74;
}
.theme-ptj input[type="range"]::-webkit-slider-thumb {
  background: #0075b0;
}
.theme-rjl input[type="range"]::-webkit-slider-thumb {
  background: #579835;
}
.theme-achima input[type="range"]::-webkit-slider-thumb {
  background: #aa2b23;
}
.theme-blekinge input[type="range"]::-webkit-slider-thumb {
  background: #00a6e2;
}
.theme-sll input[type="range"]::-webkit-slider-thumb {
  background: #002d5a;
}
.theme-halland input[type="range"]::-webkit-slider-thumb {
  background: #7bb1db;
}
.theme-orebro input[type="range"]::-webkit-slider-thumb {
  background: #4f80ff;
}
.theme-rg input[type="range"]::-webkit-slider-thumb {
  background: #0097cf;
}

input[type="range"]:focus {
  outline: none;
}

.theme-default input[type="range"]:focus::-webkit-slider-runnable-track {
  background: #000;
}

.theme-helsa input[type="range"]:focus::-webkit-slider-runnable-track {
  background: #000;
}

.theme-ptj input[type="range"]:focus::-webkit-slider-runnable-track {
  background: #333333;
}

.theme-rjl input[type="range"]:focus::-webkit-slider-runnable-track {
  background: #000;
}

.theme-achima input[type="range"]:focus::-webkit-slider-runnable-track {
  background: #000;
}

.theme-blekinge input[type="range"]:focus::-webkit-slider-runnable-track {
  background: #000;
}

.theme-sll input[type="range"]:focus::-webkit-slider-runnable-track {
  background: #000;
}

.theme-halland input[type="range"]:focus::-webkit-slider-runnable-track {
  background: #000;
}

.theme-orebro input[type="range"]:focus::-webkit-slider-runnable-track {
  background: #000;
}

.theme-rg input[type="range"]:focus::-webkit-slider-runnable-track {
  background: #000;
}

/* SLIDER - Mozilla Firefox */
input[type="range"]::-moz-range-track {
  width: 100%;
  height: 5px;
  border: none;
}
.theme-default input[type="range"]::-moz-range-track {
  background: #000;
  border-radius: 0;
}
.theme-helsa input[type="range"]::-moz-range-track {
  background: #000;
  border-radius: 0;
}
.theme-ptj input[type="range"]::-moz-range-track {
  background: #333333;
  border-radius: 0px;
}
.theme-rjl input[type="range"]::-moz-range-track {
  background: #000;
  border-radius: 0;
}
.theme-achima input[type="range"]::-moz-range-track {
  background: #000;
  border-radius: 3px;
}
.theme-blekinge input[type="range"]::-moz-range-track {
  background: #000;
  border-radius: 0;
}
.theme-sll input[type="range"]::-moz-range-track {
  background: #000;
  border-radius: 0;
}
.theme-halland input[type="range"]::-moz-range-track {
  background: #000;
  border-radius: 0;
}
.theme-orebro input[type="range"]::-moz-range-track {
  background: #000;
  border-radius: 3px;
}
.theme-rg input[type="range"]::-moz-range-track {
  background: #000;
  border-radius: 0px;
}

/* BALL - Mozilla Firefox */
input[type="range"]::-moz-range-thumb {
  border: none;
  height: 30px;
  width: 30px;
  border-radius: 50%;
}
.theme-default input[type="range"]::-moz-range-thumb {
  background: #0a4a7c;
}
.theme-helsa input[type="range"]::-moz-range-thumb {
  background: #1d5e74;
}
.theme-ptj input[type="range"]::-moz-range-thumb {
  background: #0075b0;
}
.theme-rjl input[type="range"]::-moz-range-thumb {
  background: #579835;
}
.theme-achima input[type="range"]::-moz-range-thumb {
  background: #aa2b23;
}
.theme-blekinge input[type="range"]::-moz-range-thumb {
  background: #00a6e2;
}
.theme-sll input[type="range"]::-moz-range-thumb {
  background: #002d5a;
}
.theme-halland input[type="range"]::-moz-range-thumb {
  background: #7bb1db;
}
.theme-orebro input[type="range"]::-moz-range-thumb {
  background: #4f80ff;
}
.theme-rg input[type="range"]::-moz-range-thumb {
  background: #0097cf;
}

/*hide the outline behind the border*/
input[type="range"]:-moz-focusring {
  outline-offset: -1px;
}
.theme-default input[type="range"]:-moz-focusring {
  outline: 1px solid #fff;
}
.theme-helsa input[type="range"]:-moz-focusring {
  outline: 1px solid #fff;
}
.theme-ptj input[type="range"]:-moz-focusring {
  outline: 1px solid #fff;
}
.theme-rjl input[type="range"]:-moz-focusring {
  outline: 1px solid #fff;
}
.theme-achima input[type="range"]:-moz-focusring {
  outline: 1px solid #fff;
}
.theme-blekinge input[type="range"]:-moz-focusring {
  outline: 1px solid #fff;
}
.theme-sll input[type="range"]:-moz-focusring {
  outline: 1px solid #fff;
}
.theme-halland input[type="range"]:-moz-focusring {
  outline: 1px solid #fff;
}
.theme-orebro input[type="range"]:-moz-focusring {
  outline: 1px solid #fff;
}
.theme-rg input[type="range"]:-moz-focusring {
  outline: 1px solid #fff;
}

/* SLIDER - Internet Explorer */
input[type="range"]::-ms-track {
  width: 100%;
  height: 5px;
  /*remove bg colour from the track, we'll use ms-fill-lower and ms-fill-upper instead */
  background: transparent;
  /*leave room for the larger thumb to overflow with a transparent border */
  border-color: transparent;
  border-width: 6px 0;
  /*remove default tick marks*/
  color: transparent;
}

/* SLIDER - Internet Explorer */
.theme-default input[type="range"]::-ms-fill-lower {
  background: #555;
  border-radius: 0;
}

.theme-helsa input[type="range"]::-ms-fill-lower {
  background: #555;
  border-radius: 0;
}

.theme-ptj input[type="range"]::-ms-fill-lower {
  background: #555;
  border-radius: 0px;
}

.theme-rjl input[type="range"]::-ms-fill-lower {
  background: #636466;
  border-radius: 0;
}

.theme-achima input[type="range"]::-ms-fill-lower {
  background: #555;
  border-radius: 10px;
}

.theme-blekinge input[type="range"]::-ms-fill-lower {
  background: #193f76;
  border-radius: 0;
}

.theme-sll input[type="range"]::-ms-fill-lower {
  background: #002d5a;
  border-radius: 0;
}

.theme-halland input[type="range"]::-ms-fill-lower {
  background: #000000;
  border-radius: 0;
}

.theme-orebro input[type="range"]::-ms-fill-lower {
  background: #000000;
  border-radius: 5px;
}

.theme-rg input[type="range"]::-ms-fill-lower {
  background: #000000;
  border-radius: 0px;
}

/* SLIDER - Internet Explorer */
.theme-default input[type="range"]::-ms-fill-upper {
  background: #000;
  border-radius: 0;
}

.theme-helsa input[type="range"]::-ms-fill-upper {
  background: #000;
  border-radius: 0;
}

.theme-ptj input[type="range"]::-ms-fill-upper {
  background: #333333;
  border-radius: 0px;
}

.theme-rjl input[type="range"]::-ms-fill-upper {
  background: #000;
  border-radius: 0;
}

.theme-achima input[type="range"]::-ms-fill-upper {
  background: #000;
  border-radius: 10px;
}

.theme-blekinge input[type="range"]::-ms-fill-upper {
  background: #000;
  border-radius: 0;
}

.theme-sll input[type="range"]::-ms-fill-upper {
  background: #000;
  border-radius: 0;
}

.theme-halland input[type="range"]::-ms-fill-upper {
  background: #000;
  border-radius: 0;
}

.theme-orebro input[type="range"]::-ms-fill-upper {
  background: #000;
  border-radius: 5px;
}

.theme-rg input[type="range"]::-ms-fill-upper {
  background: #000;
  border-radius: 0px;
}

/* BALL - Internet Explorer */
input[type="range"]::-ms-thumb {
  border: none;
  height: 25px;
  width: 25px;
  border-radius: 15px;
  z-index: 5;
  margin-top: -1px;
}
.theme-default input[type="range"]::-ms-thumb {
  background: #0a4a7c;
}
.theme-helsa input[type="range"]::-ms-thumb {
  background: #1d5e74;
}
.theme-ptj input[type="range"]::-ms-thumb {
  background: #0075b0;
}
.theme-rjl input[type="range"]::-ms-thumb {
  background: #579835;
}
.theme-achima input[type="range"]::-ms-thumb {
  background: #aa2b23;
}
.theme-blekinge input[type="range"]::-ms-thumb {
  background: #00a6e2;
}
.theme-sll input[type="range"]::-ms-thumb {
  background: #002d5a;
}
.theme-halland input[type="range"]::-ms-thumb {
  background: #7bb1db;
}
.theme-orebro input[type="range"]::-ms-thumb {
  background: #4f80ff;
}
.theme-rg input[type="range"]::-ms-thumb {
  background: #0097cf;
}

/* SLIDER - Internet Explorer */
.theme-default input[type="range"]:focus::-ms-fill-lower {
  background: #555;
}

.theme-helsa input[type="range"]:focus::-ms-fill-lower {
  background: #555;
}

.theme-ptj input[type="range"]:focus::-ms-fill-lower {
  background: #555;
}

.theme-rjl input[type="range"]:focus::-ms-fill-lower {
  background: #636466;
}

.theme-achima input[type="range"]:focus::-ms-fill-lower {
  background: #555;
}

.theme-blekinge input[type="range"]:focus::-ms-fill-lower {
  background: #193f76;
}

.theme-sll input[type="range"]:focus::-ms-fill-lower {
  background: #002d5a;
}

.theme-halland input[type="range"]:focus::-ms-fill-lower {
  background: #000000;
}

.theme-orebro input[type="range"]:focus::-ms-fill-lower {
  background: #000000;
}

.theme-rg input[type="range"]:focus::-ms-fill-lower {
  background: #000000;
}

/* SLIDER - Internet Explorer */
.theme-default input[type="range"]:focus::-ms-fill-upper {
  background: #000;
}

.theme-helsa input[type="range"]:focus::-ms-fill-upper {
  background: #000;
}

.theme-ptj input[type="range"]:focus::-ms-fill-upper {
  background: #333333;
}

.theme-rjl input[type="range"]:focus::-ms-fill-upper {
  background: #000;
}

.theme-achima input[type="range"]:focus::-ms-fill-upper {
  background: #000;
}

.theme-blekinge input[type="range"]:focus::-ms-fill-upper {
  background: #000;
}

.theme-sll input[type="range"]:focus::-ms-fill-upper {
  background: #000;
}

.theme-halland input[type="range"]:focus::-ms-fill-upper {
  background: #000;
}

.theme-orebro input[type="range"]:focus::-ms-fill-upper {
  background: #000;
}

.theme-rg input[type="range"]:focus::-ms-fill-upper {
  background: #000;
}

/*----------------------[ Number Input[type=number]]------------------------*/
input[type="number"] {
  padding: 7px;
}
.theme-default input[type="number"] {
  border-radius: 0;
  background: #d0f8f5;
  border: 1px solid;
  color: #0a4a7c;
  border-radius: 0;
}
.theme-helsa input[type="number"] {
  border-radius: 0;
  background: #d7ead9;
  border: 1px solid;
  color: #000000;
  border-radius: 0;
}
.theme-ptj input[type="number"] {
  border-radius: 0px;
  background: #ffffff;
  border: 1px solid;
  color: #333333;
  border-radius: 0px;
}
.theme-rjl input[type="number"] {
  border-radius: 0;
  background: #fff;
  border: 1px solid;
  color: #000;
  border-radius: 0;
}
.theme-achima input[type="number"] {
  border-radius: 5px;
  background: #fff;
  border: 1px solid;
  color: #000;
  border-radius: 5px;
}
.theme-blekinge input[type="number"] {
  border-radius: 0;
  background: #fff;
  border: 1px solid;
  color: #000;
  border-radius: 0;
}
.theme-sll input[type="number"] {
  border-radius: 0;
  background: #fff;
  border: 1px solid;
  color: #000;
  border-radius: 0;
}
.theme-halland input[type="number"] {
  border-radius: 0;
  background: #fff;
  border: 1px solid;
  color: #000;
  border-radius: 0;
}
.theme-orebro input[type="number"] {
  border-radius: 3px;
  background: #fff;
  border: 1px solid;
  color: #000;
  border-radius: 3px;
}
.theme-rg input[type="number"] {
  border-radius: 0px;
  background: #fff;
  border: 1px solid;
  color: #000;
  border-radius: 0px;
}

input[type="number"]:focus {
  box-shadow: none;
  outline: none;
}
.theme-default input[type="number"]:focus {
  background: #d0f8f5;
  border: 1px solid;
  color: #000000;
}
.theme-helsa input[type="number"]:focus {
  background: #d7ead9;
  border: 1px solid;
  color: #000000;
}
.theme-ptj input[type="number"]:focus {
  background: #faf2e8;
  border: 1px solid;
  color: #333333;
}
.theme-rjl input[type="number"]:focus {
  background: #fff;
  border: 1px solid;
  color: #000000;
}
.theme-achima input[type="number"]:focus {
  background: #fff;
  border: 1px solid;
  color: #000000;
}
.theme-blekinge input[type="number"]:focus {
  background: #fff;
  border: 1px solid;
  color: #000000;
}
.theme-sll input[type="number"]:focus {
  background: #e5f1f8;
  border: 1px solid;
  color: #002d5a;
}
.theme-halland input[type="number"]:focus {
  background: #d3e6f5;
  border: 1px solid;
  color: #000;
}
.theme-orebro input[type="number"]:focus {
  background: #d1e9f2;
  border: 1px solid;
  color: #000;
}
.theme-rg input[type="number"]:focus {
  background: #fff;
  border: 1px solid;
  color: #000;
}

/*----------------------[ Number Input[type=text]]------------------------*/
input[type="text"],
input[type="password"] {
  padding: 7px;
  box-shadow: none;
}
.theme-default input[type="text"],
.theme-default input[type="password"] {
  border: 1px solid #a0f0eb;
  background-color: #d0f8f5;
  color: #0a4a7c;
  border-radius: 0;
}
.theme-helsa input[type="text"],
.theme-helsa input[type="password"] {
  border: 1px solid #bedcc1;
  background-color: #d7ead9;
  color: #000000;
  border-radius: 0;
}
.theme-ptj input[type="text"],
.theme-ptj input[type="password"] {
  border: 1px solid #0075b0;
  background-color: #ffffff;
  color: #333333;
  border-radius: 0px;
}
.theme-rjl input[type="text"],
.theme-rjl input[type="password"] {
  border: 1px solid #8cc63f;
  background-color: #fff;
  color: #000;
  border-radius: 0;
}
.theme-achima input[type="text"],
.theme-achima input[type="password"] {
  border: 1px solid #777;
  background-color: #fff;
  color: #000;
  border-radius: 5px;
}
.theme-blekinge input[type="text"],
.theme-blekinge input[type="password"] {
  border: 1px solid #000;
  background-color: #fff;
  color: #000;
  border-radius: 0;
}
.theme-sll input[type="text"],
.theme-sll input[type="password"] {
  border: 1px solid #002d5a;
  background-color: #fff;
  color: #000;
  border-radius: 0;
}
.theme-halland input[type="text"],
.theme-halland input[type="password"] {
  border: 1px solid #6dacde;
  background-color: #fff;
  color: #000;
  border-radius: 0;
}
.theme-orebro input[type="text"],
.theme-orebro input[type="password"] {
  border: 1px solid #000;
  background-color: #fff;
  color: #000;
  border-radius: 3px;
}
.theme-rg input[type="text"],
.theme-rg input[type="password"] {
  border: 1px solid #000;
  background-color: #fff;
  color: #000;
  border-radius: 0px;
}

input[type="text"]:-ms-input-placeholder.form-control,
input[type="password"]:-ms-input-placeholder.form-control {
  color: #999;
}

input[type="text"]:focus,
input[type="password"]:focus {
  box-shadow: none;
  outline: none;
}
.theme-default input[type="text"]:focus,
.theme-default input[type="password"]:focus {
  border: 1px solid #0a4a7c;
  background-color: #d0f8f5;
  color: #000000;
}
.theme-helsa input[type="text"]:focus,
.theme-helsa input[type="password"]:focus {
  border: 1px solid #6cb680;
  background-color: #d7ead9;
  color: #000000;
}
.theme-ptj input[type="text"]:focus,
.theme-ptj input[type="password"]:focus {
  border: 1px solid #e78300;
  background-color: #faf2e8;
  color: #333333;
}
.theme-rjl input[type="text"]:focus,
.theme-rjl input[type="password"]:focus {
  border: 1px solid #579835;
  background-color: #fff;
  color: #000000;
}
.theme-achima input[type="text"]:focus,
.theme-achima input[type="password"]:focus {
  border: 1px solid #00ada8;
  background-color: #fff;
  color: #000000;
}
.theme-blekinge input[type="text"]:focus,
.theme-blekinge input[type="password"]:focus {
  border: 1px solid #00a6e2;
  background-color: #fff;
  color: #000000;
}
.theme-sll input[type="text"]:focus,
.theme-sll input[type="password"]:focus {
  border: 1px solid #00a6e2;
  background-color: #e5f1f8;
  color: #002d5a;
}
.theme-halland input[type="text"]:focus,
.theme-halland input[type="password"]:focus {
  border: 1px solid #6dacde;
  background-color: #d3e6f5;
  color: #000;
}
.theme-orebro input[type="text"]:focus,
.theme-orebro input[type="password"]:focus {
  border: 1px solid #4f80ff;
  background-color: #d1e9f2;
  color: #000;
}
.theme-rg input[type="text"]:focus,
.theme-rg input[type="password"]:focus {
  border: 1px solid #faa634;
  background-color: #fff;
  color: #000;
}

.answer input,
.patientanswer input {
  display: none;
}

.three-toggle input {
  display: none;
}

.theme-default .three-toggle input:checked + label {
  color: #fff;
  background-color: #0a4a7c;
  border-color: #0a4a7c;
}

.theme-helsa .three-toggle input:checked + label {
  color: #fff;
  background-color: #1d5e74;
  border-color: #1d5e74;
}

.theme-ptj .three-toggle input:checked + label {
  color: #fff;
  background-color: #0075b0;
  border-color: #0075b0;
}

.theme-rjl .three-toggle input:checked + label {
  color: #fff;
  background-color: #579835;
  border-color: #579835;
}

.theme-achima .three-toggle input:checked + label {
  color: #fff;
  background-color: #aa2b23;
  border-color: #aa2b23;
}

.theme-blekinge .three-toggle input:checked + label {
  color: #fff;
  background-color: #00a6e2;
  border-color: #00a6e2;
}

.theme-sll .three-toggle input:checked + label {
  color: #fff;
  background-color: #002d5a;
  border-color: #002d5a;
}

.theme-halland .three-toggle input:checked + label {
  color: #fff;
  background-color: #7bb1db;
  border-color: #7bb1db;
}

.theme-orebro .three-toggle input:checked + label {
  color: #fff;
  background-color: #4f80ff;
  border-color: #4f80ff;
}

.theme-rg .three-toggle input:checked + label {
  color: #fff;
  background-color: #0097cf;
  border-color: #0097cf;
}

.slider .form-control {
  width: 60px;
  display: inline-block;
  border-style: solid;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: none;
  transition: none;
}
.theme-default .slider .form-control {
  border-radius: 0;
}
.theme-helsa .slider .form-control {
  border-radius: 0;
}
.theme-ptj .slider .form-control {
  border-radius: 0px;
}
.theme-rjl .slider .form-control {
  border-radius: 0;
}
.theme-achima .slider .form-control {
  border-radius: 5px;
}
.theme-blekinge .slider .form-control {
  border-radius: 0;
}
.theme-sll .slider .form-control {
  border-radius: 0;
}
.theme-halland .slider .form-control {
  border-radius: 0;
}
.theme-orebro .slider .form-control {
  border-radius: 3px;
}
.theme-rg .slider .form-control {
  border-radius: 0px;
}

.theme-default .slider .form-control:focus {
  border: 1px solid #000;
}

.theme-helsa .slider .form-control:focus {
  border: 1px solid #000;
}

.theme-ptj .slider .form-control:focus {
  border: 1px solid #333333;
}

.theme-rjl .slider .form-control:focus {
  border: 1px solid #000;
}

.theme-achima .slider .form-control:focus {
  border: 1px solid #000;
}

.theme-blekinge .slider .form-control:focus {
  border: 1px solid #000;
}

.theme-sll .slider .form-control:focus {
  border: 1px solid #000;
}

.theme-halland .slider .form-control:focus {
  border: 1px solid #000;
}

.theme-orebro .slider .form-control:focus {
  border: 1px solid #000;
}

.theme-rg .slider .form-control:focus {
  border: 1px solid #000;
}

.theme-default .input-group-addon.primary-addon {
  color: #a0f0eb;
  background-color: #0a4a7c;
  border: 1px solid #09385c;
  border-radius: 0;
}

.theme-helsa .input-group-addon.primary-addon {
  color: #ebf5ec;
  background-color: #1d5e74;
  border: 1px solid #044557;
  border-radius: 0;
}

.theme-ptj .input-group-addon.primary-addon {
  color: #ffffff;
  background-color: #0075b0;
  border: 1px solid #00a0c8;
  border-radius: 0px;
}

.theme-rjl .input-group-addon.primary-addon {
  color: #fff;
  background-color: #579835;
  border: 1px solid #4c8b2b;
  border-radius: 0;
}

.theme-achima .input-group-addon.primary-addon {
  color: #fff;
  background-color: #aa2b23;
  border: 1px solid #880000;
  border-radius: 5px;
}

.theme-blekinge .input-group-addon.primary-addon {
  color: #fff;
  background-color: #00a6e2;
  border: 1px solid #00a6e2;
  border-radius: 0;
}

.theme-sll .input-group-addon.primary-addon {
  color: #fff;
  background-color: #002d5a;
  border: 1px solid #000;
  border-radius: 0;
}

.theme-halland .input-group-addon.primary-addon {
  color: #fff;
  background-color: #7bb1db;
  border: 1px solid #2f7cac;
  border-radius: 0;
}

.theme-orebro .input-group-addon.primary-addon {
  color: #fff;
  background-color: #4f80ff;
  border: 1px solid #004f9f;
  border-radius: 3px;
}

.theme-rg .input-group-addon.primary-addon {
  color: #fff;
  background-color: #0097cf;
  border: 1px solid #26bdf5;
  border-radius: 0px;
}

.theme-default .input-group-addon.primary-addon:hover,
.theme-default .input-group-addon.primary-addon:active {
  background-color: #09385c;
}

.theme-helsa .input-group-addon.primary-addon:hover,
.theme-helsa .input-group-addon.primary-addon:active {
  background-color: #044557;
}

.theme-ptj .input-group-addon.primary-addon:hover,
.theme-ptj .input-group-addon.primary-addon:active {
  background-color: #00a0c8;
}

.theme-rjl .input-group-addon.primary-addon:hover,
.theme-rjl .input-group-addon.primary-addon:active {
  background-color: #4c8b2b;
}

.theme-achima .input-group-addon.primary-addon:hover,
.theme-achima .input-group-addon.primary-addon:active {
  background-color: #880000;
}

.theme-blekinge .input-group-addon.primary-addon:hover,
.theme-blekinge .input-group-addon.primary-addon:active {
  background-color: #00a6e2;
}

.theme-sll .input-group-addon.primary-addon:hover,
.theme-sll .input-group-addon.primary-addon:active {
  background-color: #000;
}

.theme-halland .input-group-addon.primary-addon:hover,
.theme-halland .input-group-addon.primary-addon:active {
  background-color: #2f7cac;
}

.theme-orebro .input-group-addon.primary-addon:hover,
.theme-orebro .input-group-addon.primary-addon:active {
  background-color: #004f9f;
}

.theme-rg .input-group-addon.primary-addon:hover,
.theme-rg .input-group-addon.primary-addon:active {
  background-color: #26bdf5;
}

.slider .input-group-addon {
  width: auto;
  font-size: 18px;
  padding-left: 6px;
  padding-right: 6px;
}
.theme-default .slider .input-group-addon {
  background-color: #f1f1f1;
  border: 1px solid #000;
  border-radius: 0;
  border-left: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.theme-helsa .slider .input-group-addon {
  background-color: #e7e7e8;
  border: 1px solid #000;
  border-radius: 0;
  border-left: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.theme-ptj .slider .input-group-addon {
  background-color: #f1f1f1;
  border: 1px solid #333333;
  border-radius: 0px;
  border-left: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.theme-rjl .slider .input-group-addon {
  background-color: #f1f1f1;
  border: 1px solid #000;
  border-radius: 0;
  border-left: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.theme-achima .slider .input-group-addon {
  background-color: #f1f1f1;
  border: 1px solid #000;
  border-radius: 3px;
  border-left: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.theme-blekinge .slider .input-group-addon {
  background-color: #b7c5ca;
  border: 1px solid #000;
  border-radius: 0;
  border-left: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.theme-sll .slider .input-group-addon {
  background-color: #e0ded9;
  border: 1px solid #000;
  border-radius: 0;
  border-left: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.theme-halland .slider .input-group-addon {
  background-color: #f5f5f5;
  border: 1px solid #000;
  border-radius: 0;
  border-left: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.theme-orebro .slider .input-group-addon {
  background-color: #f1f1f1;
  border: 1px solid #000;
  border-radius: 3px;
  border-left: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.theme-rg .slider .input-group-addon {
  background-color: #f1f1f1;
  border: 1px solid #000;
  border-radius: 0px;
  border-left: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

/* Medium screens sizes */
@media screen and (max-width: 992px) {
  .slider .input-group-addon {
    font-size: 16px;
  }
}

.slider .input-group {
  margin-top: 4px;
}

#expirationdatesection .input-group {
  padding-left: 15px;
  padding-right: 15px;
}

.theme-default #expirationdatesection .input-group-addon {
  background-color: #e4e4e4;
  color: #000;
  border-radius: 0;
}

.theme-helsa #expirationdatesection .input-group-addon {
  background-color: #d7d7d7;
  color: #000000;
  border-radius: 0;
}

.theme-ptj #expirationdatesection .input-group-addon {
  background-color: #e4e4e4;
  color: #333333;
  border-radius: 0px;
}

.theme-rjl #expirationdatesection .input-group-addon {
  background-color: #e4e4e4;
  color: #000;
  border-radius: 0;
}

.theme-achima #expirationdatesection .input-group-addon {
  background-color: #e4e4e4;
  color: #000;
  border-radius: 5px;
}

.theme-blekinge #expirationdatesection .input-group-addon {
  background-color: #e5e5e5;
  color: #000;
  border-radius: 0;
}

.theme-sll #expirationdatesection .input-group-addon {
  background-color: #a79d96;
  color: #002d5a;
  border-radius: 0;
}

.theme-halland #expirationdatesection .input-group-addon {
  background-color: #dfdfdf;
  color: #000;
  border-radius: 0;
}

.theme-orebro #expirationdatesection .input-group-addon {
  background-color: #eeeeee;
  color: #000;
  border-radius: 3px;
}

.theme-rg #expirationdatesection .input-group-addon {
  background-color: #eeeeee;
  color: #000;
  border-radius: 0px;
}

#expirationdatesection .input-group-addon :hover {
  cursor: pointer;
}

/*---------------------------------------------------------------------------
        COMMENT BOX (Dialogrutan) & COMMENTARY BOX (Kommentarsrutan)
---------------------------------------------------------------------------*/
.theme-default .admin .commentBoxDiv-reg .question-block {
  border: 1px solid #a0f0eb;
  background-color: #d0f8f5;
  color: #0a4a7c;
}

.theme-helsa .admin .commentBoxDiv-reg .question-block {
  border: 1px solid #5b9c6c;
  background-color: #6cb680;
  color: #ffffff;
}

.theme-ptj .admin .commentBoxDiv-reg .question-block {
  border: 1px solid #0075b0;
  background-color: #00a0c8;
  color: #ffffff;
}

.theme-rjl .admin .commentBoxDiv-reg .question-block {
  border: 1px solid #8cc63f;
  background-color: #8cc63f;
  color: #fff;
}

.theme-achima .admin .commentBoxDiv-reg .question-block {
  border: 1px solid #880000;
  background-color: #aa2b23;
  color: #fff;
}

.theme-blekinge .admin .commentBoxDiv-reg .question-block {
  border: 1px solid #14325e;
  background-color: #193f76;
  color: #fff;
}

.theme-sll .admin .commentBoxDiv-reg .question-block {
  border: 1px solid #006fb4;
  background-color: #00aeef;
  color: #fff;
}

.theme-halland .admin .commentBoxDiv-reg .question-block {
  border: 1px solid #2f7cac;
  background-color: #7bb1db;
  color: #fff;
}

.theme-orebro .admin .commentBoxDiv-reg .question-block {
  border: 1px solid #004f9f;
  background-color: #4f80ff;
  color: #fff;
}

.theme-rg .admin .commentBoxDiv-reg .question-block {
  border: 1px solid #26bdf5;
  background-color: #0097cf;
  color: #fff;
}

.theme-default .admin .commentBoxDiv-com .question-block {
  border: 1px solid #f7da4f;
  background-color: #ffe150;
}

.theme-helsa .admin .commentBoxDiv-com .question-block {
  border: 1px solid #f7da4f;
  background-color: #ffe150;
}

.theme-ptj .admin .commentBoxDiv-com .question-block {
  border: 1px solid #e49f4e;
  background-color: #f9b360;
}

.theme-rjl .admin .commentBoxDiv-com .question-block {
  border: 1px solid #f59a1b;
  background-color: #fdb913;
}

.theme-achima .admin .commentBoxDiv-com .question-block {
  border: 1px solid #f7da4f;
  background-color: #ffe150;
}

.theme-blekinge .admin .commentBoxDiv-com .question-block {
  border: 1px solid #f7da4f;
  background-color: #ffe150;
}

.theme-sll .admin .commentBoxDiv-com .question-block {
  border: 1px solid #ffd400;
  background-color: #ffd400;
}

.theme-halland .admin .commentBoxDiv-com .question-block {
  border: 1px solid #f7da4f;
  background-color: #ffe150;
}

.theme-orebro .admin .commentBoxDiv-com .question-block {
  border: 1px solid #f7da4f;
  background-color: #ffe150;
}

.theme-rg .admin .commentBoxDiv-com .question-block {
  border: 1px solid #ffec98;
  background-color: #faa634;
}

.admin .commentBoxDiv-reg .question-block .question,
.admin .commentBoxDiv-com .question-block .question {
  font-weight: 500;
}

@media screen and (min-width: 768px) {
  .admin .commentBoxDiv-reg,
  .commentBoxDiv-com {
    float: right;
  }
}

.commentaryLabelDiv {
  float: left;
  margin-top: 4px;
  padding-right: 4px;
}

.commentaryInputDiv {
  width: auto;
  overflow: hidden;
}

/*---------------------------------------------------------------------------
                             DIAGNOSIS SEARCH
---------------------------------------------------------------------------*/
.admin .diagnosissearch {
  padding-bottom: 10px;
}

.admin .diagnosissearch input {
  border-top-left-radius: 0px !important;
}
