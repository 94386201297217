/* SASS variables and intellisense */
/*---------------------------------------------------------------------------
                                 BODY
---------------------------------------------------------------------------*/
.theme-default body {
  color: #000000;
  font-size: 16px;
  font-family: "proximanova-regular, sans-serif";
  font-weight: 400;
  background-color: #fff;
  padding-top: 80px;
  padding-bottom: 20px; }

.theme-helsa body {
  color: #000000;
  font-size: 16px;
  font-family: "source-sans-regular, sans-serif";
  font-weight: 400;
  background-color: #FFFFFF;
  padding-top: 80px;
  padding-bottom: 20px; }

.theme-ptj body {
  color: #333333;
  font-size: 16px;
  font-family: "source-sans-pro-r, sans-serif";
  font-weight: 400;
  background-color: #F3F3F3;
  padding-top: 80px;
  padding-bottom: 20px; }

.theme-rjl body {
  color: #000;
  font-size: 16px;
  font-family: "open-sans-regular, sans-serif";
  font-weight: 400;
  background-color: #fff;
  padding-top: 80px;
  padding-bottom: 20px; }

.theme-achima body {
  color: #000;
  font-size: 16px;
  font-family: "lato-regular, sans-serif";
  font-weight: 400;
  background-color: #fff;
  padding-top: 80px;
  padding-bottom: 20px; }

.theme-blekinge body {
  color: #000;
  font-size: 16px;
  font-family: "open-sans-regular, sans-serif";
  font-weight: 400;
  background-color: #fff;
  padding-top: 80px;
  padding-bottom: 20px; }

.theme-sll body {
  color: #000;
  font-size: 16px;
  font-family: "open-sans-regular, sans-serif";
  font-weight: 400;
  background-color: #fff;
  padding-top: 80px;
  padding-bottom: 20px; }

.theme-halland body {
  color: #000;
  font-size: 16px;
  font-family: "open-sans-regular, sans-serif";
  font-weight: 400;
  background-color: #fff;
  padding-top: 80px;
  padding-bottom: 20px; }

.theme-orebro body {
  color: #000;
  font-size: 16px;
  font-family: "arial-regular, sans-serif";
  font-weight: 400;
  background-color: #fff;
  padding-top: 80px;
  padding-bottom: 20px; }

.theme-rg body {
  color: #000;
  font-size: 16px;
  font-family: "museo-sans, sans-serif";
  font-weight: 500;
  background-color: #fff;
  padding-top: 80px;
  padding-bottom: 20px; }

.theme-default body.mobileApplication {
  background-color: #fff; }

.theme-helsa body.mobileApplication {
  background-color: #FFFFFF; }

.theme-ptj body.mobileApplication {
  background-color: #ffffff; }

.theme-rjl body.mobileApplication {
  background-color: #fff; }

.theme-achima body.mobileApplication {
  background-color: #fff; }

.theme-blekinge body.mobileApplication {
  background-color: #fff; }

.theme-sll body.mobileApplication {
  background-color: #f5f2ed; }

.theme-halland body.mobileApplication {
  background-color: #FFFFFF; }

.theme-orebro body.mobileApplication {
  background-color: #FFFFFF; }

.theme-rg body.mobileApplication {
  background-color: #FFFFFF; }

.patientRegistration {
  padding: 0;
  margin: 0; }
  .theme-default .patientRegistration {
    font-size: 18px;
    background-color: #fff; }
  .theme-helsa .patientRegistration {
    font-size: 18px;
    background-color: #FFFFFF; }
  .theme-ptj .patientRegistration {
    font-size: 18px;
    background-color: #ffffff; }
  .theme-rjl .patientRegistration {
    font-size: 18px;
    background-color: #fff; }
  .theme-achima .patientRegistration {
    font-size: 18px;
    background-color: #fff; }
  .theme-blekinge .patientRegistration {
    font-size: 18px;
    background-color: #fff; }
  .theme-sll .patientRegistration {
    font-size: 18px;
    background-color: #f5f2ed; }
  .theme-halland .patientRegistration {
    font-size: 18px;
    background-color: #FFFFFF; }
  .theme-orebro .patientRegistration {
    font-size: 18px;
    background-color: #FFFFFF; }
  .theme-rg .patientRegistration {
    font-size: 18px;
    background-color: #FFFFFF; }

.theme-default body.empty-body {
  padding-top: 15px;
  padding-bottom: 0; }

.theme-helsa body.empty-body {
  padding-top: 15px;
  padding-bottom: 0; }

.theme-ptj body.empty-body {
  padding-top: 15px;
  padding-bottom: 0; }

.theme-rjl body.empty-body {
  padding-top: 15px;
  padding-bottom: 0; }

.theme-achima body.empty-body {
  padding-top: 15px;
  padding-bottom: 0; }

.theme-blekinge body.empty-body {
  padding-top: 15px;
  padding-bottom: 0; }

.theme-sll body.empty-body {
  padding-top: 15px;
  padding-bottom: 0; }

.theme-halland body.empty-body {
  padding-top: 15px;
  padding-bottom: 0; }

.theme-orebro body.empty-body {
  padding-top: 15px;
  padding-bottom: 0; }

.theme-rg body.empty-body {
  padding-top: 15px;
  padding-bottom: 0; }

.theme-default body .admin {
  font-size: 14px; }

.theme-helsa body .admin {
  font-size: 14px; }

.theme-ptj body .admin {
  font-size: 14px; }

.theme-rjl body .admin {
  font-size: 14px; }

.theme-achima body .admin {
  font-size: 14px; }

.theme-blekinge body .admin {
  font-size: 14px; }

.theme-sll body .admin {
  font-size: 14px; }

.theme-halland body .admin {
  font-size: 14px; }

.theme-orebro body .admin {
  font-size: 14px; }

.theme-rg body .admin {
  font-size: 14px; }

/* Override the default bootstrap behavior where horizontal description lists
   will truncate terms that are too long to fit in the left column
*/
.dl-horizontal dt {
  white-space: normal; }

/*---------------------------------------------------------------------------
                                 COLORS
---------------------------------------------------------------------------*/
.theme-default .CollaboDocPrimaryColor, .theme-default .modal .modal-content .CollaboDocPrimaryColor {
  color: #0A4A7C; }

.theme-helsa .CollaboDocPrimaryColor, .theme-helsa .modal .modal-content .CollaboDocPrimaryColor {
  color: #1D5E74; }

.theme-ptj .CollaboDocPrimaryColor, .theme-ptj .modal .modal-content .CollaboDocPrimaryColor {
  color: #0075B0; }

.theme-rjl .CollaboDocPrimaryColor, .theme-rjl .modal .modal-content .CollaboDocPrimaryColor {
  color: #579835; }

.theme-achima .CollaboDocPrimaryColor, .theme-achima .modal .modal-content .CollaboDocPrimaryColor {
  color: #AA2B23; }

.theme-blekinge .CollaboDocPrimaryColor, .theme-blekinge .modal .modal-content .CollaboDocPrimaryColor {
  color: #00A6E2; }

.theme-sll .CollaboDocPrimaryColor, .theme-sll .modal .modal-content .CollaboDocPrimaryColor {
  color: #002d5a; }

.theme-halland .CollaboDocPrimaryColor, .theme-halland .modal .modal-content .CollaboDocPrimaryColor {
  color: #7BB1DB; }

.theme-orebro .CollaboDocPrimaryColor, .theme-orebro .modal .modal-content .CollaboDocPrimaryColor {
  color: #4F80FF; }

.theme-rg .CollaboDocPrimaryColor, .theme-rg .modal .modal-content .CollaboDocPrimaryColor {
  color: #0097cf; }

.theme-default .CollaboDocStandardColor, .theme-default .CollabodocDefaultColor, .theme-default .CollabodocSecondaryColor, .theme-default
.modal .modal-content .CollaboDocStandardColor, .theme-default .modal .modal-content .CollabodocDefaultColor, .theme-default .modal .modal-content .CollabodocSecondaryColor {
  color: #000; }

.theme-helsa .CollaboDocStandardColor, .theme-helsa .CollabodocDefaultColor, .theme-helsa .CollabodocSecondaryColor, .theme-helsa
.modal .modal-content .CollaboDocStandardColor, .theme-helsa .modal .modal-content .CollabodocDefaultColor, .theme-helsa .modal .modal-content .CollabodocSecondaryColor {
  color: #000; }

.theme-ptj .CollaboDocStandardColor, .theme-ptj .CollabodocDefaultColor, .theme-ptj .CollabodocSecondaryColor, .theme-ptj
.modal .modal-content .CollaboDocStandardColor, .theme-ptj .modal .modal-content .CollabodocDefaultColor, .theme-ptj .modal .modal-content .CollabodocSecondaryColor {
  color: #333333; }

.theme-rjl .CollaboDocStandardColor, .theme-rjl .CollabodocDefaultColor, .theme-rjl .CollabodocSecondaryColor, .theme-rjl
.modal .modal-content .CollaboDocStandardColor, .theme-rjl .modal .modal-content .CollabodocDefaultColor, .theme-rjl .modal .modal-content .CollabodocSecondaryColor {
  color: #000; }

.theme-achima .CollaboDocStandardColor, .theme-achima .CollabodocDefaultColor, .theme-achima .CollabodocSecondaryColor, .theme-achima
.modal .modal-content .CollaboDocStandardColor, .theme-achima .modal .modal-content .CollabodocDefaultColor, .theme-achima .modal .modal-content .CollabodocSecondaryColor {
  color: #000; }

.theme-blekinge .CollaboDocStandardColor, .theme-blekinge .CollabodocDefaultColor, .theme-blekinge .CollabodocSecondaryColor, .theme-blekinge
.modal .modal-content .CollaboDocStandardColor, .theme-blekinge .modal .modal-content .CollabodocDefaultColor, .theme-blekinge .modal .modal-content .CollabodocSecondaryColor {
  color: #000; }

.theme-sll .CollaboDocStandardColor, .theme-sll .CollabodocDefaultColor, .theme-sll .CollabodocSecondaryColor, .theme-sll
.modal .modal-content .CollaboDocStandardColor, .theme-sll .modal .modal-content .CollabodocDefaultColor, .theme-sll .modal .modal-content .CollabodocSecondaryColor {
  color: #000; }

.theme-halland .CollaboDocStandardColor, .theme-halland .CollabodocDefaultColor, .theme-halland .CollabodocSecondaryColor, .theme-halland
.modal .modal-content .CollaboDocStandardColor, .theme-halland .modal .modal-content .CollabodocDefaultColor, .theme-halland .modal .modal-content .CollabodocSecondaryColor {
  color: #000; }

.theme-orebro .CollaboDocStandardColor, .theme-orebro .CollabodocDefaultColor, .theme-orebro .CollabodocSecondaryColor, .theme-orebro
.modal .modal-content .CollaboDocStandardColor, .theme-orebro .modal .modal-content .CollabodocDefaultColor, .theme-orebro .modal .modal-content .CollabodocSecondaryColor {
  color: #000; }

.theme-rg .CollaboDocStandardColor, .theme-rg .CollabodocDefaultColor, .theme-rg .CollabodocSecondaryColor, .theme-rg
.modal .modal-content .CollaboDocStandardColor, .theme-rg .modal .modal-content .CollabodocDefaultColor, .theme-rg .modal .modal-content .CollabodocSecondaryColor {
  color: #000; }

.theme-default .CollaboDocSuccessColor, .theme-default .modal .modal-content .CollaboDocSuccessColor {
  color: #49b63e; }

.theme-helsa .CollaboDocSuccessColor, .theme-helsa .modal .modal-content .CollaboDocSuccessColor {
  color: #5b9c6c; }

.theme-ptj .CollaboDocSuccessColor, .theme-ptj .modal .modal-content .CollaboDocSuccessColor {
  color: #a3aa17; }

.theme-rjl .CollaboDocSuccessColor, .theme-rjl .modal .modal-content .CollaboDocSuccessColor {
  color: #4c8b2b; }

.theme-achima .CollaboDocSuccessColor, .theme-achima .modal .modal-content .CollaboDocSuccessColor {
  color: #009894; }

.theme-blekinge .CollaboDocSuccessColor, .theme-blekinge .modal .modal-content .CollaboDocSuccessColor {
  color: #206C3A; }

.theme-sll .CollaboDocSuccessColor, .theme-sll .modal .modal-content .CollaboDocSuccessColor {
  color: #406618; }

.theme-halland .CollaboDocSuccessColor, .theme-halland .modal .modal-content .CollaboDocSuccessColor {
  color: #046E15; }

.theme-orebro .CollaboDocSuccessColor, .theme-orebro .modal .modal-content .CollaboDocSuccessColor {
  color: #76B82A; }

.theme-rg .CollaboDocSuccessColor, .theme-rg .modal .modal-content .CollaboDocSuccessColor {
  color: #9ACF8A; }

.theme-default .CollaboDocSuccessColor, .theme-default .modal .modal-content .CollaboDocSuccessColor {
  color: #49b63e; }

.theme-helsa .CollaboDocSuccessColor, .theme-helsa .modal .modal-content .CollaboDocSuccessColor {
  color: #5b9c6c; }

.theme-ptj .CollaboDocSuccessColor, .theme-ptj .modal .modal-content .CollaboDocSuccessColor {
  color: #a3aa17; }

.theme-rjl .CollaboDocSuccessColor, .theme-rjl .modal .modal-content .CollaboDocSuccessColor {
  color: #4c8b2b; }

.theme-achima .CollaboDocSuccessColor, .theme-achima .modal .modal-content .CollaboDocSuccessColor {
  color: #009894; }

.theme-blekinge .CollaboDocSuccessColor, .theme-blekinge .modal .modal-content .CollaboDocSuccessColor {
  color: #206C3A; }

.theme-sll .CollaboDocSuccessColor, .theme-sll .modal .modal-content .CollaboDocSuccessColor {
  color: #406618; }

.theme-halland .CollaboDocSuccessColor, .theme-halland .modal .modal-content .CollaboDocSuccessColor {
  color: #046E15; }

.theme-orebro .CollaboDocSuccessColor, .theme-orebro .modal .modal-content .CollaboDocSuccessColor {
  color: #76B82A; }

.theme-rg .CollaboDocSuccessColor, .theme-rg .modal .modal-content .CollaboDocSuccessColor {
  color: #9ACF8A; }

.theme-default .CollabodocDangerColor, .theme-default .modal .modal-content .CollabodocDangerColor {
  color: #E32424; }

.theme-helsa .CollabodocDangerColor, .theme-helsa .modal .modal-content .CollabodocDangerColor {
  color: #F48174; }

.theme-ptj .CollabodocDangerColor, .theme-ptj .modal .modal-content .CollabodocDangerColor {
  color: #F54359; }

.theme-rjl .CollabodocDangerColor, .theme-rjl .modal .modal-content .CollabodocDangerColor {
  color: #ce1141; }

.theme-achima .CollabodocDangerColor, .theme-achima .modal .modal-content .CollabodocDangerColor {
  color: #AA2B23; }

.theme-blekinge .CollabodocDangerColor, .theme-blekinge .modal .modal-content .CollabodocDangerColor {
  color: #d70480; }

.theme-sll .CollabodocDangerColor, .theme-sll .modal .modal-content .CollabodocDangerColor {
  color: #9a0932; }

.theme-halland .CollabodocDangerColor, .theme-halland .modal .modal-content .CollabodocDangerColor {
  color: #8E0000; }

.theme-orebro .CollabodocDangerColor, .theme-orebro .modal .modal-content .CollabodocDangerColor {
  color: #E32424; }

.theme-rg .CollabodocDangerColor, .theme-rg .modal .modal-content .CollabodocDangerColor {
  color: #ee3780; }

.theme-default .CollabodocDisabledColor, .theme-default .modal .modal-content .CollabodocDisabledColor {
  color: #F1F1F1; }

.theme-helsa .CollabodocDisabledColor, .theme-helsa .modal .modal-content .CollabodocDisabledColor {
  color: #E7E7E8; }

.theme-ptj .CollabodocDisabledColor, .theme-ptj .modal .modal-content .CollabodocDisabledColor {
  color: #F1F1F1; }

.theme-rjl .CollabodocDisabledColor, .theme-rjl .modal .modal-content .CollabodocDisabledColor {
  color: #F1F1F1; }

.theme-achima .CollabodocDisabledColor, .theme-achima .modal .modal-content .CollabodocDisabledColor {
  color: #F1F1F1; }

.theme-blekinge .CollabodocDisabledColor, .theme-blekinge .modal .modal-content .CollabodocDisabledColor {
  color: #B7C5CA; }

.theme-sll .CollabodocDisabledColor, .theme-sll .modal .modal-content .CollabodocDisabledColor {
  color: #e0ded9; }

.theme-halland .CollabodocDisabledColor, .theme-halland .modal .modal-content .CollabodocDisabledColor {
  color: #F5F5F5; }

.theme-orebro .CollabodocDisabledColor, .theme-orebro .modal .modal-content .CollabodocDisabledColor {
  color: #F1F1F1; }

.theme-rg .CollabodocDisabledColor, .theme-rg .modal .modal-content .CollabodocDisabledColor {
  color: #F1F1F1; }

.theme-default .CollabodocFocusColor, .theme-default .modal .modal-content .CollabodocFocusColor {
  color: #FFE150; }

.theme-helsa .CollabodocFocusColor, .theme-helsa .modal .modal-content .CollabodocFocusColor {
  color: #6CB680; }

.theme-ptj .CollabodocFocusColor, .theme-ptj .modal .modal-content .CollabodocFocusColor {
  color: #E78300; }

.theme-rjl .CollabodocFocusColor, .theme-rjl .modal .modal-content .CollabodocFocusColor {
  color: #579835; }

.theme-achima .CollabodocFocusColor, .theme-achima .modal .modal-content .CollabodocFocusColor {
  color: #00ADA8; }

.theme-blekinge .CollabodocFocusColor, .theme-blekinge .modal .modal-content .CollabodocFocusColor {
  color: #193F76; }

.theme-sll .CollabodocFocusColor, .theme-sll .modal .modal-content .CollabodocFocusColor {
  color: #e1056d; }

.theme-halland .CollabodocFocusColor, .theme-halland .modal .modal-content .CollabodocFocusColor {
  color: #4E9023; }

.theme-orebro .CollabodocFocusColor, .theme-orebro .modal .modal-content .CollabodocFocusColor {
  color: #9FC63B; }

.theme-rg .CollabodocFocusColor, .theme-rg .modal .modal-content .CollabodocFocusColor {
  color: #50b848; }

/*---------------------------------------------------------------------------
                               LINES / HR
---------------------------------------------------------------------------*/
.horizontalLine {
  width: 100%;
  height: 1px;
  margin: 20px 0; }
  .theme-default .horizontalLine {
    background-color: #0A4A7C; }
  .theme-helsa .horizontalLine {
    background-color: #6CB680; }
  .theme-ptj .horizontalLine {
    background-color: #00A0C8; }
  .theme-rjl .horizontalLine {
    background-color: #579835; }
  .theme-achima .horizontalLine {
    background-color: #AA2B23; }
  .theme-blekinge .horizontalLine {
    background-color: #E5E5E5; }
  .theme-sll .horizontalLine {
    background-color: #DAD5D0; }
  .theme-halland .horizontalLine {
    background-color: #6DACDE; }
  .theme-orebro .horizontalLine {
    background-color: #EEEEEE; }
  .theme-rg .horizontalLine {
    background-color: #ccc; }

.admin .horizontalLine {
  margin: 8px 0; }

/*---------------------------------------------------------------------------
                            MARGIN & PADDING
---------------------------------------------------------------------------*/
.topMargin-symptomName {
  margin-top: -32px; }

.upToNavBar {
  margin-top: -42px;
  margin-right: -15px; }

.noSidePadding {
  padding-left: 0;
  padding-right: 0; }

@media screen and (max-width: 992px) {
  .marginLeftOneElement {
    margin-left: 37.5%; } }

.filterDiagnosis {
  padding: 5px 0px; }

.paddingTop {
  padding-top: 10px; }

.marginRight7 {
    margin-right: 7px;
  }

.headerTopPadding {
  padding-top: 20px; }

@media screen and (min-width: 1200px) {
  .leftColMarginLg {
    margin-left: 0; }
  .leftColMarginMdLg {
    padding-left: 30px; } }

@media screen and (min-width: 992px) and (max-width: 1200px) {
  .leftColMarginMdLg {
    padding-left: 30px; } }

@media screen and (max-width: 768px) {
  .toSmallToShow-xs {
    width: 0;
    height: 0;
    font-size: 0px; } }

/*---------------------------------------------------------------------------
                               ALLIGNMENT
---------------------------------------------------------------------------*/
.floatRight {
  float: right; }

.floatLeft {
  float: left; }

.dispInline {
  display: inline; }

.dispInlineBlock {
  display: inline-block; }

.bottomMargin-zero {
  margin-bottom: 0; }

@media screen and (min-width: 768px) {
  .leftMargin-zero {
    margin-left: 0;
    padding-left: 0; } }

.leftMargin-sm {
  margin-left: 5px; }

.leftMargin-md {
  margin-left: 10px; }

.rightMargin-sm {
  margin-right: 5px; }

.rightMargin-lg {
  margin-right: 20px; }

.rightMargin-xl {
  margin-right: 40px; }

.topMargin-sm {
  margin-top: 10px; }

.topMargin-sm-md {
  margin-top: 15px; }

.topMargin-md {
  margin-top: 20px; }

.leftPadding-zero{
  padding-left: 0px;;
}

/*---------------------------------------------------------------------------
                                 LOADING
---------------------------------------------------------------------------*/
.diagnosis-loading, .secondarydiagnosis-loading {
  font-size: 25px;
  position: absolute;
  margin-top: 5px;
  right: 12px;
  float: right;
  z-index: 10; }

.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite; }
  .theme-default .loader {
    border-top: 16px solid #0A4A7C;
    border-bottom: 16px solid #0A4A7C; }
  .theme-helsa .loader {
    border-top: 16px solid #1D5E74;
    border-bottom: 16px solid #1D5E74; }
  .theme-ptj .loader {
    border-top: 16px solid #0075B0;
    border-bottom: 16px solid #0075B0; }
  .theme-rjl .loader {
    border-top: 16px solid #579835;
    border-bottom: 16px solid #579835; }
  .theme-achima .loader {
    border-top: 16px solid #AA2B23;
    border-bottom: 16px solid #AA2B23; }
  .theme-blekinge .loader {
    border-top: 16px solid #00A6E2;
    border-bottom: 16px solid #00A6E2; }
  .theme-sll .loader {
    border-top: 16px solid #002d5a;
    border-bottom: 16px solid #002d5a; }
  .theme-halland .loader {
    border-top: 16px solid #7BB1DB;
    border-bottom: 16px solid #7BB1DB; }
  .theme-orebro .loader {
    border-top: 16px solid #4F80FF;
    border-bottom: 16px solid #4F80FF; }
  .theme-rg .loader {
    border-top: 16px solid #0097cf;
    border-bottom: 16px solid #0097cf; }

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg); } }

@keyframes spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

/*---------------------------------------------------------------------------
                                 COPY TO CLIPBOARD NOTIFICATION MESSAGE
---------------------------------------------------------------------------*/
#copyToClipboardNotification {
  position: absolute;
  text-align: center;
  margin: 0 auto;
  width: 200px;
  top: -35px;
  left: 50%;
  margin-left: -100px;
  z-index: 999;
  background-color: #FFF; }

/*---------------------------------------------------------------------------
                          VIDEO / MEDIA PLAYER
---------------------------------------------------------------------------*/
.videoPlayer {
  height: auto !important;
  width: 100% !important; }

/*---------------------------------------------------------------------------
                              PANELS
---------------------------------------------------------------------------*/
/*---------------- Heading -------------------------*/
.theme-default .admin .panel-default .panel-heading, .theme-default .journalTypeButtons.panel-default .panel-heading {
  background-color: #F1F1F1;
  border-bottom: 1px solid transparent;
  border-color: #CCC; }

.theme-helsa .admin .panel-default .panel-heading, .theme-helsa .journalTypeButtons.panel-default .panel-heading {
  background-color: #F3F6F6;
  border-bottom: 1px solid transparent;
  border-color: #CCCCCC; }

.theme-ptj .admin .panel-default .panel-heading, .theme-ptj .journalTypeButtons.panel-default .panel-heading {
  background-color: #00A0C8;
  border-bottom: 1px solid transparent;
  border-color: #0075B0; }

.theme-rjl .admin .panel-default .panel-heading, .theme-rjl .journalTypeButtons.panel-default .panel-heading {
  background-color: #EDEDED;
  border-bottom: 1px solid transparent;
  border-color: #E4E4E4; }

.theme-achima .admin .panel-default .panel-heading, .theme-achima .journalTypeButtons.panel-default .panel-heading {
  background-color: #EDEDED;
  border-bottom: 1px solid transparent;
  border-color: #777; }

.theme-blekinge .admin .panel-default .panel-heading, .theme-blekinge .journalTypeButtons.panel-default .panel-heading {
  background-color: #E5F6FC;
  border-bottom: 1px solid transparent;
  border-color: #E5F6FC; }

.theme-sll .admin .panel-default .panel-heading, .theme-sll .journalTypeButtons.panel-default .panel-heading {
  background-color: #e0ded9;
  border-bottom: 1px solid transparent;
  border-color: #a79d96; }

.theme-halland .admin .panel-default .panel-heading, .theme-halland .journalTypeButtons.panel-default .panel-heading {
  background-color: #F5F5F5;
  border-bottom: 1px solid transparent;
  border-color: #DDDDDD; }

.theme-orebro .admin .panel-default .panel-heading, .theme-orebro .journalTypeButtons.panel-default .panel-heading {
  background-color: #D1E9F2;
  border-bottom: 1px solid transparent;
  border-color: #C8E1EB; }

.theme-rg .admin .panel-default .panel-heading, .theme-rg .journalTypeButtons.panel-default .panel-heading {
  background-color: #F6F6F6;
  border-bottom: 0px solid transparent;
  border-color: #ccc; }

.admin .panel-default .panel-heading,
.admin .panel-danger .panel-heading,
.journalTypeButtons.panel-default .panel-heading,
.admin .panel-default .panel-footer,
.admin .panel-danger .panel-footer {
  padding: 5px 15px; }

.theme-default .admin .panel-danger .panel-heading {
  background-color: #E32424; }

.theme-helsa .admin .panel-danger .panel-heading {
  background-color: #F48174; }

.theme-ptj .admin .panel-danger .panel-heading {
  background-color: #F54359; }

.theme-rjl .admin .panel-danger .panel-heading {
  background-color: #ce1141; }

.theme-achima .admin .panel-danger .panel-heading {
  background-color: #AA2B23; }

.theme-blekinge .admin .panel-danger .panel-heading {
  background-color: #d70480; }

.theme-sll .admin .panel-danger .panel-heading {
  background-color: #9a0932; }

.theme-halland .admin .panel-danger .panel-heading {
  background-color: #8E0000; }

.theme-orebro .admin .panel-danger .panel-heading {
  background-color: #E32424; }

.theme-rg .admin .panel-danger .panel-heading {
  background-color: #ee3780; }

.theme-default .admin .panel-danger .panel-heading {
  background-color: #E32424; }

.theme-helsa .admin .panel-danger .panel-heading {
  background-color: #F48174; }

.theme-ptj .admin .panel-danger .panel-heading {
  background-color: #F54359; }

.theme-rjl .admin .panel-danger .panel-heading {
  background-color: #ce1141; }

.theme-achima .admin .panel-danger .panel-heading {
  background-color: #AA2B23; }

.theme-blekinge .admin .panel-danger .panel-heading {
  background-color: #d70480; }

.theme-sll .admin .panel-danger .panel-heading {
  background-color: #9a0932; }

.theme-halland .admin .panel-danger .panel-heading {
  background-color: #8E0000; }

.theme-orebro .admin .panel-danger .panel-heading {
  background-color: #E32424; }

.theme-rg .admin .panel-danger .panel-heading {
  background-color: #ee3780; }

.theme-default .admin .panel-danger .panel-heading p.panel-title {
  color: #fff; }

.theme-helsa .admin .panel-danger .panel-heading p.panel-title {
  color: #fff; }

.theme-ptj .admin .panel-danger .panel-heading p.panel-title {
  color: #fff; }

.theme-rjl .admin .panel-danger .panel-heading p.panel-title {
  color: #fff; }

.theme-achima .admin .panel-danger .panel-heading p.panel-title {
  color: #fff; }

.theme-blekinge .admin .panel-danger .panel-heading p.panel-title {
  color: #fff; }

.theme-sll .admin .panel-danger .panel-heading p.panel-title {
  color: #fff; }

.theme-halland .admin .panel-danger .panel-heading p.panel-title {
  color: #fff; }

.theme-orebro .admin .panel-danger .panel-heading p.panel-title {
  color: #fff; }

.theme-rg .admin .panel-danger .panel-heading p.panel-title {
  color: #fff; }

.panel-default {
  border-top: none; }
  .theme-default .panel-default {
    border-color: #CCC; }
  .theme-helsa .panel-default {
    border-color: #CCCCCC; }
  .theme-ptj .panel-default {
    border-color: #0075B0; }
  .theme-rjl .panel-default {
    border-color: #E4E4E4; }
  .theme-achima .panel-default {
    border-color: #777; }
  .theme-blekinge .panel-default {
    border-color: #E5F6FC; }
  .theme-sll .panel-default {
    border-color: #a79d96; }
  .theme-halland .panel-default {
    border-color: #DDDDDD; }
  .theme-orebro .panel-default {
    border-color: #C8E1EB; }
  .theme-rg .panel-default {
    border-color: #ccc; }

.theme-default .admin .panel {
  border-radius: 0; }

.theme-helsa .admin .panel {
  border-radius: 0; }

.theme-ptj .admin .panel {
  border-radius: 0px; }

.theme-rjl .admin .panel {
  border-radius: 0; }

.theme-achima .admin .panel {
  border-radius: 5px; }

.theme-blekinge .admin .panel {
  border-radius: 0; }

.theme-sll .admin .panel {
  border-radius: 0; }

.theme-halland .admin .panel {
  border-radius: 0; }

.theme-orebro .admin .panel {
  border-radius: 3px; }

.theme-rg .admin .panel {
  border-radius: 0px; }

.theme-default .panel-heading {
  border-top: 1px solid #CCC; }

.theme-helsa .panel-heading {
  border-top: 1px solid #CCCCCC; }

.theme-ptj .panel-heading {
  border-top: 1px solid #0075B0; }

.theme-rjl .panel-heading {
  border-top: 1px solid #E4E4E4; }

.theme-achima .panel-heading {
  border-top: 1px solid #777; }

.theme-blekinge .panel-heading {
  border-top: 1px solid #E5F6FC; }

.theme-sll .panel-heading {
  border-top: 1px solid #a79d96; }

.theme-halland .panel-heading {
  border-top: 1px solid #DDDDDD; }

.theme-orebro .panel-heading {
  border-top: 1px solid #C8E1EB; }

.theme-rg .panel-heading {
  border-top: 1px solid #ccc; }

.theme-default .panel-body {
  background-color: #fff;
  color: #000; }

.theme-helsa .panel-body {
  background-color: #FFFFFF;
  color: #000000; }

.theme-ptj .panel-body {
  background-color: #fffFFF;
  color: #333333; }

.theme-rjl .panel-body {
  background-color: #fff;
  color: #000; }

.theme-achima .panel-body {
  background-color: #fff;
  color: #000; }

.theme-blekinge .panel-body {
  background-color: #fff;
  color: #000; }

.theme-sll .panel-body {
  background-color: #fff;
  color: #000; }

.theme-halland .panel-body {
  background-color: #fff;
  color: #000; }

.theme-orebro .panel-body {
  background-color: #fff;
  color: #000; }

.theme-rg .panel-body {
  background-color: #fff;
  color: #000; }

.panel-body.disabledPanel {
  opacity: 0.3;
  cursor: not-allowed; }

.admin .panel .panel-body.disabledPanel .btn:hover {
  cursor: not-allowed; }

.panel-title-span {
  text-align: center;
  display: block;
  margin-top: -24px; }

.right-panel-chevron {
  margin-top: -19px;
  float: right; }

/*---------------- Footer -------------------------*/
.panel-footer {
  padding: 10px 15px;
  overflow: hidden; }
  .theme-default .panel-footer {
    background-color: #F1F1F1;
    border-top: 1px solid #F1F1F1;
    border-color: #CCC; }
  .theme-helsa .panel-footer {
    background-color: #F3F6F6;
    border-top: 1px solid #F3F6F6;
    border-color: #CCCCCC; }
  .theme-ptj .panel-footer {
    background-color: #00A0C8;
    border-top: 1px solid #00A0C8;
    border-color: #0075B0; }
  .theme-rjl .panel-footer {
    background-color: #EDEDED;
    border-top: 1px solid #EDEDED;
    border-color: #E4E4E4; }
  .theme-achima .panel-footer {
    background-color: #EDEDED;
    border-top: 1px solid #EDEDED;
    border-color: #777; }
  .theme-blekinge .panel-footer {
    background-color: #E5F6FC;
    border-top: 1px solid #E5F6FC;
    border-color: #E5F6FC; }
  .theme-sll .panel-footer {
    background-color: #e0ded9;
    border-top: 1px solid #e0ded9;
    border-color: #a79d96; }
  .theme-halland .panel-footer {
    background-color: #F5F5F5;
    border-top: 1px solid #F5F5F5;
    border-color: #DDDDDD; }
  .theme-orebro .panel-footer {
    background-color: #D1E9F2;
    border-top: 1px solid #D1E9F2;
    border-color: #C8E1EB; }
  .theme-rg .panel-footer {
    background-color: #F6F6F6;
    border-top: 0px solid #F6F6F6;
    border-color: #ccc; }

/*---------------- Panel sections -------------------------*/
#journalnote .rotated {
  writing-mode: vertical-lr;
  transform: rotate(180deg);
  margin-top: 0; }

/*Internet Explorer*/
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  #journalnote .rotated {
    transform: rotate(270deg);
    margin-top: 70px;
    margin-bottom: 10px; }
  #journalnote .nurse-panel-IE .rotated {
    margin-top: 130px; }
  #journalnote .panel-heading .btn-journalnote-size-0 {
    margin-left: 15px; } }

/*Edge*/
@supports (-ms-ime-align: auto) {
  #journalnote .rotated {
    display: inline; } }

#journalnote .panel.collapsed {
  width: 70px;
  float: right; }

#journalnote .panel-body {
  overflow-y: scroll;
  position: relative;
  width: auto; }
  .theme-default #journalnote .panel-body {
    padding-top: 0;
    padding-bottom: 5px; }
  .theme-helsa #journalnote .panel-body {
    padding-top: 0;
    padding-bottom: 5px; }
  .theme-ptj #journalnote .panel-body {
    padding-top: 0;
    padding-bottom: 5px; }
  .theme-rjl #journalnote .panel-body {
    padding-top: 0;
    padding-bottom: 5px; }
  .theme-achima #journalnote .panel-body {
    padding-top: 0;
    padding-bottom: 5px; }
  .theme-blekinge #journalnote .panel-body {
    padding-top: 0;
    padding-bottom: 5px; }
  .theme-sll #journalnote .panel-body {
    padding-top: 0;
    padding-bottom: 5px; }
  .theme-halland #journalnote .panel-body {
    padding-top: 0;
    padding-bottom: 5px; }
  .theme-orebro #journalnote .panel-body {
    padding-top: 0;
    padding-bottom: 5px; }
  .theme-rg #journalnote .panel-body {
    padding-top: 0;
    padding-bottom: 5px; }

@media screen and (min-width: 768px) {
  #journalnote {
    position: fixed;
    top: auto;
    right: 0;
    padding-left: 15px; }
  .theme-default #journalnote .panel-footer {
    position: relative;
    top: 0; }
  .theme-helsa #journalnote .panel-footer {
    position: relative;
    top: 0; }
  .theme-ptj #journalnote .panel-footer {
    position: relative;
    top: 0; }
  .theme-rjl #journalnote .panel-footer {
    position: relative;
    top: 0; }
  .theme-achima #journalnote .panel-footer {
    position: relative;
    top: 0; }
  .theme-blekinge #journalnote .panel-footer {
    position: relative;
    top: 0; }
  .theme-sll #journalnote .panel-footer {
    position: relative;
    top: 0; }
  .theme-halland #journalnote .panel-footer {
    position: relative;
    top: 0; }
  .theme-orebro #journalnote .panel-footer {
    position: relative;
    top: 0; }
  .theme-rg #journalnote .panel-footer {
    position: relative;
    top: 0; } }

.theme-default .admin #journalnote .panel {
  border: 1px solid #09385c; }

.theme-helsa .admin #journalnote .panel {
  border: 1px solid #044557; }

.theme-ptj .admin #journalnote .panel {
  border: 1px solid #00A0C8; }

.theme-rjl .admin #journalnote .panel {
  border: 1px solid #4c8b2b; }

.theme-achima .admin #journalnote .panel {
  border: 1px solid #880000; }

.theme-blekinge .admin #journalnote .panel {
  border: 1px solid #00A6E2; }

.theme-sll .admin #journalnote .panel {
  border: 1px solid #000; }

.theme-halland .admin #journalnote .panel {
  border: 1px solid #2F7CAC; }

.theme-orebro .admin #journalnote .panel {
  border: 1px solid #004F9F; }

.theme-rg .admin #journalnote .panel {
  border: 1px solid #26BDF5; }

.theme-default .admin #journalnote .panel-heading {
  border-top: 1px solid #09385c;
  background-color: #0A4A7C; }

.theme-helsa .admin #journalnote .panel-heading {
  border-top: 1px solid #044557;
  background-color: #1D5E74; }

.theme-ptj .admin #journalnote .panel-heading {
  border-top: 1px solid #00A0C8;
  background-color: #0075B0; }

.theme-rjl .admin #journalnote .panel-heading {
  border-top: 1px solid #4c8b2b;
  background-color: #579835; }

.theme-achima .admin #journalnote .panel-heading {
  border-top: 1px solid #880000;
  background-color: #AA2B23; }

.theme-blekinge .admin #journalnote .panel-heading {
  border-top: 1px solid #00A6E2;
  background-color: #00A6E2; }

.theme-sll .admin #journalnote .panel-heading {
  border-top: 1px solid #000;
  background-color: #002d5a; }

.theme-halland .admin #journalnote .panel-heading {
  border-top: 1px solid #2F7CAC;
  background-color: #7BB1DB; }

.theme-orebro .admin #journalnote .panel-heading {
  border-top: 1px solid #004F9F;
  background-color: #4F80FF; }

.theme-rg .admin #journalnote .panel-heading {
  border-top: 1px solid #26BDF5;
  background-color: #0097cf; }

.theme-default .admin #journalnote .panel-heading label, .theme-default .admin #journalnote .panel-heading p.panel-title {
  color: #fff; }

.theme-helsa .admin #journalnote .panel-heading label, .theme-helsa .admin #journalnote .panel-heading p.panel-title {
  color: #fff; }

.theme-ptj .admin #journalnote .panel-heading label, .theme-ptj .admin #journalnote .panel-heading p.panel-title {
  color: #fff; }

.theme-rjl .admin #journalnote .panel-heading label, .theme-rjl .admin #journalnote .panel-heading p.panel-title {
  color: #fff; }

.theme-achima .admin #journalnote .panel-heading label, .theme-achima .admin #journalnote .panel-heading p.panel-title {
  color: #fff; }

.theme-blekinge .admin #journalnote .panel-heading label, .theme-blekinge .admin #journalnote .panel-heading p.panel-title {
  color: #fff; }

.theme-sll .admin #journalnote .panel-heading label, .theme-sll .admin #journalnote .panel-heading p.panel-title {
  color: #fff; }

.theme-halland .admin #journalnote .panel-heading label, .theme-halland .admin #journalnote .panel-heading p.panel-title {
  color: #fff; }

.theme-orebro .admin #journalnote .panel-heading label, .theme-orebro .admin #journalnote .panel-heading p.panel-title {
  color: #fff; }

.theme-rg .admin #journalnote .panel-heading label, .theme-rg .admin #journalnote .panel-heading p.panel-title {
  color: #fff; }

.theme-default .admin #journalnote .panel-body {
  background-color: #fff; }

.theme-helsa .admin #journalnote .panel-body {
  background-color: #FFFFFF; }

.theme-ptj .admin #journalnote .panel-body {
  background-color: #fffFFF; }

.theme-rjl .admin #journalnote .panel-body {
  background-color: #fff; }

.theme-achima .admin #journalnote .panel-body {
  background-color: #fff; }

.theme-blekinge .admin #journalnote .panel-body {
  background-color: #fff; }

.theme-sll .admin #journalnote .panel-body {
  background-color: #fff; }

.theme-halland .admin #journalnote .panel-body {
  background-color: #fff; }

.theme-orebro .admin #journalnote .panel-body {
  background-color: #fff; }

.theme-rg .admin #journalnote .panel-body {
  background-color: #fff; }

.theme-default .admin #journalnote .panel-body label {
  color: #0A4A7C; }

.theme-helsa .admin #journalnote .panel-body label {
  color: #1D5E74; }

.theme-ptj .admin #journalnote .panel-body label {
  color: #333333; }

.theme-rjl .admin #journalnote .panel-body label {
  color: #000; }

.theme-achima .admin #journalnote .panel-body label {
  color: #880000; }

.theme-blekinge .admin #journalnote .panel-body label {
  color: #000; }

.theme-sll .admin #journalnote .panel-body label {
  color: #000; }

.theme-halland .admin #journalnote .panel-body label {
  color: #000; }

.theme-orebro .admin #journalnote .panel-body label {
  color: #000; }

.theme-rg .admin #journalnote .panel-body label {
  color: #000; }

.theme-default .admin #journalnote .panel-body textarea {
  color: #000;
  background-color: #fff;
  border: 1px solid #999; }

.theme-helsa .admin #journalnote .panel-body textarea {
  color: #000000;
  background-color: #FFFFFF;
  border: 1px solid #CCCCCC; }

.theme-ptj .admin #journalnote .panel-body textarea {
  color: #333333;
  background-color: #fff;
  border: 1px solid #333333; }

.theme-rjl .admin #journalnote .panel-body textarea {
  color: #000;
  background-color: #fff;
  border: 1px solid #000; }

.theme-achima .admin #journalnote .panel-body textarea {
  color: #000;
  background-color: #fff;
  border: 1px solid #777; }

.theme-blekinge .admin #journalnote .panel-body textarea {
  color: #000;
  background-color: #fff;
  border: 1px solid #000; }

.theme-sll .admin #journalnote .panel-body textarea {
  color: #002d5a;
  background-color: #fff;
  border: 1px solid #685c53; }

.theme-halland .admin #journalnote .panel-body textarea {
  color: #000;
  background-color: #fff;
  border: 1px solid #AAAAAA; }

.theme-orebro .admin #journalnote .panel-body textarea {
  color: #000;
  background-color: #fff;
  border: 1px solid #C8E1EB; }

.theme-rg .admin #journalnote .panel-body textarea {
  color: #000;
  background-color: #fff;
  border: 1px solid #ccc; }

.theme-default .admin #journalnote .panel-body textarea:focus, .theme-default .admin #journalnote .panel-body textarea:focus {
  background-color: #D0F8F5;
  border: 1px solid #0A4A7C; }

.theme-helsa .admin #journalnote .panel-body textarea:focus, .theme-helsa .admin #journalnote .panel-body textarea:focus {
  background-color: #D7EAD9;
  border: 1px solid #6CB680; }

.theme-ptj .admin #journalnote .panel-body textarea:focus, .theme-ptj .admin #journalnote .panel-body textarea:focus {
  background-color: #fff;
  border: 1px solid #0A4A7C; }

.theme-rjl .admin #journalnote .panel-body textarea:focus, .theme-rjl .admin #journalnote .panel-body textarea:focus {
  background-color: #fff;
  border: 1px solid #579835; }

.theme-achima .admin #journalnote .panel-body textarea:focus, .theme-achima .admin #journalnote .panel-body textarea:focus {
  background-color: #fff;
  border: 1px solid #00ADA8; }

.theme-blekinge .admin #journalnote .panel-body textarea:focus, .theme-blekinge .admin #journalnote .panel-body textarea:focus {
  background-color: #fff;
  border: 1px solid #00A6E2; }

.theme-sll .admin #journalnote .panel-body textarea:focus, .theme-sll .admin #journalnote .panel-body textarea:focus {
  background-color: #E5F1F8;
  border: 1px solid #00A6E2; }

.theme-halland .admin #journalnote .panel-body textarea:focus, .theme-halland .admin #journalnote .panel-body textarea:focus {
  background-color: #FFF;
  border: 1px solid #6DACDE; }

.theme-orebro .admin #journalnote .panel-body textarea:focus, .theme-orebro .admin #journalnote .panel-body textarea:focus {
  background-color: #FFF;
  border: 1px solid #4F80FF; }

.theme-rg .admin #journalnote .panel-body textarea:focus, .theme-rg .admin #journalnote .panel-body textarea:focus {
  background-color: #FFF;
  border: 1px solid #faa634; }

.theme-default .admin #journalnote .panel-footer {
  background-color: #F1F1F1;
  border-top: 1px solid #09385c;
  -webkit-box-shadow: 0px 1px 0px 1px #09385c;
  -moz-box-shadow: 0px 1px 0px 1px #09385c;
  box-shadow: 0px 1px 0px 1px #09385c; }

.theme-helsa .admin #journalnote .panel-footer {
  background-color: #F3F6F6;
  border-top: 1px solid #044557;
  -webkit-box-shadow: 0px 1px 0px 1px #044557;
  -moz-box-shadow: 0px 1px 0px 1px #044557;
  box-shadow: 0px 1px 0px 1px #044557; }

.theme-ptj .admin #journalnote .panel-footer {
  background-color: #00A0C8;
  border-top: 1px solid #00A0C8;
  -webkit-box-shadow: 0px 1px 0px 1px #00A0C8;
  -moz-box-shadow: 0px 1px 0px 1px #00A0C8;
  box-shadow: 0px 1px 0px 1px #00A0C8; }

.theme-rjl .admin #journalnote .panel-footer {
  background-color: #EDEDED;
  border-top: 1px solid #4c8b2b;
  -webkit-box-shadow: 0px 1px 0px 1px #4c8b2b;
  -moz-box-shadow: 0px 1px 0px 1px #4c8b2b;
  box-shadow: 0px 1px 0px 1px #4c8b2b; }

.theme-achima .admin #journalnote .panel-footer {
  background-color: #EDEDED;
  border-top: 1px solid #880000;
  -webkit-box-shadow: 0px 1px 0px 1px #880000;
  -moz-box-shadow: 0px 1px 0px 1px #880000;
  box-shadow: 0px 1px 0px 1px #880000; }

.theme-blekinge .admin #journalnote .panel-footer {
  background-color: #E5F6FC;
  border-top: 1px solid #00A6E2;
  -webkit-box-shadow: 0px 1px 0px 1px #00A6E2;
  -moz-box-shadow: 0px 1px 0px 1px #00A6E2;
  box-shadow: 0px 1px 0px 1px #00A6E2; }

.theme-sll .admin #journalnote .panel-footer {
  background-color: #e0ded9;
  border-top: 1px solid #000;
  -webkit-box-shadow: 0px 1px 0px 1px #000;
  -moz-box-shadow: 0px 1px 0px 1px #000;
  box-shadow: 0px 1px 0px 1px #000; }

.theme-halland .admin #journalnote .panel-footer {
  background-color: #F5F5F5;
  border-top: 1px solid #2F7CAC;
  -webkit-box-shadow: 0px 1px 0px 1px #2F7CAC;
  -moz-box-shadow: 0px 1px 0px 1px #2F7CAC;
  box-shadow: 0px 1px 0px 1px #2F7CAC; }

.theme-orebro .admin #journalnote .panel-footer {
  background-color: #D1E9F2;
  border-top: 1px solid #004F9F;
  -webkit-box-shadow: 0px 1px 0px 1px #004F9F;
  -moz-box-shadow: 0px 1px 0px 1px #004F9F;
  box-shadow: 0px 1px 0px 1px #004F9F; }

.theme-rg .admin #journalnote .panel-footer {
  background-color: #F6F6F6;
  border-top: 1px solid #26BDF5;
  -webkit-box-shadow: 0px 1px 0px 1px #26BDF5;
  -moz-box-shadow: 0px 1px 0px 1px #26BDF5;
  box-shadow: 0px 1px 0px 1px #26BDF5; }

.theme-default .admin #journalnote .panel-footer label, .theme-default .admin #journalnote .panel-footer a {
  color: #0A4A7C; }

.theme-helsa .admin #journalnote .panel-footer label, .theme-helsa .admin #journalnote .panel-footer a {
  color: #1D5E74; }

.theme-ptj .admin #journalnote .panel-footer label, .theme-ptj .admin #journalnote .panel-footer a {
  color: #333333; }

.theme-rjl .admin #journalnote .panel-footer label, .theme-rjl .admin #journalnote .panel-footer a {
  color: #000; }

.theme-achima .admin #journalnote .panel-footer label, .theme-achima .admin #journalnote .panel-footer a {
  color: #880000; }

.theme-blekinge .admin #journalnote .panel-footer label, .theme-blekinge .admin #journalnote .panel-footer a {
  color: #000; }

.theme-sll .admin #journalnote .panel-footer label, .theme-sll .admin #journalnote .panel-footer a {
  color: #000; }

.theme-halland .admin #journalnote .panel-footer label, .theme-halland .admin #journalnote .panel-footer a {
  color: #000; }

.theme-orebro .admin #journalnote .panel-footer label, .theme-orebro .admin #journalnote .panel-footer a {
  color: #000; }

.theme-rg .admin #journalnote .panel-footer label, .theme-rg .admin #journalnote .panel-footer a {
  color: #000; }

#sect04 #symptomform {
  padding-right: 0; }

/*---------------------------------------------------------------------------
                                 FIGURES
---------------------------------------------------------------------------*/
/*---------------- Circle -------------------------*/
.circle-container {
  position: absolute;
  overflow: hidden;
  top: 360px;
  right: 0px;
  z-index: -10; }

.circle {
  position: absolute;
  border-radius: 50%;
  text-align: center;
  z-index: -9; }
  .theme-default .circle {
    background-color: #FFE150; }
  .theme-helsa .circle {
    background-color: #6CB680; }
  .theme-ptj .circle {
    background-color: #E78300; }
  .theme-rjl .circle {
    background-color: #579835; }
  .theme-achima .circle {
    background-color: #00ADA8; }
  .theme-blekinge .circle {
    background-color: #193F76; }
  .theme-sll .circle {
    background-color: #e1056d; }
  .theme-halland .circle {
    background-color: #4E9023; }
  .theme-orebro .circle {
    background-color: #9FC63B; }
  .theme-rg .circle {
    background-color: #50b848; }

.circle-text {
  width: 75%;
  line-height: 1.5;
  display: inline-block;
  vertical-align: middle;
  font-weight: 600; }
  .theme-default .circle-text {
    color: #0A4A7C; }
  .theme-helsa .circle-text {
    color: #fff; }
  .theme-ptj .circle-text {
    color: #fff; }
  .theme-rjl .circle-text {
    color: #fff; }
  .theme-achima .circle-text {
    color: #fff; }
  .theme-blekinge .circle-text {
    color: #fff; }
  .theme-sll .circle-text {
    color: #fff; }
  .theme-halland .circle-text {
    color: #fff; }
  .theme-orebro .circle-text {
    color: #fff; }
  .theme-rg .circle-text {
    color: #fff; }

/*XS*/
@media screen and (max-width: 768px) {
  .circle-text {
    font-size: 20px;
    padding-right: 5px; }
  .circle-container {
    width: 220px;
    height: 220px; }
  .circle {
    line-height: 220px;
    width: 220px;
    left: 10px; } }

/*SM MD*/
@media screen and (min-width: 768px) and (max-width: 1200px) {
  .circle-text {
    font-size: 30px;
    padding-right: 18px; }
  .circle-container {
    width: 380px;
    height: 380px; }
  .circle {
    line-height: 380px;
    width: 380px;
    left: 16px; } }

/*LG*/
@media screen and (min-width: 1200px) {
  .circle-text {
    font-size: 36px;
    padding-right: 10px; }
  .circle-container {
    width: 420px;
    height: 420px; }
  .circle {
    line-height: 420px;
    width: 420px;
    left: 20px; } }

.small-circle-container {
  overflow: hidden;
  top: 360px;
  right: 0px;
  z-index: -10; }

.small-circle {
  border-radius: 50%;
  text-align: center;
  z-index: -9; }
  .theme-default .small-circle {
    background-color: #FFE150; }
  .theme-helsa .small-circle {
    background-color: #6CB680; }
  .theme-ptj .small-circle {
    background-color: #E78300; }
  .theme-rjl .small-circle {
    background-color: #579835; }
  .theme-achima .small-circle {
    background-color: #00ADA8; }
  .theme-blekinge .small-circle {
    background-color: #193F76; }
  .theme-sll .small-circle {
    background-color: #e1056d; }
  .theme-halland .small-circle {
    background-color: #4E9023; }
  .theme-orebro .small-circle {
    background-color: #9FC63B; }
  .theme-rg .small-circle {
    background-color: #50b848; }

.small-circle-text {
  width: 75%;
  line-height: 1.5;
  display: inline-block;
  vertical-align: middle;
  font-weight: 600; }
  .theme-default .small-circle-text {
    color: #0A4A7C; }
  .theme-helsa .small-circle-text {
    color: #fff; }
  .theme-ptj .small-circle-text {
    color: #fff; }
  .theme-rjl .small-circle-text {
    color: #fff; }
  .theme-achima .small-circle-text {
    color: #fff; }
  .theme-blekinge .small-circle-text {
    color: #fff; }
  .theme-sll .small-circle-text {
    color: #fff; }
  .theme-halland .small-circle-text {
    color: #fff; }
  .theme-orebro .small-circle-text {
    color: #fff; }
  .theme-rg .small-circle-text {
    color: #fff; }

/*LG*/
@media screen and (min-width: 1200px) {
  .small-circle-text {
    font-size: 30px;
    padding-right: 10px; }
  .small-circle-container {
    width: 150px;
    height: 150px; }
  .small-circle {
    line-height: 150px;
    width: 150px; } }

/*XS SM MD*/
@media screen and (min-width: 150px) and (max-width: 1200px) {
  .small-circle-text {
    font-size: 20px;
    padding-right: 10px; }
  .small-circle-container {
    width: 100px;
    height: 100px; }
  .small-circle {
    line-height: 100px;
    width: 100px; } }

/*---------------------------------------------------------------------------
                    INTERNET EXPLORER EXEPTIONS
---------------------------------------------------------------------------*/
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* IE10+ CSS styles go here */
  .hideInInternetExplorer {
    display: none; }
  .theme-default .admin #journalnote .panel-body textarea, .theme-default
  .nurseJournalSectionDiv textarea {
    min-height: 26px; }
  .theme-helsa .admin #journalnote .panel-body textarea, .theme-helsa
  .nurseJournalSectionDiv textarea {
    min-height: 26px; }
  .theme-ptj .admin #journalnote .panel-body textarea, .theme-ptj
  .nurseJournalSectionDiv textarea {
    min-height: 26px; }
  .theme-rjl .admin #journalnote .panel-body textarea, .theme-rjl
  .nurseJournalSectionDiv textarea {
    min-height: 26px; }
  .theme-achima .admin #journalnote .panel-body textarea, .theme-achima
  .nurseJournalSectionDiv textarea {
    min-height: 26px; }
  .theme-blekinge .admin #journalnote .panel-body textarea, .theme-blekinge
  .nurseJournalSectionDiv textarea {
    min-height: 26px; }
  .theme-sll .admin #journalnote .panel-body textarea, .theme-sll
  .nurseJournalSectionDiv textarea {
    min-height: 26px; }
  .theme-halland .admin #journalnote .panel-body textarea, .theme-halland
  .nurseJournalSectionDiv textarea {
    min-height: 26px; }
  .theme-orebro .admin #journalnote .panel-body textarea, .theme-orebro
  .nurseJournalSectionDiv textarea {
    min-height: 26px; }
  .theme-rg .admin #journalnote .panel-body textarea, .theme-rg
  .nurseJournalSectionDiv textarea {
    min-height: 26px; } }

/*---------------------------------------------------------------------------
                                 REACTSTRAP
---------------------------------------------------------------------------*/
/*------------------------------- Modal -----------------------------------*/
.modal-footer {
  display: block; }

.table td, .table th, .table thead th {
  padding: 5px;
  border: none; }

.theme-default .modal-backdrop {
  background-color: #0A4A7C; }

.theme-helsa .modal-backdrop {
  background-color: #1D5E74; }

.theme-ptj .modal-backdrop {
  background-color: #0075B0; }

.theme-rjl .modal-backdrop {
  background-color: #579835; }

.theme-achima .modal-backdrop {
  background-color: #AA2B23; }

.theme-blekinge .modal-backdrop {
  background-color: #00A6E2; }

.theme-sll .modal-backdrop {
  background-color: #002d5a; }

.theme-halland .modal-backdrop {
  background-color: #7BB1DB; }

.theme-orebro .modal-backdrop {
  background-color: #4F80FF; }

.theme-rg .modal-backdrop {
  background-color: #0097cf; }

.modal-backdrop.show {
  opacity: .65; }

.theme-default .admin .PatientContactFormBtns .btn {
  padding: .375rem .75rem;
  font-size: 16px; }

.theme-helsa .admin .PatientContactFormBtns .btn {
  padding: .375rem .75rem;
  font-size: 16px; }

.theme-ptj .admin .PatientContactFormBtns .btn {
  padding: .375rem .75rem;
  font-size: 16px; }

.theme-rjl .admin .PatientContactFormBtns .btn {
  padding: .375rem .75rem;
  font-size: 16px; }

.theme-achima .admin .PatientContactFormBtns .btn {
  padding: .375rem .75rem;
  font-size: 16px; }

.theme-blekinge .admin .PatientContactFormBtns .btn {
  padding: .375rem .75rem;
  font-size: 16px; }

.theme-sll .admin .PatientContactFormBtns .btn {
  padding: .375rem .75rem;
  font-size: 16px; }

.theme-halland .admin .PatientContactFormBtns .btn {
  padding: .375rem .75rem;
  font-size: 16px; }

.theme-orebro .admin .PatientContactFormBtns .btn {
  padding: .375rem .75rem;
  font-size: 16px; }

.theme-rg .admin .PatientContactFormBtns .btn {
  padding: .375rem .75rem;
  font-size: 16px; }

/*---------------------------------------------------------------------------
                              BOOTSTRAP 4 CARDS
                    Replace the Panel, thumbnails, and wells
---------------------------------------------------------------------------*/
/*---------------- CARD Heading -------------------------*/
.theme-default .card.primary .card-header, .theme-default .card .card-header {
  background-color: #F1F1F1;
  border-bottom: 1px solid transparent;
  border-color: #CCC; }

.theme-helsa .card.primary .card-header, .theme-helsa .card .card-header {
  background-color: #F3F6F6;
  border-bottom: 1px solid transparent;
  border-color: #CCCCCC; }

.theme-ptj .card.primary .card-header, .theme-ptj .card .card-header {
  background-color: #00A0C8;
  border-bottom: 1px solid transparent;
  border-color: #0075B0; }

.theme-rjl .card.primary .card-header, .theme-rjl .card .card-header {
  background-color: #EDEDED;
  border-bottom: 1px solid transparent;
  border-color: #E4E4E4; }

.theme-achima .card.primary .card-header, .theme-achima .card .card-header {
  background-color: #EDEDED;
  border-bottom: 1px solid transparent;
  border-color: #777; }

.theme-blekinge .card.primary .card-header, .theme-blekinge .card .card-header {
  background-color: #E5F6FC;
  border-bottom: 1px solid transparent;
  border-color: #E5F6FC; }

.theme-sll .card.primary .card-header, .theme-sll .card .card-header {
  background-color: #e0ded9;
  border-bottom: 1px solid transparent;
  border-color: #a79d96; }

.theme-halland .card.primary .card-header, .theme-halland .card .card-header {
  background-color: #F5F5F5;
  border-bottom: 1px solid transparent;
  border-color: #DDDDDD; }

.theme-orebro .card.primary .card-header, .theme-orebro .card .card-header {
  background-color: #D1E9F2;
  border-bottom: 1px solid transparent;
  border-color: #C8E1EB; }

.theme-rg .card.primary .card-header, .theme-rg .card .card-header {
  background-color: #F6F6F6;
  border-bottom: 0px solid transparent;
  border-color: #ccc; }

.card-header, .card-footer {
  padding: 5px 15px; }

.card-header.toggle {
  cursor: pointer; }

.theme-default .card.danger .card-header {
  background-color: #E32424;
  color: #fff; }

.theme-helsa .card.danger .card-header {
  background-color: #F48174;
  color: #fff; }

.theme-ptj .card.danger .card-header {
  background-color: #F54359;
  color: #fff; }

.theme-rjl .card.danger .card-header {
  background-color: #ce1141;
  color: #fff; }

.theme-achima .card.danger .card-header {
  background-color: #AA2B23;
  color: #fff; }

.theme-blekinge .card.danger .card-header {
  background-color: #d70480;
  color: #fff; }

.theme-sll .card.danger .card-header {
  background-color: #9a0932;
  color: #fff; }

.theme-halland .card.danger .card-header {
  background-color: #8E0000;
  color: #fff; }

.theme-orebro .card.danger .card-header {
  background-color: #E32424;
  color: #fff; }

.theme-rg .card.danger .card-header {
  background-color: #ee3780;
  color: #fff; }

.theme-default .card.info .card-header {
  background-color: #D0F8F5;
  color: #000000; }

.theme-helsa .card.info .card-header {
  background-color: #D7EAD9;
  color: #000000; }

.theme-ptj .card.info .card-header {
  background-color: #F3F3F3;
  color: #333333; }

.theme-rjl .card.info .card-header {
  background-color: #EDEDED;
  color: #000000; }

.theme-achima .card.info .card-header {
  background-color: #EDEDED;
  color: #000; }

.theme-blekinge .card.info .card-header {
  background-color: #E5F6FC;
  color: #000; }

.theme-sll .card.info .card-header {
  background-color: #FFFFFF;
  color: #000; }

.theme-halland .card.info .card-header {
  background-color: #D3E6F5;
  color: #000; }

.theme-orebro .card.info .card-header {
  background-color: #E3F2F8;
  color: #000000; }

.theme-rg .card.info .card-header {
  background-color: #F6F6F6;
  color: #000000; }

.card, card.primary {
  border-top: none; }
  .theme-default .card, .theme-default card.primary {
    border-color: #CCC; }
  .theme-helsa .card, .theme-helsa card.primary {
    border-color: #CCCCCC; }
  .theme-ptj .card, .theme-ptj card.primary {
    border-color: #0075B0; }
  .theme-rjl .card, .theme-rjl card.primary {
    border-color: #E4E4E4; }
  .theme-achima .card, .theme-achima card.primary {
    border-color: #777; }
  .theme-blekinge .card, .theme-blekinge card.primary {
    border-color: #E5F6FC; }
  .theme-sll .card, .theme-sll card.primary {
    border-color: #a79d96; }
  .theme-halland .card, .theme-halland card.primary {
    border-color: #DDDDDD; }
  .theme-orebro .card, .theme-orebro card.primary {
    border-color: #C8E1EB; }
  .theme-rg .card, .theme-rg card.primary {
    border-color: #ccc; }

.theme-default .card {
  border-radius: 0; }

.theme-helsa .card {
  border-radius: 0; }

.theme-ptj .card {
  border-radius: 0px; }

.theme-rjl .card {
  border-radius: 0; }

.theme-achima .card {
  border-radius: 5px; }

.theme-blekinge .card {
  border-radius: 0; }

.theme-sll .card {
  border-radius: 0; }

.theme-halland .card {
  border-radius: 0; }

.theme-orebro .card {
  border-radius: 3px; }

.theme-rg .card {
  border-radius: 0px; }

.theme-default .card .card-header {
  border-top: 1px solid #CCC; }

.theme-helsa .card .card-header {
  border-top: 1px solid #CCCCCC; }

.theme-ptj .card .card-header {
  border-top: 1px solid #0075B0; }

.theme-rjl .card .card-header {
  border-top: 1px solid #E4E4E4; }

.theme-achima .card .card-header {
  border-top: 1px solid #777; }

.theme-blekinge .card .card-header {
  border-top: 1px solid #E5F6FC; }

.theme-sll .card .card-header {
  border-top: 1px solid #a79d96; }

.theme-halland .card .card-header {
  border-top: 1px solid #DDDDDD; }

.theme-orebro .card .card-header {
  border-top: 1px solid #C8E1EB; }

.theme-rg .card .card-header {
  border-top: 1px solid #ccc; }

.theme-default .card .card-body {
  background-color: #fff;
  color: #000;
  padding: 15px; }

.theme-helsa .card .card-body {
  background-color: #FFFFFF;
  color: #000000;
  padding: 15px; }

.theme-ptj .card .card-body {
  background-color: #fffFFF;
  color: #333333;
  padding: 15px; }

.theme-rjl .card .card-body {
  background-color: #fff;
  color: #000;
  padding: 15px; }

.theme-achima .card .card-body {
  background-color: #fff;
  color: #000;
  padding: 15px; }

.theme-blekinge .card .card-body {
  background-color: #fff;
  color: #000;
  padding: 15px; }

.theme-sll .card .card-body {
  background-color: #fff;
  color: #000;
  padding: 15px; }

.theme-halland .card .card-body {
  background-color: #fff;
  color: #000;
  padding: 15px; }

.theme-orebro .card .card-body {
  background-color: #fff;
  color: #000;
  padding: 15px; }

.theme-rg .card .card-body {
  background-color: #fff;
  color: #000;
  padding: 15px; }

.card .card-body p {
  margin: 0; }

.card .card-body.disabled {
  opacity: 0.3;
  cursor: not-allowed; }

.card .card-body.disabled .btn:hover {
  cursor: not-allowed; }

/*---------------- Footer -------------------------*/
.card .card-footer, .card.primary .card-footer {
  padding: 10px 15px;
  overflow: hidden; }
  .theme-default .card .card-footer, .theme-default .card.primary .card-footer {
    background-color: #F1F1F1;
    border-top: 1px solid #F1F1F1;
    border-color: #CCC; }
  .theme-helsa .card .card-footer, .theme-helsa .card.primary .card-footer {
    background-color: #F3F6F6;
    border-top: 1px solid #F3F6F6;
    border-color: #CCCCCC; }
  .theme-ptj .card .card-footer, .theme-ptj .card.primary .card-footer {
    background-color: #00A0C8;
    border-top: 1px solid #00A0C8;
    border-color: #0075B0; }
  .theme-rjl .card .card-footer, .theme-rjl .card.primary .card-footer {
    background-color: #EDEDED;
    border-top: 1px solid #EDEDED;
    border-color: #E4E4E4; }
  .theme-achima .card .card-footer, .theme-achima .card.primary .card-footer {
    background-color: #EDEDED;
    border-top: 1px solid #EDEDED;
    border-color: #777; }
  .theme-blekinge .card .card-footer, .theme-blekinge .card.primary .card-footer {
    background-color: #E5F6FC;
    border-top: 1px solid #E5F6FC;
    border-color: #E5F6FC; }
  .theme-sll .card .card-footer, .theme-sll .card.primary .card-footer {
    background-color: #e0ded9;
    border-top: 1px solid #e0ded9;
    border-color: #a79d96; }
  .theme-halland .card .card-footer, .theme-halland .card.primary .card-footer {
    background-color: #F5F5F5;
    border-top: 1px solid #F5F5F5;
    border-color: #DDDDDD; }
  .theme-orebro .card .card-footer, .theme-orebro .card.primary .card-footer {
    background-color: #D1E9F2;
    border-top: 1px solid #D1E9F2;
    border-color: #C8E1EB; }
  .theme-rg .card .card-footer, .theme-rg .card.primary .card-footer {
    background-color: #F6F6F6;
    border-top: 0px solid #F6F6F6;
    border-color: #ccc; }
