/* SASS variables and intellisense */
/*---------------------------------------------------------------------------
                          PATIENTSTYELE ARABIC
---------------------------------------------------------------------------*/
html[dir="rtl"] .floatLeft {
  float: right; }

html[dir="rtl"] .stepDiv {
  float: left; }

html[dir="rtl"] .navbar-brand {
  float: right; }

html[dir="rtl"] .col-lg-offset-4 {
  margin-left: 0; }

html[dir="rtl"] .dropdown-menu-right {
  right: auto;
  left: 0; }

html[dir="rtl"] .pictogram-img {
  display: none; }

html[dir="rtl"] .patientQuestionNiceClass {
  float: right; }

html[dir="rtl"] .welcome-title {
  text-align: right;
  float: right; }

html[dir="rtl"] .welcome-info {
  float: right; }

html[dir="rtl"] .step {
  text-align: left;
  float: left; }

/* Small screens sizes */
@media screen and (max-width: 768px) {
  html[dir="rtl"] .welcome-title, html[dir="rtl"] .welcome-info, html[dir="rtl"] .stepDiv, html[dir="rtl"] .step {
    width: 100%; }
  html[dir="rtl"] .patientInformation .floatLeft.patientInformationButton {
    float: none; } }

@media screen and (min-width: 768px) and (max-width: 992px) {
  html[dir="rtl"] .step {
    width: 75%; }
  html[dir="rtl"] .regPatient .welcome-title, html[dir="rtl"] .regPatient .step {
    width: 100%; } }

@media screen and (min-width: 768px) and (max-width: 1200px) {
  html[dir="rtl"] .careCenterHelpDiv .step {
    width: 100%; }
  html[dir="rtl"] .careCenterHelpDiv .welcome-title {
    display: none; } }

html[dir="rtl"] .patientInformationHeadding {
  float: right; }

@media screen and (max-width: 768px) {
  html[dir="rtl"] .registration.patientInformation {
    margin-top: 0; } }

/* Bigger screens sizes */
@media screen and (min-width: 768px) {
  html[dir="rtl"] .groupTitle .HeaddingDoctorView {
    margin-right: 0;
    margin-left: 80px; } }

/*---------------------------------------------------------------------------
                                 IMAGES
---------------------------------------------------------------------------*/
/* Bigger than MEDIUM sqreen size (col-md-up) */
@media screen and (min-width: 992px) {
  html[dir="rtl"] .handHoldingTicketImg {
    padding-right: 30%;
    padding-left: 0; }
  html[dir="rtl"] .writingHandImg {
    padding-right: 10%;
    padding-left: 0; } }

html[dir="rtl"] .TimeImg {
  float: left; }

html[dir="rtl"] .left-col-noMargin {
  margin-left: 0;
  margin-right: -8px; }

html[dir="rtl"] .arrow-card {
  left: 26px;
  right: auto;
  -moz-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  transform: scaleX(-1);
  -ms-filter: fliph;
  filter: fliph; }

html[dir="rtl"] .secondHeader h4 {
  margin-left: 40px;
  margin-right: 0; }

html[dir="rtl"] .select-wrapper::after {
  margin-right: 0;
  margin-left: 33px;
  right: auto;
  left: 0; }

html[dir="rtl"] .footer {
  padding-left: 0;
  padding-right: 16px; }

html[dir="rtl"] .secondFooter {
  padding-left: 0;
  padding-right: 16px; }

html[dir="rtl"] .form-block .select-wrapper::after {
  margin-left: 40px;
  margin-right: 0; }

html[dir="rtl"] #reservenumber {
  margin-left: -30px;
  margin-right: 0; }

html[dir="rtl"] .checkbox-inline, html[dir="rtl"] .radio-inline {
  padding-left: 0;
  padding-right: 22px; }

html[dir="rtl"] .tightCheckBoxLabel {
  margin-left: 0;
  margin-right: -20px; }

html[dir="rtl"] .unitSpan {
  float: left; }

html[dir="rtl"] .question-block.row.child, html[dir="rtl"] .child {
  margin-left: 0;
  margin-right: 16px; }

html[dir="rtl"] .question-block.row.childchild, html[dir="rtl"] .childchild {
  margin-left: 0;
  margin-right: 32px; }

html[dir="rtl"] .question-block.row.childchildchild, html[dir="rtl"] .childchildchild {
  margin-left: 0;
  margin-right: 48px; }

html[dir="rtl"] .note-title {
  text-align: right; }

html[dir="rtl"] .note-title2 {
  text-align: right; }

html[dir="rtl"] .registration label {
  margin-left: 0;
  margin-right: 36px; }

@media screen and (max-width: 768px) {
  html[dir="rtl"] .bigBulletArrow {
    margin-left: 6px;
    margin-right: 20px;
    -moz-transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
    -o-transform: scaleX(-1);
    transform: scaleX(-1);
    -ms-filter: fliph;
    filter: fliph; } }

@media screen and (min-width: 768px) {
  html[dir="rtl"] .bigBulletArrow {
    margin-right: 40px;
    margin-left: 8px;
    -moz-transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
    -o-transform: scaleX(-1);
    transform: scaleX(-1);
    -ms-filter: fliph;
    filter: fliph; }
  html[dir="rtl"] .afterBullets {
    margin-right: 72px;
    margin-left: 0; } }

html[dir="rtl"] .circle {
  right: 25px;
  left: auto; }

html[dir="rtl"] .question-block {
  text-align: right; }

html[dir="rtl"] .patientRegistration .rangeInputValue {
  margin-right: 0;
  margin-left: 6px; }

/*--------------------------[ General setting ]---------------------------*/
html[dir="rtl"] .secondHeader input {
  float: right;
  margin-right: 10px;
  margin-left: 0; }

html[dir="rtl"] .secondHeader .btn {
  float: left;
  margin-left: 10px;
  margin-right: 0; }

/*---------------------------[ Button Groups ]----------------------------*/
html[dir="rtl"] .patientanswer .leftBtn {
  margin-right: 0;
  margin-left: 5px; }

html[dir="rtl"] .patientanswer .rightBtn {
  margin-right: 5px;
  margin-left: -20px; }

html[dir="rtl"] .languageP {
  margin-right: 12px; }

/*---------------------------------------------------------------------------
                             INPUT / TEXTBOXES 
---------------------------------------------------------------------------*/
html[dir="rtl"] .arrow-right {
  margin-left: 0;
  margin-right: 12px;
  -moz-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  transform: scaleX(-1);
  -ms-filter: fliph;
  filter: fliph; }

html[dir="rtl"] .arrow-right-mini {
  margin-right: 12px;
  margin-left: 0;
  float: left;
  -moz-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  transform: scaleX(-1);
  -ms-filter: fliph;
  filter: fliph; }

html[dir="rtl"] .floatRight {
  float: left; }

html[dir="rtl"] .textLeft {
  text-align: right !important; }

html[dir="rtl"] .textRight {
  text-align: left !important; }

html[dir="rtl"] .arrow-right-floatRight {
  float: left;
  -moz-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  transform: scaleX(-1);
  -ms-filter: fliph;
  filter: fliph; }

html[dir="rtl"] .arrow-left-floatLeft {
  float: right;
  -moz-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  transform: scaleX(-1);
  -ms-filter: fliph;
  filter: fliph; }

html[dir="rtl"] .pictogram-img {
  float: left; }

html[dir="rtl"] a .flag-img {
  float: left; }

html[dir="rtl"] .btnFlag {
  margin-left: 2px;
  margin-right: 0; }

html[dir="rtl"] .panel-heading h4.panel-title a:after {
  float: left; }

html[dir="rtl"] .panel-footer {
  direction: ltr; }
