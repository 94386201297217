/* SASS variables and intellisense */
/*---------------------------------------------------------------------------
                               Lists (ul/li)
---------------------------------------------------------------------------*/
ul.no-style-list {
  list-style-type: none;
  padding: 0px;
  margin: 0px; }

ul.no-style-list li {
  display: inline; }

/*---------------------------------------------------------------------------
                                 TABLE 
---------------------------------------------------------------------------*/
ul.pagination li a.page-link, .page-item:first-child .page-link, .page-item:last-child .page-link {
  border-radius: 50%;
  margin: 0 -1px;
  margin: 0 2px; }
  .theme-default ul.pagination li a.page-link, .theme-default .page-item:first-child .page-link, .theme-default .page-item:last-child .page-link {
    border: 1px solid #000;
    color: #000; }
  .theme-helsa ul.pagination li a.page-link, .theme-helsa .page-item:first-child .page-link, .theme-helsa .page-item:last-child .page-link {
    border: 1px solid #000;
    color: #000; }
  .theme-ptj ul.pagination li a.page-link, .theme-ptj .page-item:first-child .page-link, .theme-ptj .page-item:last-child .page-link {
    border: 1px solid #333333;
    color: #333333; }
  .theme-rjl ul.pagination li a.page-link, .theme-rjl .page-item:first-child .page-link, .theme-rjl .page-item:last-child .page-link {
    border: 1px solid #000;
    color: #000; }
  .theme-achima ul.pagination li a.page-link, .theme-achima .page-item:first-child .page-link, .theme-achima .page-item:last-child .page-link {
    border: 1px solid #000;
    color: #000; }
  .theme-blekinge ul.pagination li a.page-link, .theme-blekinge .page-item:first-child .page-link, .theme-blekinge .page-item:last-child .page-link {
    border: 1px solid #000;
    color: #000; }
  .theme-sll ul.pagination li a.page-link, .theme-sll .page-item:first-child .page-link, .theme-sll .page-item:last-child .page-link {
    border: 1px solid #000;
    color: #000; }
  .theme-halland ul.pagination li a.page-link, .theme-halland .page-item:first-child .page-link, .theme-halland .page-item:last-child .page-link {
    border: 1px solid #000;
    color: #000; }
  .theme-orebro ul.pagination li a.page-link, .theme-orebro .page-item:first-child .page-link, .theme-orebro .page-item:last-child .page-link {
    border: 1px solid #000;
    color: #000; }
  .theme-rg ul.pagination li a.page-link, .theme-rg .page-item:first-child .page-link, .theme-rg .page-item:last-child .page-link {
    border: 0px solid #000;
    color: #000; }

.theme-default ul.pagination li.previous a.page-link, .theme-default ul.pagination li.next a.page-link {
  border-radius: 0; }

.theme-helsa ul.pagination li.previous a.page-link, .theme-helsa ul.pagination li.next a.page-link {
  border-radius: 0; }

.theme-ptj ul.pagination li.previous a.page-link, .theme-ptj ul.pagination li.next a.page-link {
  border-radius: 0px; }

.theme-rjl ul.pagination li.previous a.page-link, .theme-rjl ul.pagination li.next a.page-link {
  border-radius: 0; }

.theme-achima ul.pagination li.previous a.page-link, .theme-achima ul.pagination li.next a.page-link {
  border-radius: 5px; }

.theme-blekinge ul.pagination li.previous a.page-link, .theme-blekinge ul.pagination li.next a.page-link {
  border-radius: 0; }

.theme-sll ul.pagination li.previous a.page-link, .theme-sll ul.pagination li.next a.page-link {
  border-radius: 0; }

.theme-halland ul.pagination li.previous a.page-link, .theme-halland ul.pagination li.next a.page-link {
  border-radius: 0; }

.theme-orebro ul.pagination li.previous a.page-link, .theme-orebro ul.pagination li.next a.page-link {
  border-radius: 3px; }

.theme-rg ul.pagination li.previous a.page-link, .theme-rg ul.pagination li.next a.page-link {
  border-radius: 0px; }

.theme-default ul.pagination li a.page-link:hover {
  background-color: #fff; }

.theme-helsa ul.pagination li a.page-link:hover {
  background-color: #fff; }

.theme-ptj ul.pagination li a.page-link:hover {
  background-color: #fff; }

.theme-rjl ul.pagination li a.page-link:hover {
  background-color: #fff; }

.theme-achima ul.pagination li a.page-link:hover {
  background-color: #fff; }

.theme-blekinge ul.pagination li a.page-link:hover {
  background-color: #fff; }

.theme-sll ul.pagination li a.page-link:hover {
  background-color: #fff; }

.theme-halland ul.pagination li a.page-link:hover {
  background-color: #fff; }

.theme-orebro ul.pagination li a.page-link:hover {
  background-color: #fff; }

.theme-rg ul.pagination li a.page-link:hover {
  background-color: #fff; }

.theme-default ul.pagination li.disabled a.page-link {
  background-color: #fff;
  border: 1px solid #E4E4E4;
  color: #E4E4E4; }

.theme-helsa ul.pagination li.disabled a.page-link {
  background-color: #fff;
  border: 1px solid #d7d7d7;
  color: #d7d7d7; }

.theme-ptj ul.pagination li.disabled a.page-link {
  background-color: #fff;
  border: 1px solid #F3F3F3;
  color: #F3F3F3; }

.theme-rjl ul.pagination li.disabled a.page-link {
  background-color: #fff;
  border: 1px solid #E4E4E4;
  color: #E4E4E4; }

.theme-achima ul.pagination li.disabled a.page-link {
  background-color: #fff;
  border: 1px solid #E4E4E4;
  color: #E4E4E4; }

.theme-blekinge ul.pagination li.disabled a.page-link {
  background-color: #fff;
  border: 1px solid #E5E5E5;
  color: #E5E5E5; }

.theme-sll ul.pagination li.disabled a.page-link {
  background-color: #fff;
  border: 1px solid #a79d96;
  color: #a79d96; }

.theme-halland ul.pagination li.disabled a.page-link {
  background-color: #fff;
  border: 1px solid #CCCCCC;
  color: #CCCCCC; }

.theme-orebro ul.pagination li.disabled a.page-link {
  background-color: #fff;
  border: 1px solid #E4E4E4;
  color: #E4E4E4; }

.theme-rg ul.pagination li.disabled a.page-link {
  background-color: #fff;
  border: 0px solid #E4E4E4;
  color: #E4E4E4; }

.theme-default ul.pagination li.active a.page-link {
  background-color: #FFE150;
  border: 1px solid #FFE150;
  color: #0A4A7C; }

.theme-helsa ul.pagination li.active a.page-link {
  background-color: #6CB680;
  border: 1px solid #6CB680;
  color: #fff; }

.theme-ptj ul.pagination li.active a.page-link {
  background-color: #E78300;
  border: 1px solid #E78300;
  color: #fff; }

.theme-rjl ul.pagination li.active a.page-link {
  background-color: #579835;
  border: 1px solid #579835;
  color: #fff; }

.theme-achima ul.pagination li.active a.page-link {
  background-color: #00ADA8;
  border: 1px solid #00ADA8;
  color: #fff; }

.theme-blekinge ul.pagination li.active a.page-link {
  background-color: #193F76;
  border: 1px solid #193F76;
  color: #fff; }

.theme-sll ul.pagination li.active a.page-link {
  background-color: #e1056d;
  border: 1px solid #e1056d;
  color: #fff; }

.theme-halland ul.pagination li.active a.page-link {
  background-color: #4E9023;
  border: 1px solid #4E9023;
  color: #fff; }

.theme-orebro ul.pagination li.active a.page-link {
  background-color: #9FC63B;
  border: 1px solid #9FC63B;
  color: #fff; }

.theme-rg ul.pagination li.active a.page-link {
  background-color: #50b848;
  border: 0px solid #50b848;
  color: #fff; }

/*---------------------------------------------------------------------------
                                 PATIENT LIST 
---------------------------------------------------------------------------*/
.dataTables_wrapper {
  margin-top: -22px; }

#daysVal {
  width: 100px; }

@media screen and (max-width: 992px) {
  #daysVal {
    margin-top: 20px; } }

th, td {
  padding: 6px; }

#patientListTable th:first-child, #patientListTable td:first-child {
  padding-left: 15px; }

#patientListTable th:last-child, #patientListTable td:last-child {
  padding-right: 15px; }

#patientListTable_filter input[type="search"] {
  margin-right: 15px; }

#patientListTable_filter, #userListTable_filter {
  margin-bottom: 24px; }

#userListTable_wrapper #userListTable_filter {
  margin-bottom: 14px; }

@media screen and (min-width: 768px) {
  #userListTable_wrapper #userListTable_filter {
    margin-top: -36px; } }

@media screen and (max-width: 768px) {
  #listContainer .dataTables_wrapper .dataTables_filter {
    text-align: right; } }

@media screen and (max-width: 768px) {
  .dataTables_filter input[type=search] {
    margin-top: 20px; } }

#patientListTable_info, #userListTable_info {
  text-align: center;
  margin-bottom: 20px; }

.webbapp .pagination {
  display: inline-block; }

#patientListTable_paginate, #userListTable_paginate {
  text-align: center; }

.table-hover tbody tr:hover {
  cursor: pointer !important; }
  .theme-default .table-hover tbody tr:hover {
    background-color: #A0F0EB;
    color: #000; }
  .theme-helsa .table-hover tbody tr:hover {
    background-color: #D7EAD9;
    color: #1D5E74; }
  .theme-ptj .table-hover tbody tr:hover {
    background-color: #00A0C8;
    color: #fff; }
  .theme-rjl .table-hover tbody tr:hover {
    background-color: #8cc63f;
    color: #fff; }
  .theme-achima .table-hover tbody tr:hover {
    background-color: #AEAEAE;
    color: #000; }
  .theme-blekinge .table-hover tbody tr:hover {
    background-color: #28B8CC;
    color: #000; }
  .theme-sll .table-hover tbody tr:hover {
    background-color: #00aeef;
    color: #000; }
  .theme-halland .table-hover tbody tr:hover {
    background-color: #6DACDE;
    color: #000; }
  .theme-orebro .table-hover tbody tr:hover {
    background-color: #4F80FF;
    color: #ffffff; }
  .theme-rg .table-hover tbody tr:hover {
    background-color: #26BDF5;
    color: #ffffff; }

tr.odd, tbody tr:nth-child(odd) {
  font-style: normal; }
  .theme-default tr.odd, .theme-default tbody tr:nth-child(odd) {
    background: #D0F8F5;
    color: #000; }
  .theme-helsa tr.odd, .theme-helsa tbody tr:nth-child(odd) {
    background: #EBF5EC;
    color: #000; }
  .theme-ptj tr.odd, .theme-ptj tbody tr:nth-child(odd) {
    background: #fff;
    color: #333333; }
  .theme-rjl tr.odd, .theme-rjl tbody tr:nth-child(odd) {
    background: #EDEDED;
    color: #000; }
  .theme-achima tr.odd, .theme-achima tbody tr:nth-child(odd) {
    background: #EDEDED;
    color: #000; }
  .theme-blekinge tr.odd, .theme-blekinge tbody tr:nth-child(odd) {
    background: #E5F6FC;
    color: #000; }
  .theme-sll tr.odd, .theme-sll tbody tr:nth-child(odd) {
    background: #E5F1F8;
    color: #000; }
  .theme-halland tr.odd, .theme-halland tbody tr:nth-child(odd) {
    background: #D3E6F5;
    color: #000; }
  .theme-orebro tr.odd, .theme-orebro tbody tr:nth-child(odd) {
    background: #D1E9F2;
    color: #000; }
  .theme-rg tr.odd, .theme-rg tbody tr:nth-child(odd) {
    background: #DCF1DA;
    color: #000; }

tr.even, tbody tr:nth-child(even) {
  font-style: normal; }
  .theme-default tr.even, .theme-default tbody tr:nth-child(even) {
    background-color: #fff;
    color: #000; }
  .theme-helsa tr.even, .theme-helsa tbody tr:nth-child(even) {
    background-color: #FFFFFF;
    color: #000; }
  .theme-ptj tr.even, .theme-ptj tbody tr:nth-child(even) {
    background-color: #F3F3F3;
    color: #333333; }
  .theme-rjl tr.even, .theme-rjl tbody tr:nth-child(even) {
    background-color: #fff;
    color: #000; }
  .theme-achima tr.even, .theme-achima tbody tr:nth-child(even) {
    background-color: #fff;
    color: #000; }
  .theme-blekinge tr.even, .theme-blekinge tbody tr:nth-child(even) {
    background-color: #fff;
    color: #000; }
  .theme-sll tr.even, .theme-sll tbody tr:nth-child(even) {
    background-color: #ffffff;
    color: #000; }
  .theme-halland tr.even, .theme-halland tbody tr:nth-child(even) {
    background-color: #ffffff;
    color: #000; }
  .theme-orebro tr.even, .theme-orebro tbody tr:nth-child(even) {
    background-color: #ffffff;
    color: #000; }
  .theme-rg tr.even, .theme-rg tbody tr:nth-child(even) {
    background-color: #ffffff;
    color: #000; }

.table-hover tbody tr:hover.emergencyRow {
  cursor: pointer !important;
  font-style: normal; }
  .theme-default .table-hover tbody tr:hover.emergencyRow {
    background-color: #fff;
    color: #E32424; }
  .theme-helsa .table-hover tbody tr:hover.emergencyRow {
    background-color: #FFFFFF;
    color: #F48174; }
  .theme-ptj .table-hover tbody tr:hover.emergencyRow {
    background-color: #F3F3F3;
    color: #F54359; }
  .theme-rjl .table-hover tbody tr:hover.emergencyRow {
    background-color: #fff;
    color: #ce1141; }
  .theme-achima .table-hover tbody tr:hover.emergencyRow {
    background-color: #fff;
    color: #AA2B23; }
  .theme-blekinge .table-hover tbody tr:hover.emergencyRow {
    background-color: #fff;
    color: #d70480; }
  .theme-sll .table-hover tbody tr:hover.emergencyRow {
    background-color: #ffffff;
    color: #9a0932; }
  .theme-halland .table-hover tbody tr:hover.emergencyRow {
    background-color: #ffffff;
    color: #8E0000; }
  .theme-orebro .table-hover tbody tr:hover.emergencyRow {
    background-color: #ffffff;
    color: #E32424; }
  .theme-rg .table-hover tbody tr:hover.emergencyRow {
    background-color: #ffffff;
    color: #ee3780; }

tr.odd, tbody tr:nth-child(odd).emergencyRow {
  font-style: normal; }
  .theme-default tr.odd, .theme-default tbody tr:nth-child(odd).emergencyRow {
    background-color: #E32424;
    color: #fff; }
  .theme-helsa tr.odd, .theme-helsa tbody tr:nth-child(odd).emergencyRow {
    background-color: #F48174;
    color: #fff; }
  .theme-ptj tr.odd, .theme-ptj tbody tr:nth-child(odd).emergencyRow {
    background-color: #F54359;
    color: #fff; }
  .theme-rjl tr.odd, .theme-rjl tbody tr:nth-child(odd).emergencyRow {
    background-color: #ce1141;
    color: #fff; }
  .theme-achima tr.odd, .theme-achima tbody tr:nth-child(odd).emergencyRow {
    background-color: #AA2B23;
    color: #fff; }
  .theme-blekinge tr.odd, .theme-blekinge tbody tr:nth-child(odd).emergencyRow {
    background-color: #d70480;
    color: #fff; }
  .theme-sll tr.odd, .theme-sll tbody tr:nth-child(odd).emergencyRow {
    background-color: #9a0932;
    color: #fff; }
  .theme-halland tr.odd, .theme-halland tbody tr:nth-child(odd).emergencyRow {
    background-color: #8E0000;
    color: #fff; }
  .theme-orebro tr.odd, .theme-orebro tbody tr:nth-child(odd).emergencyRow {
    background-color: #E32424;
    color: #fff; }
  .theme-rg tr.odd, .theme-rg tbody tr:nth-child(odd).emergencyRow {
    background-color: #ee3780;
    color: #fff; }

tr.even, tbody tr:nth-child(even).emergencyRow {
  font-style: normal; }
  .theme-default tr.even, .theme-default tbody tr:nth-child(even).emergencyRow {
    background-color: #c81e1e;
    color: #fff; }
  .theme-helsa tr.even, .theme-helsa tbody tr:nth-child(even).emergencyRow {
    background-color: #d06b60;
    color: #fff; }
  .theme-ptj tr.even, .theme-ptj tbody tr:nth-child(even).emergencyRow {
    background-color: #d92a3f;
    color: #fff; }
  .theme-rjl tr.even, .theme-rjl tbody tr:nth-child(even).emergencyRow {
    background-color: #b1063a;
    color: #fff; }
  .theme-achima tr.even, .theme-achima tbody tr:nth-child(even).emergencyRow {
    background-color: #880000;
    color: #fff; }
  .theme-blekinge tr.even, .theme-blekinge tbody tr:nth-child(even).emergencyRow {
    background-color: #AC0366;
    color: #fff; }
  .theme-sll tr.even, .theme-sll tbody tr:nth-child(even).emergencyRow {
    background-color: #9a0932;
    color: #fff; }
  .theme-halland tr.even, .theme-halland tbody tr:nth-child(even).emergencyRow {
    background-color: #7c0000;
    color: #fff; }
  .theme-orebro tr.even, .theme-orebro tbody tr:nth-child(even).emergencyRow {
    background-color: #c81e1e;
    color: #fff; }
  .theme-rg tr.even, .theme-rg tbody tr:nth-child(even).emergencyRow {
    background-color: #f588b2;
    color: #fff; }

#contextMenu {
  position: absolute;
  display: none; }

.dropdown-submenu {
  position: relative; }

.dropdown-submenu > .dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: -6px;
  margin-left: -1px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px; }

.dropdown-submenu:hover > .dropdown-menu {
  display: block; }

.dropdown-submenu > a:after {
  display: block;
  content: " ";
  float: right;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  border-width: 5px 0 5px 5px;
  margin-top: 5px;
  margin-right: -10px; }
  .theme-default .dropdown-submenu > a:after {
    border-left-color: #D0F8F5; }
  .theme-helsa .dropdown-submenu > a:after {
    border-left-color: #EBF5EC; }
  .theme-ptj .dropdown-submenu > a:after {
    border-left-color: #fff; }
  .theme-rjl .dropdown-submenu > a:after {
    border-left-color: #EDEDED; }
  .theme-achima .dropdown-submenu > a:after {
    border-left-color: #EDEDED; }
  .theme-blekinge .dropdown-submenu > a:after {
    border-left-color: #E5F6FC; }
  .theme-sll .dropdown-submenu > a:after {
    border-left-color: #E5F1F8; }
  .theme-halland .dropdown-submenu > a:after {
    border-left-color: #D3E6F5; }
  .theme-orebro .dropdown-submenu > a:after {
    border-left-color: #D1E9F2; }
  .theme-rg .dropdown-submenu > a:after {
    border-left-color: #DCF1DA; }

.theme-default .dropdown-submenu:hover > a:after {
  border-left-color: #D0F8F5; }

.theme-helsa .dropdown-submenu:hover > a:after {
  border-left-color: #EBF5EC; }

.theme-ptj .dropdown-submenu:hover > a:after {
  border-left-color: #fff; }

.theme-rjl .dropdown-submenu:hover > a:after {
  border-left-color: #EDEDED; }

.theme-achima .dropdown-submenu:hover > a:after {
  border-left-color: #EDEDED; }

.theme-blekinge .dropdown-submenu:hover > a:after {
  border-left-color: #E5F6FC; }

.theme-sll .dropdown-submenu:hover > a:after {
  border-left-color: #E5F1F8; }

.theme-halland .dropdown-submenu:hover > a:after {
  border-left-color: #D3E6F5; }

.theme-orebro .dropdown-submenu:hover > a:after {
  border-left-color: #D1E9F2; }

.theme-rg .dropdown-submenu:hover > a:after {
  border-left-color: #DCF1DA; }

.dropdown-submenu.pull-left {
  float: none; }

.dropdown-submenu.pull-left > .dropdown-menu {
  left: -100%;
  margin-left: 10px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px; }

div .table.dataTable {
  margin-top: 50px !important; }
