@import "themes.scss";
@import "mixins.scss";

$card-radius: 0px;
$textSection-radius: 10px;
$textarea-radius: 0px;

/*-----------------------------  Body ----------------------------*/

body .patientcontact .admin {
  @include themify($themes) {
    font-size: 16px;
  }
}

/*-----------------------------  Card ---------------------------*/
.card {
  @include themify($themes) {
    background-color: themed("admin-panel-body-bg-color");
  }
  -webkit-box-shadow: 0 2px 6px -6px #000;
  -moz-box-shadow: 0 2px 6px -6px #000;
  box-shadow: 0 2px 6px -6px #000;
  margin-bottom: 16px;
}

.card.default .card-header,
.card.default .card-body,
.card.default,
.card.default .card-footer {
  @include themify($themes) {
    border: 1px solid;
    border-color: themed("admin-panel-body-bg-color");
    background-color: themed("admin-panel-body-bg-color");
  }
}

.card.disabled .card-header,
.card.disabled .card-body {
  @include themify($themes) {
    background-color: themed("disabled-color");
    color: themed("disabled-color-supplement");
  }
}

.card .card-footer {
  margin: 20px -15px -15px;
}

.card.default .card-header,
.card.default .card-footer {
  @include themify($themes) {
    background-color: themed("admin-panel-heading-bg-color");
    padding-bottom: 0px;
  }
}

.card .card-header h5 {
  @include themify($themes) {
    color: themed("admin-panel-heading-text-color");
  }
}

.card.default .card-header h4 {
  @include themify($themes) {
    color: themed("admin-panel-heading-text-color");
  }
}

.card.disabled .card-header h4 {
  @include themify($themes) {
    color: themed("disabled-color-supplement");
  }
}

.card-header:first-child {
  border-radius: $card-radius $card-radius 0 0;
}

.card-body {
  border-radius: $card-radius;
}

.card-body label {
  font-weight: bold;
  margin-bottom: 0;
}

.card-body .blackText {
  color: #000;
}

.cardLabel {
  margin: 5px 15px 0px 15px;
}

.card-body h5 {
  font-size: 18px;
}

.navMenu {
  color: #f3f3f3;
}

/*---------------------------  Textarea --------------------------*/
.patientcontact .admin textarea,
.patientcontact .admin input[type="text"],
.patientcontact .admin input {
  @include themify($themes) {
    border-radius: $textarea-radius;
    border: 1px solid themed("primary-color");
    color: themed("primary-color");
    background-color: themed("admin-input-bg-color");
    font-size: 16px;
    padding: 2px 8px;
  }
}

.patientcontact .admin textarea:focus,
.patientcontact input[type="text"]:focus,
.patientcontact .admin input:focus {
  @include themify($themes) {
    border: 2px solid themed("primary-color");
    color: themed("primary-color");
    background-color: themed("admin-input-bg-color");
    padding: 1px 7px;
    box-shadow: none;
    outline: none;
  }
}

.DayPickerInput input,
.numberInput input {
  width: 100%;
  max-width: 190px;
}

// @media screen and (max-width: 992px) {
//     .DayPickerInput input, .numberInput input{
//     width: 100%;
//         max-width: 190px;
//     }
// }

.patientcontact .admin .question-block {
  @include themify($themes) {
    background-color: themed("symptom-btn-background-color");
    color: themed("symptom-btn-text-color");
  }
}

.patientcontact .admin .question-block div {
  padding-left: 0;
  padding-right: 0;
}

.patientcontact .admin textarea.highlight {
  @include themify($themes) {
    background-color: themed("symptom-btn-background-color");
    color: themed("symptom-btn-text-color");
  }
}

.patientcontact .admin textarea.highlight:focus {
  @include themify($themes) {
    background-color: themed("symptom-btn-background-color");
    color: themed("symptom-btn-text-color");
  }
}

.asterisk {
  @include themify($themes) {
    color: themed("danger-color");
    font-weight: bold;
    margin-right: 5px;
  }
}

.patientcontact .warning {
  @include themify($themes) {
    color: themed("danger-color");
  }
}

.patientcontact .admin textarea.straightRightTopCorner {
  border-top-right-radius: 0;
}

.patientcontact .admin .btn.copy-btn {
  padding: 0.375rem 0.75rem;
  font-size: 16px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  margin-bottom: -2px;
  margin-right: 0;
}

.patientcontact .admin .diagnoseRow .btn.copy-btn {
  padding: 0.375rem 0.75rem;
  font-size: 16px;
  border-radius: 50px;
  margin-bottom: -2px;
  margin-right: 0;
  float: right;
}

.admin .diagnosCopyDiv {
  padding-left: 0;
  padding-right: 0;
  margin-left: -15px;
}

.patientcontact .admin .diagnoseRow .diagnoseSection {
  padding: 2px 8px;
}

.card .card-body .textSection {
  @include themify($themes) {
    background-color: themed("light-color-supplement");
    border: 1px solid themed("primary-color");
  }
  border-radius: $textSection-radius;
  border-top-right-radius: 0;
  padding: 15px;
  margin: 0 15px;
}

.preWrapText {
  white-space: pre-line;
}

p.whiteText {
  color: white;
}

ul.pagination a.page-link {
  min-width: 38px;
}

/*----------------------------  Lists ---------------------------*/

.list {
  list-style: none;
}

.fileList {
  list-style: none;
}

.fileList ul {
  list-style: none;
  padding-left: 0px;
}

.list .listItem::before {
  @include themify($themes) {
    color: themed("primary-color");
  }
  content: "\2022";
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

/*----------------------------  Fonts ---------------------------*/

.consentLabel {
  margin-left: 5px;
  font-weight: bold;
}

.messageSentLabel {
  margin-top: 8px;
  padding-top: 1px;
}

@media screen and (min-width: 576px) {
  .choseTeamMember {
    margin-top: 8px;
    text-align: right;
  }
}

.smallText {
  font-size: 12px;
}

/*----------------------------  Forms ---------------------------*/
.well .input-one-line {
  @include themify($themes) {
    background-color: themed("light-color");
    border-color: themed("secondary-color");
    margin-bottom: 0.5em;
  }
}

.well .small-textarea {
  @include themify($themes) {
    width: 100%;
    background-color: themed("light-color");
    border-color: themed("secondary-color");
    margin-bottom: 0.5em;
  }
}

/*-----------------------------  Buttons ----------------------------*/
.fileUpload input.upload {
  @include themify($themes) {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    padding: 0;
    font-size: 20px;
    cursor: pointer;
    opacity: 0;
    filter: alpha(opacity=0);
    color: themed("primary-color");
  }
}

.btn.btn-symptom-btn {
  @include themify($themes) {
    margin-top: 8px;
    font-size: 16px;
    color: themed("primary-color");
  }
}

.btn.btn-success {
  @include themify($themes) {
    margin-top: 8px;
    font-size: 16px;
  }
}

.btn.btn-video {
  @include themify($themes) {
    margin-top: 8px;
    font-size: 16px;
  }
}

.btn.small-btn {
  @include themify($themes) {
    border-radius: 50%;
    font-size: 16px;
    margin: 0 auto;
    padding: 0px;
    text-align: center;
    width: 24px;
    height: 24px;
  }
}

.btn.btn-modal {
  @include themify($themes) {
    margin-top: 0px;
    font-size: 16px;
  }
}

/*-----------------------------  Manage Menu ----------------------------*/

.manage-btn {
  @include themify($themes) {
    border: 1px solid transparent;
    background: none;
    display: block;
    font-size: 16px;
    line-height: 16px;
    margin: 0 16px;
    padding: 3px;
    text-align: center;
    width: 24px;
    height: 24px;
  }
}

.manage-btn:before {
  color: black;
}

.dropdown button.manage-btn:hover,
.dropdown button.manage-btn:active,
.dropdown button.manage-btn:focus,
.dropdown button.manage-btn:focus:active {
  @include themify($themes) {
    border: 1px solid themed("primary-color");
    border-radius: 50%;
    color: themed("primary-color");
    background: none;
    box-shadow: none;
  }
}

/*-----------------------------  DropDown Menu ----------------------------*/
.dropdown-item:hover {
  @include themify($themes) {
    background-color: themed("list-color-odd-row");
  }
}

.status-dropdown:hover {
  @include themify($themes) {
    background-color: themed("light-color");
  }
}

.dropdown-toggle:after {
  display: none;
}

.chooseCareCenter {
  margin-top: 8px;
  text-align: left;
}

/*----------------------------  Switch/toggle button ---------------------------*/

.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 25px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 17px;
  width: 17px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input + .slider {
  @include themify($themes) {
    background-color: themed("primary-color");
  }
}

input:checked + .slider:before {
  -webkit-transform: translateX(25px);
  -ms-transform: translateX(25px);
  transform: translateX(25px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.toggleLabelLeft {
  padding-top: 2px;
  padding-right: 5px;
}

.toggleLabelRight {
  padding-top: 2px;
  padding-left: 5px;
}

.toggleCloser {
  margin-bottom: -15px;
}

/*----------------------------  Other ---------------------------*/
.topMargin-xs {
  margin-top: 3px;
}

.filterIcon {
  cursor: pointer;
}

.rightMargin-md {
  margin-right: 10px;
}

.react-datepicker__input-container {
  cursor: pointer;
}

.overflowText {
  max-width: 100px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

/*----------------------------  Chat ---------------------------*/

.chatMessage {
  margin-bottom: 10px;
}

.currentSender {
  @include themify($themes) {
    background-color: themed("symptom-btn-border-color");
    color: black;
    padding: 15px;
    font-size: 15px;
    white-space: pre-line;
    border-radius: 15px 0px 15px 15px;
  }
}

.currentReceiver {
  @include themify($themes) {
    background-color: themed("footer-bg-color");
    color: black;
    padding: 15px;
    font-size: 15px;
    white-space: pre-line;
    border-radius: 0px 15px 15px 15px;
  }
}

.currentReceiverName {
  @include themify($themes) {
    padding-left: 5px;
  }
}

.messageTextBox {
  @include themify($themes) {
    background-color: white;
  }
}

.messageTextBox:focus {
  @include themify($themes) {
    background-color: white;
    border-color: themed("primary-color");
  }
}

.chatContainer {
  padding: 10px 10px 0px 10px;
  height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
  border-bottom: 1px solid #ccc;
}

.paddingRight10 {
  padding-right: 10px;
}

.btn-closechat {
  position: absolute;
  left: 15px;
}

.btn-sm {
  padding: 3px 7px 3px 7px;
}

.videolinkmessage {
  padding-bottom: 20px;
  margin-bottom: 0px;
  margin-top: 0px;
}

.attachment-section {
  @include themify($themes) {
    background-color: themed("question-block-bg-color");
    border: solid 0px;
    border-color: themed("primary-color");
    padding: 4px;
    margin-top: 8px;
    box-shadow: rgba(0, 0, 0, 0.05) 2.4px 2.4px 3.2px;
    overflow: hidden;
  }
}

.attachment-text {
  width: max-content;
}

label.uploadFileBtn {
  font-weight: normal;
}

.loading {
  opacity: 0.5;
}

/*---------------------------- navbar bottom ---------------------------*/

.navbar-bottom {
  @include themify($themes) {
    background-color: themed("second-header-bg-color");
    border: 1px solid;
    border-color: themed("second-bottom-border-color");
  }
}

.paddingLeft20 {
  padding-left: 20px;
}

.paddingRight20 {
  padding-right: 20px;
}

.marginBottom {
  margin-bottom: 60px;
}

.paddingTop5 {
  padding-top: 5px;
}

.paddingTop25 {
  padding-top: 25px;
}

@media screen and (min-width: 991px) {
  .hiddenXl {
    display: none;
  }
}

.createIssueText {
  @media screen and (max-width: 1200px) {
    padding-top: 25px;
  }
  @media screen and (min-width: 991px) {
    padding-top: 83px;
  }
}

.validationText {
  @include themify($themes) {
    color: themed("primary-color");
  }
}

/*---------------------------- tooltip ---------------------------*/

.tippy-box[data-theme~="tippy"] {
  @include themify($themes) {
    background-color: themed("primary-color");
  }
  white-space: pre-wrap;
  max-width: 100px;
  overflow-wrap: break-word;
}

.tippy-box[data-theme~="tippy"][data-placement^="left"] > .tippy-arrow::before {
  @include themify($themes) {
    border-left-color: themed("primary-color");
  }
}

/*---------------------------- react daypicker ---------------------------*/

.react-datepicker-wrapper
  .react-datepicker__input-container
  .react-datepicker__close-icon {
  right: -30px;
}

//below needed bcs https://github.com/Hacker0x01/react-datepicker/issues/3924
.react-datepicker__aria-live {
  position: absolute;
  clip-path: circle(0);
  border: 0;
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  width: 1px;
  white-space: nowrap;
}

/*---------------------------- Current User Intials ---------------------------*/

.currentUserInitials {
  @include themify($themes) {
    padding: 0px 4px;
    display: inline-block;
    border-radius: 8px;
    font-weight: bold;
  }
}

/*---------------------------- Statistics ---------------------------*/

.tabcontainer {
  width: 80%;
}

.timecon {
  float: right;
}

.tabcontainer .nav-link {
  font-weight: 600;
  color: #7a7b7c;
  border-color: #e1e1e1;
}

.tabcontainer .nav-link.disabled {
  font-weight: 600;
  color: #e1e1e1;
  border-color: #e1e1e1;
}

.nav-tabs {
  border-bottom: none;
}

.tabcontainer .nav-link.active {
  @include themify($themes) {
    color: themed("primary-color");
  }
  background-color: #e1e1e1;
  border: 1px solid #e1e1e1;
}

.statisticsLabel {
  position: absolute;
  margin: 15px 0px 0px 15px;
}

.statisticsLabelRight {
  right: 0%;
  position: absolute;
  margin: 20px 50px 0px 0px;
  font-size: 15px;
}

.react-datepicker__input-container input[type="text"] {
  border: 1px solid #e1e1e1;
  color: #0a4a7c;
  background-color: #fff;
  padding: 1px 7px;
  box-shadow: none;
  outline: none;
}

.rangeDiv {
  z-index: 100;
  margin-bottom: -35px;
}

.rc-slider-track {
  @include themify($themes) {
    background-color: themed("primary-color");
  }
}

.rc-slider-handle,
.rc-slider-handle:active,
.rc-slider-handle:hover,
.rc-slider-handle-1:active,
.rc-slider-handle-2:active,
.rc-slider-handle-click-focused:focus,
.rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
  @include themify($themes) {
    background-color: themed("primary-color");
    border: 1px solid themed("primary-color");
  }
  box-shadow: none;
}

.rc-slider-handle .rc-slider-handle-1 {
  width: 20px;
  height: 20px;
}

/*---------------------------- Statistics ---------------------------*/

.parentDataDiv {
  display: flex;
  flex: 1;
  justify-content: space-between;
  gap: 10px;
}

.parentData {
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  border: 1px solid lightgray;
  padding: 10px;
  width: 50%;

  &:hover {
    cursor: pointer;
    @include themify($themes) {
      background-color: themed("question-block-bg-color");
      border: 1px solid themed("question-block-border-color");
      box-shadow: 1px 1px 5px lightgray;
    }
  }

  &.selectedParent {
    @include themify($themes) {
      background-color: themed("question-block-bg-color");
      border: 1px solid themed("question-block-border-color");
      box-shadow: 1px 1px 5px lightgray;
    }
  }
}
