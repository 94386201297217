/* SASS variables and intellisense */

/*---------------------------------------------------------------------------
                               SECOND HEADER
---------------------------------------------------------------------------*/
.secondHeader-rightElement, .secondHeader-leftElement {
  margin-top: -28px; }

.section-head {
  margin-bottom: 24px; }

.secondHeader {
  z-index: 11;
  top: 50px;
  position: fixed;
  left: 0px;
  right: 0px;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 8px;
  margin: 0 auto; }
  .theme-default .secondHeader {
    background-color: #F1F1F1;
    border-bottom: 1px solid #CCC; }
  .theme-helsa .secondHeader {
    background-color: #D7EAD9;
    border-bottom: 1px solid #6CB680; }
  .theme-ptj .secondHeader {
    background-color: #0075B0;
    border-bottom: 1px solid #ffffff; }
  .theme-rjl .secondHeader {
    background-color: #579835;
    border-bottom: 1px solid #579835; }
  .theme-achima .secondHeader {
    background-color: #EDEDED;
    border-bottom: 1px solid #EDEDED; }
  .theme-blekinge .secondHeader {
    background-color: #E5F6FC;
    border-bottom: 1px solid #E5F6FC; }
  .theme-sll .secondHeader {
    background-color: #00aeef;
    border-bottom: 1px solid #00aeef; }
  .theme-halland .secondHeader {
    background-color: #D1E6F5;
    border-bottom: 1px solid #6DACDE; }
  .theme-orebro .secondHeader {
    background-color: #004F9F;
    border-bottom: 1px solid #0b1d32; }
  .theme-rg .secondHeader {
    background-color: #F6F6F6;
    border-bottom: 0px solid #cccccc; }

.secondHeader h1, .secondHeader h2, .secondHeader h3, .secondHeader h4, .secondHeader h5, .secondHeader h6 {
  text-align: center; }
  .theme-default .secondHeader h1, .theme-default .secondHeader h2, .theme-default .secondHeader h3, .theme-default .secondHeader h4, .theme-default .secondHeader h5, .theme-default .secondHeader h6 {
    color: #0A4A7C; }
  .theme-helsa .secondHeader h1, .theme-helsa .secondHeader h2, .theme-helsa .secondHeader h3, .theme-helsa .secondHeader h4, .theme-helsa .secondHeader h5, .theme-helsa .secondHeader h6 {
    color: #1D5E74; }
  .theme-ptj .secondHeader h1, .theme-ptj .secondHeader h2, .theme-ptj .secondHeader h3, .theme-ptj .secondHeader h4, .theme-ptj .secondHeader h5, .theme-ptj .secondHeader h6 {
    color: #ffffff; }
  .theme-rjl .secondHeader h1, .theme-rjl .secondHeader h2, .theme-rjl .secondHeader h3, .theme-rjl .secondHeader h4, .theme-rjl .secondHeader h5, .theme-rjl .secondHeader h6 {
    color: #fff; }
  .theme-achima .secondHeader h1, .theme-achima .secondHeader h2, .theme-achima .secondHeader h3, .theme-achima .secondHeader h4, .theme-achima .secondHeader h5, .theme-achima .secondHeader h6 {
    color: #880000; }
  .theme-blekinge .secondHeader h1, .theme-blekinge .secondHeader h2, .theme-blekinge .secondHeader h3, .theme-blekinge .secondHeader h4, .theme-blekinge .secondHeader h5, .theme-blekinge .secondHeader h6 {
    color: #000; }
  .theme-sll .secondHeader h1, .theme-sll .secondHeader h2, .theme-sll .secondHeader h3, .theme-sll .secondHeader h4, .theme-sll .secondHeader h5, .theme-sll .secondHeader h6 {
    color: #ffffff; }
  .theme-halland .secondHeader h1, .theme-halland .secondHeader h2, .theme-halland .secondHeader h3, .theme-halland .secondHeader h4, .theme-halland .secondHeader h5, .theme-halland .secondHeader h6 {
    color: #000; }
  .theme-orebro .secondHeader h1, .theme-orebro .secondHeader h2, .theme-orebro .secondHeader h3, .theme-orebro .secondHeader h4, .theme-orebro .secondHeader h5, .theme-orebro .secondHeader h6 {
    color: #ffffff; }
  .theme-rg .secondHeader h1, .theme-rg .secondHeader h2, .theme-rg .secondHeader h3, .theme-rg .secondHeader h4, .theme-rg .secondHeader h5, .theme-rg .secondHeader h6 {
    color: #000; }

.theme-default .secondHeader p, .theme-default .secondHeader span, .theme-default .secondHeader label {
  color: #000; }

.theme-helsa .secondHeader p, .theme-helsa .secondHeader span, .theme-helsa .secondHeader label {
  color: #000000; }

.theme-ptj .secondHeader p, .theme-ptj .secondHeader span, .theme-ptj .secondHeader label {
  color: #ffffff; }

.theme-rjl .secondHeader p, .theme-rjl .secondHeader span, .theme-rjl .secondHeader label {
  color: #fff; }

.theme-achima .secondHeader p, .theme-achima .secondHeader span, .theme-achima .secondHeader label {
  color: #000; }

.theme-blekinge .secondHeader p, .theme-blekinge .secondHeader span, .theme-blekinge .secondHeader label {
  color: #000; }

.theme-sll .secondHeader p, .theme-sll .secondHeader span, .theme-sll .secondHeader label {
  color: #ffffff; }

.theme-halland .secondHeader p, .theme-halland .secondHeader span, .theme-halland .secondHeader label {
  color: #000; }

.theme-orebro .secondHeader p, .theme-orebro .secondHeader span, .theme-orebro .secondHeader label {
  color: #ffffff; }

.theme-rg .secondHeader p, .theme-rg .secondHeader span, .theme-rg .secondHeader label {
  color: #000; }

@media screen and (max-width: 768px) {
  .secondHeader-patientInfo {
    text-align: right; }
  .patientcontact .secondHeader-patientInfo {
    text-align: center; } }

@media screen and (min-width: 768px) {
  .secondHeader-patientInfo, .patientcontact .secondHeader-patientInfo {
    text-align: center; } }

.theme-default .languageHeader {
  background-color: #D0F8F5;
  border-bottom: 1px solid #A0F0EB; }

.theme-helsa .languageHeader {
  background-color: #D7EAD9;
  border-bottom: 1px solid #BEDCC1; }

.theme-ptj .languageHeader {
  background-color: #F3F3F3;
  border-bottom: 5px solid #CCCCCC; }

.theme-rjl .languageHeader {
  background-color: #EDEDED;
  border-bottom: 1px solid #EDEDED; }

.theme-achima .languageHeader {
  background-color: #EDEDED;
  border-bottom: 1px solid #EDEDED; }

.theme-blekinge .languageHeader {
  background-color: #E5F6FC;
  border-bottom: 1px solid #E5F6FC; }

.theme-sll .languageHeader {
  background-color: #FFFFFF;
  border-bottom: 3px solid #FFFFFF; }

.theme-halland .languageHeader {
  background-color: #D3E6F5;
  border-bottom: 3px solid #6DACDE; }

.theme-orebro .languageHeader {
  background-color: #E3F2F8;
  border-bottom: 2px solid #C8E1EB; }

.theme-rg .languageHeader {
  background-color: #F6F6F6;
  border-bottom: 1px solid #ccc; }

.languageHeaderBottomMargin {
  margin-bottom: 36px; }

.languageHeader {
  position: absolute;
  width: 100%;
  left: 0;
  margin-top: -30px; }

@media screen and (max-width: 768px) {
  .languageHeader {
    margin-top: -15px; } }
